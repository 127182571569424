import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import BackButton from "./../components/backButton";
import { Icon, Input, Textarea, Button } from "native-base";
import AddressNicknameButton from "./../components/addressNickName";
import axios from "axios";
import { API_URL } from "../constants";
import Toast from "./../components/toast";
import { performRequest } from "./../services/apiHandler";
import { backToProfile } from "./../store/actions";
import { FontLetterSpacing } from "./../handlers/utils";
import StyledText from "../components/styledText";
import i18n from "../i18n";
class AddressForm extends React.Component {
  static navigationOptions = {
    header: null,
    gesturesEnabled: false,
    animationEnabled: true,
  };
  throwMessageFn = (message, status) => {
    this.setState({
      message_status: status,
      error_message: message,
      message_display: "inherit",
    });

    setTimeout(
      function () {
        this.setState({
          message_status: "",
          error_message: "",
          message_display: "none",
        });
      }.bind(this),
      3000
    );
  };
  state = {
    error_message: "",
    message_status: "",
    message_display: "none",

    activeAddressNick: 1,
    activeNickTextColor: "white",
    activeNickBackgroundColor: "blue",

    address: "",
    instructions: "",
    street: "",
    building: "",
  };

  componentDidMount() {
    // console.log(this.props.location, 'llllll')
  }
  addAddress = () => {
    if (!this.state.address || !this.state.street || !this.state.building) {
      this.throwMessageFn("Building, Street and Address is required", "red");
      return;
    }
    const { state, setParams, navigate } = this.props.navigation;
    const params = state.params || {};
    let data = {
      venue: this.props.config.venue,
      user: this.props.user.id,
      address_nickname: this.state.activeAddressNick,
      default_address: false,
      complete_address: this.state.address,
      instructions: this.state.instructions,
      location: JSON.stringify(this.props.location),
      building: this.state.building,
      street: this.state.street,
    };

    performRequest("post", "api/v2/pwa/address/", (data = data))
      .then(async (response) => {
        this.throwMessageFn("Address added", "green");
        try {
          await params.loadAddress();
        } catch (error) {
          console.log(error, "errrr");
        }
        if (this.props.is_from_profile_screen) {
          this.props.backToProfile(false);
          this.props.navigation.navigate("AddressScreen");
          return;
        }

        this.props.navigation.navigate("CheckoutScreen");
      })
      .catch((error) => {
        this.throwMessageFn("Something went wrong. Try again!", "red");
      });
  };
  returnHere = () => this.props.navigation.navigate("AddressFormScreen");

  changeLocation = () => {
    this.props.navigation.navigate("selectDestinationScreen", {
      returnHere: this.returnHere,
    });
  };
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: this.props.design.list_view_background_color,
        //   direction: this.props.isRTL && "rtl",
        }}
      >
        {
            console.log(this.props.isRTL,this.props.default_language,"ooooooooooooooo")
        }
        <StyledText
          font_color={this.props.design.list_view_header_color}
          font_weight={this.props.design.list_view_header_font_weight}
          font_style={this.props.design.list_view_header_font_style}
          text_transform={this.props.design.list_view_header_font_transform}
          font_family={this.props.design.list_view_header_font_family}
          font_size={this.props.design.list_view_header_font_size ?? 20}
          letter_spacing={FontLetterSpacing(
            this.props.design.custom_font.primary_letter_space
          )}
          style={{
            marginTop: 80,
            marginVertical: 20,
            marginHorizontal: 11,
            textAlign: this.props.isRTL ? "right" : "left",
          }}
        >
          {i18n.t("labels.your_deli_address")}
        </StyledText>

        <View
          style={{
            width: "96%",
            alignSelf: "center",
            direction: this.props.isRTL && "rtl",
          }}
        >
          <StyledText
            font_color={this.props.design.list_view_text_color}
            font_weight={this.props.design.list_view_text_font_weight}
            font_style={this.props.design.list_view_text_font_style}
            text_transform={this.props.design.list_view_text_font_transform}
            font_family={this.props.design.list_view_text_font_family}
            font_size={this.props.design.list_view_text_font_size ?? 12}
            letter_spacing={FontLetterSpacing(
              this.props.design.custom_font.primary_letter_space
            )}
            style={{textAlign: this.props.isRTL ? "right" : "left",}}
          >
            {i18n.t("labels.area")} *
          </StyledText>
          <View
            style={{
              height: 35,
              marginTop: 5,
              marginBottom: 20,
              direction: this.props.isRTL && "rtl",
            }}
          >
            <TouchableOpacity onPress={() => this.changeLocation()}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginHorizontal: 7,
                }}
              >
                <StyledText
                  font_color={this.props.design.list_view_text_color}
                  font_weight={this.props.design.list_view_text_font_weight}
                  font_style={this.props.design.list_view_text_font_style}
                  text_transform={
                    this.props.design.list_view_text_font_transform
                  }
                  font_family={this.props.design.list_view_text_font_family}
                  font_size={this.props.design.list_view_text_font_size ?? 20}
                  letter_spacing={FontLetterSpacing(
                    this.props.design.custom_font.primary_letter_space
                  )}
                  style={{textAlign: this.props.isRTL ? "right" : "left",}}
                >
                  {this.props.location.label}
                </StyledText>
                <Icon
                  type="Ionicons"
                  name="ios-arrow-forward"
                  style={{ fontSize: 25, color: this.props.color }}
                />
              </View>
            </TouchableOpacity>
          </View>
          <StyledText
            font_color={this.props.design.list_view_text_color}
            font_weight={this.props.design.list_view_text_font_weight}
            font_style={this.props.design.list_view_text_font_style}
            text_transform={this.props.design.list_view_text_font_transform}
            font_family={this.props.design.list_view_text_font_family}
            font_size={this.props.design.list_view_text_font_size ?? 20}
            letter_spacing={FontLetterSpacing(
              this.props.design.custom_font.primary_letter_space
            )}
            style={{textAlign: this.props.isRTL ? "right" : "left",}}
          >
            {i18n.t("labels.building")} *
          </StyledText>
          <View
            style={{
              marginTop: 5,
              marginBottom: 20,
              direction: this.props.isRTL && "rtl",
            }}
          >
            <Textarea
              rowSpan={1}
              bordered
              maxLength={300}
              value={this.state.building}
              onChangeText={(text) => this.setState({ building: text })}
              style={{
                // fontSize:30,
                color: "grey",
                border: "1px solid grey",
                borderRadius: 5,
                fontFamily: "SecondaryFont",
              }}
            />
          </View>
          <StyledText
            font_color={this.props.design.list_view_text_color}
            font_weight={this.props.design.list_view_text_font_weight}
            font_style={this.props.design.list_view_text_font_style}
            text_transform={this.props.design.list_view_text_font_transform}
            font_family={this.props.design.list_view_text_font_family}
            font_size={this.props.design.list_view_text_font_size ?? 20}
            letter_spacing={FontLetterSpacing(
              this.props.design.custom_font.primary_letter_space
            )}
            style={{textAlign: this.props.isRTL ? "right" : "left",}}
          >
            {i18n.t("labels.street")} *
          </StyledText>
          <View style={{ marginTop: 5, marginBottom: 20 }}>
            <Textarea
              rowSpan={1}
              bordered
              maxLength={300}
              value={this.state.street}
              onChangeText={(text) => this.setState({ street: text })}
              style={{
                // fontSize:30,
                color: "grey",
                border: "1px solid grey",
                borderRadius: 5,
                fontFamily: "SecondaryFont",
              }}
            />
          </View>
          <StyledText
            font_color={this.props.design.list_view_text_color}
            font_weight={this.props.design.list_view_text_font_weight}
            font_style={this.props.design.list_view_text_font_style}
            text_transform={this.props.design.list_view_text_font_transform}
            font_family={this.props.design.list_view_text_font_family}
            font_size={this.props.design.list_view_text_font_size ?? 20}
            letter_spacing={FontLetterSpacing(
              this.props.design.custom_font.primary_letter_space
            )}
            style={{textAlign: this.props.isRTL ? "right" : "left",}}
          >
            {i18n.t("labels.complete_address")} *
          </StyledText>
          <View style={{ marginTop: 5, marginBottom: 20 }}>
            <Textarea
              rowSpan={5}
              bordered
              maxLength={300}
              value={this.state.address}
              onChangeText={(text) => this.setState({ address: text })}
              style={{
                // fontSize:30,
                color: "grey",
                border: "1px solid grey",
                borderRadius: 5,
                fontFamily: "SecondaryFont",
              }}
            />
          </View>

          <StyledText
            font_color={this.props.design.list_view_text_color}
            font_weight={this.props.design.list_view_text_font_weight}
            font_style={this.props.design.list_view_text_font_style}
            text_transform={this.props.design.list_view_text_font_transform}
            font_family={this.props.design.list_view_text_font_family}
            font_size={this.props.design.list_view_text_font_size ?? 20}
            letter_spacing={FontLetterSpacing(
              this.props.design.custom_font.primary_letter_space
            )}
            style={{textAlign: this.props.isRTL ? "right" : "left",}}
          >
            {i18n.t("labels.address_nickname")} *
          </StyledText>
          <View
            style={{
              height: 35,
              marginTop: 5,
              marginBottom: 20,
              flexDirection: "row",
            }}
          >
            <AddressNicknameButton
              {...this.state}
              text={i18n.t("labels.home")}
              isActive={this.state.activeAddressNick == 1}
              pressedNick={() => this.setState({ activeAddressNick: 1 })}
            />
            <AddressNicknameButton
              {...this.state}
              text={i18n.t("labels.office")}
              isActive={this.state.activeAddressNick == 2}
              pressedNick={() => this.setState({ activeAddressNick: 2 })}
            />
            <AddressNicknameButton
              {...this.state}
              text={i18n.t("labels.other")}
              isActive={this.state.activeAddressNick == 3}
              pressedNick={() => this.setState({ activeAddressNick: 3 })}
            />
          </View>
        </View>

        <Button
          onPress={this.addAddress}
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
            position: "absolute",
            bottom: 6,
            width: "98%",
            alignSelf: "center",
            backgroundColor: this.props.design.top_buttons_background_color,
          }}
        >
          <StyledText
            font_color={this.props.design.top_buttons_text_color}
            font_weight={this.props.design.top_button_text_font_weight}
            font_style={this.props.design.top_button_text_font_style}
            text_transform={this.props.design.top_button_text_font_transform}
            font_family={this.props.design.top_button_text_font_family}
            font_size={this.props.design.top_button_text_font_size}
            letter_spacing={FontLetterSpacing(
              this.props.design.custom_font.primary_letter_space
            )}
            //  font_color={this.props.design.list_view_text_color}
            //  font_weight={this.props.design.list_view_text_font_weight}
            //  font_style={this.props.design.list_view_text_font_style}
            //  text_transform={this.props.design.list_view_text_font_transform}
            //  font_family={this.props.design.list_view_text_font_family}
            //  font_size={this.props.design.list_view_text_font_size?? 20}
            //  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

            style={{}}
          >
            {" "}
            {i18n.t("labels.continue")}{" "}
          </StyledText>
        </Button>

        <TouchableOpacity
          style={{ position: "absolute", top: 0 }}
          onPress={() => this.props.navigation.goBack()}
        >
          <BackButton
            border_color={`1px solid ${this.props.design.top_button_border_color}`}
            // goBack={() => this.props.navigation.goBack()}
            backgroundColor={this.props.design.top_buttons_background_color}
            borderColor={"black"}
            color={this.props.design.top_buttons_text_color}
            default_language={this.props.default_language}
            font_size={this.props.design.custom_font.heading_size}
          />
        </TouchableOpacity>

        <Toast
          error_message={this.state.error_message}
          message_status={this.state.message_status}
          message_display={this.state.message_display}
          custom_font={this.props.design.custom_font}
        />
      </View>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    config: state.config.config,
    location: state.location.location,
    number: state.number.number,
    user: state.number.user,
    is_from_profile_screen: state.number.backToProfile,
    design: state.config.design,
    isRTL: state.languages.isRTL,
    default_language: state.languages.default_language
  };
};

export default connect(mapStateToProps, { backToProfile })(AddressForm);
