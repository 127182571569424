'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var AssessmentsList;
var AssessmentsPage;
var AssessmentsInstance;
var AssessmentsContext;

/* jshint ignore:start */
/**
 * Initialize the AssessmentsList
 *
 * @constructor Twilio.FlexApi.V1.AssessmentsList
 *
 * @param {Twilio.FlexApi.V1} version - Version of the resource
 */
/* jshint ignore:end */
AssessmentsList = function AssessmentsList(version) {
  /* jshint ignore:start */
  /**
   * @function assessments
   * @memberof Twilio.FlexApi.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.FlexApi.V1.AssessmentsContext}
   */
  /* jshint ignore:end */
  function AssessmentsListInstance(sid) {
    return AssessmentsListInstance.get(sid);
  }

  AssessmentsListInstance._version = version;
  // Path Solution
  AssessmentsListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a assessments
   *
   * @function get
   * @memberof Twilio.FlexApi.V1.AssessmentsList#
   *
   * @returns {Twilio.FlexApi.V1.AssessmentsContext}
   */
  /* jshint ignore:end */
  AssessmentsListInstance.get = function get() {
    return new AssessmentsContext(this._version);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.FlexApi.V1.AssessmentsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  AssessmentsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  AssessmentsListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return AssessmentsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the AssessmentsPage
 *
 * @constructor Twilio.FlexApi.V1.AssessmentsPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {AssessmentsSolution} solution - Path solution
 *
 * @returns AssessmentsPage
 */
/* jshint ignore:end */
AssessmentsPage = function AssessmentsPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(AssessmentsPage.prototype, Page.prototype);
AssessmentsPage.prototype.constructor = AssessmentsPage;

/* jshint ignore:start */
/**
 * Build an instance of AssessmentsInstance
 *
 * @function getInstance
 * @memberof Twilio.FlexApi.V1.AssessmentsPage#
 *
 * @param {AssessmentsPayload} payload - Payload response from the API
 *
 * @returns AssessmentsInstance
 */
/* jshint ignore:end */
AssessmentsPage.prototype.getInstance = function getInstance(payload) {
  return new AssessmentsInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.AssessmentsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
AssessmentsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

AssessmentsPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the AssessmentsContext
 *
 * @constructor Twilio.FlexApi.V1.AssessmentsInstance
 *
 * @property {string} url - The URL of this resource.
 *
 * @param {V1} version - Version of the resource
 * @param {AssessmentsPayload} payload - The instance payload
 */
/* jshint ignore:end */
AssessmentsInstance = function AssessmentsInstance(version, payload) {
  this._version = version;

  // Marshaled Properties
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

Object.defineProperty(AssessmentsInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new AssessmentsContext(this._version);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * create a AssessmentsInstance
 *
 * @function create
 * @memberof Twilio.FlexApi.V1.AssessmentsInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed AssessmentsInstance
 */
/* jshint ignore:end */
AssessmentsInstance.prototype.create = function create(callback) {
  return this._proxy.create(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.AssessmentsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
AssessmentsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

AssessmentsInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the AssessmentsContext
 *
 * @constructor Twilio.FlexApi.V1.AssessmentsContext
 *
 * @param {V1} version - Version of the resource
 */
/* jshint ignore:end */
AssessmentsContext = function AssessmentsContext(version) {
  this._version = version;

  // Path Solution
  this._solution = {};
  this._uri = `/Accounts/Assessments`;
};

/* jshint ignore:start */
/**
 * create a AssessmentsInstance
 *
 * @function create
 * @memberof Twilio.FlexApi.V1.AssessmentsContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed AssessmentsInstance
 */
/* jshint ignore:end */
AssessmentsContext.prototype.create = function create(callback) {
  var deferred = Q.defer();
  var promise = this._version.create({uri: this._uri, method: 'POST'});

  promise = promise.then(function(payload) {
    deferred.resolve(new AssessmentsInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.AssessmentsContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
AssessmentsContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

AssessmentsContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  AssessmentsList: AssessmentsList,
  AssessmentsPage: AssessmentsPage,
  AssessmentsInstance: AssessmentsInstance,
  AssessmentsContext: AssessmentsContext
};
