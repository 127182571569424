import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CART_COUNTER,
    CART_REDUCE_COUNT,
    ADD_TOTAL_PRICE,
    RESET_CART,
    SET_COUNT,
    ALL_CART,
    UPDATE_CART_COUNT,
    SET_ORDER_DATA
} from "../actionTypes";




export const addToCart = (items) => {
    return{
        type: ADD_TO_CART,
        items: items,

    };
};

export const removeItemFromCart = (items) => {
    return{
        type: REMOVE_FROM_CART,
        items: items
    }
};


export const addCount = () => {
    return{
        type: CART_COUNTER
    }
};

export const subtractCount = () => {
    return{
        type: CART_REDUCE_COUNT
    }
};

export const totalPrice = (price) => {
    return{
        type: ADD_TOTAL_PRICE,
        price: price
    }
};

export const resetCart = () => {
    return{
        type: RESET_CART,
    }
};

export const setCount = (count) => {
    return{
        type:SET_COUNT,
        count
    }
};


export const allCart = (cart) => {
    return{
        type:ALL_CART,
        cart: cart
    }
};


export const updateCartCount = (count) => {
    return{
        type: UPDATE_CART_COUNT,
        count
    }
}

export const setOrderData = (order) => {
    console.log(order,"actionnnnnnnnnnn")
    return{
        type: SET_ORDER_DATA,
        order
    }
}