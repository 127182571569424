import React,{Component}  from 'react';
import {View,Text, TouchableOpacity, ScrollView, Image, Dimensions} from 'react-native';
import {Row, Col,Button} from 'native-base';
import {connect} from 'react-redux'
import BackButton from './../components/backButton'
import axios from 'axios'
import {API_URL} from './../constants/index'
import Toast from './../components/toast'
import {style} from "../../assets/style";
import Modal from 'modal-react-native-web';
import Icono from '@expo/vector-icons/Ionicons';
import {Hr, Br} from './../components/horizontalLine';
import {performRequest} from './../services/apiHandler'
import ShimmerPlaceHolder from './../components/placeHolder'
import Material from '@expo/vector-icons/MaterialCommunityIcons'
import OctI from '@expo/vector-icons/Octicons'
import {backToProfile} from './../store/actions'
import { FontLetterSpacing } from './../handlers/utils';
import StyledText from "../components/styledText";
import i18n from '../i18n';

const STATUS_CHOICES = {
    1: 'Pending',
    2: 'Accepted',
    3: 'Cancelled',
    4: 'Rejected',
    5: 'In Kichen',
    6: 'In Route',
    7: 'Delivered',
    8: "Ready to pick",
    9: "Accepted"
}



class Orders extends Component{
    state={
        error_message:'',
        message_status:'',
        message_display:'none',
        address: [],
        isLoading:true,
        default_address: ""

    }
    static navigationOptions = {
        header: null,
        gesturesEnabled: true,
        animationEnabled:true,
    };

    componentDidMount(){
        this.setState({isLoading: true})
        this.loadAddress()
    }

    loadAddress = () => {
        performRequest('get', `api/v2/pwa/address/?venue=${this.props.config.venue}` )
        .then(response => {
            let default_address = response.data.find(item => item.default_address == true)
            this.setState({address: response.data, default_address: default_address})
            this.setState({isLoading: false})
        })
        .catch(error=>{
            this.setState({isLoading: false})
            this.throwMessageFn('Something went wrong. Try reloading page', 'red')
        })
    }


    deleteAddress = (id) => {
        performRequest('delete', `api/v2/pwa/address/?id=${id}`)
        .then(response => {
            this.loadAddress()
            this.throwMessageFn('Address Deleted', 'green')
        })
    }
    throwMessageFn = (message, status) => {
        this.setState({
            message_status: status,
            error_message:message,
            message_display:'inherit'
        });

        setTimeout(
            function() {
                this.setState({
                    message_status: '',
                    error_message:'',
                    message_display:'none'
                });
            }
                .bind(this),
            3000
        );
    };




    _renderCols(loadingColAnimated, numberRow, uniqueKey) {
        let shimmerRows = [];
        for(let index = 0; index < numberRow; index++ ){
          shimmerRows.push(
            <ShimmerPlaceHolder
                colorShimmer={['#ececec', '#e4e4e4', '#e4e4e4']}
                height={110}
                width={Dimensions.get('window').width * .95}
                key={`loading-${index}-${uniqueKey}`}
                ref={(ref) => loadingColAnimated.push(ref)}
                style={{ 
                    marginBottom:13,
                    borderRadius:6,
                }}
            />
          )
        }
        return (
          <View style={{marginTop:30,alignItems:'center'}}>
            {shimmerRows}
          </View>
        )
      }
      runColPlaceHolder() {
        if (Array.isArray(this.loadingColAnimated) && this.loadingColAnimated.length > 0) {
          Animated.parallel(
            this.loadingColAnimated.map(animate => {
              if (animate&&animate.getAnimated) {
                return animate.getAnimated();
              }
              return null;
            }),
            {
              stopTogether: false,
            }
          ).start(() => {
              this.runColPlaceHolder();
          })
        }
      }
      toAddressForm = () => {
          this.props.backToProfile(true)
            this.props.navigation.navigate('AddressFormScreen')
        }
    returnHere = () => this.props.navigation.navigate('AddressScreen')
    setDefaultAddress = (item) => 
    performRequest('put', 'api/v2/pwa/address/',{id: item.id})
    .then(() => {
        this.setState({default_address: item,})
        this.loadAddress()
    })

    render(){
        this.loadingColAnimated = []
        if(this.state.isLoading){
            return(
                <View style={{flex:1,alignItems:"center",justifyContent:"center"}}>
                    {this._renderCols(this.loadingColAnimated, 5, '5cols')}
                    <TouchableOpacity style={{position:'absolute',top:0,left:0}} onPress={() => this.props.navigation.goBack()} >
                        <BackButton
                            border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                            // goBack={() => this.props.navigation.goBack()}
                            backgroundColor={this.props.design.top_buttons_background_color}
                            borderColor={'black'}
                            color={this.props.design.top_buttons_text_color}
                            default_language={this.props.default_language}
                            font_size={this.props.design.custom_font.heading_size}

                        />
                    </TouchableOpacity>
                </View>
            )
        }
       
            return(
                <View style={{flex:1}}>
    
                <View style={{flex:1,backgroundColor:this.props.design.list_view_background_color}}>

                    <View style={{flexDirection:'row',justifyContent:'space-between' ,marginTop:80}}>
                        <StyledText 
                          font_color={this.props.design.list_view_text_color}
                          font_weight={this.props.design.list_view_text_font_weight}
                          font_style={this.props.design.list_view_text_font_style}
                          text_transform={this.props.design.list_view_text_font_transform}
                          font_family={this.props.design.list_view_text_font_family}
                          font_size={this.props.design.list_view_text_font_size?? 20}
                          letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
          
                        style={{ marginVertical:15,marginLeft:7}}>{i18n.t("labels.saved_address")}</StyledText>


                        <TouchableOpacity  onClick={() => this.toAddressForm()}>
                            <StyledText 
                             font_color={this.props.design.list_view_text_color}
                             font_weight={this.props.design.list_view_text_font_weight}
                             font_style={this.props.design.list_view_text_font_style}
                             text_transform={this.props.design.list_view_text_font_transform}
                             font_family={this.props.design.list_view_text_font_family}
                             font_size={this.props.design.list_view_text_font_size?? 16}
                             letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
             
                            style={{marginVertical:15,marginRight:7, }}>{i18n.t("button_text.add_s")}</StyledText>
                        </TouchableOpacity>
                        


                    </View>
                    <ScrollView >
                        {
                            this.state.address.map((item, index) => {
                                return(
                                    <View style={style.checkoutContainer}>
                                        <View style={{width:40,justifyContent:'center'}}>
                                            <View style={style.checkoutIconWrap}>
                                                <Material size={24} name={'map-marker-multiple'} color={'#5d6670'}/>  
                                            </View>
                                        </View>
                                        <View style={{flex:5,justifyContent:'center'}}>
                                            <StyledText
                                             font_color={this.props.design.list_view_text_color}
                                             font_weight={this.props.design.list_view_text_font_weight}
                                             font_style={this.props.design.list_view_text_font_style}
                                             text_transform={this.props.design.list_view_text_font_transform}
                                             font_family={this.props.design.list_view_text_font_family}
                                             font_size={this.props.design.list_view_text_font_size?? 20}
                                             letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                                             style={{fontWeight:'bold',}}>{item.address_nickname == 1 ? "Home" : item.address_nickname == 2 ? "Office" : 'Other'}</StyledText>
                                            <StyledText 
                                             font_color={this.props.design.list_view_text_color}
                                             font_weight={this.props.design.list_view_text_font_weight}
                                             font_style={this.props.design.list_view_text_font_style}
                                             text_transform={this.props.design.list_view_text_font_transform}
                                             font_family={this.props.design.list_view_text_font_family}
                                             font_size={this.props.design.list_view_text_font_size?? 20}
                                             letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                             
                                            style={{}}>{item.complete_address}</StyledText>
                                            <StyledText 
                                             font_color={this.props.design.list_view_text_color}
                                             font_weight={this.props.design.list_view_text_font_weight}
                                             font_style={this.props.design.list_view_text_font_style}
                                             text_transform={this.props.design.list_view_text_font_transform}
                                             font_family={this.props.design.list_view_text_font_family}
                                             font_size={this.props.design.list_view_text_font_size?? 20}
                                             letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                             
                                            style={{}}>{item.location_str}</StyledText>
                                        </View>
                                        <View  style={{flex:2,justifyContent:'center',alignItems:'flex-end',marginRight:8}}>
                                            {
                                                item.default_address ?
                                                    <OctI size={19} name={'check'} color={'green'} />  
                                                    :
                                                    <View style={{flexDirection:'row'}}>
                                                        <TouchableOpacity  onClick={() => this.setDefaultAddress(item)} hitSlop={{top: 20, bottom: 20, left: 50, right: 50}}>
                                                            <StyledText 
                                                             font_color={this.props.design.list_view_text_color}
                                                             font_weight={this.props.design.list_view_text_font_weight}
                                                             font_style={this.props.design.list_view_text_font_style}
                                                             text_transform={this.props.design.list_view_text_font_transform}
                                                             font_family={this.props.design.list_view_text_font_family}
                                                             font_size={this.props.design.list_view_text_font_size?? 20}
                                                             letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                                             
                                                            style={{marginRight:20}}>Make Default</StyledText>  
                                                        </TouchableOpacity>
                                                        <TouchableOpacity  onClick={() => this.deleteAddress(item.id)} hitSlop={{top: 20, bottom: 20, left: 50, right: 50}}>
                                                            <OctI size={19} name={'trashcan'} color={'red'} />  
                                                        </TouchableOpacity>
                                                    </View>
                                                    
                                            }
                                        </View>
                                    </View>
                                )
                            })
                        }

                    </ScrollView>
    
                </View>
    
                    <TouchableOpacity style={{position:'absolute',top:0}} onPress={() => this.props.navigation.goBack()} >
                        <BackButton
                            border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                            // goBack={() => this.props.navigation.goBack()}
                            backgroundColor={this.props.design.top_buttons_background_color}
                            borderColor={'black'}
                            color={this.props.design.top_buttons_text_color}
                            default_language={this.props.default_language}         
    
                        />
                    </TouchableOpacity>
                    <Toast
                        error_message={this.state.error_message}
                        message_status={this.state.message_status}
                        message_display={this.state.message_display}
                        custom_font={this.props.design.custom_font}
                    />
    

                </View>
            )
    }
}

const mapStateToProps = state => {
    return{
        user: state.number.user,
        config: state.config.config,
        default_language: state.languages.default_language,
        design: state.config.design


    }
};
export default connect(mapStateToProps, {backToProfile})(Orders)