import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CART_COUNTER,
    CART_REDUCE_COUNT,
    ADD_TOTAL_PRICE, RESET_CART,SET_COUNT,
    ALL_CART,
    UPDATE_CART_COUNT,
    SET_ORDER_DATA

} from "../actionTypes";


const initialState = {
    cart: [],
    cart_count:0,
    total_price:0,
    order: {}


};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            return {
                ...state,
                cart: [...state.cart, action.items]
            };
        case REMOVE_FROM_CART:
            return {
                ...state,
                cart: action.items
            };
        case CART_COUNTER:
            return {
                ...state,
                cart_count: state.cart_count + 1
            };
        case CART_REDUCE_COUNT:
            return {
                ...state,
                cart_count: state.cart_count == 1 ? 0 : state.cart_count - 1
            };
        case ADD_TOTAL_PRICE:
            console.log(action.price, "priceeeeeeee")
            return {
                ...state,
                total_price: action.price
            };
        case RESET_CART:
            return {
                ...state,
                total_price: 0,
                cart_count:0,
                cart:[]

            };
        case SET_COUNT:
            return {
                ...state,
                cart_count: action.count,

            };
        case ALL_CART:
            return{
                ...state,
                cart: action.cart
            }
        case UPDATE_CART_COUNT:
            return{
                ...state,
                cart_count: state.cart_count+action.count
            }
        case SET_ORDER_DATA:
            console.log(action.order,"reducerrrr")
            return{
                ...state,
                order: action.order
            }
        default:
            return state;
    }
};

export default reducer;