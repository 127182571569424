'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var OpenidDiscoveryList;
var OpenidDiscoveryPage;
var OpenidDiscoveryInstance;
var OpenidDiscoveryContext;

/* jshint ignore:start */
/**
 * Initialize the OpenidDiscoveryList
 *
 * @constructor Twilio.Oauth.V1.OpenidDiscoveryList
 *
 * @param {Twilio.Oauth.V1} version - Version of the resource
 */
/* jshint ignore:end */
OpenidDiscoveryList = function OpenidDiscoveryList(version) {
  /* jshint ignore:start */
  /**
   * @function openidDiscovery
   * @memberof Twilio.Oauth.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Oauth.V1.OpenidDiscoveryContext}
   */
  /* jshint ignore:end */
  function OpenidDiscoveryListInstance(sid) {
    return OpenidDiscoveryListInstance.get(sid);
  }

  OpenidDiscoveryListInstance._version = version;
  // Path Solution
  OpenidDiscoveryListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a openid_discovery
   *
   * @function get
   * @memberof Twilio.Oauth.V1.OpenidDiscoveryList#
   *
   * @returns {Twilio.Oauth.V1.OpenidDiscoveryContext}
   */
  /* jshint ignore:end */
  OpenidDiscoveryListInstance.get = function get() {
    return new OpenidDiscoveryContext(this._version);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Oauth.V1.OpenidDiscoveryList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  OpenidDiscoveryListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  OpenidDiscoveryListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return OpenidDiscoveryListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the OpenidDiscoveryPage
 *
 * @constructor Twilio.Oauth.V1.OpenidDiscoveryPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {OpenidDiscoverySolution} solution - Path solution
 *
 * @returns OpenidDiscoveryPage
 */
/* jshint ignore:end */
OpenidDiscoveryPage = function OpenidDiscoveryPage(version, response, solution)
                                                    {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(OpenidDiscoveryPage.prototype, Page.prototype);
OpenidDiscoveryPage.prototype.constructor = OpenidDiscoveryPage;

/* jshint ignore:start */
/**
 * Build an instance of OpenidDiscoveryInstance
 *
 * @function getInstance
 * @memberof Twilio.Oauth.V1.OpenidDiscoveryPage#
 *
 * @param {OpenidDiscoveryPayload} payload - Payload response from the API
 *
 * @returns OpenidDiscoveryInstance
 */
/* jshint ignore:end */
OpenidDiscoveryPage.prototype.getInstance = function getInstance(payload) {
  return new OpenidDiscoveryInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Oauth.V1.OpenidDiscoveryPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
OpenidDiscoveryPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

OpenidDiscoveryPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the OpenidDiscoveryContext
 *
 * @constructor Twilio.Oauth.V1.OpenidDiscoveryInstance
 *
 * @property {string} issuer - The issuer URL
 * @property {string} authorizationEndpoint - The URL of authorization endpoint
 * @property {string} deviceAuthorizationEndpoint -
 *          The URL of device code authorization endpoint
 * @property {string} tokenEndpoint - The URL of token endpoint
 * @property {string} userinfoEndpoint - The URL of user info endpoint
 * @property {string} revocationEndpoint - The URL of revocation endpoint
 * @property {string} jwkUri - The URL of public JWK endpoint
 * @property {string} responseTypeSupported -
 *          List of response type supported for identity token
 * @property {string} subjectTypeSupported -
 *          List of subject supported for identity token
 * @property {string} idTokenSigningAlgValuesSupported -
 *          List of JWS signing algorithms supported for identity token
 * @property {string} scopesSupported - List of scopes supported identity token
 * @property {string} claimsSupported - List of claims supported for identity token
 * @property {string} url - The url
 *
 * @param {V1} version - Version of the resource
 * @param {OpenidDiscoveryPayload} payload - The instance payload
 */
/* jshint ignore:end */
OpenidDiscoveryInstance = function OpenidDiscoveryInstance(version, payload) {
  this._version = version;

  // Marshaled Properties
  this.issuer = payload.issuer; // jshint ignore:line
  this.authorizationEndpoint = payload.authorization_endpoint; // jshint ignore:line
  this.deviceAuthorizationEndpoint = payload.device_authorization_endpoint; // jshint ignore:line
  this.tokenEndpoint = payload.token_endpoint; // jshint ignore:line
  this.userinfoEndpoint = payload.userinfo_endpoint; // jshint ignore:line
  this.revocationEndpoint = payload.revocation_endpoint; // jshint ignore:line
  this.jwkUri = payload.jwk_uri; // jshint ignore:line
  this.responseTypeSupported = payload.response_type_supported; // jshint ignore:line
  this.subjectTypeSupported = payload.subject_type_supported; // jshint ignore:line
  this.idTokenSigningAlgValuesSupported = payload.id_token_signing_alg_values_supported; // jshint ignore:line
  this.scopesSupported = payload.scopes_supported; // jshint ignore:line
  this.claimsSupported = payload.claims_supported; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

Object.defineProperty(OpenidDiscoveryInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new OpenidDiscoveryContext(this._version);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a OpenidDiscoveryInstance
 *
 * @function fetch
 * @memberof Twilio.Oauth.V1.OpenidDiscoveryInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed OpenidDiscoveryInstance
 */
/* jshint ignore:end */
OpenidDiscoveryInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Oauth.V1.OpenidDiscoveryInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
OpenidDiscoveryInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

OpenidDiscoveryInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the OpenidDiscoveryContext
 *
 * @constructor Twilio.Oauth.V1.OpenidDiscoveryContext
 *
 * @param {V1} version - Version of the resource
 */
/* jshint ignore:end */
OpenidDiscoveryContext = function OpenidDiscoveryContext(version) {
  this._version = version;

  // Path Solution
  this._solution = {};
  this._uri = `/.well-known/openid-configuration`;
};

/* jshint ignore:start */
/**
 * fetch a OpenidDiscoveryInstance
 *
 * @function fetch
 * @memberof Twilio.Oauth.V1.OpenidDiscoveryContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed OpenidDiscoveryInstance
 */
/* jshint ignore:end */
OpenidDiscoveryContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new OpenidDiscoveryInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Oauth.V1.OpenidDiscoveryContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
OpenidDiscoveryContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

OpenidDiscoveryContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  OpenidDiscoveryList: OpenidDiscoveryList,
  OpenidDiscoveryPage: OpenidDiscoveryPage,
  OpenidDiscoveryInstance: OpenidDiscoveryInstance,
  OpenidDiscoveryContext: OpenidDiscoveryContext
};
