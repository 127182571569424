'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var DeviceCodeList;
var DeviceCodePage;
var DeviceCodeInstance;

/* jshint ignore:start */
/**
 * Initialize the DeviceCodeList
 *
 * @constructor Twilio.Oauth.V1.DeviceCodeList
 *
 * @param {Twilio.Oauth.V1} version - Version of the resource
 */
/* jshint ignore:end */
DeviceCodeList = function DeviceCodeList(version) {
  /* jshint ignore:start */
  /**
   * @function deviceCode
   * @memberof Twilio.Oauth.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Oauth.V1.DeviceCodeContext}
   */
  /* jshint ignore:end */
  function DeviceCodeListInstance(sid) {
    return DeviceCodeListInstance.get(sid);
  }

  DeviceCodeListInstance._version = version;
  // Path Solution
  DeviceCodeListInstance._solution = {};
  DeviceCodeListInstance._uri = `/device/code`;
  /* jshint ignore:start */
  /**
   * create a DeviceCodeInstance
   *
   * @function create
   * @memberof Twilio.Oauth.V1.DeviceCodeList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.clientSid -
   *          A string that uniquely identifies this oauth app
   * @param {string|list} opts.scopes - An Array of scopes
   * @param {string|list} [opts.audiences] - An array of intended audiences
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed DeviceCodeInstance
   */
  /* jshint ignore:end */
  DeviceCodeListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['clientSid'])) {
      throw new Error('Required parameter "opts[\'clientSid\']" missing.');
    }
    if (_.isUndefined(opts['scopes'])) {
      throw new Error('Required parameter "opts[\'scopes\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'ClientSid': _.get(opts, 'clientSid'),
      'Scopes': serialize.map(_.get(opts, 'scopes'), function(e) { return e; }),
      'Audiences': serialize.map(_.get(opts, 'audiences'), function(e) { return e; })
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new DeviceCodeInstance(this._version, payload));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Oauth.V1.DeviceCodeList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  DeviceCodeListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  DeviceCodeListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return DeviceCodeListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the DeviceCodePage
 *
 * @constructor Twilio.Oauth.V1.DeviceCodePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {DeviceCodeSolution} solution - Path solution
 *
 * @returns DeviceCodePage
 */
/* jshint ignore:end */
DeviceCodePage = function DeviceCodePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(DeviceCodePage.prototype, Page.prototype);
DeviceCodePage.prototype.constructor = DeviceCodePage;

/* jshint ignore:start */
/**
 * Build an instance of DeviceCodeInstance
 *
 * @function getInstance
 * @memberof Twilio.Oauth.V1.DeviceCodePage#
 *
 * @param {DeviceCodePayload} payload - Payload response from the API
 *
 * @returns DeviceCodeInstance
 */
/* jshint ignore:end */
DeviceCodePage.prototype.getInstance = function getInstance(payload) {
  return new DeviceCodeInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Oauth.V1.DeviceCodePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
DeviceCodePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

DeviceCodePage.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the DeviceCodeContext
 *
 * @constructor Twilio.Oauth.V1.DeviceCodeInstance
 *
 * @property {string} deviceCode - The device verification code
 * @property {string} userCode - The verification code for the end user
 * @property {string} verificationUri -
 *          The URI that the end user visits to verify request
 * @property {string} verificationUriComplete -
 *          he URI with user_code that the end-user alternatively visits to verify request
 * @property {number} expiresIn -
 *          The expiration time of the device_code and user_code in seconds
 * @property {number} interval -
 *          The minimum amount of time in seconds that the client should wait between polling requests to the token endpoint
 *
 * @param {V1} version - Version of the resource
 * @param {DeviceCodePayload} payload - The instance payload
 */
/* jshint ignore:end */
DeviceCodeInstance = function DeviceCodeInstance(version, payload) {
  this._version = version;

  // Marshaled Properties
  this.deviceCode = payload.device_code; // jshint ignore:line
  this.userCode = payload.user_code; // jshint ignore:line
  this.verificationUri = payload.verification_uri; // jshint ignore:line
  this.verificationUriComplete = payload.verification_uri_complete; // jshint ignore:line
  this.expiresIn = deserialize.integer(payload.expires_in); // jshint ignore:line
  this.interval = deserialize.integer(payload.interval); // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Oauth.V1.DeviceCodeInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
DeviceCodeInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

DeviceCodeInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  DeviceCodeList: DeviceCodeList,
  DeviceCodePage: DeviceCodePage,
  DeviceCodeInstance: DeviceCodeInstance
};
