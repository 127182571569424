import React, { Component, useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  Image,
  CheckBox,
  Text,
  AsyncStorage
} from "react-native";
import { Row, Col, Button, Input, Icon } from "native-base";
import { connect } from "react-redux";
import BackButton from "./../components/backButton";
import axios from "axios";
import { API_URL } from "./../constants/index";
import Material from "@expo/vector-icons/MaterialCommunityIcons";
import { performRequest } from "./../services/apiHandler";
import Toast from "./../components/toast";
import { Spinner } from "native-base";
import { Hr, Br } from "./../components/horizontalLine";

import { style } from "../../assets/style";
import * as ImagePicker from "expo-image-picker";
import i18n from "../i18n";
import {adduser, addToken} from './../store/actions'
import { FontLetterSpacing } from './../handlers/utils';
import StyledText from "../components/styledText"

const Profile = (props) => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [error_message, setErrorMessage] = useState("");
  const [message_status, setMessageStatus] = useState("");
  const [message_display, setMessageDisplay] = useState("none");
  const [profile_picture, setProfilePicure] = useState(null);
  const [new_image, setNewImage] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    performRequest("get", `api/v2/pwa/profile/${props.user.id}`)
      .then((response) => {
        // const [first_name, last_name, gender, email, mobile_number] = response.data
        setFirstName(response.data.first_name);
        setLastName(response.data.last_name);
        setGender(response.data.gender);
        setEmail(response.data.email);
        setMobile(response.data.mobile_number);
        setProfilePicure(response.data.avatar);
      })
      .catch((errr) => {
        console.log(errr.response, "errorrrrr");
      });
  };
  const returnHere = () => {
    loadData();
  };

  const logout = async() => {
    await AsyncStorage.removeItem('token');
    props.addToken()
    props.adduser()
    props.navigation.goBack()
  }
  const throwMessageFn = (message, status) => {
    setErrorMessage(message);
    setMessageStatus(status);
    setMessageDisplay("inherit");

    setTimeout(
      function () {
        setErrorMessage("");
        setMessageStatus("");
        setMessageDisplay("none");
      },
      // .bind(this),
      3000
    );
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      setProfilePicure(result.uri);
      setNewImage(true);
    }
  };
  const returnAccount = () => {
    props.navigation.navigate("AccountScreen");
  };
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: props.config.list_page_background_color,
      }}
    >
      <View style={{ flex: 1, marginHorizontal: 12 }}>
        <ScrollView>
          <TouchableOpacity
            onPress={() =>
              props.navigation.navigate("ProfileScreen", { returnHere })
            }
            style={{ marginTop: 80 }}
          >
            <View
              style={{
                width: "100%",
                padding: 10,
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "60%",
                  marginLeft: 10,
                  justifyContent: "center",
                }}
              >
                <StyledText
                font_color={props.design.list_view_text_color}
                font_weight={props.design.list_view_text_font_weight}
                font_style={props.design.list_view_text_font_style}
                text_transform={props.design.list_view_text_font_transform}
                font_family={props.design.list_view_text_font_family}
                font_size={props.design.list_view_text_font_size?? 18}
                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                  style={{
                  
                  }}
                >
                  {first_name + " " + last_name}
                </StyledText>
                <StyledText
                font_color={props.design.list_view_text_color}
                font_weight={props.design.list_view_text_font_weight}
                font_style={props.design.list_view_text_font_style}
                text_transform={props.design.list_view_text_font_transform}
                font_family={props.design.list_view_text_font_family}
                font_size={props.design.list_view_text_font_size?? 14}
                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                 style={{}}>
                  {email ? email : mobile}
                </StyledText>
                <StyledText
                font_color={props.design.list_view_text_color}
                font_weight={props.design.list_view_text_font_weight}
                font_style={props.design.list_view_text_font_style}
                text_transform={props.design.list_view_text_font_transform}
                font_family={props.design.list_view_text_font_family}
                font_size={props.design.list_view_text_font_size?? 12}
                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                  style={{
                    
                    marginTop: 8,
                  }}
                >
                  {i18n.t("labels.view_profile")}
                </StyledText>
              </View>
              <Image
                style={{ width: 100, height: 100, borderRadius: "100%" }}
                source={
                  profile_picture
                    ? { uri: profile_picture }
                    : require("./../../assets/avatar.png")
                }
              />
            </View>
          </TouchableOpacity>
          <Hr />
          <View style={{ marginLeft: 14, marginTop: 10 }}>
            <StyledText
             font_color={props.design.list_view_text_color}
             font_weight={props.design.list_view_text_font_weight}
             font_style={props.design.list_view_text_font_style}
             text_transform={props.design.list_view_text_font_transform}
             font_family={props.design.list_view_text_font_family}
             font_size={props.design.list_view_text_font_size?? 17}
             letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

              style={{
                fontVariant: "proportional-nums",
              }}
            >
              {i18n.t("labels.account")}
            </StyledText>
            <TouchableOpacity
              onPress={() =>
                props.navigation.navigate("RecentOrdersScreen", {
                  returnAccount,
                })
              }
              style={{ marginTop: 20 }}
            >
              <View style={style.checkoutContainer}>
                <View style={{ width: 40, justifyContent: "center" }}>
                  <View style={style.checkoutIconWrap}>
                    <Material size={24} name={"basket"} color={"#5d6670"} />
                  </View>
                </View>

                <View style={{ flex: 4, justifyContent: "center" }}>
                  <StyledText
                   font_color={props.design.list_view_text_color}
                   font_weight={props.design.list_view_text_font_weight}
                   font_style={props.design.list_view_text_font_style}
                   text_transform={props.design.list_view_text_font_transform}
                   font_family={props.design.list_view_text_font_family}
                   font_size={props.design.list_view_text_font_size?? 14}
                   letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
   
                    style={{ 
                      }}
                  >
                    {i18n.t("checkout.your_order")}
                  </StyledText>
                </View>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => props.navigation.navigate("AddressScreen")}
              style={{ marginTop: 7 }}
            >
              <View style={style.checkoutContainer}>
                <View style={{ width: 40, justifyContent: "center" }}>
                  <View style={style.checkoutIconWrap}>
                    <Material size={24} name={"contacts"} color={"#5d6670"} />
                  </View>
                </View>

                <View style={{ flex: 4, justifyContent: "center" }}>
                  <StyledText
                   font_color={props.design.list_view_text_color}
                   font_weight={props.design.list_view_text_font_weight}
                   font_style={props.design.list_view_text_font_style}
                   text_transform={props.design.list_view_text_font_transform}
                   font_family={props.design.list_view_text_font_family}
                   font_size={props.design.list_view_text_font_size?? 14}
                   letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
   
                    style={{ }}
                  >
                    {i18n.t("labels.address_book")}
                  </StyledText>
                </View>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => props.navigation.navigate("CardScreen")}
              style={{ marginTop: 7 }}
            >
              <View style={style.checkoutContainer}>
                <View style={{ width: 40, justifyContent: "center" }}>
                  <View style={style.checkoutIconWrap}>
                    <Material
                      size={24}
                      name={"credit-card-multiple"}
                      color={"#5d6670"}
                    />
                  </View>
                </View>

                <View style={{ flex: 4, justifyContent: "center" }}>
                  <StyledText
                   font_color={props.design.list_view_text_color}
                   font_weight={props.design.list_view_text_font_weight}
                   font_style={props.design.list_view_text_font_style}
                   text_transform={props.design.list_view_text_font_transform}
                   font_family={props.design.list_view_text_font_family}
                   font_size={props.design.list_view_text_font_size?? 14}
                   letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
   
                    style={{  }}
                  >
                    {i18n.t("button_text.save_cards")}
                  </StyledText>
                </View>
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => logout()}
              style={{ marginTop: 7 }}
            >
              <View style={style.checkoutContainer}>
                <View style={{ width: 40, justifyContent: "center" }}>
                  <View style={style.checkoutIconWrap}>
                    <i
                      style={{ color: "#5d6670", fontSize: 25,marginTop: 2 }}
                      class="fas fa-sign-out-alt"
                    ></i>
                  </View>
                </View>

                <View style={{ flex: 4, justifyContent: "center" }}>
                  <StyledText
                   font_color={props.design.list_view_text_color}
                   font_weight={props.design.list_view_text_font_weight}
                   font_style={props.design.list_view_text_font_style}
                   text_transform={props.design.list_view_text_font_transform}
                   font_family={props.design.list_view_text_font_family}
                   font_size={props.design.list_view_text_font_size?? 14}
                   letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
   
                    style={{ }}
                  >
                    {i18n.t("button_text.logout")}
                  </StyledText>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>

      <TouchableOpacity
        style={{ position: "absolute", top: 0 }}
        onPress={() => props.navigation.goBack()}
      >
        <BackButton
          detail_page={true}
          border_color={`1px solid ${props.design.top_buttons_border_color}`}
          // goBack={() => props.navigation.goBack()}
          backgroundColor={props.design.top_buttons_background_color}
          borderColor={"black"}
          color={props.design.top_buttons_text_color}
          default_language={props.default_language}
          font_size={props.design.custom_font.heading_size}
        />
      </TouchableOpacity>
      <Toast
        error_message={error_message}
        message_status={message_status}
        message_display={message_display}
        custom_font={props.design.custom_font}
      />
    </View>
  );
};

Profile["navigationOptions"] = (screenProps) => ({
  header: null,
  gesturesEnabled: false,
  animationEnabled: true,
});

const mapStateToProps = (state) => {
  return {
    menus: state.menus.menus,
    allChildren: state.menus.allChildren,
    immediateChildren: state.menus._immediateChildren,
    cart: state.cart.cart,
    cart_count: state.cart.cart_count,
    total_price: state.cart.total_price,
    config: state.config.config,
    languages: state.languages.languages,
    default_language: state.languages.default_language,
    isRTL: state.languages.isRTL,
    menu_type: state.config.menu_type,
    platform: state.config.platform,
    user: state.number.user,
    design: state.config.design,

  };
};

export default connect(mapStateToProps, {addToken, adduser})(Profile);
