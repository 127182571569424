import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Button,
  FlatList,
  Image,
  AsyncStorage,
  Platform,
  StyleSheet,
  Dimensions,
  ImageBackground,
  ScrollView,
  TouchableWithoutFeedback
} from "react-native";
import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from "accordion-collapse-react-native";
import {
  Thumbnail,
  List,
  ListItem,
  Separator,
  Item,
  Input,
  Label,
} from "native-base";
import { connect } from "react-redux";
import BackButton from "./../components/backButton";
import axios from "axios";
import { API_URL, image_api } from "../constants";
import {
  setDeliveryMode,
  addCampaign,
  addConfig,
  addDefaultLanguage,
  addLanguage,
  addOnlineChildren,
  selectMenu,
  isLoading,
  selectMenuData,
  resetCart,
  allCart,
  totalPrice,
  updateCartCount
} from "./../store/actions";
import _ from "lodash";
import Constants from 'expo-constants';
import { style } from "../../assets/style";
import { AvailabilityHandler } from './../handlers/utils'
import Toast from "./../components/toast";
import Modal from "modal-react-native-web";
import DefaultLanguage from "./../components/DefaultLanguage";
import LanguageContainer from "./../components/languageContainer";
import HTMLView from "react-native-htmlview";
import Icon from "./../components/Warning";
import FeedbackButton from "./../components/FeedbackButton";
import FeedbackForm from "./../components/FeedbackForm";
import IntlLabel from "./../handlers/languageHandler";
import StyledText from "../components/styledText"
import { FontLetterSpacing } from "./../handlers/utils"
import ProgressiveImage from "./../components/progressiveImage";
import {performRequest} from "./../services/apiHandler";
import i18n from "../i18n";
class indexScreen extends React.Component {
  static navigationOptions = {
    header: null,
    gesturesEnabled: true,
    animationEnabled: true,
  };
  state = {
    not_avaliable: false,
    pickup_config: "",
    delivery_config: "",
    error_message: "",
    message_status: "",
    message_display: "none",
    infoModal: false,
    defaultLangModal: false,
    FeedbackModal: false,
    feedback_q_a: [],
    modalVisible: false,
    dataLoading: false
  };
  throwMessageFn = (message, status, warn_text = "white") => {
    this.setState({
      message_status: status,
      error_message: message,
      message_display: "inherit",
      warn_text
    });

    setTimeout(
      function () {
        this.setState({
          message_status: "",
          error_message: "",
          message_display: "none",
          warn_text: "white"
        });
      }.bind(this),
      3000
    );
  };
  componentDidMount() {
    if (this.props.platform == "order_enterprise") {
      document.title = this.props.config.displayName + " | My Menu Mobile";
      i18n.locale = "en";
      i18n.defaultLocale = "en";
      this.props.addDefaultLanguage("en");
      let config = { ...this.props.config }
      let button_text_delivery = JSON.parse(config.welcome_messages_multi)['delivery']
      let button_text_pickup = JSON.parse(config.welcome_messages_multi)['pickup']
      let delivery_config = { ...this.props.config, welcome_messages_multi: JSON.stringify(button_text_delivery) }
      let pickup_config = { ...this.props.config, welcome_messages_multi: JSON.stringify(button_text_pickup) }

      this.setState({ delivery_config, pickup_config });

    } else {
      document.title = this.props.config[0].displayName + " | My Menu Mobile";

      let config = [...this.props.config];
      let pickup_config = config.find((item) => item.platform == "pickup");
      let delivery_config = config.find((item) => item.platform == "delivery");
      this.setState({ delivery_config, pickup_config });
      let feedback_q_a = [];
      if (delivery_config.survey.question_survey) {
        Promise.all(
          delivery_config.survey.question_survey.map((item, index) => {
            item["display_warning"] = "none";
            feedback_q_a.push(item);
          })
        ).then(() => {
          this.setState({ feedback_q_a: feedback_q_a });
        });
      }
    }


    // window.history.pushState("object or string", "Title", "/"+this.props.config[0].venueName);
  }

  MymenuLogo = () => (
    <View
      style={{ backgroundColor: "transparent", width: this.state.width * 0.5 }}
    >
      <TouchableOpacity
      onPress={()=> window.open("https://www.mydigimenu.com/","_blank")}
      // onLongPress={this.onClickSettingsModal}
      >
        <Image
          style={{ width: 140, height: 40 }}
          source={require("./../../assets/logo-tans.png")}
        />
      </TouchableOpacity>
    </View>
  );

  getCart = (uuid, platform) => {
    return performRequest("get", `api/pwa/cart?uuid=${uuid}&mid=${Constants.deviceId}&platform=${platform}`,{})
  }
  getAllData = (uuid, platform) => {
    this.props.resetCart()
    axios.all([
      this.getChild(uuid, platform),
      this.getModifiers(uuid),
      this.getCart(uuid, platform)
    ]).then(
      axios.spread(async (children, modifiers, cart) => {
        if(cart.data){
          this.props.updateCartCount(cart.data.cart?.quantity || 0);
          this.props.totalPrice(cart.data.cart?.total || 0);
          this.props.allCart(cart.data.cart?.items || []);
        }
        let datum = JSON.parse(JSON.stringify(children.data));
        datum.forEach((element) => {
          let recoms = [];
          if (element.recommended_items) {
            element.recommended_items.forEach((rItem) => {
              let data = datum.find(
                (val) => rItem == val.id && val.type == "item"
              );
              rItem = data;
              if (data) recoms.push(data);
            });
          }
          element.recommended_items = recoms;
        });
        this.props.addOnlineChildren(datum, modifiers.data);
      }))
  }

  navigateToHome = () => {
    let config = { ...this.state.pickup_config };

    if (this.props.platform == "order_enterprise") {
      this.setState({ modalVisible: true })
      return
    }
    let languages = [];
    if (config.languages) {
      languages = config.languages;
      languages.unshift(config.default_language);
    } else {
      languages.push(config.default_language);
    }
    this.props.addLanguage(languages);
    this.setLocale(config)
    //    this.props.addDefaultLanguage(config.default_language.value);
    this.props.addConfig(config);
    this.props.selectMenu(config.menu_to_display)
    this.props.selectMenuData({ id: config.menu_to_display });
    AsyncStorage.getItem("uuid", async (err, uuid) => {
      if (err) {
        return;
      } else {
        let if_not_available = await this.getMenuDetail(config.menu_to_display, uuid)
        console.log("hereeeeeeeeeeeee", if_not_available)
        if (if_not_available) {
          this.throwMessageFn(
            "Menu Not Available",
            "red",
            "white"
          )
          return
        }
        this.props.isLoading();
        this.getCampaign(uuid)
          .then(campaign => {
            console.log(campaign.data, config.menu_to_display, "ccccccc")
            this.props.addCampaign(campaign.data);
            this.props.setDeliveryMode("pickup");
            this.getAllData(uuid, "pickup")
            // axios.all([
            //   this.getChild(uuid),
            //   this.getModifiers(uuid)
            // ]).then(
            //   axios.spread(async (children, modifiers) => {
            //     // this.props.setDeliveryMode("pickup");


            //     let datum = JSON.parse(JSON.stringify(children.data));

            //     datum.forEach((element) => {
            //       let recoms = [];
            //       if (element.recommended_items) {
            //         element.recommended_items.forEach((rItem) => {
            //           let data = datum.find(
            //             (val) => rItem == val.id && val.type == "item"
            //           );
            //           rItem = data;
            //           if (data) recoms.push(data);
            //         });
            //       }
            //       element.recommended_items = recoms;
            //     });
            //     this.props.addOnlineChildren(datum, modifiers.data);
            //   }))
            this.props.navigation.navigate("HomeScreen");

          })


        // axios
        //   .get(API_URL + "api/campaigns/online-pwa/" + uuid + "?platform=pickup")
        //   .then((response) => {
        //     this.props.addCampaign(response.data);
        //     this.props.setDeliveryMode("pickup");
        //     this.props.navigation.navigate("HomeScreen");
        //   })
        //   .catch((error) => {
        //     // this.props.setDeliveryMode('pickup')
        //     // this.props.navigation.navigate('HomeScreen')
        //   });
      }
    });
  };
  getModifiers = (venue) =>
    axios.get(API_URL + "api/online-modifiers/" + venue + "/");

  getChild = (venue, platform) => axios.get(API_URL + "api/online-menu-children/" + venue + "/" + `?platform=${platform}`);

  getCampaign = (venue) => axios.get(API_URL + "api/campaigns/online-pwa/" + venue + "/" + "?platform=pickup")


  getMenuDetail = async (menu, venue) => {
    let response = await axios.get(API_URL + `api/online-menu/${venue}/${menu}/?menu=${menu}`)

    // .then(response =>{
    if (response.data) {
      let is_available = AvailabilityHandler(response.data)
      if (is_available.length) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }

    // }).catch(error => {
    //   return true
    // })
  }
  setLocale = (data) => {
    try {
      i18n.locale = data.default_language.value;
      i18n.defaultLocale = data.default_language.value;
      this.props.addDefaultLanguage(data.default_language.value);

      for (let index = 0; index < data.languages.length; index++) {
        const element = data.languages[index];

        if (Localization.locale.split("-")[0] === element.value) {
          i18n.locale = Localization.locale.split("-")[0];
          this.props.addDefaultLanguage(i18n.locale);
        }
      }
    } catch (error) {
      i18n.defaultLocale = "en";
    }
  };
  navigateToDestination = () => {
    let config = { ...this.state.delivery_config };

    let languages = [];
    if (config.languages) {
      languages = config.languages;
      languages.unshift(config.default_language);
    } else {
      languages.push(config.default_language);
    }
    this.props.addLanguage(languages);
    this.setLocale(config)
    // this.props.addDefaultLanguage(config.default_language.value);
    this.props.addConfig(config);
    this.props.selectMenu(config.menu_to_display)
    this.props.selectMenuData({ id: config.menu_to_display });

    if(this.props.platform=="order_enterprise"){
      this.props.navigation.navigate("selectDestinationScreen");
      return
    }
    this.props.isLoading();

    AsyncStorage.getItem("uuid", async (err, uuid) => {
      if (err) {
        return;
      } else {
        let if_not_available = await this.getMenuDetail(config.menu_to_display, uuid)
        if (if_not_available) {
          this.throwMessageFn(
            "Menu Not Available",
            "red",
            "white"
          )
          return
        }
        axios
          .get(
            API_URL + "api/campaigns/online-pwa/" + uuid + "/" + "?platform=delivery",
            { platform: "delivery" }
          )
          .then((response) => {
            this.props.addCampaign(response.data);
            this.props.setDeliveryMode("delivery");
            this.getAllData(uuid, "delivery")
            this.props.navigation.navigate("selectDestinationScreen");
          })
          .catch((error) => {
            console.log(error);
            // this.props.setDeliveryMode('delivery')
            // this.props.navigation.navigate('selectDestinationScreen')
          });
      }
    });
  };
  alignButtons = () => {

    if (this.state.delivery_config.menu_button_position == "center") {
      return 'flex-start'
    } else {
      return 'center'
    }
  }
  buttonRadius = () => {
    if (this.state.delivery_config.menu_button_shape == "curved") {
      return 10
    } else if (this.state.delivery_config.menu_button_shape == "circle") {
      return 100
    } else {
      return ""
    }
  }

  socialIcon = (sourc) => (
    <View
      style={{
        height: 30,
        width: 30,
        backgroundColor: this.props.design.info_button_background_color,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
      }}
    >

      <i style={{ color: this.props.design.info_button_text_color, fontSize: 25 }}
        class={sourc}></i>
    </View>
  );

  loadSocialIconContainerStyle = () => {
    switch (this.state.delivery_config.social_profile_icon_position) {

      case "center_left":
        return {
          position: "fixed",
          left: this.state.delivery_config.menu_button_position == "left" ? 50 : 2,
          top: "40%",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          // alignSelf: 'center'
        }
      case "center_right":
        return {
          position: "fixed",
          right: this.state.delivery_config.menu_button_position == "right" ? 50 : 2,
          top: "40%",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          alignSelf: 'center'
        }
      case "top":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          top: 30,
          alignSelf: "center",
        }
      case "top_left":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          top: 30,
          left: 2,
        }
      case "top_right":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          top: 30,
          right: 2,
        }
      case "bottom_right":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          bottom: 50,
          right: 5,
        }
      case "bottom_left":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          bottom: 50,
          left: 5,
        }
      case "bottom":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          bottom: 50,
          alignSelf: "center",
        }
      default:
        return {
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          position: this.state.delivery_config.menu_button_position == "bottom" ? "fixed" : "",
          top: this.state.delivery_config.menu_button_position == "bottom" ? '40%' : ''

        }

    }
  }
  loadSocialIconStyle = () => {
    switch (this.state.delivery_config.social_profile_icon_position) {

      case "center_left":
      case "center_right":
      case "top_left":
      case "top_right":
      case "bottom_left":
      case "bottom_right":
        return {
          flexDirection: "column",
          borderRadius: 4,
        }
      default:
        return {
          flexDirection: "row",
          borderRadius: 4,
        }

    }
  }

  infoModalView = () => {
    const { config, design } = this.props;
    const { delivery_config } = this.state;
    var htmlstyles = StyleSheet.create({
      a: {
        fontWeight: design.popup_text_font_weight,
        fontSize: design.popup_text_font_size ?? 15,
        letterSpacing: FontLetterSpacing(design.custom_font.secondary_letter_space),
        color: design.popup_text_color,
        textTransform: design.popup_text_font_transform,
        fontStyle: design.popup_text_font_style,
        fontFamily: design.popup_text_font_family,


        marginVertical: 5,
      },
      p: {
        fontWeight: design.popup_text_font_weight,
        fontSize: design.popup_text_font_size ?? 15,
        letterSpacing: FontLetterSpacing(design.custom_font.secondary_letter_space),
        color: design.popup_text_color,
        textTransform: design.popup_text_font_transform,
        fontStyle: design.popup_text_font_style,
        fontFamily: design.popup_text_font_family,
        position: "relative",
        margin: 1,
      },
      strong: {

        fontWeight: design.popup_text_font_weight,
        fontSize: design.popup_text_font_size ?? 15,
        letterSpacing: FontLetterSpacing(design.custom_font.secondary_letter_space),
        color: design.popup_text_color,
        textTransform: design.popup_text_font_transform,
        fontStyle: design.popup_text_font_style,
        fontFamily: design.popup_text_font_family,
      },
      h1: {
        fontWeight: design.popup_text_font_weight,
        letterSpacing: FontLetterSpacing(design.custom_font.secondary_letter_space),
        color: design.popup_text_color,
        textTransform: design.popup_text_font_transform,
        fontStyle: design.popup_text_font_style,
        fontFamily: design.popup_text_font_family,
      },
      h2: {
        fontWeight: design.popup_text_font_weight,
        letterSpacing: FontLetterSpacing(design.custom_font.secondary_letter_space),
        color: design.popup_text_color,
        textTransform: design.popup_text_font_transform,
        fontStyle: design.popup_text_font_style,
        fontFamily: design.popup_text_font_family,
      },
      h3: {

        color: design.popup_text_color,
        textTransform: design.popup_text_font_transform,
        fontFamily: design.popup_text_font_family,
      },
      h4: {

        color: design.popup_text_color,
        textTransform: design.popup_text_font_transform,
        fontFamily: design.popup_text_font_family,
      },
      h5: {
        color: design.popup_text_color,
        textTransform: design.popup_text_font_transform,
        fontFamily: design.popup_text_font_family,
      },
      div: {
        color: design.popup_text_color
      },
      styledText: { fontFamily: "SecondaryFont" }
    });
    if (delivery_config && delivery_config.info) {
      return (
        <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backdropFilter: "blur(5px)" }}>
          <View
            style={{
              width: Dimensions.get('window').width * .8,
              backgroundColor: this.props.design.popup_background_color,
              borderWidth: 1,
              // padding:  22,
              justifyContent: 'center',
              // alignItems: 'center',
              borderRadius: 4,
              borderColor: 'rgba(0, 0, 0, 0.1)',
              height: Dimensions.get('window').height * .8
            }}
          >
            <ImageBackground imageStyle={{ borderRadius: 4 }} style={{ width: '100%', height: '100%', justifyContent: 'flex-end' }} source={delivery_config.info.image && { uri: delivery_config.info.image }}>
              <View style={{ flexDirection: 'row', width: '90%' }}>
                <View style={{ alignItems: 'center', marginBottom: 60, borderRadius: 10, flex: 1, marginTop: 20 }}>
                  <View style={{ alignItems: 'center', marginBottom: 22 }}>
                    <StyledText
                      font_color={this.props.design.popup_text_color}
                      font_weight={this.props.design.popup_text_font_weight}
                      font_style={this.props.design.popup_text_font_style}
                      text_transform={this.props.design.popup_text_font_transform}
                      font_family={this.props.design.popup_text_font_family}
                      font_size={this.props.design.popup_text_font_size ?? 18}
                      letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}


                      style={{}}>{delivery_config.info.header}</StyledText>
                  </View>
                </View>
              </View>
              <ScrollView>
                <View style={{ width: '100%', paddingHorizontal: 15 }}>
                  <HTMLView
                    value={delivery_config.info.text}
                    stylesheet={htmlstyles}
                    onLinkPress={(url) => console.log('clicked link: ', url)}
                  />

                </View>
              </ScrollView>
              <View style={{ borderRadius: 10, position: 'absolute', right: 5, top: 5 }}>
                <TouchableOpacity onPress={() => this.setState({ infoModal: false })}>
                  <View style={{ borderWidth: 1, borderColor: 'black', width: 50, height: 50, borderRadius: 7, alignItems: 'center', justifyContent: 'center', backgroundColor: this.props.design.popup_button_color }}>
                    {/* <Icon style={{ color: this.props.design.popup_button_text_color }} size={30} name={'close'} /> */}
                    <i
                      style={{ color: this.props.design.popup_button_text_color, fontSize: 25 }}
                      class="fas fa-times"
                    ></i>
                  </View>
                </TouchableOpacity>
              </View>
            </ImageBackground>
          </View>
        </View>
      );
    } else {
      return (
        <View />
      )
    }

  };
  // defaultLangView = () => (
  //   <LanguageContainer
  //     closeModal={() => this.setState({ defaultLangModal: false })}
  //   >
  //     {this.props.languages && this.props.languages.map((item, i) => (
  //       <TouchableOpacity
  //         key={i}
  //         onPress={() => {
  //           i18n.locale = item.value;

  //           this.props.addDefaultLanguage(item.value);
  //           this.setState({ defaultLangModal: false });
  //         }}
  //       >
  //         <View
  //           style={{
  //             paddingLeft: 3,
  //             paddingVertical: 7,
  //             borderBottom: "1px solid black",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <Text>{item.label}</Text>
  //         </View>
  //       </TouchableOpacity>
  //     ))}
  //   </LanguageContainer>
  // );
  getOnlineConfig = (venue, platform = "online") => {
    return axios.get(
      API_URL + `api/v2/online-config/${venue}/?platform=${platform}`
    );
  };
  getVenueDetails = async (item) => {
    this.setState({dataLoading:true})
    let uuid = item.online_ordering_prefix
    const exiting_uuid = await AsyncStorage.getItem("uuid");
    if (exiting_uuid == uuid && this.props.menu_type == "pickup") {
      this.setState({ modalVisible: !this.state.modalVisible })
      this.setState({dataLoading:false})

      this.props.navigation.navigate("HomeScreen");

      return
    }
    this.props.resetCart()
    axios.all([
      this.getOnlineConfig(uuid, "online"),

    ]).then(
      axios.spread(async (response,) => {

        let config = response.data.find(data => data.platform == "pickup");
        let if_not_available = await this.getMenuDetail(config.menu_to_display, uuid)
        console.log("hereeeeeeeeeeeee", if_not_available)
        if (if_not_available) {
          this.throwMessageFn(
            "Menu Not Available",
            "red",
            "white"
          )
          return
        }
        let languages = [];
        if (config.languages) {
          languages = config.languages;
          languages.unshift(config.default_language);
        } else {
          languages.push(config.default_language);
        }
        this.props.addLanguage(languages);
        this.setLocale(config)
        //    this.props.addDefaultLanguage(config.default_language.value);
        this.props.addConfig(config);
        this.props.selectMenu(config.menu_to_display)
        this.props.selectMenuData({ id: config.menu_to_display });
        this.getCampaign(uuid)
          .then(campaign => {
            this.setState({ modalVisible: !this.state.modalVisible })
            this.props.isLoading();

            this.props.addCampaign(campaign.data);
            this.props.setDeliveryMode("pickup");
            this.getAllData(uuid, "pickup")
            AsyncStorage.setItem("uuid", uuid);
            this.setState({dataLoading:false})

            this.props.navigation.navigate("HomeScreen");

          })


      }))
  }
  _renderModalContent = () => {
    return (
      <TouchableOpacity
        // style={{backgroundColor: 'rgba(0,0,0,0.5)'}}
        style={{
          flex: 1, alignItems: "center", justifyContent: "center",
          // backdropFilter: "blur(8px)"
          backgroundColor: "rgba(0, 0, 0, 0.5)"
        }}
        onPressOut={() => {
          this.setState({ modalVisible: !this.state.modalVisible });
        }}
      >
        <TouchableWithoutFeedback>
          <View
            style={{
              width: Dimensions.get("window").width * 0.73,
              backgroundColor: this.props.design.popup_background_color,
              // borderColor:'black',
              // borderWidth:1,
              padding: 8,
              // justifyContent: 'center',
              // alignItems: 'center',
              borderRadius: 8,
              maxHeight: "80%",
              backdropFilter: "blur(5px)",
              // alignItems:"center"
            }}
          >
            <ScrollView >
              {/*<List>*/}
              {this.state.delivery_config.venues
                ? this.state.delivery_config.venues.map((item, index) => {

                  return (
                    <View
                      key={index}
                      style={{

                      }}
                    >
                      <TouchableOpacity
                        key={index}
                        onPress={() => this.getVenueDetails(item)}
                        disabled={this.state.dataLoading}
                      >
                        <View style={{
                          // borderBottomColor: "black",
                          // borderBottomWidth: 1,
                          padding: 10,
                          // width: "100%",
                          alignItems: "center",
                          backgroundColor: this.props.design.popup_button_color,
                          marginBottom: index + 1 == this.state.delivery_config.venues.length ? 0 : 8,
                          borderRadius: 5,
                          // marginBottom:2,
                          //     borderRadius:5,
                          //     backgroundColor:this.props.design.popup_button_color
                        }}>
                          <StyledText
                            font_color={this.props.design.popup_button_text_color}
                            font_weight={this.props.design.popup_button_font_weight}
                            font_style={this.props.design.popup_button_font_style}
                            text_transform={this.props.design.popup_button_font_transform}
                            font_family={this.props.design.popup_button_font_family}
                            font_size={this.props.design.popup_button_font_size ?? 16}
                            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {
                              this.state.dataLoading ?
                                <i style={{ color: this.props.design.popup_button_text_color, fontSize: 25 }} class="fas fa-spinner fa-spin"></i>
                                :
                                <IntlLabel data={{ "en": item.displayName }} />
                            }

                          </StyledText>
                        </View>
                      </TouchableOpacity>
                    </View>
                  );
                })
                : null}
              {/*</List>*/}
            </ScrollView>
          </View>
        </TouchableWithoutFeedback>
      </TouchableOpacity>
    );

  };
  TopButtons = () => (
    <View style={{ height: "100%", position: "absolute", width: "100%" }}>
      <View
        style={{
          height: "100%",
          flexDirection: "row",
          alignItems: "center",
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >

        {/* {this.state.delivery_config.multi_language ? (
          <DefaultLanguage
            border_color={`1px solid ${this.props.design.info_button_background_color}`}
            changeLanguage={() =>
              this.setState({ defaultLangModal: !this.state.defaultLangModal })
            }
            backgroundColor={this.props.design.info_button_background_color}
            borderColor={"black"}
            color={this.props.design.info_button_text_color}
            default_language={this.props.default_language}
          />
        ) : null} */}
      </View>
      <View
        style={{
          height: "100%",
          flexDirection: "row",
          alignItems: "center",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      >
        {this.state.delivery_config.show_info && (
          <TouchableOpacity
            onPress={() => this.setState({ infoModal: true })}
            activeOpacity={1}
          >
            <View
              style={[
                style.fontButton,
                {
                  backgroundColor:
                    this.props.design.info_button_background_color,
                  border: `1px solid ${this.state.delivery_config.info_button_background_color}`,
                },
              ]}
            >
              <i
                style={{
                  color: this.props.design.info_button_text_color,
                  fontSize: 22,
                }}
                class="fa fa-info"
              ></i>
            </View>
          </TouchableOpacity>
        )}

        {this.state.delivery_config.feedback && (
          <FeedbackButton
            design={this.props.design}
            config={this.state.delivery_config}
            feedbackHandler={this.feedbackHandler}
            style={style}

          />
        )}




      </View>
    </View>
  );

  feedbackHandler = () => {
    if (this.state.delivery_config.use_external_feedback && this.state.delivery_config.feedback_url) {
      window.open(this.state.delivery_config.feedback_url, "_blank",);
    } else {
      this.setState({ FeedbackModal: true })

    }
  }
  onChangeFeedbackText = (text, index) => {
    let Q_A = this.state.feedback_q_a;
    Q_A[index]["answer"] = text;

    this.setState({ feedback_q_a: Q_A });
  };
  ratingCompleted = (rating, index) => {
    let Q_A = this.state.feedback_q_a;
    Q_A[index]["answer"] = rating;
    this.setState({ feedback_q_a: Q_A });
  };
  onPressYesBox = (index) => {
    console.log(index, "kk")
    let Q_A = this.state.feedback_q_a;
    Q_A[index]["answer"] = true;
    this.setState({ feedback_q_a: Q_A });
  };
  onPressNoBox = (index) => {
    let Q_A = this.state.feedback_q_a;
    Q_A[index]["answer"] = false;
    this.setState({ feedback_q_a: Q_A });
  };

  onSubmitFeedBack = () => {
    let feedbacks = this.state.feedback_q_a;
    let new_feedback_with_warning = [];
    let warning_count = 0;
    Promise.all(
      feedbacks.map((item, index) => {
        if (item.mandatory_answer && item.answer == null) {
          item["display_warning"] = "flex";
          warning_count = warning_count + 1;
          new_feedback_with_warning.push(item);
        } else {
          new_feedback_with_warning.push(item);
        }
      })
    ).then(async () => {
      if (warning_count > 0) {
        this.setState({ feedback_q_a: new_feedback_with_warning });
      } else {
        let results = this.state.delivery_config.survey;
        results["question_survey"] = this.state.feedback_q_a;
        let results_converted = JSON.stringify(results);
        // await AsyncStorage.getItem('LOGIN',async (err, res) => {
        //     if (res){
        //         let login = JSON.parse(res);
        const table = await AsyncStorage.getItem("uuid");
        axios
          .post(API_URL + "api/result/create/pwa/", {
            results: results_converted,
            venue: table,
            platform: "online"
          })
          .then((response) => {
            this.throwMessageFn(
              i18n.t("labels.feedback_submitted_successfully"),
              this.props.design.home_button_background_color
            );

            let feedback_q_a = [];
            Promise.all(
              this.props.survey.question_survey.map((item, index) => {
                item["display_warning"] = "none";
                item["answer"] = null;
                feedback_q_a.push(item);
              })
            ).then(() => {
              this.setState({ feedback: this.props.survey });
              this.setState({ feedback_q_a: feedback_q_a }, () => {
                this.setState({ FeedbackModal: false });
              });
            });
          })
          .catch((error) => {
            this.setState({ FeedbackModal: false });
            // this.throwMessageFn('Error submitting feedback', 'red')
          });
        //     }
        // });
      }
    });
  };
  toggleFeedbackModal = () => {
    this.setState({ FeedbackModal: !this.state.FeedbackModal })
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: this.props.design.home_page_background_color,
        }}
      >
        {/* <Image
          style={{ position: "absolute", width: "100%", height: "100%" }}
          source={{ uri: this.props.design.background_image }}
        /> */}
        {this.props.design.background_image && (
          <ProgressiveImage
            position={"absolute"}
            thumbnailSource={{
              uri:
                image_api +
                this.props.design.background_image
                  .split("/")
                  .splice(3)
                  .join("/") +
                "?w=250&h=250",
            }}
            source={{
              uri:
                image_api +
                this.props.design.background_image
                  .split("/")
                  .splice(3)
                  .join("/") +
                "?w=800&h=800",
            }}
            style={{ width: "100%", height: "100%", position: "absolute" }}
            resizeMode="cover"
          />
        )}
        {
          !!this.state.delivery_config.logo_image &&
          <View style={style.logoContainer} >
            <View style={style.logoImgContainer}>
              <Image
                source={{ uri: this.state.delivery_config.logo_image }}
                style={{ width: "100%", height: "100%" }}
              />
            </View>
          </View>

        }


        <View
          style={{ flex: 3, alignItems: "center", justifyContent: "center" }}
        >
          <StyledText
            font_color={this.props.design.list_view_text_color}
            font_weight={this.props.design.list_view_text_font_weight}
            font_style={this.props.design.list_view_text_font_style}
            text_transform={this.props.design.list_view_text_font_transform}
            font_family={this.props.design.list_view_text_font_family}
            font_size={this.props.design.list_view_text_font_size ?? 24}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

            style={{

            }}
          >
            {
              console.log(this.state.delivery_config, "ddd")
            }
            {this.state.delivery_config.welcome_message_online}
          </StyledText>
        </View>
        {this.state.not_avaliable ? (
          <View
            style={{ flex: 2, alignItems: "center", justifyContent: "center" }}
          >
            <StyledText
              font_color={this.props.design.list_view_text_color}
              font_weight={this.props.design.list_view_text_font_weight}
              font_style={this.props.design.list_view_text_font_style}
              text_transform={this.props.design.list_view_text_font_transform}
              font_family={this.props.design.list_view_text_font_family}
              font_size={this.props.design.list_view_text_font_size ?? 24}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{}}
            >
              Error Configuration
            </StyledText>
          </View>
        ) : (
          <View
            style={{ flex: 3, alignItems: "center", justifyContent: this.alignButtons() }}
          >
            {(this.state.pickup_config && this.state.pickup_config.allow_pickup) ?
              <TouchableOpacity
                style={{
                  width: "80%",
                  alignItems: "center",
                  marginBottom: 10,
                }}
                onPress={this.navigateToHome}
              >
                <View
                  style={{
                    width: "80%",
                    backgroundColor:
                      this.props.design.home_button_background_color,
                    height: 50,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: this.buttonRadius()
                  }}
                >
                  <StyledText
                    font_color={this.props.design.home_button_text_color}
                    font_weight={this.props.design.home_button_text_font_weight}
                    font_style={this.props.design.home_button_text_font_style}
                    text_transform={this.props.design.home_button_text_font_transform}
                    font_family={this.props.design.home_button_text_font_family}
                    font_size={this.props.design.home_button_text_font_size}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                    style={{}}
                  >
                    <IntlLabel data={JSON.parse(this.state.pickup_config.welcome_messages_multi)} />
                    {/* {this.state.pickup_config.welcome_note_pick} */}
                  </StyledText>
                </View>
              </TouchableOpacity>
              :
              <View />
            }
            {this.state.delivery_config.allow_delivery && (
              <TouchableOpacity
                style={{ width: "80%", alignItems: "center" }}
                onPress={this.navigateToDestination}
              >
                <View
                  style={{
                    width: "80%",
                    backgroundColor:
                      this.props.design.home_button_background_color,
                    height: 50,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: this.buttonRadius()
                  }}
                >
                  <StyledText
                    font_color={this.props.design.home_button_text_color}
                    font_weight={this.props.design.home_button_text_font_weight}
                    font_style={this.props.design.home_button_text_font_style}
                    text_transform={this.props.design.home_button_text_font_transform}
                    font_family={this.props.design.home_button_text_font_family}
                    font_size={this.props.design.home_button_text_font_size}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                    style={{}}
                  >
                    <IntlLabel data={JSON.parse(this.state.delivery_config.welcome_messages_multi)} />
                   
                    {/* {this.state.delivery_config.welcome_note} */}
                  </StyledText>
                </View>
              </TouchableOpacity>
            )}








            {
              this.state.delivery_config.social_profile_icons &&
              (
                <View
                  style={this.loadSocialIconContainerStyle()}
                >
                  <View
                    style={this.loadSocialIconStyle()}
                  >
                    {
                      !!this.state.delivery_config.social_profile.facebook &&
                      <View
                        style={{
                          margin: 4,
                          borderRadius: 4,
                          alignSelf: "center",
                          margin: 8,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            zIndex: 56,
                          }}
                          onPress={() => {
                            if (Platform.OS == "web") {
                              window.open(
                                `${this.state.delivery_config.social_profile.facebook ? "https://facebook.com/" + this.state.delivery_config.social_profile.facebook : "https://facebook.com/"}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {this.socialIcon("fab fa-facebook-f")}
                        </TouchableOpacity>
                      </View>

                    }
                    {
                      !!this.state.delivery_config.social_profile.twitter &&
                      <View
                        style={{
                          margin: 4,
                          borderRadius: 4,
                          alignSelf: "center",
                          margin: 8,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            zIndex: 56,
                          }}
                          onPress={() => {
                            if (Platform.OS == "web") {
                              window.open(
                                `${this.state.delivery_config.social_profile.twitter ? "https://twitter.com/" + this.state.delivery_config.social_profile.twitter : "https://twitter.com/"}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {this.socialIcon("fab fa-twitter")}
                        </TouchableOpacity>
                      </View>
                    }

                    {
                      !!this.state.delivery_config.social_profile.instagram &&
                      <View
                        style={{
                          margin: 4,
                          alignSelf: "center",
                          margin: 8,
                          borderRadius: 4,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            if (Platform.OS == "web") {
                              window.open(
                                `${this.state.delivery_config.social_profile.instagram ? "https://instagram.com/" + this.state.delivery_config.social_profile.instagram : "https://instagram.com/"}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {this.socialIcon("fab fa-instagram")}
                        </TouchableOpacity>
                      </View>
                    }

                    {
                      !!this.state.delivery_config.social_profile.tiktok &&
                      <View
                        style={{
                          margin: 4,
                          alignSelf: "center",
                          margin: 8,
                          borderRadius: 4,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            if (Platform.OS == "web") {
                              window.open(
                                `${this.state.delivery_config.social_profile.tiktok ? "https://tiktok.com/@" + this.state.delivery_config.social_profile.tiktok : "https://tiktok.com/"}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {this.socialIcon("fab fa-tiktok")}
                        </TouchableOpacity>
                      </View>
                    }

                    {
                      !!this.state.delivery_config.social_profile.snapchat &&
                      <View
                        style={{
                          margin: 4,
                          alignSelf: "center",
                          margin: 8,
                          borderRadius: 4,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            if (Platform.OS == "web") {
                              window.open(
                                `${this.state.delivery_config.social_profile.snapchat ? "https://snapchat.com/add/" + this.state.delivery_config.social_profile.snapchat : "https://snapchat.com/"}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {this.socialIcon("fab fa-snapchat")}
                        </TouchableOpacity>
                      </View>
                    }

                    {
                      !!this.state.delivery_config.social_profile.youtube &&
                      <View
                        style={{
                          margin: 4,
                          alignSelf: "center",
                          margin: 8,
                          borderRadius: 4,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            if (Platform.OS == "web") {
                              window.open(
                                `${this.state.delivery_config.social_profile.youtube ? "https://youtube.com/c/" + this.state.delivery_config.social_profile.youtube : "https://youtube.com/"}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {this.socialIcon("fab fa-youtube")}
                        </TouchableOpacity>
                      </View>
                    }

                    {
                      !!this.state.delivery_config.social_profile.website &&
                      <View
                        style={{
                          margin: 4,
                          alignSelf: "center",
                          margin: 8,
                          borderRadius: 4,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            if (Platform.OS == "web") {
                              window.open(
                                `${this.state.delivery_config.social_profile.website ? this.state.delivery_config.social_profile.website : "https://instagram.com/"}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {this.socialIcon("fas fa-globe")}
                        </TouchableOpacity>
                      </View>
                    }

                  </View>
                </View>
              )}


          </View>
        )}
        <Modal
          // animationType="slide"
          transparent
          visible={this.state.modalVisible}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          {this._renderModalContent()}
        </Modal>

        {/*info modal*/}
        <Modal
          transparent
          animationType="slide"
          visible={this.state.infoModal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          {this.infoModalView()}
        </Modal>

        {/*end info modal*/}
        {/*feedback modal*/}
        <Modal
          transparent
          animationType="slide"
          visible={this.state.FeedbackModal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          {
            this.state.delivery_config.survey &&
            <FeedbackForm
              config={this.state.delivery_config}
              design={this.props.design}
              onChangeFeedbackText={this.onChangeFeedbackText}
              feedback_q_a={this.state.feedback_q_a}
              ratingCompleted={this.ratingCompleted}
              onPressYesBox={this.onPressYesBox}
              onPressNoBox={this.onPressNoBox}
              toggleFeedbackModal={this.toggleFeedbackModal}
              onSubmitFeedBack={this.onSubmitFeedBack}
            />
          }

        </Modal>

        {/*end feedback modal*/}
        <View
          style={{
            position: "absolute",
            height: 60,
            width: "100%",
            bottom: 0,
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {this.MymenuLogo()}
        </View>

        <View
          style={{
            position: "absolute",
            height: 60,
            width: "100%",
            top: 0,
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          {this.TopButtons()}
        </View>

        <StyledText style={{ position: "absolute", bottom: 0, fontSize: 11, alignSelf: "center", fontFamily: 'SecondaryFont' }} >v{Constants.manifest.version
        }</StyledText>
        <Toast
          error_message={this.state.error_message}
          message_status={this.state.message_status}
          message_display={this.state.message_display}
          warn_text={this.state.warn_text}
          custom_font={this.props.design.custom_font}
        />

      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config.config,
    design: state.config.design,
    languages: state.languages.languages,
    platform: state.config.platform,
    selectedMenu: state.menus.menu,
    menu_type: state.config.menu_type

  };
};
export default connect(mapStateToProps, {
  setDeliveryMode,
  addCampaign,
  addConfig,
  addLanguage,
  addDefaultLanguage,
  addOnlineChildren,
  selectMenu,
  isLoading,
  selectMenuData,
  resetCart,
  allCart,
  totalPrice,
  updateCartCount

})(indexScreen);
