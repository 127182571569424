'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var util = require('util');  /* jshint ignore:line */
var TwiML = require('./TwiML');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * <Response> TwiML for Voice
 *
 * @constructor VoiceResponse
 */
/* jshint ignore:end */
function VoiceResponse() {
  TwiML.call(this);
  this._propertyName = 'response';
}

VoiceResponse.prototype = Object.create(TwiML.prototype);
VoiceResponse.prototype.constructor = VoiceResponse;

/* jshint ignore:start */
/**
 * <Connect> TwiML Verb
 *
 * @function connect
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.action] - Action URL
 * @param {string} [attributes.method] - Action URL method
 *
 * @returns Connect
 */
/* jshint ignore:end */
VoiceResponse.prototype.connect = function connect(attributes) {
  return new Connect(this.response.ele('Connect', attributes));
};

/* jshint ignore:start */
/**
 * <Dial> TwiML Verb
 *
 * @function dial
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.action] - Action URL
 * @param {string} [attributes.method] - Action URL method
 * @param {number} [attributes.timeout] - Time to wait for answer
 * @param {boolean} [attributes.hangupOnStar] - Hangup call on star press
 * @param {number} [attributes.timeLimit] - Max time length
 * @param {string} [attributes.callerId] - Caller ID to display
 * @param {dial.record} [attributes.record] - Record the call
 * @param {dial.trim} [attributes.trim] - Trim the recording
 * @param {string} [attributes.recordingStatusCallback] -
 *          Recording status callback URL
 * @param {string} [attributes.recordingStatusCallbackMethod] -
 *          Recording status callback URL method
 * @param {dial.recording_event} [attributes.recordingStatusCallbackEvent] -
 *          Recording status callback events
 * @param {boolean} [attributes.answerOnBridge] -
 *          Preserve the ringing behavior of the inbound call until the Dialed call picks up
 * @param {dial.ring_tone} [attributes.ringTone] -
 *          Ringtone allows you to override the ringback tone that Twilio will play back to the caller while executing the Dial
 * @param {dial.recording_track} [attributes.recordingTrack] -
 *          To indicate which audio track should be recorded
 * @param {boolean} [attributes.sequential] -
 *          Used to determine if child TwiML nouns should be dialed in order, one after the other (sequential) or dial all at once (parallel). Default is false, parallel
 * @param {string} [attributes.referUrl] -
 *          Webhook that will receive future SIP REFER requests
 * @param {string} [attributes.referMethod] -
 *          The HTTP method to use for the refer Webhook
 * @param {string} [number] - Phone number to dial
 *
 * @returns Dial
 */
/* jshint ignore:end */
VoiceResponse.prototype.dial = function dial(attributes, number) {
  return new Dial(this.response.ele('Dial', attributes, number));
};

/* jshint ignore:start */
/**
 * <Echo> TwiML Verb
 *
 * @function echo
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 *
 * @returns Echo
 */
/* jshint ignore:end */
VoiceResponse.prototype.echo = function echo(attributes) {
  return new Echo(this.response.ele('Echo', attributes));
};

/* jshint ignore:start */
/**
 * <Enqueue> TwiML Noun
 *
 * @function enqueue
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.action] - Action URL
 * @param {number} [attributes.maxQueueSize] - Maximum size of queue
 * @param {string} [attributes.method] - Action URL method
 * @param {string} [attributes.waitUrl] - Wait URL
 * @param {string} [attributes.waitUrlMethod] - Wait URL method
 * @param {string} [attributes.workflowSid] - TaskRouter Workflow SID
 * @param {string} [name] - Friendly name
 *
 * @returns Enqueue
 */
/* jshint ignore:end */
VoiceResponse.prototype.enqueue = function enqueue(attributes, name) {
  return new Enqueue(this.response.ele('Enqueue', attributes, name));
};

/* jshint ignore:start */
/**
 * <Gather> TwiML Verb
 *
 * @function gather
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {gather.input} [attributes.input] - Input type Twilio should accept
 * @param {string} [attributes.action] - Action URL
 * @param {string} [attributes.method] - Action URL method
 * @param {number} [attributes.timeout] - Time to wait to gather input
 * @param {string} [attributes.speechTimeout] -
 *          Time to wait to gather speech input and it should be either auto or a positive integer.
 * @param {number} [attributes.maxSpeechTime] - Max allowed time for speech input
 * @param {boolean} [attributes.profanityFilter] - Profanity Filter on speech
 * @param {string} [attributes.finishOnKey] - Finish gather on key
 * @param {number} [attributes.numDigits] - Number of digits to collect
 * @param {string} [attributes.partialResultCallback] - Partial result callback URL
 * @param {string} [attributes.partialResultCallbackMethod] -
 *          Partial result callback URL method
 * @param {gather.language} [attributes.language] - Language to use
 * @param {string} [attributes.hints] - Speech recognition hints
 * @param {boolean} [attributes.bargeIn] - Stop playing media upon speech
 * @param {boolean} [attributes.debug] - Allow debug for gather
 * @param {boolean} [attributes.actionOnEmptyResult] -
 *          Force webhook to the action URL event if there is no input
 * @param {gather.speech_model} [attributes.speechModel] -
 *          Specify the model that is best suited for your use case
 * @param {boolean} [attributes.enhanced] - Use enhanced speech model
 *
 * @returns Gather
 */
/* jshint ignore:end */
VoiceResponse.prototype.gather = function gather(attributes) {
  return new Gather(this.response.ele('Gather', attributes));
};

/* jshint ignore:start */
/**
 * <Hangup> TwiML Verb
 *
 * @function hangup
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 *
 * @returns Hangup
 */
/* jshint ignore:end */
VoiceResponse.prototype.hangup = function hangup(attributes) {
  return new Hangup(this.response.ele('Hangup', attributes));
};

/* jshint ignore:start */
/**
 * <Leave> TwiML Verb
 *
 * @function leave
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 *
 * @returns Leave
 */
/* jshint ignore:end */
VoiceResponse.prototype.leave = function leave(attributes) {
  return new Leave(this.response.ele('Leave', attributes));
};

/* jshint ignore:start */
/**
 * <Pause> TwiML Verb
 *
 * @function pause
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {number} [attributes.length] - Length in seconds to pause
 *
 * @returns Pause
 */
/* jshint ignore:end */
VoiceResponse.prototype.pause = function pause(attributes) {
  return new Pause(this.response.ele('Pause', attributes));
};

/* jshint ignore:start */
/**
 * <Play> TwiML Verb
 *
 * @function play
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {number} [attributes.loop] - Times to loop media
 * @param {string} [attributes.digits] - Play DTMF tones for digits
 * @param {url} [url] - Media URL
 *
 * @returns Play
 */
/* jshint ignore:end */
VoiceResponse.prototype.play = function play(attributes, url) {
  return new Play(this.response.ele('Play', attributes, url));
};

/* jshint ignore:start */
/**
 * <Queue> TwiML Noun
 *
 * @function queue
 * @memberof VoiceResponse#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.url] - Action URL
 * @param {string} [attributes.method] - Action URL method
 * @param {string} [attributes.reservationSid] - TaskRouter Reservation SID
 * @param {string} [attributes.postWorkActivitySid] - TaskRouter Activity SID
 * @param {string} name - Queue name
 *
 * @returns Queue
 */
/* jshint ignore:end */
VoiceResponse.prototype.queue = function queue(attributes, name) {
  return new Queue(this.response.ele('Queue', attributes, name));
};

/* jshint ignore:start */
/**
 * <Record> TwiML Verb
 *
 * @function record
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.action] - Action URL
 * @param {string} [attributes.method] - Action URL method
 * @param {number} [attributes.timeout] - Timeout to begin recording
 * @param {string} [attributes.finishOnKey] - Finish recording on key
 * @param {number} [attributes.maxLength] - Max time to record in seconds
 * @param {boolean} [attributes.playBeep] - Play beep
 * @param {record.trim} [attributes.trim] - Trim the recording
 * @param {string} [attributes.recordingStatusCallback] - Status callback URL
 * @param {string} [attributes.recordingStatusCallbackMethod] -
 *          Status callback URL method
 * @param {record.recording_event} [attributes.recordingStatusCallbackEvent] -
 *          Recording status callback events
 * @param {boolean} [attributes.transcribe] - Transcribe the recording
 * @param {string} [attributes.transcribeCallback] - Transcribe callback URL
 *
 * @returns Record
 */
/* jshint ignore:end */
VoiceResponse.prototype.record = function record(attributes) {
  return new Record(this.response.ele('Record', attributes));
};

/* jshint ignore:start */
/**
 * <Redirect> TwiML Verb
 *
 * @function redirect
 * @memberof VoiceResponse#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.method] - Redirect URL method
 * @param {url} url - Redirect URL
 *
 * @returns Redirect
 */
/* jshint ignore:end */
VoiceResponse.prototype.redirect = function redirect(attributes, url) {
  return new Redirect(this.response.ele('Redirect', attributes, url));
};

/* jshint ignore:start */
/**
 * <Reject> TwiML Verb
 *
 * @function reject
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {reject.reason} [attributes.reason] - Rejection reason
 *
 * @returns Reject
 */
/* jshint ignore:end */
VoiceResponse.prototype.reject = function reject(attributes) {
  return new Reject(this.response.ele('Reject', attributes));
};

/* jshint ignore:start */
/**
 * <Say> TwiML Verb
 *
 * @function say
 * @memberof VoiceResponse#
 *
 * @param {object} attributes - TwiML attributes
 * @param {say.voice} [attributes.voice] - Voice to use
 * @param {number} [attributes.loop] - Times to loop message
 * @param {say.language} [attributes.language] - Message language
 * @param {string} message - Message to say
 *
 * @returns Say
 */
/* jshint ignore:end */
VoiceResponse.prototype.say = function say(attributes, message) {
  return new Say(this.response.ele('Say', attributes, message));
};

/* jshint ignore:start */
/**
 * <Sms> TwiML Noun
 *
 * @function sms
 * @memberof VoiceResponse#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.to] - Number to send message to
 * @param {string} [attributes.from] - Number to send message from
 * @param {string} [attributes.action] - Action URL
 * @param {string} [attributes.method] - Action URL method
 * @param {string} [attributes.statusCallback] - Status callback URL
 * @param {string} message - Message body
 *
 * @returns Sms
 */
/* jshint ignore:end */
VoiceResponse.prototype.sms = function sms(attributes, message) {
  return new Sms(this.response.ele('Sms', attributes, message));
};

/* jshint ignore:start */
/**
 * <Pay> Twiml Verb
 *
 * @function pay
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {pay.input} [attributes.input] - Input type Twilio should accept
 * @param {string} [attributes.action] - Action URL
 * @param {pay.bank_account_type} [attributes.bankAccountType] -
 *          Bank account type for ach transactions. If set, payment method attribute must be provided and value should be set to ach-debit. defaults to consumer-checking
 * @param {string} [attributes.statusCallback] - Status callback URL
 * @param {pay.status_callback_method} [attributes.statusCallbackMethod] -
 *          Status callback method
 * @param {number} [attributes.timeout] - Time to wait to gather input
 * @param {number} [attributes.maxAttempts] -
 *          Maximum number of allowed retries when gathering input
 * @param {boolean} [attributes.securityCode] - Prompt for security code
 * @param {string} [attributes.postalCode] -
 *          Prompt for postal code and it should be true/false or default postal code
 * @param {number} [attributes.minPostalCodeLength] -
 *          Prompt for minimum postal code length
 * @param {string} [attributes.paymentConnector] -
 *          Unique name for payment connector
 * @param {pay.payment_method} [attributes.paymentMethod] -
 *          Payment method to be used. defaults to credit-card
 * @param {pay.token_type} [attributes.tokenType] - Type of token
 * @param {string} [attributes.chargeAmount] -
 *          Amount to process. If value is greater than 0 then make the payment else create a payment token
 * @param {string} [attributes.currency] - Currency of the amount attribute
 * @param {string} [attributes.description] - Details regarding the payment
 * @param {pay.valid_card_types} [attributes.validCardTypes] -
 *          Comma separated accepted card types
 * @param {pay.language} [attributes.language] - Language to use
 *
 * @returns Pay
 */
/* jshint ignore:end */
VoiceResponse.prototype.pay = function pay(attributes) {
  return new Pay(this.response.ele('Pay', attributes));
};

/* jshint ignore:start */
/**
 * <Prompt> Twiml Verb
 *
 * @function prompt
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {prompt.for} [attributes.for_] - Name of the payment source data element
 * @param {prompt.error_type} [attributes.errorType] - Type of error
 * @param {prompt.card_type} [attributes.cardType] - Type of the credit card
 * @param {number} [attributes.attempt] - Current attempt count
 * @param {boolean} [attributes.requireMatchingInputs] -
 *          Require customer to input requested information twice and verify matching.
 *
 * @returns Prompt
 */
/* jshint ignore:end */
VoiceResponse.prototype.prompt = function prompt(attributes) {
  return new Prompt(this.response.ele('Prompt', attributes));
};

/* jshint ignore:start */
/**
 * <Start> TwiML Verb
 *
 * @function start
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.action] - Action URL
 * @param {string} [attributes.method] - Action URL method
 *
 * @returns Start
 */
/* jshint ignore:end */
VoiceResponse.prototype.start = function start(attributes) {
  return new Start(this.response.ele('Start', attributes));
};

/* jshint ignore:start */
/**
 * <Stop> TwiML Verb
 *
 * @function stop
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 *
 * @returns Stop
 */
/* jshint ignore:end */
VoiceResponse.prototype.stop = function stop(attributes) {
  return new Stop(this.response.ele('Stop', attributes));
};

/* jshint ignore:start */
/**
 * <Refer> TwiML Verb
 *
 * @function refer
 * @memberof VoiceResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.action] - Action URL
 * @param {string} [attributes.method] - Action URL method
 *
 * @returns Refer
 */
/* jshint ignore:end */
VoiceResponse.prototype.refer = function refer(attributes) {
  return new Refer(this.response.ele('Refer', attributes));
};


/* jshint ignore:start */
/**
 * <Refer> TwiML Verb
 *
 * @constructor Refer
 *
 * @param {object} refer - <Refer> TwiML Verb
 */
/* jshint ignore:end */
function Refer(refer) {
  this.refer = refer;
  this._propertyName = 'refer';
}

Refer.prototype = Object.create(TwiML.prototype);
Refer.prototype.constructor = Refer;

/* jshint ignore:start */
/**
 * <Sip> TwiML Noun used in <Refer>
 *
 * @function sip
 * @memberof Refer#
 *
 * @param {object} attributes - TwiML attributes
 * @param {url} sipUrl - SIP URL
 *
 * @returns ReferSip
 */
/* jshint ignore:end */
Refer.prototype.sip = function sip(attributes, sipUrl) {
  return new ReferSip(this.refer.ele('Sip', attributes, sipUrl));
};

/* jshint ignore:start */
/**
 * <Sip> TwiML Noun used in <Refer>
 *
 * @function referSip
 * @memberof Refer#
 * @deprecated - Use sip() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {url} sipUrl - SIP URL
 *
 * @returns ReferSip
 */
/* jshint ignore:end */
Refer.prototype.referSip = util.deprecate(function referSip(attributes, sipUrl)
                                                             {
  return this.sip(attributes, sipUrl);
}, 'referSip() is deprecated. Use sip() instead.');


/* jshint ignore:start */
/**
 * <Sip> TwiML Noun used in <Refer>
 *
 * @constructor ReferSip
 *
 * @param {object} referSip - <Sip> TwiML Noun used in <Refer>
 */
/* jshint ignore:end */
function ReferSip(referSip) {
  this.referSip = referSip;
  this._propertyName = 'referSip';
}

ReferSip.prototype = Object.create(TwiML.prototype);
ReferSip.prototype.constructor = ReferSip;


/* jshint ignore:start */
/**
 * <Stop> TwiML Verb
 *
 * @constructor Stop
 *
 * @param {object} stop - <Stop> TwiML Verb
 */
/* jshint ignore:end */
function Stop(stop) {
  this.stop = stop;
  this._propertyName = 'stop';
}

Stop.prototype = Object.create(TwiML.prototype);
Stop.prototype.constructor = Stop;

/* jshint ignore:start */
/**
 * <Stream> TwiML Noun
 *
 * @function stream
 * @memberof Stop#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.name] - Friendly name given to the Stream
 * @param {string} [attributes.connectorName] - Unique name for Stream Connector
 * @param {string} [attributes.url] -
 *          URL of the remote service where the Stream is routed
 * @param {stream.track} [attributes.track] -
 *          Track to be streamed to remote service
 * @param {string} [attributes.statusCallback] - Status Callback URL
 * @param {stream.status_callback_method} [attributes.statusCallbackMethod] -
 *          Status Callback URL method
 *
 * @returns Stream
 */
/* jshint ignore:end */
Stop.prototype.stream = function stream(attributes) {
  return new Stream(this.stop.ele('Stream', attributes));
};

/* jshint ignore:start */
/**
 * <Siprec> TwiML Noun
 *
 * @function siprec
 * @memberof Stop#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.name] - Friendly name given to SIPREC
 * @param {string} [attributes.connectorName] - Unique name for Connector
 * @param {siprec.track} [attributes.track] -
 *          Track to be streamed to remote service
 *
 * @returns Siprec
 */
/* jshint ignore:end */
Stop.prototype.siprec = function siprec(attributes) {
  return new Siprec(this.stop.ele('Siprec', attributes));
};


/* jshint ignore:start */
/**
 * <Siprec> TwiML Noun
 *
 * @constructor Siprec
 *
 * @param {object} siprec - <Siprec> TwiML Noun
 */
/* jshint ignore:end */
function Siprec(siprec) {
  this.siprec = siprec;
  this._propertyName = 'siprec';
}

Siprec.prototype = Object.create(TwiML.prototype);
Siprec.prototype.constructor = Siprec;

/* jshint ignore:start */
/**
 * <Parameter> TwiML Noun
 *
 * @function parameter
 * @memberof Siprec#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.name] - The name of the custom parameter
 * @param {string} [attributes.value] - The value of the custom parameter
 *
 * @returns Parameter
 */
/* jshint ignore:end */
Siprec.prototype.parameter = function parameter(attributes) {
  return new Parameter(this.siprec.ele('Parameter', attributes));
};


/* jshint ignore:start */
/**
 * <Parameter> TwiML Noun
 *
 * @constructor Parameter
 *
 * @param {object} parameter - <Parameter> TwiML Noun
 */
/* jshint ignore:end */
function Parameter(parameter) {
  this.parameter = parameter;
  this._propertyName = 'parameter';
}

Parameter.prototype = Object.create(TwiML.prototype);
Parameter.prototype.constructor = Parameter;


/* jshint ignore:start */
/**
 * <Stream> TwiML Noun
 *
 * @constructor Stream
 *
 * @param {object} stream - <Stream> TwiML Noun
 */
/* jshint ignore:end */
function Stream(stream) {
  this.stream = stream;
  this._propertyName = 'stream';
}

Stream.prototype = Object.create(TwiML.prototype);
Stream.prototype.constructor = Stream;

/* jshint ignore:start */
/**
 * <Parameter> TwiML Noun
 *
 * @function parameter
 * @memberof Stream#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.name] - The name of the custom parameter
 * @param {string} [attributes.value] - The value of the custom parameter
 *
 * @returns Parameter
 */
/* jshint ignore:end */
Stream.prototype.parameter = function parameter(attributes) {
  return new Parameter(this.stream.ele('Parameter', attributes));
};


/* jshint ignore:start */
/**
 * <Start> TwiML Verb
 *
 * @constructor Start
 *
 * @param {object} start - <Start> TwiML Verb
 */
/* jshint ignore:end */
function Start(start) {
  this.start = start;
  this._propertyName = 'start';
}

Start.prototype = Object.create(TwiML.prototype);
Start.prototype.constructor = Start;

/* jshint ignore:start */
/**
 * <Stream> TwiML Noun
 *
 * @function stream
 * @memberof Start#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.name] - Friendly name given to the Stream
 * @param {string} [attributes.connectorName] - Unique name for Stream Connector
 * @param {string} [attributes.url] -
 *          URL of the remote service where the Stream is routed
 * @param {stream.track} [attributes.track] -
 *          Track to be streamed to remote service
 * @param {string} [attributes.statusCallback] - Status Callback URL
 * @param {stream.status_callback_method} [attributes.statusCallbackMethod] -
 *          Status Callback URL method
 *
 * @returns Stream
 */
/* jshint ignore:end */
Start.prototype.stream = function stream(attributes) {
  return new Stream(this.start.ele('Stream', attributes));
};

/* jshint ignore:start */
/**
 * <Siprec> TwiML Noun
 *
 * @function siprec
 * @memberof Start#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.name] - Friendly name given to SIPREC
 * @param {string} [attributes.connectorName] - Unique name for Connector
 * @param {siprec.track} [attributes.track] -
 *          Track to be streamed to remote service
 *
 * @returns Siprec
 */
/* jshint ignore:end */
Start.prototype.siprec = function siprec(attributes) {
  return new Siprec(this.start.ele('Siprec', attributes));
};


/* jshint ignore:start */
/**
 * <Prompt> Twiml Verb
 *
 * @constructor Prompt
 *
 * @param {object} prompt - <Prompt> Twiml Verb
 */
/* jshint ignore:end */
function Prompt(prompt) {
  this.prompt = prompt;
  this._propertyName = 'prompt';
}

Prompt.prototype = Object.create(TwiML.prototype);
Prompt.prototype.constructor = Prompt;

/* jshint ignore:start */
/**
 * <Say> TwiML Verb
 *
 * @function say
 * @memberof Prompt#
 *
 * @param {object} attributes - TwiML attributes
 * @param {say.voice} [attributes.voice] - Voice to use
 * @param {number} [attributes.loop] - Times to loop message
 * @param {say.language} [attributes.language] - Message language
 * @param {string} message - Message to say
 *
 * @returns Say
 */
/* jshint ignore:end */
Prompt.prototype.say = function say(attributes, message) {
  return new Say(this.prompt.ele('Say', attributes, message));
};

/* jshint ignore:start */
/**
 * <Play> TwiML Verb
 *
 * @function play
 * @memberof Prompt#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {number} [attributes.loop] - Times to loop media
 * @param {string} [attributes.digits] - Play DTMF tones for digits
 * @param {url} [url] - Media URL
 *
 * @returns Play
 */
/* jshint ignore:end */
Prompt.prototype.play = function play(attributes, url) {
  return new Play(this.prompt.ele('Play', attributes, url));
};

/* jshint ignore:start */
/**
 * <Pause> TwiML Verb
 *
 * @function pause
 * @memberof Prompt#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {number} [attributes.length] - Length in seconds to pause
 *
 * @returns Pause
 */
/* jshint ignore:end */
Prompt.prototype.pause = function pause(attributes) {
  return new Pause(this.prompt.ele('Pause', attributes));
};


/* jshint ignore:start */
/**
 * <Pause> TwiML Verb
 *
 * @constructor Pause
 *
 * @param {object} pause - <Pause> TwiML Verb
 */
/* jshint ignore:end */
function Pause(pause) {
  this.pause = pause;
  this._propertyName = 'pause';
}

Pause.prototype = Object.create(TwiML.prototype);
Pause.prototype.constructor = Pause;


/* jshint ignore:start */
/**
 * <Play> TwiML Verb
 *
 * @constructor Play
 *
 * @param {object} play - <Play> TwiML Verb
 */
/* jshint ignore:end */
function Play(play) {
  this.play = play;
  this._propertyName = 'play';
}

Play.prototype = Object.create(TwiML.prototype);
Play.prototype.constructor = Play;


/* jshint ignore:start */
/**
 * <Say> TwiML Verb
 *
 * @constructor Say
 *
 * @param {object} say - <Say> TwiML Verb
 */
/* jshint ignore:end */
function Say(say) {
  this.say = say;
  this._propertyName = 'say';
}

Say.prototype = Object.create(TwiML.prototype);
Say.prototype.constructor = Say;

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function break_
 * @memberof Say#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
Say.prototype.break_ = function break_(attributes) {
  return new SsmlBreak(this.say.ele('break', attributes));
};

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function ssmlBreak
 * @memberof Say#
 * @deprecated - Use break_() instead.
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
Say.prototype.ssmlBreak = util.deprecate(function ssmlBreak(attributes) {
  return this.break_(attributes);
}, 'ssmlBreak() is deprecated. Use break_() instead.');

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function emphasis
 * @memberof Say#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
Say.prototype.emphasis = function emphasis(attributes, words) {
  return new SsmlEmphasis(this.say.ele('emphasis', attributes, words));
};

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function ssmlEmphasis
 * @memberof Say#
 * @deprecated - Use emphasis() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
Say.prototype.ssmlEmphasis = util.deprecate(function ssmlEmphasis(attributes,
    words) {
  return this.emphasis(attributes, words);
}, 'ssmlEmphasis() is deprecated. Use emphasis() instead.');

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function lang
 * @memberof Say#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
Say.prototype.lang = function lang(attributes, words) {
  return new SsmlLang(this.say.ele('lang', attributes, words));
};

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function ssmlLang
 * @memberof Say#
 * @deprecated - Use lang() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
Say.prototype.ssmlLang = util.deprecate(function ssmlLang(attributes, words) {
  return this.lang(attributes, words);
}, 'ssmlLang() is deprecated. Use lang() instead.');

/* jshint ignore:start */
/**
 * Adding a Pause Between Paragraphs in <Say>
 *
 * @function p
 * @memberof Say#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlP
 */
/* jshint ignore:end */
Say.prototype.p = function p(attributes, words) {
  return new SsmlP(this.say.ele('p', attributes, words));
};

/* jshint ignore:start */
/**
 * Adding a Pause Between Paragraphs in <Say>
 *
 * @function ssmlP
 * @memberof Say#
 * @deprecated - Use p() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlP
 */
/* jshint ignore:end */
Say.prototype.ssmlP = util.deprecate(function ssmlP(attributes, words) {
  return this.p(attributes, words);
}, 'ssmlP() is deprecated. Use p() instead.');

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function phoneme
 * @memberof Say#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
Say.prototype.phoneme = function phoneme(attributes, words) {
  return new SsmlPhoneme(this.say.ele('phoneme', attributes, words));
};

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function ssmlPhoneme
 * @memberof Say#
 * @deprecated - Use phoneme() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
Say.prototype.ssmlPhoneme = util.deprecate(function ssmlPhoneme(attributes,
    words) {
  return this.phoneme(attributes, words);
}, 'ssmlPhoneme() is deprecated. Use phoneme() instead.');

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function prosody
 * @memberof Say#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
Say.prototype.prosody = function prosody(attributes, words) {
  return new SsmlProsody(this.say.ele('prosody', attributes, words));
};

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function ssmlProsody
 * @memberof Say#
 * @deprecated - Use prosody() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
Say.prototype.ssmlProsody = util.deprecate(function ssmlProsody(attributes,
    words) {
  return this.prosody(attributes, words);
}, 'ssmlProsody() is deprecated. Use prosody() instead.');

/* jshint ignore:start */
/**
 * Adding A Pause Between Sentences in <Say>
 *
 * @function s
 * @memberof Say#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlS
 */
/* jshint ignore:end */
Say.prototype.s = function s(attributes, words) {
  return new SsmlS(this.say.ele('s', attributes, words));
};

/* jshint ignore:start */
/**
 * Adding A Pause Between Sentences in <Say>
 *
 * @function ssmlS
 * @memberof Say#
 * @deprecated - Use s() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlS
 */
/* jshint ignore:end */
Say.prototype.ssmlS = util.deprecate(function ssmlS(attributes, words) {
  return this.s(attributes, words);
}, 'ssmlS() is deprecated. Use s() instead.');

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function sayAs
 * @memberof Say#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
Say.prototype.sayAs = function sayAs(attributes, words) {
  return new SsmlSayAs(this.say.ele('say-as', attributes, words));
};

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function ssmlSayAs
 * @memberof Say#
 * @deprecated - Use sayAs() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
Say.prototype.ssmlSayAs = util.deprecate(function ssmlSayAs(attributes, words) {
  return this.sayAs(attributes, words);
}, 'ssmlSayAs() is deprecated. Use sayAs() instead.');

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function sub
 * @memberof Say#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
Say.prototype.sub = function sub(attributes, words) {
  return new SsmlSub(this.say.ele('sub', attributes, words));
};

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function ssmlSub
 * @memberof Say#
 * @deprecated - Use sub() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
Say.prototype.ssmlSub = util.deprecate(function ssmlSub(attributes, words) {
  return this.sub(attributes, words);
}, 'ssmlSub() is deprecated. Use sub() instead.');

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function w
 * @memberof Say#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
Say.prototype.w = function w(attributes, words) {
  return new SsmlW(this.say.ele('w', attributes, words));
};

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function ssmlW
 * @memberof Say#
 * @deprecated - Use w() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
Say.prototype.ssmlW = util.deprecate(function ssmlW(attributes, words) {
  return this.w(attributes, words);
}, 'ssmlW() is deprecated. Use w() instead.');


/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @constructor SsmlW
 *
 * @param {object} ssmlW -
 *          Improving Pronunciation by Specifying Parts of Speech in <Say>
 */
/* jshint ignore:end */
function SsmlW(ssmlW) {
  this.ssmlW = ssmlW;
  this._propertyName = 'ssmlW';
}

SsmlW.prototype = Object.create(TwiML.prototype);
SsmlW.prototype.constructor = SsmlW;

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function break_
 * @memberof SsmlW#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlW.prototype.break_ = function break_(attributes) {
  return new SsmlBreak(this.w.ele('break', attributes));
};

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function ssmlBreak
 * @memberof SsmlW#
 * @deprecated - Use break_() instead.
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlW.prototype.ssmlBreak = util.deprecate(function ssmlBreak(attributes) {
  return this.break_(attributes);
}, 'ssmlBreak() is deprecated. Use break_() instead.');

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function emphasis
 * @memberof SsmlW#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlW.prototype.emphasis = function emphasis(attributes, words) {
  return new SsmlEmphasis(this.w.ele('emphasis', attributes, words));
};

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function ssmlEmphasis
 * @memberof SsmlW#
 * @deprecated - Use emphasis() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlW.prototype.ssmlEmphasis = util.deprecate(function ssmlEmphasis(attributes,
    words) {
  return this.emphasis(attributes, words);
}, 'ssmlEmphasis() is deprecated. Use emphasis() instead.');

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function phoneme
 * @memberof SsmlW#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlW.prototype.phoneme = function phoneme(attributes, words) {
  return new SsmlPhoneme(this.w.ele('phoneme', attributes, words));
};

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function ssmlPhoneme
 * @memberof SsmlW#
 * @deprecated - Use phoneme() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlW.prototype.ssmlPhoneme = util.deprecate(function ssmlPhoneme(attributes,
    words) {
  return this.phoneme(attributes, words);
}, 'ssmlPhoneme() is deprecated. Use phoneme() instead.');

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function prosody
 * @memberof SsmlW#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlW.prototype.prosody = function prosody(attributes, words) {
  return new SsmlProsody(this.w.ele('prosody', attributes, words));
};

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function ssmlProsody
 * @memberof SsmlW#
 * @deprecated - Use prosody() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlW.prototype.ssmlProsody = util.deprecate(function ssmlProsody(attributes,
    words) {
  return this.prosody(attributes, words);
}, 'ssmlProsody() is deprecated. Use prosody() instead.');

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function sayAs
 * @memberof SsmlW#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlW.prototype.sayAs = function sayAs(attributes, words) {
  return new SsmlSayAs(this.w.ele('say-as', attributes, words));
};

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function ssmlSayAs
 * @memberof SsmlW#
 * @deprecated - Use sayAs() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlW.prototype.ssmlSayAs = util.deprecate(function ssmlSayAs(attributes, words)
    {
  return this.sayAs(attributes, words);
}, 'ssmlSayAs() is deprecated. Use sayAs() instead.');

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function sub
 * @memberof SsmlW#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlW.prototype.sub = function sub(attributes, words) {
  return new SsmlSub(this.w.ele('sub', attributes, words));
};

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function ssmlSub
 * @memberof SsmlW#
 * @deprecated - Use sub() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlW.prototype.ssmlSub = util.deprecate(function ssmlSub(attributes, words) {
  return this.sub(attributes, words);
}, 'ssmlSub() is deprecated. Use sub() instead.');


/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @constructor SsmlSub
 *
 * @param {object} ssmlSub - Pronouncing Acronyms and Abbreviations in <Say>
 */
/* jshint ignore:end */
function SsmlSub(ssmlSub) {
  this.ssmlSub = ssmlSub;
  this._propertyName = 'ssmlSub';
}

SsmlSub.prototype = Object.create(TwiML.prototype);
SsmlSub.prototype.constructor = SsmlSub;


/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @constructor SsmlSayAs
 *
 * @param {object} ssmlSayAs -
 *          Controlling How Special Types of Words Are Spoken in <Say>
 */
/* jshint ignore:end */
function SsmlSayAs(ssmlSayAs) {
  this.ssmlSayAs = ssmlSayAs;
  this._propertyName = 'ssmlSayAs';
}

SsmlSayAs.prototype = Object.create(TwiML.prototype);
SsmlSayAs.prototype.constructor = SsmlSayAs;


/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @constructor SsmlProsody
 *
 * @param {object} ssmlProsody -
 *          Controling Volume, Speaking Rate, and Pitch in <Say>
 */
/* jshint ignore:end */
function SsmlProsody(ssmlProsody) {
  this.ssmlProsody = ssmlProsody;
  this._propertyName = 'ssmlProsody';
}

SsmlProsody.prototype = Object.create(TwiML.prototype);
SsmlProsody.prototype.constructor = SsmlProsody;

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function break_
 * @memberof SsmlProsody#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlProsody.prototype.break_ = function break_(attributes) {
  return new SsmlBreak(this.prosody.ele('break', attributes));
};

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function ssmlBreak
 * @memberof SsmlProsody#
 * @deprecated - Use break_() instead.
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlProsody.prototype.ssmlBreak = util.deprecate(function ssmlBreak(attributes)
    {
  return this.break_(attributes);
}, 'ssmlBreak() is deprecated. Use break_() instead.');

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function emphasis
 * @memberof SsmlProsody#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlProsody.prototype.emphasis = function emphasis(attributes, words) {
  return new SsmlEmphasis(this.prosody.ele('emphasis', attributes, words));
};

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function ssmlEmphasis
 * @memberof SsmlProsody#
 * @deprecated - Use emphasis() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlProsody.prototype.ssmlEmphasis = util.deprecate(function
    ssmlEmphasis(attributes, words) {
  return this.emphasis(attributes, words);
}, 'ssmlEmphasis() is deprecated. Use emphasis() instead.');

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function lang
 * @memberof SsmlProsody#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
SsmlProsody.prototype.lang = function lang(attributes, words) {
  return new SsmlLang(this.prosody.ele('lang', attributes, words));
};

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function ssmlLang
 * @memberof SsmlProsody#
 * @deprecated - Use lang() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
SsmlProsody.prototype.ssmlLang = util.deprecate(function ssmlLang(attributes,
    words) {
  return this.lang(attributes, words);
}, 'ssmlLang() is deprecated. Use lang() instead.');

/* jshint ignore:start */
/**
 * Adding a Pause Between Paragraphs in <Say>
 *
 * @function p
 * @memberof SsmlProsody#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlP
 */
/* jshint ignore:end */
SsmlProsody.prototype.p = function p(attributes, words) {
  return new SsmlP(this.prosody.ele('p', attributes, words));
};

/* jshint ignore:start */
/**
 * Adding a Pause Between Paragraphs in <Say>
 *
 * @function ssmlP
 * @memberof SsmlProsody#
 * @deprecated - Use p() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlP
 */
/* jshint ignore:end */
SsmlProsody.prototype.ssmlP = util.deprecate(function ssmlP(attributes, words) {
  return this.p(attributes, words);
}, 'ssmlP() is deprecated. Use p() instead.');

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function phoneme
 * @memberof SsmlProsody#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlProsody.prototype.phoneme = function phoneme(attributes, words) {
  return new SsmlPhoneme(this.prosody.ele('phoneme', attributes, words));
};

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function ssmlPhoneme
 * @memberof SsmlProsody#
 * @deprecated - Use phoneme() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlProsody.prototype.ssmlPhoneme = util.deprecate(function
    ssmlPhoneme(attributes, words) {
  return this.phoneme(attributes, words);
}, 'ssmlPhoneme() is deprecated. Use phoneme() instead.');

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function prosody
 * @memberof SsmlProsody#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlProsody.prototype.prosody = function prosody(attributes, words) {
  return new SsmlProsody(this.prosody.ele('prosody', attributes, words));
};

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function ssmlProsody
 * @memberof SsmlProsody#
 * @deprecated - Use prosody() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlProsody.prototype.ssmlProsody = util.deprecate(function
    ssmlProsody(attributes, words) {
  return this.prosody(attributes, words);
}, 'ssmlProsody() is deprecated. Use prosody() instead.');

/* jshint ignore:start */
/**
 * Adding A Pause Between Sentences in <Say>
 *
 * @function s
 * @memberof SsmlProsody#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlS
 */
/* jshint ignore:end */
SsmlProsody.prototype.s = function s(attributes, words) {
  return new SsmlS(this.prosody.ele('s', attributes, words));
};

/* jshint ignore:start */
/**
 * Adding A Pause Between Sentences in <Say>
 *
 * @function ssmlS
 * @memberof SsmlProsody#
 * @deprecated - Use s() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlS
 */
/* jshint ignore:end */
SsmlProsody.prototype.ssmlS = util.deprecate(function ssmlS(attributes, words) {
  return this.s(attributes, words);
}, 'ssmlS() is deprecated. Use s() instead.');

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function sayAs
 * @memberof SsmlProsody#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlProsody.prototype.sayAs = function sayAs(attributes, words) {
  return new SsmlSayAs(this.prosody.ele('say-as', attributes, words));
};

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function ssmlSayAs
 * @memberof SsmlProsody#
 * @deprecated - Use sayAs() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlProsody.prototype.ssmlSayAs = util.deprecate(function ssmlSayAs(attributes,
    words) {
  return this.sayAs(attributes, words);
}, 'ssmlSayAs() is deprecated. Use sayAs() instead.');

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function sub
 * @memberof SsmlProsody#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlProsody.prototype.sub = function sub(attributes, words) {
  return new SsmlSub(this.prosody.ele('sub', attributes, words));
};

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function ssmlSub
 * @memberof SsmlProsody#
 * @deprecated - Use sub() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlProsody.prototype.ssmlSub = util.deprecate(function ssmlSub(attributes,
    words) {
  return this.sub(attributes, words);
}, 'ssmlSub() is deprecated. Use sub() instead.');

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function w
 * @memberof SsmlProsody#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
SsmlProsody.prototype.w = function w(attributes, words) {
  return new SsmlW(this.prosody.ele('w', attributes, words));
};

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function ssmlW
 * @memberof SsmlProsody#
 * @deprecated - Use w() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
SsmlProsody.prototype.ssmlW = util.deprecate(function ssmlW(attributes, words) {
  return this.w(attributes, words);
}, 'ssmlW() is deprecated. Use w() instead.');


/* jshint ignore:start */
/**
 * Adding A Pause Between Sentences in <Say>
 *
 * @constructor SsmlS
 *
 * @param {object} ssmlS - Adding A Pause Between Sentences in <Say>
 */
/* jshint ignore:end */
function SsmlS(ssmlS) {
  this.ssmlS = ssmlS;
  this._propertyName = 'ssmlS';
}

SsmlS.prototype = Object.create(TwiML.prototype);
SsmlS.prototype.constructor = SsmlS;

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function break_
 * @memberof SsmlS#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlS.prototype.break_ = function break_(attributes) {
  return new SsmlBreak(this.s.ele('break', attributes));
};

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function ssmlBreak
 * @memberof SsmlS#
 * @deprecated - Use break_() instead.
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlS.prototype.ssmlBreak = util.deprecate(function ssmlBreak(attributes) {
  return this.break_(attributes);
}, 'ssmlBreak() is deprecated. Use break_() instead.');

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function emphasis
 * @memberof SsmlS#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlS.prototype.emphasis = function emphasis(attributes, words) {
  return new SsmlEmphasis(this.s.ele('emphasis', attributes, words));
};

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function ssmlEmphasis
 * @memberof SsmlS#
 * @deprecated - Use emphasis() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlS.prototype.ssmlEmphasis = util.deprecate(function ssmlEmphasis(attributes,
    words) {
  return this.emphasis(attributes, words);
}, 'ssmlEmphasis() is deprecated. Use emphasis() instead.');

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function lang
 * @memberof SsmlS#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
SsmlS.prototype.lang = function lang(attributes, words) {
  return new SsmlLang(this.s.ele('lang', attributes, words));
};

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function ssmlLang
 * @memberof SsmlS#
 * @deprecated - Use lang() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
SsmlS.prototype.ssmlLang = util.deprecate(function ssmlLang(attributes, words) {
  return this.lang(attributes, words);
}, 'ssmlLang() is deprecated. Use lang() instead.');

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function phoneme
 * @memberof SsmlS#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlS.prototype.phoneme = function phoneme(attributes, words) {
  return new SsmlPhoneme(this.s.ele('phoneme', attributes, words));
};

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function ssmlPhoneme
 * @memberof SsmlS#
 * @deprecated - Use phoneme() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlS.prototype.ssmlPhoneme = util.deprecate(function ssmlPhoneme(attributes,
    words) {
  return this.phoneme(attributes, words);
}, 'ssmlPhoneme() is deprecated. Use phoneme() instead.');

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function prosody
 * @memberof SsmlS#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlS.prototype.prosody = function prosody(attributes, words) {
  return new SsmlProsody(this.s.ele('prosody', attributes, words));
};

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function ssmlProsody
 * @memberof SsmlS#
 * @deprecated - Use prosody() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlS.prototype.ssmlProsody = util.deprecate(function ssmlProsody(attributes,
    words) {
  return this.prosody(attributes, words);
}, 'ssmlProsody() is deprecated. Use prosody() instead.');

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function sayAs
 * @memberof SsmlS#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlS.prototype.sayAs = function sayAs(attributes, words) {
  return new SsmlSayAs(this.s.ele('say-as', attributes, words));
};

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function ssmlSayAs
 * @memberof SsmlS#
 * @deprecated - Use sayAs() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlS.prototype.ssmlSayAs = util.deprecate(function ssmlSayAs(attributes, words)
    {
  return this.sayAs(attributes, words);
}, 'ssmlSayAs() is deprecated. Use sayAs() instead.');

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function sub
 * @memberof SsmlS#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlS.prototype.sub = function sub(attributes, words) {
  return new SsmlSub(this.s.ele('sub', attributes, words));
};

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function ssmlSub
 * @memberof SsmlS#
 * @deprecated - Use sub() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlS.prototype.ssmlSub = util.deprecate(function ssmlSub(attributes, words) {
  return this.sub(attributes, words);
}, 'ssmlSub() is deprecated. Use sub() instead.');

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function w
 * @memberof SsmlS#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
SsmlS.prototype.w = function w(attributes, words) {
  return new SsmlW(this.s.ele('w', attributes, words));
};

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function ssmlW
 * @memberof SsmlS#
 * @deprecated - Use w() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
SsmlS.prototype.ssmlW = util.deprecate(function ssmlW(attributes, words) {
  return this.w(attributes, words);
}, 'ssmlW() is deprecated. Use w() instead.');


/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @constructor SsmlPhoneme
 *
 * @param {object} ssmlPhoneme - Using Phonetic Pronunciation in <Say>
 */
/* jshint ignore:end */
function SsmlPhoneme(ssmlPhoneme) {
  this.ssmlPhoneme = ssmlPhoneme;
  this._propertyName = 'ssmlPhoneme';
}

SsmlPhoneme.prototype = Object.create(TwiML.prototype);
SsmlPhoneme.prototype.constructor = SsmlPhoneme;


/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @constructor SsmlLang
 *
 * @param {object} ssmlLang -
 *          Specifying Another Language for Specific Words in <Say>
 */
/* jshint ignore:end */
function SsmlLang(ssmlLang) {
  this.ssmlLang = ssmlLang;
  this._propertyName = 'ssmlLang';
}

SsmlLang.prototype = Object.create(TwiML.prototype);
SsmlLang.prototype.constructor = SsmlLang;

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function break_
 * @memberof SsmlLang#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlLang.prototype.break_ = function break_(attributes) {
  return new SsmlBreak(this.lang.ele('break', attributes));
};

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function ssmlBreak
 * @memberof SsmlLang#
 * @deprecated - Use break_() instead.
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlLang.prototype.ssmlBreak = util.deprecate(function ssmlBreak(attributes) {
  return this.break_(attributes);
}, 'ssmlBreak() is deprecated. Use break_() instead.');

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function emphasis
 * @memberof SsmlLang#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlLang.prototype.emphasis = function emphasis(attributes, words) {
  return new SsmlEmphasis(this.lang.ele('emphasis', attributes, words));
};

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function ssmlEmphasis
 * @memberof SsmlLang#
 * @deprecated - Use emphasis() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlLang.prototype.ssmlEmphasis = util.deprecate(function
    ssmlEmphasis(attributes, words) {
  return this.emphasis(attributes, words);
}, 'ssmlEmphasis() is deprecated. Use emphasis() instead.');

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function lang
 * @memberof SsmlLang#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
SsmlLang.prototype.lang = function lang(attributes, words) {
  return new SsmlLang(this.lang.ele('lang', attributes, words));
};

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function ssmlLang
 * @memberof SsmlLang#
 * @deprecated - Use lang() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
SsmlLang.prototype.ssmlLang = util.deprecate(function ssmlLang(attributes,
    words) {
  return this.lang(attributes, words);
}, 'ssmlLang() is deprecated. Use lang() instead.');

/* jshint ignore:start */
/**
 * Adding a Pause Between Paragraphs in <Say>
 *
 * @function p
 * @memberof SsmlLang#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlP
 */
/* jshint ignore:end */
SsmlLang.prototype.p = function p(attributes, words) {
  return new SsmlP(this.lang.ele('p', attributes, words));
};

/* jshint ignore:start */
/**
 * Adding a Pause Between Paragraphs in <Say>
 *
 * @function ssmlP
 * @memberof SsmlLang#
 * @deprecated - Use p() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlP
 */
/* jshint ignore:end */
SsmlLang.prototype.ssmlP = util.deprecate(function ssmlP(attributes, words) {
  return this.p(attributes, words);
}, 'ssmlP() is deprecated. Use p() instead.');

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function phoneme
 * @memberof SsmlLang#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlLang.prototype.phoneme = function phoneme(attributes, words) {
  return new SsmlPhoneme(this.lang.ele('phoneme', attributes, words));
};

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function ssmlPhoneme
 * @memberof SsmlLang#
 * @deprecated - Use phoneme() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlLang.prototype.ssmlPhoneme = util.deprecate(function ssmlPhoneme(attributes,
    words) {
  return this.phoneme(attributes, words);
}, 'ssmlPhoneme() is deprecated. Use phoneme() instead.');

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function prosody
 * @memberof SsmlLang#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlLang.prototype.prosody = function prosody(attributes, words) {
  return new SsmlProsody(this.lang.ele('prosody', attributes, words));
};

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function ssmlProsody
 * @memberof SsmlLang#
 * @deprecated - Use prosody() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlLang.prototype.ssmlProsody = util.deprecate(function ssmlProsody(attributes,
    words) {
  return this.prosody(attributes, words);
}, 'ssmlProsody() is deprecated. Use prosody() instead.');

/* jshint ignore:start */
/**
 * Adding A Pause Between Sentences in <Say>
 *
 * @function s
 * @memberof SsmlLang#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlS
 */
/* jshint ignore:end */
SsmlLang.prototype.s = function s(attributes, words) {
  return new SsmlS(this.lang.ele('s', attributes, words));
};

/* jshint ignore:start */
/**
 * Adding A Pause Between Sentences in <Say>
 *
 * @function ssmlS
 * @memberof SsmlLang#
 * @deprecated - Use s() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlS
 */
/* jshint ignore:end */
SsmlLang.prototype.ssmlS = util.deprecate(function ssmlS(attributes, words) {
  return this.s(attributes, words);
}, 'ssmlS() is deprecated. Use s() instead.');

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function sayAs
 * @memberof SsmlLang#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlLang.prototype.sayAs = function sayAs(attributes, words) {
  return new SsmlSayAs(this.lang.ele('say-as', attributes, words));
};

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function ssmlSayAs
 * @memberof SsmlLang#
 * @deprecated - Use sayAs() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlLang.prototype.ssmlSayAs = util.deprecate(function ssmlSayAs(attributes,
    words) {
  return this.sayAs(attributes, words);
}, 'ssmlSayAs() is deprecated. Use sayAs() instead.');

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function sub
 * @memberof SsmlLang#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlLang.prototype.sub = function sub(attributes, words) {
  return new SsmlSub(this.lang.ele('sub', attributes, words));
};

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function ssmlSub
 * @memberof SsmlLang#
 * @deprecated - Use sub() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlLang.prototype.ssmlSub = util.deprecate(function ssmlSub(attributes, words)
    {
  return this.sub(attributes, words);
}, 'ssmlSub() is deprecated. Use sub() instead.');

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function w
 * @memberof SsmlLang#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
SsmlLang.prototype.w = function w(attributes, words) {
  return new SsmlW(this.lang.ele('w', attributes, words));
};

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function ssmlW
 * @memberof SsmlLang#
 * @deprecated - Use w() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
SsmlLang.prototype.ssmlW = util.deprecate(function ssmlW(attributes, words) {
  return this.w(attributes, words);
}, 'ssmlW() is deprecated. Use w() instead.');


/* jshint ignore:start */
/**
 * Adding a Pause Between Paragraphs in <Say>
 *
 * @constructor SsmlP
 *
 * @param {object} ssmlP - Adding a Pause Between Paragraphs in <Say>
 */
/* jshint ignore:end */
function SsmlP(ssmlP) {
  this.ssmlP = ssmlP;
  this._propertyName = 'ssmlP';
}

SsmlP.prototype = Object.create(TwiML.prototype);
SsmlP.prototype.constructor = SsmlP;

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function break_
 * @memberof SsmlP#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlP.prototype.break_ = function break_(attributes) {
  return new SsmlBreak(this.p.ele('break', attributes));
};

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function ssmlBreak
 * @memberof SsmlP#
 * @deprecated - Use break_() instead.
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlP.prototype.ssmlBreak = util.deprecate(function ssmlBreak(attributes) {
  return this.break_(attributes);
}, 'ssmlBreak() is deprecated. Use break_() instead.');

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function emphasis
 * @memberof SsmlP#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlP.prototype.emphasis = function emphasis(attributes, words) {
  return new SsmlEmphasis(this.p.ele('emphasis', attributes, words));
};

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function ssmlEmphasis
 * @memberof SsmlP#
 * @deprecated - Use emphasis() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlP.prototype.ssmlEmphasis = util.deprecate(function ssmlEmphasis(attributes,
    words) {
  return this.emphasis(attributes, words);
}, 'ssmlEmphasis() is deprecated. Use emphasis() instead.');

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function lang
 * @memberof SsmlP#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
SsmlP.prototype.lang = function lang(attributes, words) {
  return new SsmlLang(this.p.ele('lang', attributes, words));
};

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function ssmlLang
 * @memberof SsmlP#
 * @deprecated - Use lang() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
SsmlP.prototype.ssmlLang = util.deprecate(function ssmlLang(attributes, words) {
  return this.lang(attributes, words);
}, 'ssmlLang() is deprecated. Use lang() instead.');

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function phoneme
 * @memberof SsmlP#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlP.prototype.phoneme = function phoneme(attributes, words) {
  return new SsmlPhoneme(this.p.ele('phoneme', attributes, words));
};

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function ssmlPhoneme
 * @memberof SsmlP#
 * @deprecated - Use phoneme() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlP.prototype.ssmlPhoneme = util.deprecate(function ssmlPhoneme(attributes,
    words) {
  return this.phoneme(attributes, words);
}, 'ssmlPhoneme() is deprecated. Use phoneme() instead.');

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function prosody
 * @memberof SsmlP#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlP.prototype.prosody = function prosody(attributes, words) {
  return new SsmlProsody(this.p.ele('prosody', attributes, words));
};

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function ssmlProsody
 * @memberof SsmlP#
 * @deprecated - Use prosody() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlP.prototype.ssmlProsody = util.deprecate(function ssmlProsody(attributes,
    words) {
  return this.prosody(attributes, words);
}, 'ssmlProsody() is deprecated. Use prosody() instead.');

/* jshint ignore:start */
/**
 * Adding A Pause Between Sentences in <Say>
 *
 * @function s
 * @memberof SsmlP#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlS
 */
/* jshint ignore:end */
SsmlP.prototype.s = function s(attributes, words) {
  return new SsmlS(this.p.ele('s', attributes, words));
};

/* jshint ignore:start */
/**
 * Adding A Pause Between Sentences in <Say>
 *
 * @function ssmlS
 * @memberof SsmlP#
 * @deprecated - Use s() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} words - Words to speak
 *
 * @returns SsmlS
 */
/* jshint ignore:end */
SsmlP.prototype.ssmlS = util.deprecate(function ssmlS(attributes, words) {
  return this.s(attributes, words);
}, 'ssmlS() is deprecated. Use s() instead.');

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function sayAs
 * @memberof SsmlP#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlP.prototype.sayAs = function sayAs(attributes, words) {
  return new SsmlSayAs(this.p.ele('say-as', attributes, words));
};

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function ssmlSayAs
 * @memberof SsmlP#
 * @deprecated - Use sayAs() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlP.prototype.ssmlSayAs = util.deprecate(function ssmlSayAs(attributes, words)
    {
  return this.sayAs(attributes, words);
}, 'ssmlSayAs() is deprecated. Use sayAs() instead.');

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function sub
 * @memberof SsmlP#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlP.prototype.sub = function sub(attributes, words) {
  return new SsmlSub(this.p.ele('sub', attributes, words));
};

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function ssmlSub
 * @memberof SsmlP#
 * @deprecated - Use sub() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlP.prototype.ssmlSub = util.deprecate(function ssmlSub(attributes, words) {
  return this.sub(attributes, words);
}, 'ssmlSub() is deprecated. Use sub() instead.');

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function w
 * @memberof SsmlP#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
SsmlP.prototype.w = function w(attributes, words) {
  return new SsmlW(this.p.ele('w', attributes, words));
};

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function ssmlW
 * @memberof SsmlP#
 * @deprecated - Use w() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
SsmlP.prototype.ssmlW = util.deprecate(function ssmlW(attributes, words) {
  return this.w(attributes, words);
}, 'ssmlW() is deprecated. Use w() instead.');


/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @constructor SsmlEmphasis
 *
 * @param {object} ssmlEmphasis - Emphasizing Words in <Say>
 */
/* jshint ignore:end */
function SsmlEmphasis(ssmlEmphasis) {
  this.ssmlEmphasis = ssmlEmphasis;
  this._propertyName = 'ssmlEmphasis';
}

SsmlEmphasis.prototype = Object.create(TwiML.prototype);
SsmlEmphasis.prototype.constructor = SsmlEmphasis;

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function break_
 * @memberof SsmlEmphasis#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.break_ = function break_(attributes) {
  return new SsmlBreak(this.emphasis.ele('break', attributes));
};

/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @function ssmlBreak
 * @memberof SsmlEmphasis#
 * @deprecated - Use break_() instead.
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {ssml_break.strength} [attributes.strength] -
 *          Set a pause based on strength
 * @param {string} [attributes.time] -
 *          Set a pause to a specific length of time in seconds or milliseconds, available values: [number]s, [number]ms
 *
 * @returns SsmlBreak
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.ssmlBreak = util.deprecate(function ssmlBreak(attributes)
    {
  return this.break_(attributes);
}, 'ssmlBreak() is deprecated. Use break_() instead.');

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function emphasis
 * @memberof SsmlEmphasis#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.emphasis = function emphasis(attributes, words) {
  return new SsmlEmphasis(this.emphasis.ele('emphasis', attributes, words));
};

/* jshint ignore:start */
/**
 * Emphasizing Words in <Say>
 *
 * @function ssmlEmphasis
 * @memberof SsmlEmphasis#
 * @deprecated - Use emphasis() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_emphasis.level} [attributes.level] - Specify the degree of emphasis
 * @param {string} words - Words to emphasize
 *
 * @returns SsmlEmphasis
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.ssmlEmphasis = util.deprecate(function
    ssmlEmphasis(attributes, words) {
  return this.emphasis(attributes, words);
}, 'ssmlEmphasis() is deprecated. Use emphasis() instead.');

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function lang
 * @memberof SsmlEmphasis#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.lang = function lang(attributes, words) {
  return new SsmlLang(this.emphasis.ele('lang', attributes, words));
};

/* jshint ignore:start */
/**
 * Specifying Another Language for Specific Words in <Say>
 *
 * @function ssmlLang
 * @memberof SsmlEmphasis#
 * @deprecated - Use lang() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_lang.xml_lang} [attributes.xml:lang] - Specify the language
 * @param {string} words - Words to speak
 *
 * @returns SsmlLang
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.ssmlLang = util.deprecate(function ssmlLang(attributes,
    words) {
  return this.lang(attributes, words);
}, 'ssmlLang() is deprecated. Use lang() instead.');

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function phoneme
 * @memberof SsmlEmphasis#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.phoneme = function phoneme(attributes, words) {
  return new SsmlPhoneme(this.emphasis.ele('phoneme', attributes, words));
};

/* jshint ignore:start */
/**
 * Using Phonetic Pronunciation in <Say>
 *
 * @function ssmlPhoneme
 * @memberof SsmlEmphasis#
 * @deprecated - Use phoneme() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_phoneme.alphabet} [attributes.alphabet] -
 *          Specify the phonetic alphabet
 * @param {string} [attributes.ph] -
 *          Specifiy the phonetic symbols for pronunciation
 * @param {string} words - Words to speak
 *
 * @returns SsmlPhoneme
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.ssmlPhoneme = util.deprecate(function
    ssmlPhoneme(attributes, words) {
  return this.phoneme(attributes, words);
}, 'ssmlPhoneme() is deprecated. Use phoneme() instead.');

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function prosody
 * @memberof SsmlEmphasis#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.prosody = function prosody(attributes, words) {
  return new SsmlProsody(this.emphasis.ele('prosody', attributes, words));
};

/* jshint ignore:start */
/**
 * Controling Volume, Speaking Rate, and Pitch in <Say>
 *
 * @function ssmlProsody
 * @memberof SsmlEmphasis#
 * @deprecated - Use prosody() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.volume] -
 *          Specify the volume, available values: default, silent, x-soft, soft, medium, loud, x-loud, +ndB, -ndB
 * @param {string} [attributes.rate] -
 *          Specify the rate, available values: x-slow, slow, medium, fast, x-fast, n%
 * @param {string} [attributes.pitch] -
 *          Specify the pitch, available values: default, x-low, low, medium, high, x-high, +n%, -n%
 * @param {string} words - Words to speak
 *
 * @returns SsmlProsody
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.ssmlProsody = util.deprecate(function
    ssmlProsody(attributes, words) {
  return this.prosody(attributes, words);
}, 'ssmlProsody() is deprecated. Use prosody() instead.');

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function sayAs
 * @memberof SsmlEmphasis#
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.sayAs = function sayAs(attributes, words) {
  return new SsmlSayAs(this.emphasis.ele('say-as', attributes, words));
};

/* jshint ignore:start */
/**
 * Controlling How Special Types of Words Are Spoken in <Say>
 *
 * @function ssmlSayAs
 * @memberof SsmlEmphasis#
 * @deprecated - Use sayAs() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {ssml_say_as.interpret_as} [attributes.interpret-
 *         as] - Specify the type of words are spoken
 * @param {ssml_say_as.format} [attributes.format] -
 *          Specify the format of the date when interpret-as is set to date
 * @param {string} words - Words to be interpreted
 *
 * @returns SsmlSayAs
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.ssmlSayAs = util.deprecate(function ssmlSayAs(attributes,
    words) {
  return this.sayAs(attributes, words);
}, 'ssmlSayAs() is deprecated. Use sayAs() instead.');

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function sub
 * @memberof SsmlEmphasis#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.sub = function sub(attributes, words) {
  return new SsmlSub(this.emphasis.ele('sub', attributes, words));
};

/* jshint ignore:start */
/**
 * Pronouncing Acronyms and Abbreviations in <Say>
 *
 * @function ssmlSub
 * @memberof SsmlEmphasis#
 * @deprecated - Use sub() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.alias] -
 *          Substitute a different word (or pronunciation) for selected text such as an acronym or abbreviation
 * @param {string} words - Words to be substituted
 *
 * @returns SsmlSub
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.ssmlSub = util.deprecate(function ssmlSub(attributes,
    words) {
  return this.sub(attributes, words);
}, 'ssmlSub() is deprecated. Use sub() instead.');

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function w
 * @memberof SsmlEmphasis#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.w = function w(attributes, words) {
  return new SsmlW(this.emphasis.ele('w', attributes, words));
};

/* jshint ignore:start */
/**
 * Improving Pronunciation by Specifying Parts of Speech in <Say>
 *
 * @function ssmlW
 * @memberof SsmlEmphasis#
 * @deprecated - Use w() instead.
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.role] -
 *          Customize the pronunciation of words by specifying the word’s part of speech or alternate meaning
 * @param {string} words - Words to speak
 *
 * @returns SsmlW
 */
/* jshint ignore:end */
SsmlEmphasis.prototype.ssmlW = util.deprecate(function ssmlW(attributes, words)
    {
  return this.w(attributes, words);
}, 'ssmlW() is deprecated. Use w() instead.');


/* jshint ignore:start */
/**
 * Adding a Pause in <Say>
 *
 * @constructor SsmlBreak
 *
 * @param {object} ssmlBreak - Adding a Pause in <Say>
 */
/* jshint ignore:end */
function SsmlBreak(ssmlBreak) {
  this.ssmlBreak = ssmlBreak;
  this._propertyName = 'ssmlBreak';
}

SsmlBreak.prototype = Object.create(TwiML.prototype);
SsmlBreak.prototype.constructor = SsmlBreak;


/* jshint ignore:start */
/**
 * <Pay> Twiml Verb
 *
 * @constructor Pay
 *
 * @param {object} pay - <Pay> Twiml Verb
 */
/* jshint ignore:end */
function Pay(pay) {
  this.pay = pay;
  this._propertyName = 'pay';
}

Pay.prototype = Object.create(TwiML.prototype);
Pay.prototype.constructor = Pay;

/* jshint ignore:start */
/**
 * <Prompt> Twiml Verb
 *
 * @function prompt
 * @memberof Pay#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {prompt.for} [attributes.for_] - Name of the payment source data element
 * @param {prompt.error_type} [attributes.errorType] - Type of error
 * @param {prompt.card_type} [attributes.cardType] - Type of the credit card
 * @param {number} [attributes.attempt] - Current attempt count
 * @param {boolean} [attributes.requireMatchingInputs] -
 *          Require customer to input requested information twice and verify matching.
 *
 * @returns Prompt
 */
/* jshint ignore:end */
Pay.prototype.prompt = function prompt(attributes) {
  return new Prompt(this.pay.ele('Prompt', attributes));
};

/* jshint ignore:start */
/**
 * <Parameter> TwiML Noun
 *
 * @function parameter
 * @memberof Pay#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.name] - The name of the custom parameter
 * @param {string} [attributes.value] - The value of the custom parameter
 *
 * @returns Parameter
 */
/* jshint ignore:end */
Pay.prototype.parameter = function parameter(attributes) {
  return new Parameter(this.pay.ele('Parameter', attributes));
};


/* jshint ignore:start */
/**
 * <Sms> TwiML Noun
 *
 * @constructor Sms
 *
 * @param {object} sms - <Sms> TwiML Noun
 */
/* jshint ignore:end */
function Sms(sms) {
  this.sms = sms;
  this._propertyName = 'sms';
}

Sms.prototype = Object.create(TwiML.prototype);
Sms.prototype.constructor = Sms;


/* jshint ignore:start */
/**
 * <Reject> TwiML Verb
 *
 * @constructor Reject
 *
 * @param {object} reject - <Reject> TwiML Verb
 */
/* jshint ignore:end */
function Reject(reject) {
  this.reject = reject;
  this._propertyName = 'reject';
}

Reject.prototype = Object.create(TwiML.prototype);
Reject.prototype.constructor = Reject;


/* jshint ignore:start */
/**
 * <Redirect> TwiML Verb
 *
 * @constructor Redirect
 *
 * @param {object} redirect - <Redirect> TwiML Verb
 */
/* jshint ignore:end */
function Redirect(redirect) {
  this.redirect = redirect;
  this._propertyName = 'redirect';
}

Redirect.prototype = Object.create(TwiML.prototype);
Redirect.prototype.constructor = Redirect;


/* jshint ignore:start */
/**
 * <Record> TwiML Verb
 *
 * @constructor Record
 *
 * @param {object} record - <Record> TwiML Verb
 */
/* jshint ignore:end */
function Record(record) {
  this.record = record;
  this._propertyName = 'record';
}

Record.prototype = Object.create(TwiML.prototype);
Record.prototype.constructor = Record;


/* jshint ignore:start */
/**
 * <Queue> TwiML Noun
 *
 * @constructor Queue
 *
 * @param {object} queue - <Queue> TwiML Noun
 */
/* jshint ignore:end */
function Queue(queue) {
  this.queue = queue;
  this._propertyName = 'queue';
}

Queue.prototype = Object.create(TwiML.prototype);
Queue.prototype.constructor = Queue;


/* jshint ignore:start */
/**
 * <Leave> TwiML Verb
 *
 * @constructor Leave
 *
 * @param {object} leave - <Leave> TwiML Verb
 */
/* jshint ignore:end */
function Leave(leave) {
  this.leave = leave;
  this._propertyName = 'leave';
}

Leave.prototype = Object.create(TwiML.prototype);
Leave.prototype.constructor = Leave;


/* jshint ignore:start */
/**
 * <Hangup> TwiML Verb
 *
 * @constructor Hangup
 *
 * @param {object} hangup - <Hangup> TwiML Verb
 */
/* jshint ignore:end */
function Hangup(hangup) {
  this.hangup = hangup;
  this._propertyName = 'hangup';
}

Hangup.prototype = Object.create(TwiML.prototype);
Hangup.prototype.constructor = Hangup;


/* jshint ignore:start */
/**
 * <Gather> TwiML Verb
 *
 * @constructor Gather
 *
 * @param {object} gather - <Gather> TwiML Verb
 */
/* jshint ignore:end */
function Gather(gather) {
  this.gather = gather;
  this._propertyName = 'gather';
}

Gather.prototype = Object.create(TwiML.prototype);
Gather.prototype.constructor = Gather;

/* jshint ignore:start */
/**
 * <Say> TwiML Verb
 *
 * @function say
 * @memberof Gather#
 *
 * @param {object} attributes - TwiML attributes
 * @param {say.voice} [attributes.voice] - Voice to use
 * @param {number} [attributes.loop] - Times to loop message
 * @param {say.language} [attributes.language] - Message language
 * @param {string} message - Message to say
 *
 * @returns Say
 */
/* jshint ignore:end */
Gather.prototype.say = function say(attributes, message) {
  return new Say(this.gather.ele('Say', attributes, message));
};

/* jshint ignore:start */
/**
 * <Pause> TwiML Verb
 *
 * @function pause
 * @memberof Gather#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {number} [attributes.length] - Length in seconds to pause
 *
 * @returns Pause
 */
/* jshint ignore:end */
Gather.prototype.pause = function pause(attributes) {
  return new Pause(this.gather.ele('Pause', attributes));
};

/* jshint ignore:start */
/**
 * <Play> TwiML Verb
 *
 * @function play
 * @memberof Gather#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {number} [attributes.loop] - Times to loop media
 * @param {string} [attributes.digits] - Play DTMF tones for digits
 * @param {url} [url] - Media URL
 *
 * @returns Play
 */
/* jshint ignore:end */
Gather.prototype.play = function play(attributes, url) {
  return new Play(this.gather.ele('Play', attributes, url));
};


/* jshint ignore:start */
/**
 * <Enqueue> TwiML Noun
 *
 * @constructor Enqueue
 *
 * @param {object} enqueue - <Enqueue> TwiML Noun
 */
/* jshint ignore:end */
function Enqueue(enqueue) {
  this.enqueue = enqueue;
  this._propertyName = 'enqueue';
}

Enqueue.prototype = Object.create(TwiML.prototype);
Enqueue.prototype.constructor = Enqueue;

/* jshint ignore:start */
/**
 * <Task> TwiML Noun
 *
 * @function task
 * @memberof Enqueue#
 *
 * @param {object} attributes - TwiML attributes
 * @param {number} [attributes.priority] - Task priority
 * @param {number} [attributes.timeout] - Timeout associated with task
 * @param {string} body - TaskRouter task attributes
 *
 * @returns Task
 */
/* jshint ignore:end */
Enqueue.prototype.task = function task(attributes, body) {
  return new Task(this.enqueue.ele('Task', attributes, body));
};


/* jshint ignore:start */
/**
 * <Task> TwiML Noun
 *
 * @constructor Task
 *
 * @param {object} task - <Task> TwiML Noun
 */
/* jshint ignore:end */
function Task(task) {
  this.task = task;
  this._propertyName = 'task';
}

Task.prototype = Object.create(TwiML.prototype);
Task.prototype.constructor = Task;


/* jshint ignore:start */
/**
 * <Echo> TwiML Verb
 *
 * @constructor Echo
 *
 * @param {object} echo - <Echo> TwiML Verb
 */
/* jshint ignore:end */
function Echo(echo) {
  this.echo = echo;
  this._propertyName = 'echo';
}

Echo.prototype = Object.create(TwiML.prototype);
Echo.prototype.constructor = Echo;


/* jshint ignore:start */
/**
 * <Dial> TwiML Verb
 *
 * @constructor Dial
 *
 * @param {object} dial - <Dial> TwiML Verb
 */
/* jshint ignore:end */
function Dial(dial) {
  this.dial = dial;
  this._propertyName = 'dial';
}

Dial.prototype = Object.create(TwiML.prototype);
Dial.prototype.constructor = Dial;

/* jshint ignore:start */
/**
 * <Client> TwiML Noun
 *
 * @function client
 * @memberof Dial#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.url] - Client URL
 * @param {string} [attributes.method] - Client URL Method
 * @param {client.event} [attributes.statusCallbackEvent] -
 *          Events to trigger status callback
 * @param {string} [attributes.statusCallback] - Status Callback URL
 * @param {string} [attributes.statusCallbackMethod] - Status Callback URL Method
 * @param {string} [identity] - Client identity
 *
 * @returns Client
 */
/* jshint ignore:end */
Dial.prototype.client = function client(attributes, identity) {
  return new Client(this.dial.ele('Client', attributes, identity));
};

/* jshint ignore:start */
/**
 * <Conference> TwiML Noun
 *
 * @function conference
 * @memberof Dial#
 *
 * @param {object} attributes - TwiML attributes
 * @param {boolean} [attributes.muted] - Join the conference muted
 * @param {conference.beep} [attributes.beep] - Play beep when joining
 * @param {boolean} [attributes.startConferenceOnEnter] -
 *          Start the conference on enter
 * @param {boolean} [attributes.endConferenceOnExit] - End the conferenceon exit
 * @param {string} [attributes.waitUrl] - Wait URL
 * @param {string} [attributes.waitMethod] - Wait URL method
 * @param {number} [attributes.maxParticipants] - Maximum number of participants
 * @param {conference.record} [attributes.record] - Record the conference
 * @param {conference.region} [attributes.region] - Conference region
 * @param {string} [attributes.coach] - Call coach
 * @param {conference.trim} [attributes.trim] - Trim the conference recording
 * @param {conference.event} [attributes.statusCallbackEvent] -
 *          Events to call status callback URL
 * @param {string} [attributes.statusCallback] - Status callback URL
 * @param {string} [attributes.statusCallbackMethod] - Status callback URL method
 * @param {string} [attributes.recordingStatusCallback] -
 *          Recording status callback URL
 * @param {string} [attributes.recordingStatusCallbackMethod] -
 *          Recording status callback URL method
 * @param {conference.recording_event} [attributes.recordingStatusCallbackEvent] -
 *          Recording status callback events
 * @param {string} [attributes.eventCallbackUrl] - Event callback URL
 * @param {conference.jitter_buffer_size} [attributes.jitterBufferSize] -
 *          Size of jitter buffer for participant
 * @param {string} [attributes.participantLabel] - A label for participant
 * @param {string} name - Conference name
 *
 * @returns Conference
 */
/* jshint ignore:end */
Dial.prototype.conference = function conference(attributes, name) {
  return new Conference(this.dial.ele('Conference', attributes, name));
};

/* jshint ignore:start */
/**
 * <Number> TwiML Noun
 *
 * @function number
 * @memberof Dial#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.sendDigits] -
 *          DTMF tones to play when the call is answered
 * @param {string} [attributes.url] - TwiML URL
 * @param {string} [attributes.method] - TwiML URL method
 * @param {number.event} [attributes.statusCallbackEvent] -
 *          Events to call status callback
 * @param {string} [attributes.statusCallback] - Status callback URL
 * @param {string} [attributes.statusCallbackMethod] - Status callback URL method
 * @param {string} [attributes.byoc] - BYOC trunk SID (Beta)
 * @param {string} [attributes.machineDetection] -
 *          Enable machine detection or end of greeting detection
 * @param {string} [attributes.amdStatusCallbackMethod] -
 *          HTTP Method to use with amd_status_callback
 * @param {string} [attributes.amdStatusCallback] -
 *          The URL we should call to send amd status information to your application
 * @param {number} [attributes.machineDetectionTimeout] -
 *          Number of seconds to wait for machine detection
 * @param {number} [attributes.machineDetectionSpeechThreshold] -
 *          Number of milliseconds for measuring stick for the length of the speech activity
 * @param {number} [attributes.machineDetectionSpeechEndThreshold] -
 *          Number of milliseconds of silence after speech activity
 * @param {number} [attributes.machineDetectionSilenceTimeout] -
 *          Number of milliseconds of initial silence
 * @param {phone_number} phoneNumber - Phone Number to dial
 *
 * @returns Number
 */
/* jshint ignore:end */
Dial.prototype.number = function number(attributes, phoneNumber) {
  return new Number(this.dial.ele('Number', attributes, phoneNumber));
};

/* jshint ignore:start */
/**
 * <Queue> TwiML Noun
 *
 * @function queue
 * @memberof Dial#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.url] - Action URL
 * @param {string} [attributes.method] - Action URL method
 * @param {string} [attributes.reservationSid] - TaskRouter Reservation SID
 * @param {string} [attributes.postWorkActivitySid] - TaskRouter Activity SID
 * @param {string} name - Queue name
 *
 * @returns Queue
 */
/* jshint ignore:end */
Dial.prototype.queue = function queue(attributes, name) {
  return new Queue(this.dial.ele('Queue', attributes, name));
};

/* jshint ignore:start */
/**
 * <Sim> TwiML Noun
 *
 * @function sim
 * @memberof Dial#
 *
 * @param {object} attributes - TwiML attributes
 * @param {sid} simSid - SIM SID
 *
 * @returns Sim
 */
/* jshint ignore:end */
Dial.prototype.sim = function sim(attributes, simSid) {
  return new Sim(this.dial.ele('Sim', attributes, simSid));
};

/* jshint ignore:start */
/**
 * <Sip> TwiML Noun
 *
 * @function sip
 * @memberof Dial#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.username] - SIP Username
 * @param {string} [attributes.password] - SIP Password
 * @param {string} [attributes.url] - Action URL
 * @param {string} [attributes.method] - Action URL method
 * @param {sip.event} [attributes.statusCallbackEvent] - Status callback events
 * @param {string} [attributes.statusCallback] - Status callback URL
 * @param {string} [attributes.statusCallbackMethod] - Status callback URL method
 * @param {string} [attributes.machineDetection] -
 *          Enable machine detection or end of greeting detection
 * @param {string} [attributes.amdStatusCallbackMethod] -
 *          HTTP Method to use with amd_status_callback
 * @param {string} [attributes.amdStatusCallback] -
 *          The URL we should call to send amd status information to your application
 * @param {number} [attributes.machineDetectionTimeout] -
 *          Number of seconds to wait for machine detection
 * @param {number} [attributes.machineDetectionSpeechThreshold] -
 *          Number of milliseconds for measuring stick for the length of the speech activity
 * @param {number} [attributes.machineDetectionSpeechEndThreshold] -
 *          Number of milliseconds of silence after speech activity
 * @param {number} [attributes.machineDetectionSilenceTimeout] -
 *          Number of milliseconds of initial silence
 * @param {url} sipUrl - SIP URL
 *
 * @returns Sip
 */
/* jshint ignore:end */
Dial.prototype.sip = function sip(attributes, sipUrl) {
  return new Sip(this.dial.ele('Sip', attributes, sipUrl));
};


/* jshint ignore:start */
/**
 * <Sip> TwiML Noun
 *
 * @constructor Sip
 *
 * @param {object} sip - <Sip> TwiML Noun
 */
/* jshint ignore:end */
function Sip(sip) {
  this.sip = sip;
  this._propertyName = 'sip';
}

Sip.prototype = Object.create(TwiML.prototype);
Sip.prototype.constructor = Sip;


/* jshint ignore:start */
/**
 * <Sim> TwiML Noun
 *
 * @constructor Sim
 *
 * @param {object} sim - <Sim> TwiML Noun
 */
/* jshint ignore:end */
function Sim(sim) {
  this.sim = sim;
  this._propertyName = 'sim';
}

Sim.prototype = Object.create(TwiML.prototype);
Sim.prototype.constructor = Sim;


/* jshint ignore:start */
/**
 * <Number> TwiML Noun
 *
 * @constructor Number
 *
 * @param {object} number - <Number> TwiML Noun
 */
/* jshint ignore:end */
function Number(number) {
  this.number = number;
  this._propertyName = 'number';
}

Number.prototype = Object.create(TwiML.prototype);
Number.prototype.constructor = Number;


/* jshint ignore:start */
/**
 * <Conference> TwiML Noun
 *
 * @constructor Conference
 *
 * @param {object} conference - <Conference> TwiML Noun
 */
/* jshint ignore:end */
function Conference(conference) {
  this.conference = conference;
  this._propertyName = 'conference';
}

Conference.prototype = Object.create(TwiML.prototype);
Conference.prototype.constructor = Conference;


/* jshint ignore:start */
/**
 * <Client> TwiML Noun
 *
 * @constructor Client
 *
 * @param {object} client - <Client> TwiML Noun
 */
/* jshint ignore:end */
function Client(client) {
  this.client = client;
  this._propertyName = 'client';
}

Client.prototype = Object.create(TwiML.prototype);
Client.prototype.constructor = Client;

/* jshint ignore:start */
/**
 * <Identity> TwiML Noun
 *
 * @function identity
 * @memberof Client#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} clientIdentity - Identity of the client to dial
 *
 * @returns Identity
 */
/* jshint ignore:end */
Client.prototype.identity = function identity(attributes, clientIdentity) {
  return new Identity(this.client.ele('Identity', attributes, clientIdentity));
};

/* jshint ignore:start */
/**
 * <Parameter> TwiML Noun
 *
 * @function parameter
 * @memberof Client#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.name] - The name of the custom parameter
 * @param {string} [attributes.value] - The value of the custom parameter
 *
 * @returns Parameter
 */
/* jshint ignore:end */
Client.prototype.parameter = function parameter(attributes) {
  return new Parameter(this.client.ele('Parameter', attributes));
};


/* jshint ignore:start */
/**
 * <Identity> TwiML Noun
 *
 * @constructor Identity
 *
 * @param {object} identity - <Identity> TwiML Noun
 */
/* jshint ignore:end */
function Identity(identity) {
  this.identity = identity;
  this._propertyName = 'identity';
}

Identity.prototype = Object.create(TwiML.prototype);
Identity.prototype.constructor = Identity;


/* jshint ignore:start */
/**
 * <Connect> TwiML Verb
 *
 * @constructor Connect
 *
 * @param {object} connect - <Connect> TwiML Verb
 */
/* jshint ignore:end */
function Connect(connect) {
  this.connect = connect;
  this._propertyName = 'connect';
}

Connect.prototype = Object.create(TwiML.prototype);
Connect.prototype.constructor = Connect;

/* jshint ignore:start */
/**
 * <Room> TwiML Noun
 *
 * @function room
 * @memberof Connect#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.participantIdentity] -
 *          Participant identity when connecting to the Room
 * @param {string} name - Room name
 *
 * @returns Room
 */
/* jshint ignore:end */
Connect.prototype.room = function room(attributes, name) {
  return new Room(this.connect.ele('Room', attributes, name));
};

/* jshint ignore:start */
/**
 * <Autopilot> TwiML Noun
 *
 * @function autopilot
 * @memberof Connect#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} name - Autopilot assistant sid or unique name
 *
 * @returns Autopilot
 */
/* jshint ignore:end */
Connect.prototype.autopilot = function autopilot(attributes, name) {
  return new Autopilot(this.connect.ele('Autopilot', attributes, name));
};

/* jshint ignore:start */
/**
 * <Stream> TwiML Noun
 *
 * @function stream
 * @memberof Connect#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.name] - Friendly name given to the Stream
 * @param {string} [attributes.connectorName] - Unique name for Stream Connector
 * @param {string} [attributes.url] -
 *          URL of the remote service where the Stream is routed
 * @param {stream.track} [attributes.track] -
 *          Track to be streamed to remote service
 * @param {string} [attributes.statusCallback] - Status Callback URL
 * @param {stream.status_callback_method} [attributes.statusCallbackMethod] -
 *          Status Callback URL method
 *
 * @returns Stream
 */
/* jshint ignore:end */
Connect.prototype.stream = function stream(attributes) {
  return new Stream(this.connect.ele('Stream', attributes));
};

/* jshint ignore:start */
/**
 * <VirtualAgent> TwiML Noun
 *
 * @function virtualAgent
 * @memberof Connect#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.connectorName] -
 *          Defines the conversation profile Dialogflow needs to use
 * @param {string} [attributes.language] -
 *          Language to be used by Dialogflow to transcribe speech
 * @param {boolean} [attributes.sentimentAnalysis] -
 *          Whether sentiment analysis needs to be enabled or not
 * @param {string} [attributes.statusCallback] -
 *          URL to post status callbacks from Twilio
 * @param {string} [attributes.statusCallbackMethod] -
 *          HTTP method to use when requesting the status callback URL
 *
 * @returns VirtualAgent
 */
/* jshint ignore:end */
Connect.prototype.virtualAgent = function virtualAgent(attributes) {
  return new VirtualAgent(this.connect.ele('VirtualAgent', attributes));
};

/* jshint ignore:start */
/**
 * <Conversation> TwiML Noun
 *
 * @function conversation
 * @memberof Connect#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.serviceInstanceSid] - Service instance Sid
 * @param {boolean} [attributes.inboundAutocreation] - Inbound autocreation
 * @param {number} [attributes.routingAssignmentTimeout] -
 *          Routing assignment timeout
 * @param {number} [attributes.inboundTimeout] - Inbound timeout
 * @param {string} [attributes.url] - TwiML URL
 * @param {string} [attributes.method] - TwiML URL method
 * @param {conversation.record} [attributes.record] - Record
 * @param {conversation.trim} [attributes.trim] - Trim
 * @param {string} [attributes.recordingStatusCallback] -
 *          Recording status callback URL
 * @param {string} [attributes.recordingStatusCallbackMethod] -
 *          Recording status callback URL method
 * @param {conversation.recording_event} [attributes.recordingStatusCallbackEvent] -
 *          Recording status callback events
 * @param {string} [attributes.statusCallback] - Status callback URL
 * @param {string} [attributes.statusCallbackMethod] - Status callback URL method
 * @param {conversation.event} [attributes.statusCallbackEvent] -
 *          Events to call status callback URL
 *
 * @returns Conversation
 */
/* jshint ignore:end */
Connect.prototype.conversation = function conversation(attributes) {
  return new Conversation(this.connect.ele('Conversation', attributes));
};


/* jshint ignore:start */
/**
 * <Conversation> TwiML Noun
 *
 * @constructor Conversation
 *
 * @param {object} conversation - <Conversation> TwiML Noun
 */
/* jshint ignore:end */
function Conversation(conversation) {
  this.conversation = conversation;
  this._propertyName = 'conversation';
}

Conversation.prototype = Object.create(TwiML.prototype);
Conversation.prototype.constructor = Conversation;


/* jshint ignore:start */
/**
 * <VirtualAgent> TwiML Noun
 *
 * @constructor VirtualAgent
 *
 * @param {object} virtualAgent - <VirtualAgent> TwiML Noun
 */
/* jshint ignore:end */
function VirtualAgent(virtualAgent) {
  this.virtualAgent = virtualAgent;
  this._propertyName = 'virtualAgent';
}

VirtualAgent.prototype = Object.create(TwiML.prototype);
VirtualAgent.prototype.constructor = VirtualAgent;

/* jshint ignore:start */
/**
 * <Config> TwiML Noun
 *
 * @function config
 * @memberof VirtualAgent#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.name] - The name of the custom config
 * @param {string} [attributes.value] - The value of the custom config
 *
 * @returns Config
 */
/* jshint ignore:end */
VirtualAgent.prototype.config = function config(attributes) {
  return new Config(this.virtualAgent.ele('Config', attributes));
};

/* jshint ignore:start */
/**
 * <Parameter> TwiML Noun
 *
 * @function parameter
 * @memberof VirtualAgent#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.name] - The name of the custom parameter
 * @param {string} [attributes.value] - The value of the custom parameter
 *
 * @returns Parameter
 */
/* jshint ignore:end */
VirtualAgent.prototype.parameter = function parameter(attributes) {
  return new Parameter(this.virtualAgent.ele('Parameter', attributes));
};


/* jshint ignore:start */
/**
 * <Config> TwiML Noun
 *
 * @constructor Config
 *
 * @param {object} config - <Config> TwiML Noun
 */
/* jshint ignore:end */
function Config(config) {
  this.config = config;
  this._propertyName = 'config';
}

Config.prototype = Object.create(TwiML.prototype);
Config.prototype.constructor = Config;


/* jshint ignore:start */
/**
 * <Autopilot> TwiML Noun
 *
 * @constructor Autopilot
 *
 * @param {object} autopilot - <Autopilot> TwiML Noun
 */
/* jshint ignore:end */
function Autopilot(autopilot) {
  this.autopilot = autopilot;
  this._propertyName = 'autopilot';
}

Autopilot.prototype = Object.create(TwiML.prototype);
Autopilot.prototype.constructor = Autopilot;


/* jshint ignore:start */
/**
 * <Room> TwiML Noun
 *
 * @constructor Room
 *
 * @param {object} room - <Room> TwiML Noun
 */
/* jshint ignore:end */
function Room(room) {
  this.room = room;
  this._propertyName = 'room';
}

Room.prototype = Object.create(TwiML.prototype);
Room.prototype.constructor = Room;

module.exports = VoiceResponse;
