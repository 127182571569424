import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  ScrollView,
  Dimensions,
  AsyncStorage,
  FlatList,
  TouchableOpacity,
  Platform,
} from "react-native";
import { connect } from "react-redux";
import BackButton from "./../components/backButton";
import CartButton from "./../components/cartButton";
import Modal from "modal-react-native-web";
import Constants from "expo-constants";

import { style } from "../../assets/style";
import CartTotal from "./../components/cartTotal";
import {
  addCount,
  addToCart,
  removeItemFromCart,
  subtractCount,
  totalPrice,
  updateChildren,
  resetCart,
  resetChildren,
  setCount,
  allCart,
  updateCartCount
} from "../store/actions";
import FullServiceTotal from "./../components/FullServiceTotal";

import axios from "axios";
import Toast from "./../components/toast";
// import FA from '@expo/vector-icons/FontAwesome'
import Ant from '@expo/vector-icons/AntDesign';
import {
  API_URL,
  image_api,
  THREE_FRACTION_CURRENCY,
} from "./../constants/index";
import Icon from "./../components/Warning";
import { PushData } from "./../constants/tagViewpush";
import i18n from "../i18n";
import IntlLabel from "./../handlers/languageHandler";
import {FontLetterSpacing, PriceLoader} from "./../handlers/utils"
import IntlLabelFn from "./../handlers/languageHandlerFn";
import StyledText from "../components/styledText"
import ProgressiveImage from "./../components/progressiveImage";
import { performRequest } from "../services/apiHandler";


let { width, height } = Dimensions.get("window");

Array.prototype.unique = function () {
  return Array.from(new Set(this));
};
class CartScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error_message: "",
      message_status: "",
      message_display: "none",
      buttonDisabled: false,
      selectedItemModal: false,
      selectedItem: null,

      products: this.props.allChildren,
    };
  }
  componentDidMount() {
    if (Platform.OS === "web") {
      width = 450;
    }
    AsyncStorage.getItem("uuid", (err, uuid) => {
      if (err) {
        return;
      } else {
        this.setState({ uuid: uuid });

        PushData(
          this.props.navigation.state.routeName,
          this.props.config.venueName ? this.props.config.venueName : "Ent",
          uuid ? uuid : "Ent",
          {}
        );
      }
    });
  }
  static navigationOptions = {
    header: null,
    gesturesEnabled: false,
    animationEnabled: false,
  };
  throwMessageFn = (message, status) => {
    this.setState({
      message_status: status,
      error_message: message,
      message_display: "inherit",
    });

    setTimeout(
      function () {
        this.setState({
          message_status: "",
          error_message: "",
          message_display: "none",
        });
      }.bind(this),
      3000
    );
  };
  priceLoader = (price) => {
    if (THREE_FRACTION_CURRENCY.includes(this.props.config.currency)) {
      return price ? price.toFixed(3) : "";
    } else {
      if (this.props.config.currency == "¥") {
        return price ? parseInt(price) : "";
      } else {
        return price ? price.toFixed(2) : "";
      }
    }
  };
  setSpecialNotes = () => {
    const {config, default_language} = this.props;
    let text = config.special_notes_multi ? 
    IntlLabelFn(
      JSON.parse(config.special_notes_multi), 
      config.default_language.value, 
      default_language
      ) : ""
      return text
  }
  checkout = async () => {
    // this.props.config.complete_order_only_on_payment
    if (this.props.platform == "online") {
      // if(!this.props.user){
      //   AsyncStorage.setItem('login_trigger', "CheckoutScreen")
      //   this.props.navigation.navigate("MobileForm");
      //   return
      // }
      if (!this.props.user || Object.keys(this.props.user).length === 0) {
        AsyncStorage.setItem('login_trigger', "CheckoutScreen")
        this.props.navigation.navigate("MobileForm");
      } else {
        this.props.navigation.navigate("CheckoutScreen");
      }
    } else {
      if (
        this.props.config.login_required &&
        !this.props.config.login_to_see_menu
      ) {
        if (!this.props.user) {
          AsyncStorage.setItem('login_trigger', "CheckoutScreen")
          this.props.navigation.navigate("MobileForm");
        } else {
          this.props.navigation.navigate("CheckoutScreen");
        }
      } else {
        this.props.navigation.navigate("CheckoutScreen");
      }
    }
  };
  reduceMainCounter = (item) => {
    let clicked_item = this.props.allChildren.find(
      (itm) => item.id === itm.id && itm.type === "item"
    );
    clicked_item.count = clicked_item.count - 1;
    this.props.updateChildren(this.props.allChildren);
  };
   refReplacer = () =>{
    let m = new Map(), v= new Map(), init = null;
  
    return function(field, value) {
      let p= m.get(this) + (Array.isArray(this) ? `[${field}]` : '.' + field); 
      let isComplex= value===Object(value)
      
      if (isComplex) m.set(value, p);  
      
      let pp = v.get(value)||'';
      let path = p.replace(/undefined\.\.?/,'');
      let val = pp ? `#REF:${pp[0]=='[' ? '$':'$.'}${pp}` : value;
      
      !init ? (init=value) : (val===init ? val="#REF:$" : 0);
      if(!pp && isComplex) v.set(value, path);
     
      return val;
    }
  }

  decreaseItemCount = (item) => {
    console.log(this.props.cart,item, "llllllllllllll")
    // return
    let cart_item = this.props.cart.find(
      (cart_item) => JSON.stringify(cart_item, this.refReplacer()) === JSON.stringify(item, this.refReplacer())
    );
    if (cart_item.count > 1) {
      cart_item.count = cart_item.count - 1;

      // this.reduceMainCounter(item);
      let price = this.props.total_price;
      let temp_price = item.selected_price.price;
      // item.modifier.map((mod_item) => (temp_price += (mod_item.price * mod_item.count)));
      item.modifier.map(
        (mod_item) => {
          if(!mod_item.is_nested){
            temp_price += mod_item.price ? (mod_item.price * mod_item.count) : 0
          }
          if(mod_item.is_item_modifier){
            temp_price += mod_item.nested_modifier_total
          }
        }
      );

      this.props.totalPrice(price - temp_price);
      this.props.subtractCount();
      this.props.updateChildren(this.props.allChildren);

      // this.reduceMainCounter(item);

      let clicked_item = this.props.allChildren.find(
        (itm) => item.id === itm.id && itm.type === "item"
      );
      clicked_item.count -= 1;
      this.props.updateChildren(this.props.allChildren);
    } else if (cart_item.count == 1) {
      let new_cart = this.props.cart.filter(
        (cart_item) => JSON.stringify(cart_item, this.refReplacer()) !== JSON.stringify(item, this.refReplacer())
      );
      this.props.removeItemFromCart(new_cart);

      let clicked_item = this.props.allChildren.find(
        (itm) => item.id === itm.id && itm.type === "item"
      );
      delete clicked_item.count;
      this.props.updateChildren(this.props.allChildren);

      let price = this.props.total_price;
      let temp_price = item.selected_price.price;
      // item.modifier.map((mod_item) => (temp_price += (mod_item.price * mod_item.count)));
      item.modifier.map(
        (mod_item) => {
          if(!mod_item.is_nested){
            temp_price += mod_item.price ? (mod_item.price * mod_item.count) : 0
          }
          if(mod_item.is_item_modifier){
            temp_price += mod_item.nested_modifier_total
          }
        }
      );
      this.props.totalPrice(price - temp_price);
      this.props.subtractCount();
    }
  };

  deleteItem = (item) => {
    this.setState({cartButtonLoading:true})
    performRequest("delete", `api/pwa/cart/${item.cart_item_id}?uuid=${this.state.uuid}&mid=${Constants.deviceId}&platform=${this.props.platform!=="qr"? this.props.menu_type : this.props.platform}`)
    .then(response=>{

      let all_children = [...this.props.allChildren];
      let selected_item = all_children.find(all => all.id == item.item.id && all.type=="item")
      if(selected_item){
        selected_item.count = 0
        this.props.updateChildren(all_children);
      }
      this.props.setCount(response.data.quantity || 0);

      this.props.totalPrice(response.data.total  || 0.00);
      
      this.props.allCart(response.data.items || []);
      this.setState({cartButtonLoading:false})



    })
    .catch(error=>{
      console.log(error, "kkkkkk")
    })
    // let price = this.props.total_price;

    // let price_to_reduce = item.count * item.selected_price.price;

    // // item.modifier.map(
    // //   (mod_item) => (price_to_reduce += (mod_item.price * mod_item.count) * item.count)
    // // );
    // item.modifier.map(
    //   (mod_item) => {
    //     if(!mod_item.is_nested){
    //       price_to_reduce += mod_item.price ? (mod_item.price * mod_item.count) : 0
    //     }
    //     if(mod_item.is_item_modifier){
    //       price_to_reduce += mod_item.nested_modifier_total
    //     }
    //   }
    // );

    // let cart_item = this.props.allChildren.find(
    //   (cart_item) =>
    //     JSON.stringify(cart_item.id) === JSON.stringify(item.id) &&
    //     cart_item.type === "item"
    // );
    // cart_item.count -= item.count;
    // let new_cart = this.props.cart.filter(
    //   (cart_item) => JSON.stringify(cart_item, this.refReplacer()) !== JSON.stringify(item, this.refReplacer())
    // );
    // this.props.removeItemFromCart(new_cart);

    // // let price_to_reduce = item.count * item.selected_price.price;
    // // let price = this.props.total_price;
    // this.props.totalPrice(price - price_to_reduce);
    // this.props.setCount(item.count);
    // delete item.count;

    // this.props.updateChildren(this.props.allChildren);
  };

  increaseItemCount = (item) => {
    // if (item.price.length>1 || item.modifiers.length>0){
    let clicked_item = this.props.allChildren.find(
      (itm) => item.id === itm.id && itm.type === "item"
    );
    clicked_item.count = clicked_item.count + 1;
    // }

    item.count = item.count + 1;

    let price = this.props.total_price;
    let temp_price = item.selected_price.price;
    // item.modifier.map((mod_item) => (temp_price += mod_item.price));

    // this.props.totalPrice(price + temp_price);
    let final_amount = price + temp_price
    if (item.modifier_total_price) {
      let modifier_total = item.modifier_total_price
      final_amount = final_amount + modifier_total
    }

    this.props.totalPrice(final_amount);


    this.props.addCount();

    // let item_from_all = this.props.allChildren.find(cart_item => JSON.stringify(cart_item.id) === JSON.stringify(item.id) && item.type === 'item');
    // item_from_all.count+=1
    this.props.updateChildren(this.props.allChildren);
  };
  onModifierClick = (parentIndex, childIndex, childPrice) => {
    let item = { ...this.state.selectedItem };
    item.modifiers[parentIndex]["required_indicator"] = false;
    if (
      item.modifiers[parentIndex].modifier_modifier_group[childIndex]["checked"]
    ) {
      item.modifiers[parentIndex].modifier_modifier_group[childIndex][
        "checked"
      ] = false;
      item.modifiers[parentIndex]["checked_count"] -= 1;
      item.modifier_total_price -= childPrice;
    } else {
      item.modifiers[parentIndex].modifier_modifier_group[childIndex][
        "checked"
      ] = true;
      if (!item.modifier_total_price) {
        item["modifier_total_price"] = childPrice;
      } else {
        item.modifier_total_price += childPrice;
      }
      if (item.modifiers[parentIndex]["checked_count"]) {
        item.modifiers[parentIndex]["checked_count"] += 1;
      } else {
        item.modifiers[parentIndex]["checked_count"] = 1;
      }
    }
    this.setState({ selectedItem: item });
  };
  validateItemModifier = () => {
    return {
      failed: false
    }
  }
  makeModifiersFalse = (item) => {
    for (let i = 0; i < this.state.selectedItem.modifiers.length; i++) {
      delete item.modifiers[i].checked_count;
      for (
        let j = 0;
        j < this.state.selectedItem.modifiers[i].modifier_modifier_group.length;
        j++
      ) {
        this.state.selectedItem.modifiers[i].modifier_modifier_group[j].checked = false;
      }
    }
  };
  onOptionSelect = () => {
    let item = { ...this.state.selectedItem };
    let failed = false;
    let item_modifier = this.validateItemModifier()
    console.log(item_modifier)
    if(item_modifier.failed){
        this.throwMessageFn("Select Modifiers", "red");
        return;
    }
    Promise.all(
      item.modifiers.map((item) => {
        if (item.min > 0) {
          if (item.checked_count < item.min || !item.checked_count) {
            failed = true;
            item["required_indicator"] = true;
          } else {
          }
        } else {
          // failed = true;
          // item['required_indicator'] = true;
        }
      })
    ).then(() => {
      if (!failed) {
        // if everything okay
        let _modifier = [];
        let item_count = 1
        for (let i = 0; i < item.modifiers.length; i++) {
          for (
            let j = 0;
            j < item.modifiers[i].modifier_modifier_group.length;
            j++
          ) {
            if (item.modifiers[i].modifier_modifier_group[j].checked && item.modifiers[i].modifier_modifier_group[j].checked > 0) {
              item.modifiers[i].modifier_modifier_group[j]['count'] = item.modifiers[i].modifier_modifier_group[j].checked
              _modifier.push(item.modifiers[i].modifier_modifier_group[j]);
            }
          }
        }
        if(item_modifier.modifiers){
          _modifier = [..._modifier, ...item_modifier.modifiers]
        }
        // console.log(_modifier, "pppppppp")
        item["modifier"] = _modifier;

        let all_children = [...this.props.allChildren];

        if (this.state.selectedItem.price.length > 1) {
          if (this.state.selectedPrice) {
            let item_selected_from_children = all_children.find(
              (itm) => itm.id === this.state.selectedItem.id && itm.type === "item"
            );
            item_selected_from_children.count
              ? (item_selected_from_children.count =
                item_selected_from_children.count + item_count)
              : (item_selected_from_children.count = item_count); // updating all children count
            this.props.updateChildren(all_children);
            this.setState({ item: item_selected_from_children });

            this.makeModifiersFalse(item_selected_from_children); // making modifiers to initial state
            for (let i = 0; i < item_count; i++) {
              this.props.addCount();
            }

            let price = this.props.total_price;
            let temp_price = this.state.selectedPrice.price;


            // item.modifier.map((mod_item) => (temp_price += mod_item.price));
            let final_amount = price + (temp_price * item_count)
            let modifier_total = 0
            let item_modifier_total = 0
            if (item.modifier_total_price) {
              modifier_total = item.modifier_total_price * item_count
            }
            if(item_modifier.amount){
              item_modifier_total = item_modifier.amount * item_count
            }
            final_amount = final_amount + modifier_total + item_modifier_total

            this.props.totalPrice(final_amount);
            // this.props.totalPrice(price + this.state.selectedPrice.price);

            let all_cart = [...this.props.cart];
            let if_item_exist = all_cart.find(
              (cartItem) =>
                cartItem.selected_price.id === this.state.selectedPrice.id &&
                JSON.stringify(item.modifier) ==
                JSON.stringify(cartItem.modifier)
            );
            if (if_item_exist) {
              if_item_exist.count += item_count;
              this.props.allCart(all_cart);
            } else {
              item.count = item_count;
              item["selected_price"] = this.state.selectedPrice;
              this.props.addToCart(item);
            }
          } else {
            this.throwMessageFn(
              i18n.t("labels.please_select_at_least_one_option"),
              "red"
            );
          }
        } else if (this.state.selectedItem.price.length == 1) {
          this.setState({ selectedPrice: this.state.selectedItem.price[0] }, () => {
            let item_selected_from_children = all_children.find(
              (itm) => itm.id === this.state.selectedItem.id && itm.type === "item"
            );
            item_selected_from_children.count
              ? (item_selected_from_children.count =
                item_selected_from_children.count + item_count)
              : (item_selected_from_children.count = item_count); // updating all children count
            this.props.updateChildren(all_children);
            this.setState({ item: item_selected_from_children });

            this.makeModifiersFalse(item_selected_from_children); // making modifiers to initial state

            for (let i = 0; i < item_count; i++) {
              this.props.addCount();
            }




            let price = this.props.total_price;
            let temp_price = this.state.selectedPrice
              ? this.state.selectedPrice.price
              : 0;

            // item.modifier.map((mod_item) => (temp_price += mod_item.price));
            let final_amount = price + (temp_price * item_count)
            let modifier_total = 0
            let item_modifier_total = 0
            if (item.modifier_total_price) {
              modifier_total = item.modifier_total_price * item_count
            }
            if(item_modifier.amount){
              item_modifier_total = item_modifier.amount * item_count
            }
            final_amount = final_amount + modifier_total + item_modifier_total

            this.props.totalPrice(final_amount);
   

            let all_cart = [...this.props.cart];

            let if_item_exist = all_cart.find(
              (cartItem) =>
                cartItem.selected_price.id === this.state.selectedPrice.id &&
                JSON.stringify(item.modifier) ==
                JSON.stringify(cartItem.modifier)
            );
            if (if_item_exist) {
              if_item_exist.count += item_count;
              this.props.allCart(all_cart);
            } else {
              item.count = item_count;
              item["selected_price"] = this.state.selectedPrice;

              this.props.addToCart(item);
            }
          });
        } else if (this.state.selectedItem.price.length == 0) {
          let _js = _.cloneDeep(this.state.selectedItem)
          // console.log(JSON.stringify({
          //   food_type: _js.food_type,
          //   id:_js.id,
          //   item_descriptions: _js.item_descriptions,
          //   item_names:_js.item_names,
          //   menu:_js.menu,
          //   modifier_total_price:_js.modifier_total_price,
          //   modifiers:_js.modifiers,
          //   preparation_time: _js.preparation_time,
          //   price: _js.price,
          //   section: _js.section,
          //   type: _js.type,
          // }), "itemmmmmmmmmmmmmm")
          this.setState(
            {
              selectedPrice: {
                // id: JSON.stringify(this.state.item),
                id: JSON.stringify({
                  food_type: _js.food_type,
                  id:_js.id,
                  item_descriptions: _js.item_descriptions,
                  item_names:_js.item_names,
                  menu:_js.menu,
                  modifier_total_price:_js.modifier_total_price,
                  modifiers:_js.modifiers,
                  preparation_time: _js.preparation_time,
                  price: _js.price,
                  section: _js.section,
                  type: _js.type,
                }),
                price: 0,
                description: { [this.props.default_language]: "" },
                order: 0,
              },
            },
            () => {
              let item_selected_from_children = all_children.find(
                (itm) => itm.id === this.state.selectedItem.id && itm.type === "item"
              );
              item_selected_from_children.count
                ? (item_selected_from_children.count =
                  item_selected_from_children.count + item_count)
                : (item_selected_from_children.count = item_count); // updating all children count
              this.props.updateChildren(all_children);
              this.setState({ item: item_selected_from_children });

              this.makeModifiersFalse(item_selected_from_children); // making modifiers to initial state

              for (let i = 0; i < item_count; i++) {
                this.props.addCount();
              }



              let price = this.props.total_price;
              let temp_price = this.state.selectedPrice
                ? this.state.selectedPrice.price
                : 0;


              let final_amount = price + (temp_price * item_count)
              let modifier_total = 0
              let item_modifier_total = 0
              if (item.modifier_total_price) {
                modifier_total = item.modifier_total_price * item_count
              }
              if(item_modifier.amount){
                item_modifier_total = item_modifier.amount * item_count
              }
              final_amount = final_amount + modifier_total + item_modifier_total
              this.props.totalPrice(final_amount);


              let all_cart = [...this.props.cart];

              let if_item_exist = all_cart.find(
                (cartItem) =>
                  cartItem.selected_price.id === this.state.selectedPrice.id &&
                  JSON.stringify(item.modifier) ==
                  JSON.stringify(cartItem.modifier)
              );
              if (if_item_exist) {
                if_item_exist.count += item_count;
                this.props.allCart(all_cart);
              } else {
                item.count = item_count;
                item["selected_price"] = this.state.selectedPrice;

                this.props.addToCart(item);
              }
            }
          );
        }
        this.setState({ item_count: 0, item_modifiers: [], selectedItemModal: false })
        // this.props.navigation.goBack()
      } else {
        this.throwMessageFn("Select Modifiers", "red");
      }

    });
  };
  
  addToCartFn = (item) => {
    item.count = 0; // intial time let it be 1
    let cart_items = [...this.props.cart];
    let item_to_cart = { ...item };

    let prev_item_exist = cart_items.find(
      (cart_item) => cart_item.id === item.id
    );
    if (prev_item_exist) {
      this.increaseItemCount(prev_item_exist);
    } else {
      item_to_cart["count"] = item.count ? item.count + 1 : 1;
      item_to_cart["selected_price"] =
        item.price.length > 0 ? item.price[0] : { price: 0, description: null };
      item_to_cart["modifier"] = [];
      this.props.addToCart(item_to_cart);
    }

    item.count ? (item.count += 1) : (item.count = 1);
    let allChildren = [...this.props.allChildren];
    this.props.updateChildren(allChildren);

    this.props.addCount();
    let price = this.props.total_price;
    this.props.totalPrice(
      item.price.length > 0 ? price + item.price[0].price : price
    );
  };

  recommendedNewView = (item) => {
    let filtered = this.props.cart.find((e) => e.id == item.id);

    if (!filtered)
      return (
        <View style={style.cartItem}>
          <View style={[style.cartItems, { width: '100%' }]}>
            <Image
              style={{
                height: width * 0.2,
                width: width * 0.2,
                borderBottomLeftRadius: 6,
                borderTopLeftRadius: 6,
              }}
              source={
                item.image
                  ? {
                    uri:
                      image_api +
                      item.image.split("/").splice(3).join("/") +
                      "?w=200&h=200",
                  }
                  : require("./../../assets/food.png")
              }
            />
            <View
              style={{
                marginLeft: 8,
                backgroundColor: "",
                direction: this.props.isRTL && "rtl",
                flex: 1,
              }}
            >
              <StyledText
              
              font_color={this.props.design.grid_view_heading_text_color}
              font_weight={this.props.design.grid_view_heading_text_font_weight}
              font_style={this.props.design.grid_view_heading_text_font_style}
              text_transform={this.props.design.grid_view_heading_text_font_transform}
              font_family={this.props.design.grid_view_heading_text_font_family}
              font_size={this.props.design.grid_view_heading_text_font_size?? 16}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                numberOfLines={1}
                style={{
                  direction: this.props.isRTL && "rtl",
                }}
              >
                {item.item_names[this.props.default_language] && item.item_names[this.props.default_language].length > 18
                  ? `${item.item_names[this.props.default_language].substring(
                    0,
                    18
                  )}...`
                  : <IntlLabel  data={item.item_names} /> }
              </StyledText>
              
              <StyledText
              font_color={this.props.design.grid_view_price_text_color}
              font_weight={this.props.design.grid_view_price_font_weight}
              font_style={this.props.design.grid_view_price_font_style}
              text_transform={this.props.design.grid_view_price_font_transform}
              font_family={this.props.design.grid_view_price_font_family}
              font_size={this.props.design.grid_view_price_font_size?? 12}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                style={{
                  fontWeight: 400,
                
                  direction: this.props.isRTL && "rtl",
                }}
              >
                {this.props.config.display_currency &&
                  this.props.config.currency}{" "}
                {this.props.config.display_price &&
                  PriceLoader(item.price.length ? item.price[0].price : 0, this.props.config.currency, this.props.config.display_fraction)}
              </StyledText>

              <View style={{ flexDirection: "column" }}></View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  // if (item.modifiers.length > 0) {
                  //   this.setState({
                  //     selectedItemModal: !this.state.selectedItemModal,
                  //     selectedItem: item,
                  //   });
                  // } else this.addToCartFn(item);
                }}
              >
                <View
                  style={{
                    width: 60,
                    height: 20,
                    // borderWidth:1,
                    marginRight: 2,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 4,
                    marginBottom: 2,
                    backgroundColor: "rgb(0, 191, 111)",
                    marginBottom: 0,
                  }}
                >
                  {/*borderColor:this.props.home_buttons_text_color,*/}
                  <StyledText
                  font_color={this.props.design.grid_view_button_text_color}
                  font_weight={this.props.design.grid_view_button_text_font_weight}
                  font_style={this.props.design.grid_view_button_text_font_style}
                  text_transform={this.props.design.grid_view_button_text_font_transform}
                  font_family={this.props.design.grid_view_button_text_font_family}
                  font_size={this.props.design.grid_view_button_text_font_size?? 18}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                    style={{
                    
                    }}
                  >
                    {i18n.t("button_text.add")}
                  </StyledText>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      );
  };

  recommendedView = (item) => {
    let filtered = this.props.cart.find((e) => e.id == item.id);

    if (!filtered)
      return (
        <View
          style={{
            flexDirection: "row",
            // width: width * 0.6,
            marginBottom: 8,
            marginHorizontal: 8,
            borderBottomLeftRadius: 6,
            borderTopLeftRadius: 6,
            borderRadius: 6,
            elevation: 5,
            shadowColor: "black",
            shadowOffset: { width: 0, height: 0.5 * 5 },
            shadowOpacity: 0.3,
            shadowRadius: 0.8 * 5,
          }}
        >
          <Image
            style={{
              height: width * 0.2,
              width: width * 0.2,
              borderBottomLeftRadius: 6,
              borderTopLeftRadius: 6,
            }}
            source={
              item.image
                ? {
                  uri:
                    image_api +
                    item.image.split("/").splice(3).join("/") +
                    "?w=200&h=200",
                }
                : require("./../../assets/food.png")
            }
          />
          <View
            style={{
              flex: 1,
              margin: 4,
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <View
              style={{
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "space-around",

                // width: "60%",
              }}
            >
              <View>
                <StyledText
                font_color={this.props.design.grid_view_heading_text_color}
                font_weight={this.props.design.grid_view_heading_text_font_weight}
                font_style={this.props.design.grid_view_heading_text_font_style}
                text_transform={this.props.design.grid_view_heading_text_font_transform}
                font_family={this.props.design.grid_view_heading_text_font_family}
                font_size={this.props.design.grid_view_heading_text_font_size?? 16}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
  
                  style={{}}
                >
                  {item.item_names ? 
                  <IntlLabel  data={item.item_names} />
                    : ""}{" "}
                </StyledText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginTop: 4,
                }}
              >
                <StyledText
                 font_color={this.props.design.grid_view_price_text_color}
                 font_weight={this.props.design.grid_view_price_font_weight}
                 font_style={this.props.design.grid_view_price_font_style}
                 text_transform={this.props.design.grid_view_price_font_transform}
                 font_family={this.props.design.grid_view_price_font_family}
                 font_size={this.props.design.grid_view_price_font_size?? 12}
                 letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                  style={{
                   
                    direction: this.props.isRTL && "rtl",
                  }}
                >
                  {this.props.config.display_currency &&
                    this.props.config.currency}{" "}
                  {this.props.config.display_price &&
                    PriceLoader(item.price ? item.price[0].price : 0, this.props.config.currency, this.props.config.display_fraction)}
                </StyledText>
                <TouchableOpacity
                  onPress={() => {
                    if (item.modifiers.length > 0) {
                      this.setState({
                        selectedItemModal: !this.state.selectedItemModal,
                        selectedItem: item,
                      });
                    } else this.addToCartFn(item);
                  }}
                >
                  <View
                    style={{
                      width: 40,
                      height: 20,
                      // borderWidth:1,
                      marginRight: 2,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 4,
                      marginBottom: 2,
                      backgroundColor: "rgb(0, 191, 111)",
                      marginBottom: 0,
                    }}
                  >
                    {/*borderColor:this.props.home_buttons_text_color,*/}
                    <StyledText
                    font_color={this.props.design.grid_view_button_text_color}
                    font_weight={this.props.design.grid_view_button_text_font_weight}
                    font_style={this.props.design.grid_view_button_text_font_style}
                    text_transform={this.props.design.grid_view_button_text_font_transform}
                    font_family={this.props.design.grid_view_button_text_font_family}
                    font_size={this.props.design.grid_view_button_text_font_size?? 18}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                      style={{
                        paddingHorizontal: 28,
                       
                      }}
                    >
                      {i18n.t("button_text.add")}
                    </StyledText>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      );
  };

  recommendedList = () => {
    var lookup = _.keyBy(this.props.cart, function(o) { return o.item.id.toString() + o.type });

    var result = _.filter(this.props.allChildren, function(u) {
      return lookup[u.id.toString() + u.type] !== undefined;
  });


    let recomList = [];
    result.forEach((item) => {
      if (item.recommended_items.length > 0) {
        recomList = recomList.concat(item.recommended_items);
      }
    });

    recomList = [...new Set(recomList)];
    recomList = recomList.filter((item) => !this.props.cart.includes(item));
    return recomList.map((item) => this.recommendedNewView(item));
  };

  isRecommendedItem = () => {
    var lookup = _.keyBy(this.props.cart, function(o) { return o.item.id.toString() + o.type });

    var result = _.filter(this.props.allChildren, function(u) {
      return lookup[u.id.toString() + u.type] !== undefined;
  });


    let recomList = [];
    result.forEach((item) => {
      if (item.recommended_items.length > 0) {
        recomList = recomList.concat(item.recommended_items);
      }
    });

    recomList = [...new Set(recomList)];
    recomList = recomList.filter((item) => !this.props.cart.includes(item));

    return recomList;
  };
  editCartItem = (item, condition, index) => {
    
  
    this.setState({cartButtonLoading: true})
    let payload = {
      item: item.id,
      mid: Constants.deviceId,
      quantity: 1,
      uuid: this.state.uuid,
      condition,
      platform: this.props.platform!=="qr"? this.props.menu_type : this.props.platform
    }
    performRequest("patch", `api/pwa/cart/${item.cart_item_id}`, payload)
    .then(response => {
      console.log(response.data, 'responseeeeeeeeee')
      // ====================================== logic to remove multi option items

      this.props.totalPrice(response.data.total  || 0.00);
      
      this.props.allCart(response.data.items || []);


      if(condition == "minus"){
        item["count"] == 1 ? delete item['count'] : item.count-=1;
        this.props.subtractCount();
      }else{
        this.props.addCount();
        item["count"] += 1
      }
      let all_children = [...this.props.allChildren];
      this.props.updateChildren(all_children);


    this.setState({cartButtonLoading: false, })
    })
    .catch(error => {

      console.log(error, "errorrr")    
      this.setState({cartButtonLoading: false})

    })
  };
  render() {
    const {
      list_view_background_color,
      list_view_text_color,
      home_button_background_color,
      home_button_text_color,
      top_buttons_background_color,
      top_buttons_text_color,
      grid_view_background_color,
      grid_view_text_color
    } = this.props.design
    return (
      <View style={{ flex: 1, backgroundColor: list_view_background_color,maxHeight: "100vh" }}>
        
        {this.props.design.display_image_for_menu_page_background && (
          <ProgressiveImage
            position={"absolute"}
            thumbnailSource={{
              uri:
                image_api +
                this.props.design.background_image_menu
                  .split("/")
                  .splice(3)
                  .join("/") +
                "?w=250&h=250",
            }}
            source={{
              uri:
                image_api +
                this.props.design.background_image_menu
                  .split("/")
                  .splice(3)
                  .join("/") +
                "?w=800&h=800",
            }}
            style={{ width: "100%", height: "100%", position: "absolute" }}
            resizeMode="cover"
          />
        )}

          <View style={{justifyContent:"center", alignItems:"center",marginTop:15}} >
          <StyledText
                font_color={this.props.design.list_view_header_color}
                font_weight={this.props.design.list_view_header_font_weight}
                font_style={this.props.design.list_view_header_font_style}
                text_transform={this.props.design.list_view_header_font_transform}
                font_family={this.props.design.list_view_header_font_family}
                font_size={this.props.design.list_view_header_font_size?? 23}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                  style={{
                    textAlign: "center",
                    marginHorizontal: 14,
                    alignSelf: "center",
                    marginBottom:2
                  }}
                >
                  {i18n.t("button_text.cart")}
                </StyledText>
          </View>

    
        
        <View
          style={{
            flex: 1,
            // width: Dimensions.get("window").width,
            alignItems: "center",
            direction: this.props.isRTL && "rtl",
            marginTop: 60,
            height: height* 0.8
            // maxHeight: "100vh"

          }}
        >
          
          <ScrollView
            style={{
              width: '96%',
              marginBottom: 80,
              flex:1,
              // height:200
            }}
            bounces={false}
          >
            <View>
              
              {this.props.cart.map((item) => (
                <View style={[style.cartItem, {backgroundColor:grid_view_background_color, marginBottom:10}]}>
                  <View style={[style.cartItems, { width: '100%' }]}>
                 
                    <Image
                      style={{
                        height: width * 0.2,
                        width: width * 0.2,
                        borderBottomLeftRadius: 6,
                        borderTopLeftRadius: 6,
                      }}
                      source={
                        item.item.image
                          ? {
                            uri:
                              image_api +
                              item.item?.image.split("/").splice(3).join("/") +
                              "?w=200&h=200",
                          }
                          : require("./../../assets/food.png")
                      }
                    />
                    <View
                      style={{
                        marginLeft: 8,
                        backgroundColor: "",
                        direction: this.props.isRTL && "rtl",
                        flex: 1,
                      }}
                    >
                      <StyledText
                        numberOfLines={1}
                        font_color={this.props.design.grid_view_heading_text_color}
                           font_weight={this.props.design.grid_view_heading_text_font_weight}
                           font_style={this.props.design.grid_view_heading_text_font_style}
                           text_transform={this.props.design.grid_view_heading_text_font_transform}
                           font_family={this.props.design.grid_view_heading_text_font_family}
                           font_size={this.props.design.grid_view_heading_text_font_size?? 16}
                           letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                        style={{
                          
                          direction: this.props.isRTL && "rtl",
                        }}
                      >
                        {
                        JSON.parse(item.item.item_names)[this.props.default_language] && JSON.parse(item.item.item_names)[this.props.default_language].length >
                          18
                          ? `${JSON.parse(item.item.item_names)[
                            this.props.default_language
                          ].substring(0, 18)}...`
                          : <IntlLabel  data={JSON.parse(item.item.item_names)} /> 
                        }
                      </StyledText>
                      <StyledText
                       font_color={this.props.design.grid_view_price_text_color}
                       font_weight={this.props.design.grid_view_price_font_weight}
                       font_style={this.props.design.grid_view_price_font_style}
                       text_transform={this.props.design.grid_view_price_font_transform}
                       font_family={this.props.design.grid_view_price_font_family}
                       font_size={this.props.design.grid_view_price_font_size?? 12}
                       letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                        style={{
                       
                          direction: this.props.isRTL && "rtl",
                         
                        }}
                      >
                        {this.props.config.display_currency &&
                          this.props.config.currency}{" "}
                        {this.props.config.display_price &&
                          PriceLoader(item.total, this.props.config.currency, this.props.config.display_fraction)}
                      </StyledText>
                      <StyledText
                       font_color={this.props.design.grid_view_price_text_color}
                       font_weight={this.props.design.grid_view_price_font_weight}
                       font_style={this.props.design.grid_view_price_font_style}
                       text_transform={this.props.design.grid_view_price_font_transform}
                       font_family={this.props.design.grid_view_price_font_family}
                       font_size={this.props.design.grid_view_price_font_size?? 12}
                       letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                        style={{
                       
                          direction: this.props.isRTL && "rtl",
                         
                        }}
                      >
                        {item.price.description &&
                        <IntlLabel  data={JSON.parse(item.price.description)} />
                          }
                      </StyledText>
                      <View style={{ flexDirection: "column" }}>
                            {item.modifiers !== undefined
                          ? item.modifiers.map((item) => (
                            <View>
                              <StyledText
                              font_color={this.props.design.grid_view_text_color}
                              font_weight={this.props.design.grid_view_text_font_weight}
                              font_style={this.props.design.grid_view_text_font_style}
                              text_transform={this.props.design.grid_view_text_font_transform}
                              font_family={this.props.design.grid_view_text_font_family}
                              font_size={this.props.design.grid_view_text_font_size?? 14}
                              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
   
                                style={{
                                  
                                  direction: this.props.isRTL && "rtl",
                                 
                                }}
                              >
                              
                                <IntlLabel  data={JSON.parse(item.names)} />
                              </StyledText>
                              {/*<Text> {'\n'} </Text>*/}
                            </View>
                          ))
                          : null}
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <CartButton
                        config={this.props.config}
                        item={item}
                        removeItemFromCartFn={this.decreaseItemCount}
                        onRemoveItem={this.decreaseItemCount}
                        // removeItemModal={this.state.removeItemModal}
                        onCartClick={() => this.increaseItemCount(item)}
                        _addToCart={() => this.increaseItemCount(item)}
                        editCartItem={this.editCartItem}
                        design={this.props.design}
                        cartButtonLoading={this.state.cartButtonLoading}
                      />
                      <TouchableOpacity disabled={this.state.cartButtonLoading} onPress={() => this.deleteItem(item)}>
                        <Icon
                          name={"trash"}
                          color={"red"}
                          size={25}
                          style={{ marginHorizontal: 6 }}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              ))}
            </View>
            {this.isRecommendedItem().length ? (
              <>
                <StyledText
                 font_color={this.props.design.list_view_text_color}
                 font_weight={this.props.design.list_view_text_font_weight}
                 font_style={this.props.design.list_view_text_font_style}
                 text_transform={this.props.design.list_view_text_font_transform}
                 font_family={this.props.design.list_view_text_font_family}
                 font_size={this.props.design.list_view_text_font_size?? 12}
                 letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                  style={{
                    //fontWeight: "bold",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                    marginVertical: 8,
                  }}
                >
                  {i18n.t("labels.goes_well_with")}
                </StyledText>
                <View>{this.recommendedList()}</View>
              </>
            ) : (
                <View></View>
              )}
          </ScrollView>
        </View>

        <View
          style={{
            width: "100%",
            position: "absolute",
            top: 20,
            alignItems: "center",
          }}
        >
          {/* <Text style={{   fontWeight: this.props.design.custom_font.sub_heading_is_bold? "bold":"normal",
                    letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.sub_heading_size ?? 18, fontFamily:'SecondaryFont'}}>
            {i18n.t("button_text.cart")}
          </Text> */}
          {/*<View style={{flexDirection:'row'}}>*/}

          {/*</View>*/}
        </View>
        {this.setSpecialNotes() != "" ? (
          <View
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              bottom: 55,
            }}
          >
            <StyledText
             font_color={this.props.design.list_view_text_color}
             font_weight={this.props.design.list_view_text_font_weight}
             font_style={this.props.design.list_view_text_font_style}
             text_transform={this.props.design.list_view_text_font_transform}
             font_family={this.props.design.list_view_text_font_family}
             font_size={this.props.design.list_view_text_font_size?? 12}
             letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{
                textAlign: "center",
                padding: 5,
              }}
            >
              {this.setSpecialNotes()}
            </StyledText>
          </View>
        ) : (
            <View />
          )}


        {/*customize cart item modal*/}
        {/* <Modal
          transparent
          style={{ backgroundColor: "transparent" }}
          animationType="slide"
          visible={this.state.selectedItemModal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          {this._renderModalContent()}
        </Modal> */}
        {/*end customize cart item modal*/}
        <Toast
          error_message={this.state.error_message}
          message_status={this.state.message_status}
          message_display={this.state.message_display}
          custom_font={this.props.design.custom_font}
        />

        <View style={{ position: "absolute", top: 5, left: 5 }}>
          <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
            <BackButton
              detail_page={true}
              backgroundColor={this.props.design.top_buttons_background_color}
              borderColor={"transparent"}
              color={this.props.design.top_buttons_text_color}
              default_language={this.props.default_language}
              font_size={this.props.design.custom_font.heading_size}
            />
          </TouchableOpacity>
        </View>

        {
          this.props.config.is_full_service && this.props.platform == "qr" &&
          <View style={{ marginTop: 60 }}>
            <FullServiceTotal
            cart={this.props.cart}
            design={this.props.design}
            display_currency={this.props.config.display_currency}
            display_fraction={this.props.config.display_fraction}
            display_price={this.props.config.display_price}
            currency={this.props.config.currency}
            total={this.props.cart_count}
            total_price={this.props.total_price}
            navigateToCart={() => {
            
            }}
            ShowToast = {
              ()=> this.throwMessageFn("Order sent to kitchen", "green")
            }
            navigateToHome={()=> {
              this.props.navigation.navigate("HomeScreen")}}
              navigateToCheckout={()=>{this.props.navigation.navigate("CheckoutScreen")}}
            cartScreen={true}
            custom_font={this.props.design.custom_font}
            />
            </View>
        }

        {this.props.cart.length > 0 &&  !this.props.config.is_full_service ? (
          <TouchableOpacity
            disabled={this.state.buttonDisabled}
            onPress={() => this.checkout()}
            style={{
              width: "100%",
              position: "absolute",
              bottom:0
            }}
          >
            <CartTotal
              payment_enable={true}
              design={this.props.design}
              display_currency={this.props.config.display_currency}
              display_fraction={this.props.config.display_fraction}
              display_price={this.props.config.display_price}
              currency={this.props.config.currency}
              total={this.props.cart_count}
              total_price={this.props.total_price}
              navigateToCart={() => this.checkout()}
              cartScreen={true}              
              custom_font = {this.props.design.custom_font}
            />
          </TouchableOpacity>
        ) : null}


      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    cart_count: state.cart.cart_count,
    total_price: state.cart.total_price,
    allChildren: state.menus.allChildren,
    config: state.config.config,
    default_language: state.languages.default_language,
    isRTL: state.languages.isRTL,
    number: state.number.number,
    user: state.number.user,
    platform: state.config.platform,
    user: state.number.user,
    design: state.config.design,
    menu_type: state.config.menu_type,

  };
};
export default connect(mapStateToProps, {
  updateChildren,
  addToCart,
  removeItemFromCart,
  addCount,
  subtractCount,
  totalPrice,
  resetCart,
  resetChildren,
  setCount,
  allCart,
  updateCartCount
})(CartScreen);
