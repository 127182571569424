'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var DeliveryReceiptList = require(
    './message/deliveryReceipt').DeliveryReceiptList;
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var MessageList;
var MessagePage;
var MessageInstance;
var MessageContext;

/* jshint ignore:start */
/**
 * Initialize the MessageList
 *
 * @constructor Twilio.Conversations.V1.ConversationContext.MessageList
 *
 * @param {Twilio.Conversations.V1} version - Version of the resource
 * @param {string} conversationSid -
 *          The unique ID of the Conversation for this message.
 */
/* jshint ignore:end */
MessageList = function MessageList(version, conversationSid) {
  /* jshint ignore:start */
  /**
   * @function messages
   * @memberof Twilio.Conversations.V1.ConversationContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Conversations.V1.ConversationContext.MessageContext}
   */
  /* jshint ignore:end */
  function MessageListInstance(sid) {
    return MessageListInstance.get(sid);
  }

  MessageListInstance._version = version;
  // Path Solution
  MessageListInstance._solution = {conversationSid: conversationSid};
  MessageListInstance._uri = `/Conversations/${conversationSid}/Messages`;
  /* jshint ignore:start */
  /**
   * create a MessageInstance
   *
   * @function create
   * @memberof Twilio.Conversations.V1.ConversationContext.MessageList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.author] -
   *          The channel specific identifier of the message's author.
   * @param {string} [opts.body] - The content of the message.
   * @param {Date} [opts.dateCreated] - The date that this resource was created.
   * @param {Date} [opts.dateUpdated] - The date that this resource was last updated.
   * @param {string} [opts.attributes] -
   *          A string metadata field you can use to store any data you wish.
   * @param {string} [opts.mediaSid] -
   *          The Media SID to be attached to the new Message.
   * @param {string} [opts.contentSid] -
   *          The unique ID of the multi-channel Rich Content template.
   * @param {string} [opts.contentVariables] -
   *          A structurally valid JSON string that contains values to resolve Rich Content template variables.
   * @param {message.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
   *          The X-Twilio-Webhook-Enabled HTTP request header
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed MessageInstance
   */
  /* jshint ignore:end */
  MessageListInstance.create = function create(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'Author': _.get(opts, 'author'),
      'Body': _.get(opts, 'body'),
      'DateCreated': serialize.iso8601DateTime(_.get(opts, 'dateCreated')),
      'DateUpdated': serialize.iso8601DateTime(_.get(opts, 'dateUpdated')),
      'Attributes': _.get(opts, 'attributes'),
      'MediaSid': _.get(opts, 'mediaSid'),
      'ContentSid': _.get(opts, 'contentSid'),
      'ContentVariables': _.get(opts, 'contentVariables')
    });
    var headers = values.of({'X-Twilio-Webhook-Enabled': _.get(opts, 'xTwilioWebhookEnabled')});

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data, headers: headers});

    promise = promise.then(function(payload) {
      deferred.resolve(new MessageInstance(
        this._version,
        payload,
        this._solution.conversationSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Streams MessageInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Conversations.V1.ConversationContext.MessageList#
   *
   * @param {object} [opts] - Options for request
   * @param {message.order_type} [opts.order] -
   *          The sort order of the returned messages
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  MessageListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists MessageInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Conversations.V1.ConversationContext.MessageList#
   *
   * @param {object} [opts] - Options for request
   * @param {message.order_type} [opts.order] -
   *          The sort order of the returned messages
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  MessageListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of MessageInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Conversations.V1.ConversationContext.MessageList#
   *
   * @param {object} [opts] - Options for request
   * @param {message.order_type} [opts.order] -
   *          The sort order of the returned messages
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  MessageListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'Order': _.get(opts, 'order'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new MessagePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of MessageInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Conversations.V1.ConversationContext.MessageList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  MessageListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new MessagePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a message
   *
   * @function get
   * @memberof Twilio.Conversations.V1.ConversationContext.MessageList#
   *
   * @param {string} sid -
   *          A 34 character string that uniquely identifies this resource.
   *
   * @returns {Twilio.Conversations.V1.ConversationContext.MessageContext}
   */
  /* jshint ignore:end */
  MessageListInstance.get = function get(sid) {
    return new MessageContext(this._version, this._solution.conversationSid, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Conversations.V1.ConversationContext.MessageList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  MessageListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  MessageListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return MessageListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the MessagePage
 *
 * @constructor Twilio.Conversations.V1.ConversationContext.MessagePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {MessageSolution} solution - Path solution
 *
 * @returns MessagePage
 */
/* jshint ignore:end */
MessagePage = function MessagePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(MessagePage.prototype, Page.prototype);
MessagePage.prototype.constructor = MessagePage;

/* jshint ignore:start */
/**
 * Build an instance of MessageInstance
 *
 * @function getInstance
 * @memberof Twilio.Conversations.V1.ConversationContext.MessagePage#
 *
 * @param {MessagePayload} payload - Payload response from the API
 *
 * @returns MessageInstance
 */
/* jshint ignore:end */
MessagePage.prototype.getInstance = function getInstance(payload) {
  return new MessageInstance(this._version, payload, this._solution.conversationSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ConversationContext.MessagePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
MessagePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

MessagePage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the MessageContext
 *
 * @constructor Twilio.Conversations.V1.ConversationContext.MessageInstance
 *
 * @property {string} accountSid -
 *          The unique ID of the Account responsible for this message.
 * @property {string} conversationSid -
 *          The unique ID of the Conversation for this message.
 * @property {string} sid -
 *          A 34 character string that uniquely identifies this resource.
 * @property {number} index - The index of the message within the Conversation.
 * @property {string} author -
 *          The channel specific identifier of the message's author.
 * @property {string} body - The content of the message.
 * @property {object} media -
 *          An array of objects that describe the Message's media if attached, otherwise, null.
 * @property {string} attributes -
 *          A string metadata field you can use to store any data you wish.
 * @property {string} participantSid -
 *          The unique ID of messages's author participant.
 * @property {Date} dateCreated - The date that this resource was created.
 * @property {Date} dateUpdated - The date that this resource was last updated.
 * @property {string} url - An absolute API URL for this message.
 * @property {object} delivery -
 *          An object that contains the summary of delivery statuses for the message to non-chat participants.
 * @property {string} links - Absolute URL to access the receipts of this message.
 * @property {string} contentSid -
 *          The unique ID of the multi-channel Rich Content template.
 *
 * @param {V1} version - Version of the resource
 * @param {MessagePayload} payload - The instance payload
 * @param {sid} conversationSid -
 *          The unique ID of the Conversation for this message.
 * @param {sid} sid - A 34 character string that uniquely identifies this resource.
 */
/* jshint ignore:end */
MessageInstance = function MessageInstance(version, payload, conversationSid,
                                            sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.conversationSid = payload.conversation_sid; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.index = deserialize.integer(payload.index); // jshint ignore:line
  this.author = payload.author; // jshint ignore:line
  this.body = payload.body; // jshint ignore:line
  this.media = payload.media; // jshint ignore:line
  this.attributes = payload.attributes; // jshint ignore:line
  this.participantSid = payload.participant_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.delivery = payload.delivery; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line
  this.contentSid = payload.content_sid; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {conversationSid: conversationSid, sid: sid || this.sid, };
};

Object.defineProperty(MessageInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new MessageContext(
          this._version,
          this._solution.conversationSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * update a MessageInstance
 *
 * @function update
 * @memberof Twilio.Conversations.V1.ConversationContext.MessageInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.author] -
 *          The channel specific identifier of the message's author.
 * @param {string} [opts.body] - The content of the message.
 * @param {Date} [opts.dateCreated] - The date that this resource was created.
 * @param {Date} [opts.dateUpdated] - The date that this resource was last updated.
 * @param {string} [opts.attributes] -
 *          A string metadata field you can use to store any data you wish.
 * @param {message.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed MessageInstance
 */
/* jshint ignore:end */
MessageInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * remove a MessageInstance
 *
 * @function remove
 * @memberof Twilio.Conversations.V1.ConversationContext.MessageInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {message.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed MessageInstance
 */
/* jshint ignore:end */
MessageInstance.prototype.remove = function remove(opts, callback) {
  return this._proxy.remove(opts, callback);
};

/* jshint ignore:start */
/**
 * fetch a MessageInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ConversationContext.MessageInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed MessageInstance
 */
/* jshint ignore:end */
MessageInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Access the deliveryReceipts
 *
 * @function deliveryReceipts
 * @memberof Twilio.Conversations.V1.ConversationContext.MessageInstance#
 *
 * @returns {Twilio.Conversations.V1.ConversationContext.MessageContext.DeliveryReceiptList}
 */
/* jshint ignore:end */
MessageInstance.prototype.deliveryReceipts = function deliveryReceipts() {
  return this._proxy.deliveryReceipts;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ConversationContext.MessageInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
MessageInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

MessageInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the MessageContext
 *
 * @constructor Twilio.Conversations.V1.ConversationContext.MessageContext
 *
 * @property {Twilio.Conversations.V1.ConversationContext.MessageContext.DeliveryReceiptList} deliveryReceipts -
 *          deliveryReceipts resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid_like} conversationSid -
 *          The unique ID of the Conversation for this message.
 * @param {sid} sid - A 34 character string that uniquely identifies this resource.
 */
/* jshint ignore:end */
MessageContext = function MessageContext(version, conversationSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {conversationSid: conversationSid, sid: sid, };
  this._uri = `/Conversations/${conversationSid}/Messages/${sid}`;

  // Dependents
  this._deliveryReceipts = undefined;
};

/* jshint ignore:start */
/**
 * update a MessageInstance
 *
 * @function update
 * @memberof Twilio.Conversations.V1.ConversationContext.MessageContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.author] -
 *          The channel specific identifier of the message's author.
 * @param {string} [opts.body] - The content of the message.
 * @param {Date} [opts.dateCreated] - The date that this resource was created.
 * @param {Date} [opts.dateUpdated] - The date that this resource was last updated.
 * @param {string} [opts.attributes] -
 *          A string metadata field you can use to store any data you wish.
 * @param {message.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed MessageInstance
 */
/* jshint ignore:end */
MessageContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'Author': _.get(opts, 'author'),
    'Body': _.get(opts, 'body'),
    'DateCreated': serialize.iso8601DateTime(_.get(opts, 'dateCreated')),
    'DateUpdated': serialize.iso8601DateTime(_.get(opts, 'dateUpdated')),
    'Attributes': _.get(opts, 'attributes')
  });
  var headers = values.of({'X-Twilio-Webhook-Enabled': _.get(opts, 'xTwilioWebhookEnabled')});

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data, headers: headers});

  promise = promise.then(function(payload) {
    deferred.resolve(new MessageInstance(
      this._version,
      payload,
      this._solution.conversationSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a MessageInstance
 *
 * @function remove
 * @memberof Twilio.Conversations.V1.ConversationContext.MessageContext#
 *
 * @param {object} [opts] - Options for request
 * @param {message.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed MessageInstance
 */
/* jshint ignore:end */
MessageContext.prototype.remove = function remove(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var headers = values.of({'X-Twilio-Webhook-Enabled': _.get(opts, 'xTwilioWebhookEnabled')});

  var promise = this._version.remove({uri: this._uri, method: 'DELETE', headers: headers});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * fetch a MessageInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ConversationContext.MessageContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed MessageInstance
 */
/* jshint ignore:end */
MessageContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new MessageInstance(
      this._version,
      payload,
      this._solution.conversationSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(MessageContext.prototype,
  'deliveryReceipts', {
    get: function() {
      if (!this._deliveryReceipts) {
        this._deliveryReceipts = new DeliveryReceiptList(
          this._version,
          this._solution.conversationSid,
          this._solution.sid
        );
      }
      return this._deliveryReceipts;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ConversationContext.MessageContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
MessageContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

MessageContext.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  MessageList: MessageList,
  MessagePage: MessagePage,
  MessageInstance: MessageInstance,
  MessageContext: MessageContext
};
