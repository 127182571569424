import React, { PureComponent } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  StyleSheet,
  TouchableWithoutFeedback,
  ScrollView,
  AsyncStorage,
} from "react-native";
import { Badge } from "native-base";
import { style } from "../../assets/style";
import HTMLView from "react-native-htmlview";
import CartButton from "./../components/cartButton";
import Icon from "./../components/Warning";
import { THREE_FRACTION_CURRENCY, image_api } from "./../constants";
import i18n from "../i18n";
import IntlLabel from "./../handlers/languageHandler";
import IntlLabelFn from "./../handlers/languageHandlerFn";
import ItemLabel from "./itemLabel";
import { FontLetterSpacing, PriceLoader } from "./../handlers/utils"
import StyledText from "../components/styledText"

class Recipes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      warningIndex: null,
      uuid: "",
    };
  }
  componentDidMount() {
    AsyncStorage.getItem("uuid", (err, uuid) => {
      if (err) {
        return;
      } else {
        this.setState({ uuid: uuid });
      }
    });
  }

  bounce = (index) => {
    this.setState({ warningIndex: index });
    setTimeout(() => {
      this.setState({ warningIndex: null });
    }, 2500);
  };

  priceLoader = (price, item) => {
    if (THREE_FRACTION_CURRENCY.includes(this.props.currency)) {
      return price ? price.toFixed(3) : "";
    } else {
      if (this.props.currency == "¥") {
        return price ? parseInt(price) : "";
      } else {
        return price ? price.toFixed(2) : "";
      }
    }
  };

  checkDisabled = () => {
    const { config, platform } = this.props;
    if (platform == "qr") {
      if (
        config.general_qr.qr_id == this.state.uuid ||
        !config.accepting_orders || this.props.item.snooze
      ) {
        return true;
      }
      return false;
    } else {
      if (
        !config.accepting_orders || this.props.item.snooze
      ) {
        return true;
      }
      return false;
    }
    // else{
    //     if(!config.accepting_orders_online){
    //         return true
    //     }
    //     return false
    // }
  };
  showFoodType = (type, imgStyle) => {
    if (type) {
      if (type == "5") {
        return <Image
          style={[imgStyle], {
            position: "absolute",
            bottom: 5,
            left: 5, height: 16, width: 16
          }}
          source={require("./../../assets/non.png")}
        />
      } else {
        return <Icon
          name={"veg"}
          style={{
            position: "absolute",
            bottom: 5,
            left: 5,
            color:
              type == 1
                ? "green"
                : type == 2
                  ? "brown"
                  : type == 3
                    ? "yellow"
                    : "transparent",
            fontSize: 16,
          }}
        />
      }
    } else {
      return <></>
    }
  }
  isLabelsEnabled = (item) => {
    if(item.chefs_special || item.best_seller || item.our_favorite || item.must_try || item.healthy){
      return true
    }
    return false
  }
  render() {
    const { config, design } = this.props;


    let imgStyle = !this.props.isRTL
      ? {
        borderBottomLeftRadius: 6,
        borderTopLeftRadius: 6,
      }
      : {
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      };
    // const image_api = "https://d2ceauryvxkzea.cloudfront.net/fit-in/350x350/"

   let showFoodTypeLabel = (type, imgStyle) => {
      if (type) {
        if (type == "5") {
          return <Image
            style={[imgStyle],{
               height: 16, width: 16
            }}
            source={require("./../../assets/non.png")}
          />
        } else {
          return <Icon
            name={"veg"}
            style={{
              // position: "absolute",
              // bottom: 5,
              // left: 5,
              color:
                type == 1
                  ? "green"
                  : type == 2
                    ? "brown"
                    : type == 3
                      ? "yellow"
                      : "transparent",
              fontSize: 16,
              zIndex: 1000
            }}
          />
        }
      } else {
        return <></>
      }
    }

    return (
      <View
        style={{
          width: "100%",
          alignItems: "center",
          direction: this.props.isRTL ? "rtl" : null,
        }}
      >
        {/* <View
          style={[
            style.listContainer,
            { position:"absolute" },
          ]}
        /> */}

        <View
          style={[
            style.listContainer,
            { backgroundColor: design.grid_view_background_color },
          ]}
        >
          <View style={{ flexDirection: "row", height: "100%" }}>
            
            {
              this.props.section &&  (this.props.section.show_item_image || this.props.section.type == "menu") &&

              <TouchableWithoutFeedback
                disabled={!config.show_detail_page}
                onPress={() => this.props.navigateToDetail(this.props.item)}
              >
                <View style={style.listImageContainerStyle}>
                  {this.props.item.image ? (
                    <Image
                      style={[style.listImageStyle, imgStyle]}
                      source={{
                        uri:
                          image_api +
                          this.props.item.image.split("/").splice(3).join("/") +
                          "?w=200&h=200",
                      }}
                    />
                  ) :
                    design.show_default_item_image && design.default_item_image ?
                      <Image
                        style={[style.listImageStyle, imgStyle]}
                        source={{
                          uri:
                            image_api +
                            design.default_item_image.split("/").splice(3).join("/") +
                            "?w=200&h=200",
                        }}
                      />
                      :
                      (
                        <Image
                          style={[style.listImageStyle, imgStyle]}
                          source={require("./../../assets/food.png")}
                        />
                      )}

                  {
                    this.showFoodType(this.props.item.food_type, imgStyle)
                  }
                  {
                    this.props.item.snooze ?
                      <View
                        style={[
                          style.listImageStyle, imgStyle,
                          { backgroundColor: "rgba(158, 158, 158, 0.60)", position: "absolute", justifyContent: 'space-between', flexDirection: "column", alignItems: "center" },
                        ]}
                      >
                        <View />
                        <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: "rgba(158, 158, 158, 0.80)", width: '100%', height: "21%" }} >
                          <StyledText 
                           font_color={design.grid_view_text_color}
                           font_weight={design.grid_view_text_font_weight}
                           font_style={design.grid_view_text_font_style}
                           text_transform={design.grid_view_text_font_transform}
                           font_family={design.grid_view_text_font_family}
                           font_size={design.grid_view_text_font_size?? 10}
                           letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                          style={{}}>{i18n.t("labels.not_available")}</StyledText>
                        </View>
                      </View>

                      :
                      <View />
                  }

                </View>
              </TouchableWithoutFeedback>
            }
            <View style={{ width: !this.props.section.show_item_image ? "100%" : "70%", justifyContent:"space-between" }}>
              {/* <TouchableWithoutFeedback disabled={!config.show_detail_page}   onPress={() => this.props.navigateToDetail(this.props.item)}> */}
              <View style={{ width: "100%" }}>

                <div
                  // disabled={!config.show_detail_page}
                  onClick={() => {
                    if(config.show_detail_page){
                      this.props.navigateToDetail(this.props.item)
                    }
                  }
                  }
                >
                  <View style={{width:"100%"}} >

                  <StyledText
                    numberOfLines={1}
                    font_color={design.grid_view_heading_text_color}
                           font_weight={design.grid_view_heading_text_font_weight}
                           font_style={design.grid_view_heading_text_font_style}
                           text_transform={design.grid_view_heading_text_font_transform}
                           font_family={design.grid_view_heading_text_font_family}
                           font_size={design.grid_view_heading_text_font_size?? 12}
                           letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                    style={{
                      paddingTop: 5,
                      marginHorizontal: 10,  
                      textAlign: this.props.isRTL ? "right" : "left",
                      marginVertical:2
                    }}
                  >
                    {this.props.item.mark_item_as_new && (
                      <Image
                        source={require("../../assets/new.png")}
                        style={{ width: 17, height: 17, marginRight: 5, }}
                      />
                    )}

                    {this.props.item.signature && (
                      <Image
                        source={require("../../assets/signature.png")}
                        style={{ width: 17, height: 17, marginRight: 5,  }}
                      />
                    )}
                  {
                    !(this.props.section && (this.props.section.show_item_image || this.props.section.type == "menu") )&&

                    showFoodTypeLabel(this.props.item.food_type, imgStyle)
                  }
                    {this.props.item.item_names
                      ? <IntlLabel data={this.props.item.item_names} />
                      : ""}
                      
                  </StyledText>
                  </View>

                </div>
                  {
                    this.isLabelsEnabled(this.props.item) &&
                    <ScrollView style={{marginHorizontal: 10,}} pagingEnabled horizontal showsHorizontalScrollIndicator={false}>
                    <View style={{ flexDirection: "row", width: "96%", minWidth: "340px", marginBottom: "2px" }}>
                      {this.props.item.chefs_special && (
                        <ItemLabel
                        page={'item_detail'}
                        design={design}

                          // backgroundColor={design.grid_view_button_background_color}
                          // textColor={design.grid_view_button_text_color}
                          // pillFontSize={design.custom_font.pill_size}
                          textMessage={i18n.t("labels.chefs_special")}
                          // custom_font={design.custom_font}
                        />
  
                      )}
  
                      {this.props.item.best_seller && (
                        <ItemLabel
                        page={'item_detail'}
                        design={design}
                          // backgroundColor={design.grid_view_button_background_color}
                          // textColor={design.grid_view_button_text_color}
                          // pillFontSize={design.custom_font.pill_size}
                          // custom_font={design.custom_font}
                          textMessage={i18n.t("labels.best_seller")} />
                      )}
  
                      {this.props.item.our_favorite && (
                        <ItemLabel
                        page={'item_detail'}
                        design={design}
                          // backgroundColor={design.grid_view_button_background_color}
                          // textColor={design.grid_view_button_text_color}
                          // pillFontSize={design.custom_font.pill_size}
                          // custom_font={design.custom_font}
                          textMessage={i18n.t("labels.our_favorite")} />
                      )}
  
                      {this.props.item.must_try && (
                        <ItemLabel
                        page={'item_detail'}
                        design={design}
                          // backgroundColor={design.grid_view_button_background_color}
                          // textColor={design.grid_view_button_text_color}
                          // pillFontSize={design.custom_font.pill_size}
                          // custom_font={design.custom_font}
                          textMessage={i18n.t("labels.must_try")} />
                      )}
  
                      {this.props.item.healthy && (
                        <ItemLabel
                        page={'item_detail'}
                        design={design}
                          // backgroundColor={design.grid_view_button_background_color}
                          // textColor={design.grid_view_button_text_color}
                          // pillFontSize={design.custom_font.pill_size}
                          // custom_font={design.custom_font}
                          textMessage={i18n.t("labels.healthy")} />
                      )}
                    </View>
                  </ScrollView>
                  }

                <StyledText
                  numberOfLines={2}
                  font_color={design.grid_view_text_color}
                           font_weight={design.grid_view_text_font_weight}
                           font_style={design.grid_view_text_font_style}
                           text_transform={design.grid_view_text_font_transform}
                           font_family={design.grid_view_text_font_family}
                           font_size={design.grid_view_text_font_size?? 10}
                           letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                  style={{
                    marginHorizontal: 10,
                    marginTop: 2,
                 
                    direction: this.props.isRTL && "rtl",
                    // marginRight: this.props.isRTL && 9,
                    // maxHeight: 50,
                    //   fontSize: 9,
                    textAlign: this.props.isRTL ? "right" : "left"
                  }}
                >
                  
                  {this.props.item.item_descriptions_raw
                      ? <IntlLabel data={this.props.item.item_descriptions_raw} />
                      : ""}
                  {/* <HTMLView
                    value={
                      this.props.item.item_descriptions
                        ? IntlLabelFn(this.props.item.item_descriptions, this.props.config.default_language.value, this.props.default_language, false, 80)
                        : ""
                    }
                    stylesheet={htmlstyles}
                  /> */}
                </StyledText>
              </View>

              <View style={{ flexDirection: "row", minHeight: 30, width: "100%", marginTop: 5}} >
                <View style={{ flex: 1, justifyContent: "center", alignItems: "flex-start", marginLeft:9, marginRight: this.props.isRTL&&9 }} >
                  {this.props.item.price.length > 0 &&
                    this.props.config.display_price ? (
                    <StyledText
                    font_color={design.grid_view_price_text_color}
                    font_weight={design.grid_view_price_font_weight}
                    font_style={design.grid_view_price_font_style}
                    text_transform={design.grid_view_price_font_transform}
                    font_family={design.grid_view_price_font_family}
                    font_size={design.grid_view_price_font_size?? 12}
                    letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                      style={{
                        
                      }}
                    >
                      {this.props.config.display_currency && this.props.currency}{" "}
                      {PriceLoader(
                        this.props.item.price[0].price,
                        this.props.currency,
                        this.props.config.display_fraction
                      )}
                    </StyledText>
                  ) : null}
                </View>
                <View style={{ flex: 1.5, justifyContent: "center", alignItems: "flex-end", marginRight:9,marginLeft: this.props.isRTL&&9 }} >



                  <View style={{marginBottom:6,alignItems:"center"}} >
                    {this.props.item.count ? (
                      <CartButton {...this.props} />
                    ) : (
                      config.general_qr.qr_id != this.state.uuid &&
                      this.props.platform !== "enterprise" && (
                        <TouchableOpacity
                          onPress={() =>
                            this.props.item.price.length > 1 ||
                              this.props.item.modifiers.length > 0
                              ? this.props.onCartClick(this.props.item, this.props.item)
                              : this.props._addToCart(this.props.item)
                          }
                          disabled={this.checkDisabled() || this.props.cartLoading}
                        >

                          <View
                            style={[
                              style.itemAddButton,
                              {
                                backgroundColor:
                                  design.grid_view_button_background_color,
                                fontFamily: 'SecondaryFont',


                              },
                            ]}
                          >
                            <StyledText
                            font_color={design.grid_view_button_text_color}
                            font_weight={design.grid_view_button_text_font_weight}
                            font_style={design.grid_view_button_text_font_style}
                            text_transform={design.grid_view_button_text_font_transform}
                            font_family={design.grid_view_button_text_font_family}
                            font_size={design.grid_view_button_text_font_size?? 18}
                            letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
        
                              style={{}}
                            >
                              {
                                this.props.cartLoading ? 
                                <i class="fas fa-spinner fa-spin"></i>
                                :
                                i18n.t("button_text.add")
                              }
                            </StyledText>
                          </View>
                          {
                            !this.props.config.accepting_orders || this.props.item.snooze ?
                              <View
                                style={[
                                  style.itemAddButton,
                                  {
                                    backgroundColor: "rgba(158, 158, 158, 0.40)",
                                    position: 'absolute',
                                    fontFamily: 'SecondaryFont'
                                  },
                                ]}
                              ></View>
                              :
                              <View />
                          }
                        </TouchableOpacity>
                      )
                    )}

                    {this.props.item.price.length > 1 ||
                      this.props.item.modifiers.length > 0 ? (
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: design.grid_view_button_text_font_weight,
                          letterSpacing: FontLetterSpacing(design.custom_font.secondary_letter_space),
                          // marginLeft: 7,
                          color: design.grid_view_text_color,
                          fontFamily: design.grid_view_button_text_font_family
                        }}
                      >
                        {i18n.t("labels.customizable")}
                      </Text>
                    ) : null}
                  </View>







                </View>

              </View>


            </View>

          </View>
        </View>



      </View >
    );
  }
}

export default Recipes;
