'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var BrandRegistrationList = require(
    './v1/brandRegistration').BrandRegistrationList;
var DeactivationsList = require('./v1/deactivation').DeactivationsList;
var DomainCertsList = require('./v1/domainCert').DomainCertsList;
var DomainConfigList = require('./v1/domainConfig').DomainConfigList;
var ExternalCampaignList = require(
    './v1/externalCampaign').ExternalCampaignList;
var ServiceList = require('./v1/service').ServiceList;
var TollfreeVerificationList = require(
    './v1/tollfreeVerification').TollfreeVerificationList;
var UsecaseList = require('./v1/usecase').UsecaseList;
var Version = require('../../base/Version');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize the V1 version of Messaging
 *
 * @constructor Twilio.Messaging.V1
 *
 * @property {Twilio.Messaging.V1.BrandRegistrationList} brandRegistrations -
 *          brandRegistrations resource
 * @property {Twilio.Messaging.V1.DeactivationsList} deactivations -
 *          deactivations resource
 * @property {Twilio.Messaging.V1.DomainCertsList} domainCerts -
 *          domainCerts resource
 * @property {Twilio.Messaging.V1.DomainConfigList} domainConfig -
 *          domainConfig resource
 * @property {Twilio.Messaging.V1.ExternalCampaignList} externalCampaign -
 *          externalCampaign resource
 * @property {Twilio.Messaging.V1.ServiceList} services - services resource
 * @property {Twilio.Messaging.V1.TollfreeVerificationList} tollfreeVerifications -
 *          tollfreeVerifications resource
 * @property {Twilio.Messaging.V1.UsecaseList} usecases - usecases resource
 *
 * @param {Twilio.Messaging} domain - The twilio domain
 */
/* jshint ignore:end */
function V1(domain) {
  Version.prototype.constructor.call(this, domain, 'v1');

  // Resources
  this._brandRegistrations = undefined;
  this._deactivations = undefined;
  this._domainCerts = undefined;
  this._domainConfig = undefined;
  this._externalCampaign = undefined;
  this._services = undefined;
  this._tollfreeVerifications = undefined;
  this._usecases = undefined;
}

_.extend(V1.prototype, Version.prototype);
V1.prototype.constructor = V1;

Object.defineProperty(V1.prototype,
  'brandRegistrations', {
    get: function() {
      this._brandRegistrations = this._brandRegistrations || new BrandRegistrationList(this);
      return this._brandRegistrations;
    }
});

Object.defineProperty(V1.prototype,
  'deactivations', {
    get: function() {
      this._deactivations = this._deactivations || new DeactivationsList(this);
      return this._deactivations;
    }
});

Object.defineProperty(V1.prototype,
  'domainCerts', {
    get: function() {
      this._domainCerts = this._domainCerts || new DomainCertsList(this);
      return this._domainCerts;
    }
});

Object.defineProperty(V1.prototype,
  'domainConfig', {
    get: function() {
      this._domainConfig = this._domainConfig || new DomainConfigList(this);
      return this._domainConfig;
    }
});

Object.defineProperty(V1.prototype,
  'externalCampaign', {
    get: function() {
      this._externalCampaign = this._externalCampaign || new ExternalCampaignList(this);
      return this._externalCampaign;
    }
});

Object.defineProperty(V1.prototype,
  'services', {
    get: function() {
      this._services = this._services || new ServiceList(this);
      return this._services;
    }
});

Object.defineProperty(V1.prototype,
  'tollfreeVerifications', {
    get: function() {
      this._tollfreeVerifications = this._tollfreeVerifications || new TollfreeVerificationList(this);
      return this._tollfreeVerifications;
    }
});

Object.defineProperty(V1.prototype,
  'usecases', {
    get: function() {
      this._usecases = this._usecases || new UsecaseList(this);
      return this._usecases;
    }
});

module.exports = V1;
