'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var ApprovalFetchList;
var ApprovalFetchPage;
var ApprovalFetchInstance;
var ApprovalFetchContext;

/* jshint ignore:start */
/**
 * Initialize the ApprovalFetchList
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Content.V1.ContentContext.ApprovalFetchList
 *
 * @param {Twilio.Content.V1} version - Version of the resource
 * @param {string} sid - The unique string that identifies the Content resource
 */
/* jshint ignore:end */
ApprovalFetchList = function ApprovalFetchList(version, sid) {
  /* jshint ignore:start */
  /**
   * @function approvalFetch
   * @memberof Twilio.Content.V1.ContentContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Content.V1.ContentContext.ApprovalFetchContext}
   */
  /* jshint ignore:end */
  function ApprovalFetchListInstance(sid) {
    return ApprovalFetchListInstance.get(sid);
  }

  ApprovalFetchListInstance._version = version;
  // Path Solution
  ApprovalFetchListInstance._solution = {sid: sid};
  /* jshint ignore:start */
  /**
   * Constructs a approval_fetch
   *
   * @function get
   * @memberof Twilio.Content.V1.ContentContext.ApprovalFetchList#
   *
   * @returns {Twilio.Content.V1.ContentContext.ApprovalFetchContext}
   */
  /* jshint ignore:end */
  ApprovalFetchListInstance.get = function get() {
    return new ApprovalFetchContext(this._version, this._solution.sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Content.V1.ContentContext.ApprovalFetchList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ApprovalFetchListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ApprovalFetchListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return ApprovalFetchListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ApprovalFetchPage
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Content.V1.ContentContext.ApprovalFetchPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ApprovalFetchSolution} solution - Path solution
 *
 * @returns ApprovalFetchPage
 */
/* jshint ignore:end */
ApprovalFetchPage = function ApprovalFetchPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ApprovalFetchPage.prototype, Page.prototype);
ApprovalFetchPage.prototype.constructor = ApprovalFetchPage;

/* jshint ignore:start */
/**
 * Build an instance of ApprovalFetchInstance
 *
 * @function getInstance
 * @memberof Twilio.Content.V1.ContentContext.ApprovalFetchPage#
 *
 * @param {ApprovalFetchPayload} payload - Payload response from the API
 *
 * @returns ApprovalFetchInstance
 */
/* jshint ignore:end */
ApprovalFetchPage.prototype.getInstance = function getInstance(payload) {
  return new ApprovalFetchInstance(this._version, payload, this._solution.sid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Content.V1.ContentContext.ApprovalFetchPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ApprovalFetchPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ApprovalFetchPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ApprovalFetchContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Content.V1.ContentContext.ApprovalFetchInstance
 *
 * @property {string} sid - The unique string that identifies the Content resource
 * @property {string} accountSid -
 *          The SID of the Account that created the Content resource
 * @property {object} whatsapp -
 *          Contains the whatsapp approval information for the Content resource
 * @property {string} url -
 *          The URL of the resource, relative to `https://content.twilio.com`
 *
 * @param {V1} version - Version of the resource
 * @param {ApprovalFetchPayload} payload - The instance payload
 * @param {sid} sid - The unique string that identifies the Content resource
 */
/* jshint ignore:end */
ApprovalFetchInstance = function ApprovalFetchInstance(version, payload, sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.whatsapp = payload.whatsapp; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid, };
};

Object.defineProperty(ApprovalFetchInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ApprovalFetchContext(this._version, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a ApprovalFetchInstance
 *
 * @function fetch
 * @memberof Twilio.Content.V1.ContentContext.ApprovalFetchInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ApprovalFetchInstance
 */
/* jshint ignore:end */
ApprovalFetchInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Content.V1.ContentContext.ApprovalFetchInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ApprovalFetchInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ApprovalFetchInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ApprovalFetchContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Content.V1.ContentContext.ApprovalFetchContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} sid - The unique string that identifies the Content resource
 */
/* jshint ignore:end */
ApprovalFetchContext = function ApprovalFetchContext(version, sid) {
  this._version = version;

  // Path Solution
  this._solution = {sid: sid, };
  this._uri = `/Content/${sid}/ApprovalRequests`;
};

/* jshint ignore:start */
/**
 * fetch a ApprovalFetchInstance
 *
 * @function fetch
 * @memberof Twilio.Content.V1.ContentContext.ApprovalFetchContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ApprovalFetchInstance
 */
/* jshint ignore:end */
ApprovalFetchContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ApprovalFetchInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Content.V1.ContentContext.ApprovalFetchContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ApprovalFetchContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ApprovalFetchContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ApprovalFetchList: ApprovalFetchList,
  ApprovalFetchPage: ApprovalFetchPage,
  ApprovalFetchInstance: ApprovalFetchInstance,
  ApprovalFetchContext: ApprovalFetchContext
};
