import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
  AsyncStorage,
  Dimensions,
  ImageBackground,
  StyleSheet
} from "react-native";
import { connect } from "react-redux";
import BackButton from "./../components/backButton";
import OctI from "@expo/vector-icons/Octicons";
import Material from "@expo/vector-icons/MaterialCommunityIcons";

import SI from "@expo/vector-icons/SimpleLineIcons";
import Modal from "modal-react-native-web";
import Toast from "./../components/toast";
import axios from "axios";
import { resetCart, resetChildren, addCard, setOrderData } from "../store/actions";
import { style } from "../../assets/style";
import { Button, Icon, Row, Input, Item, Textarea } from "native-base";
import ModalComponent from "./../components/selectorModal";
import PaymentButton from "./../components/paymentMethodButton";
import { percentageHandler } from "./../handlers/percentageHandler";


import Icono from "@expo/vector-icons/Ionicons";
import CalendarStrip from "react-native-calendar-strip";
import moment from "moment";
import Constants from "expo-constants";

import {
  THREE_FRACTION_CURRENCY,
  CHECKOUT_PUB_KEY,
  API_URL,
  SPECIAL_VENUE,
  debug,
  SQUARE_APP_ID,
  SQUARE_URL,
  PAYTM_URL
} from "./../constants/index";
import {PriceLoader} from "./../handlers/utils"

import { performRequest } from "./../services/apiHandler";
import TipsButtons from "./../components/tips";
import MasterScriptLoader from "./../services/masterScriptLoader";
import OrderSuccessModal from "./../components/orderSuccessModal";
import { PushData } from "./../constants/tagViewpush";
import StyledText from "../components/styledText"
import {FontLetterSpacing} from "./../handlers/utils"


import HTMLView from "react-native-htmlview";
import i18n from "../i18n";
import { loadStripe, } from "@stripe/stripe-js";
import DigiWallet from './../components/digiWalletStripe'
import { Elements } from "@stripe/react-stripe-js";
import PlaceOrderButton from './../components/placeOrderBtn'
import SelectedPayment from './../components/selectedPaymentComponent'
import DateModalComponent from './../components/dateSelectModal'
import _ from 'lodash'
import {dateLoader} from "../handlers/utils"
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import SquarePayForm from "./../components/squarePayForm";
import  "../../assets/square.css";
import IntlLabelFn from "./../handlers/languageHandlerFn";

let mid = Constants.installationId;



class CheckoutScreen extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loader: false,
      error_message: "",
      message_status: "",
      message_display: "none",
      order_placed: false,
      card_error: false,
      all_address: [],
      addressModal: false,
      default_address: "",
      default_promo: "",
      entered_coupon: "",
      coupon_status: false,
      paymentMethodModal: false,
      payment_method:
        this.props.cvv &&
        this.props.year &&
        this.props.month &&
        this.props.card_number
          ? "card"
          : "",
      timeScheduleModal: false,
      schedule_list: [],
      default_time:this.props.config.enable_asap? i18n.t("button_text.asap") : "",
      orderSuccessModal: false,
      instructions: "",
      pickup_plate_no: "",
      card_error_mzg: "Unauthorized Card",
      selectedDate:  "",
      tips: 0,
      selected_tip: "",
      masterCardView: false,
      stripePromise: undefined,
      paymentRequest:"loading",
      availablePaymentMethod: {
        applePay: false,
        googlePay: false,
        savedPayment: false
      },
      dateModal: false,
      dateList: [],
      future_enabled: this.props.platform=="qr"?this.props.config.qr_delivery_date_enabled:this.props.menu_type=="delivery"?this.props.config.online_delivery_date_enabled:this.props.config.pickup_delivery_date_enabled,
      allow_online_payment: this.props.platform=="qr"?this.props.config.allow_online_payment : this.props.menu_type=="delivery"?this.props.config.allow_online_payment:this.props.config.allow_online_payment,
      pickup_plate_no_2: "",
      crypto_invoice_loader: true,
      squarePayModal: false,
      iolpayModal: false,
      paytmData: {},
      stripe_client_secret: "",
      stripeRetryPaymentModal: false,
      pendingOrderResponse: "",
      overview: {},
      go_back_modal: false
    },
    this.card;
  }


  static navigationOptions = {
    header: null,
    gesturesEnabled: false,
    animationEnabled: true,
  };

  getLatestDate = (array, date) => {
    let new_index = array.findIndex(x => x.date == date )+1
    console.log("kkkkk",array[new_index], new_index, array, date )
    if(array.length<new_index+1){
        return ""
    }
    else if(!array[new_index].blocked){
      return array[new_index].date
    }else if(array.length>=new_index){
      return this.getLatestDate(array, array[new_index].date)
      
    }else{
      return ""
    }

  }
  setInitialDate = () => {
    let today = moment()
    let date_to_return = today.format('DD/MM/YYYY')
    if(this.props.config.show_today && this.props.config.working_hour){
      this.setState({selectedDate: today.format('DD/MM/YYYY')})
    }else if(this.state.future_enabled){

      if(this.props.config.future_date_start_from){
        date_to_return = moment().add(parseInt(this.props.config.future_date_start_from)+1, "days").format('DD/MM/YYYY')  
        let date_to_return_moment = moment().add(parseInt(this.props.config.future_date_start_from)+1, "days") 

        console.log(this.props.config.is_future_date_defined, "pppppppppppp")
        if(this.props.config.is_future_date_defined && this.props.config.block_out_days){
          let dates_arr = JSON.parse(this.props.config.block_out_days)
          let days =  [...dates_arr].filter(item=> !item.blocked)
          console.log(days, "blockkk")
          let final_days = days.map(item => {return item.date})
          console.log(final_days, "blockkk")
          let end_date = moment(final_days[final_days.length - 1], "DD/MM/YYYY" )
          let start_date = moment(final_days[0], "DD/MM/YYYY" )
          console.log(end_date,date_to_return_moment, "llllll")

          var time1 = end_date.set({hour:0,minute:0, second:0, millisecond:0});
          var time2 = date_to_return_moment.set({hour:0,minute:0, second:0, millisecond:0});
          if(time1<time2){
            console.log("lesssssssssssssss")
            return;
          }
          if(time1>time2){
            // return;
            date_to_return=start_date.format('DD/MM/YYYY') 
          }
          console.log("uuuuuuu", final_days,date_to_return )
          if (!final_days.includes(date_to_return)){
            console.log("hereeeeeeeeeee")
            date_to_return = this.getLatestDate(dates_arr, date_to_return)
            console.log(date_to_return, "returnnnnnn")
          }

        }

      }else{
        if(this.props.config.is_future_date_defined){
          let days = JSON.parse(this.props.config.block_out_days).filter(item=> !item.blocked)
          let final_days = days.map(item => {return item.date})
          let start_date = moment(final_days[0], "DD/MM/YYYY" )
          let end_date = moment(final_days[final_days.length - 1], "DD/MM/YYYY" )
          if(end_date<today){
            return
          }
          if(start_date<today){
            date_to_return = today.add(1, "days").format('DD/MM/YYYY')
          }else{
            date_to_return = start_date.format("DD/MM/YYYY")
          }
        }else{
          // return
          date_to_return = today.add(1, 'days').format('DD/MM/YYYY')
  
        }
      }

      let disabled_dates = this.props.config.working_hours ? JSON.parse(this.props.config.working_hours).filter(item => !item.checked) : []
      date_to_return = this.validateDate(date_to_return, disabled_dates)

      }

      // if(date_to_return==today.format("DD/MM/YYYY") && !this.props.config.working_hour){
      //   if(this.state.future_enabled){
      //     date_to_return=moment(date_to_return, "DD/MM/YYYY").add(1,'days').format('DD/MM/YYYY')
      //     console.log(date_to_return,"rrrrrrrrrrrrrrr")
      //     this.validateDate(date_to_return, disabled_dates)
      //   }else{
      //     this.setState({selectedDate: ""})
      //   }
      // }
      // console.log(date_to_return, "fffffffffffffffffff")
      // return date_to_return


      // return today.format('DD/MM/YYYY')

  }

  validateDate = (date, disabled_dates) => {
    let date_to_return = date
    console.log(disabled_dates,moment(date_to_return, 'DD/MM/YYYY').format('dddd'), "datesssssssssssssssssss")
    if(_.some(disabled_dates, ['label', `campaign.${moment(date_to_return, 'DD/MM/YYYY').format('dddd').toLowerCase()}`])){
      // return true
      date_to_return = moment(date_to_return, "DD/MM/YYYY").add(1, "days").format("DD/MM/YYYY")
      this.validateDate(date_to_return, disabled_dates)
    }else{

      this.setState({selectedDate: date_to_return})
      return date_to_return

    }
  }
  closeAddressModal = () => {
    if (!this.state.default_address) {
      this.throwMessageFn(i18n.t("labels.choose_an_address_or_create_one"), "red");
      return;
    }
    this.setState({ addressModal: false });
  };
  navigateToAddressForm = () => {
    this.setState({ addressModal: false }, () => {
      this.props.navigation.navigate("AddressFormScreen", {
        loadAddress: this.loadAddress,
      });
    });
  };
  setDefaultAddress = (item) =>
    performRequest("put", "api/v2/pwa/address/", { id: item.id }).then(() => {
      this.setState({ default_address: item, addressModal: false });
      this.loadAddress();
    });

  togglePaymentModal = () =>
    this.setState({ paymentMethodModal: !this.state.paymentMethodModal });

  onSelectingPaymentMethod = (method) => {

    if (method == "card") {
      this.setState(
        { payment_method: "card", paymentMethodModal: false },
        () => {
          this.updateCart()
          if (
            this.props.config.payment.service == "stripe" || 
            this.props.config.payment.service == "my menu pay" || 
            this.props.config.payment.service == "checkout"  || 
            this.props.config.payment.service == "2checkout" ||
            this.props.config.payment.service == "square pay" 
            ) {                  
            this.props.navigation.push("PaymentScreen", {
            });
          }
        }
      );
    } else {
      if(method=="crypto"){
        // let mymenu_charges = (parseFloat(this.totalWithoutMymenuCharge()) / 100) * 5;
        // this.setState({mymenu_charges: PriceLoader(mymenu_charges, this.props.config.currency, this.props.config.display_fraction)}, () => {
          this.setState({ payment_method: method },async()=>{
            await this.updateCart()
            this.cryptoPaymentCoinbase()
          });
        // })
      }else{
        this.setState({mymenu_charges: ""})
      }

      this.setState({ payment_method: method, paymentMethodModal: false },()=>{
        this.updateCart()
      });
    }
  };
  completeOnlinePayment = (response) => {
    this.props.navigation.navigate('CheckoutScreen')
    this.setState({ loader: true }, () => {
      this.checkout(response.id, "stripe_paid");
    });
  }
  toggleTimeSchedule = () =>
    this.setState({ timeScheduleModal: !this.state.timeScheduleModal });

  deleteAddress = (id) => {
    performRequest("delete", `api/v2/pwa/address/?id=${id}`).then((response) => {
      this.loadAddress();
      this.throwMessageFn(i18n.t("labels.address_deleted"), "green");
    });
  };

  _timeScheduleList = () => (
    <ModalComponent
    design={this.props.design}
      height={300}
      toggleModal={this.toggleTimeSchedule}
      title={i18n.t("checkout.select_delivery_time")}
      custom_font={this.props.design.custom_font}
    >
      {this.state.schedule_list.map((item) => (
        <TouchableOpacity
          onPress={() =>
            this.setState({ default_time: item, timeScheduleModal: false })
          }
        >
          <View style={style.ModalListContainer}>
            <View
              style={{
                flex: 0.7,
                justifyContent: "center",
                alignItems: "flex-start",
                marginLeft: 8,
              }}
            >
              {/* <Material size={13} name={'camera-timer'} color={'blue'} />   */}
              <View style={style.closeButton}>
                <Material size={13} name={"timer"} color={"#5d6670"} />
              </View>
            </View>
            <View
              style={{
                flex: 6,
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <StyledText 
               font_color={this.props.design.popup_text_color}
               font_weight={this.props.design.popup_text_font_weight}
               font_style={this.props.design.popup_text_font_style}
               text_transform={this.props.design.popup_text_font_transform}
               font_family={this.props.design.popup_text_font_family}
               font_size={this.props.design.popup_text_font_size??14}
               letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

              style={{}}>{item}</StyledText>
              {/* <StyledText numberOfLines={1} style={{fontSize:12}}>{item.complete_address}, {item.location_str}</StyledText> */}
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "flex-end",
                marginRight: 8,
              }}
            >
              {item == this.state.default_time && (
                <OctI size={19} name={"check"} color={"green"} />
              )}
            </View>
          </View>
        </TouchableOpacity>
      ))}
    </ModalComponent>
  );

  customPaymentMethods = () => {  
    return(
      <>
      {this.props.config.payment_methods.map(item => {
        return(
          <View style={style.ModalListContainer}>
            <PaymentButton
              onSelectingPaymentMethod={() =>
                this.onSelectingPaymentMethod(item)
              }
              title={item}
              icon={"fas fa-money-bill-wave-alt"}
              iconSize={10}
              design={this.props.design}
            />
          </View>
        )
      })}
      </>
    )

  }
  paymentMethods = () => {
    if (this.props.platform == "qr") {
      return (
        <>
          {this.props.config.allow_cash_payment && (
            <View style={style.ModalListContainer}>
              <PaymentButton
                onSelectingPaymentMethod={() =>
                  this.onSelectingPaymentMethod("cash")
                }
                title={i18n.t("checkout.cash")}
                icon={"fas fa-money-bill-wave-alt"}
                iconSize={10}
                design={this.props.design}
              />
            </View>
          )}
          {this.props.config.allow_online_payment && (
            <>
            {
              this.showAppleGoogleMethods(this.state.availablePaymentMethod.applePay) &&
               (
                <View style={style.ModalListContainer}>
                  <PaymentButton
                    onSelectingPaymentMethod={() =>
                      this.onSelectingPaymentMethod("apple_pay")
                    }
                    title={i18n.t("checkout.apple_pay")}
                    icon={"fab fa-apple-pay"}
                    iconSize={10}
                    design={this.props.design}
                  />
                </View>
              )
            }
            {
              this.showAppleGoogleMethods(this.state.availablePaymentMethod.googlePay) && (
                <View style={style.ModalListContainer}>
                  <PaymentButton
                    onSelectingPaymentMethod={() =>
                      this.onSelectingPaymentMethod("google_pay")
                    }
                    title={i18n.t("checkout.google_pay")}
                    icon={"fab fa-google"}
                    iconSize={10}
                    design={this.props.design}
                  />
                </View>
              )
            }
            {/* {
             !this.state.availablePaymentMethod.googlePay && !this.state.availablePaymentMethod.applePay && this.state.availablePaymentMethod.savedPayment && (
                <View style={style.ModalListContainer}>
                  <PaymentButton
                    onSelectingPaymentMethod={() =>
                      this.onSelectingPaymentMethod("saved_payment")
                    }
                    title={i18n.t("checkout.saved_payment")}
                    icon={"fas fa-credit-card"}
                    iconSize={10}
                  />
                </View>
              )
            } */}

            <View style={style.ModalListContainer}>
              <PaymentButton
                onSelectingPaymentMethod={() =>
                  this.onSelectingPaymentMethod("card")
                }
                title={i18n.t("checkout.card_payment")}
                icon={"far fa-credit-card"}
                iconSize={10}
                design={this.props.design}
              />
            </View>
              {
                this.props.config.allow_crypto && (
                  <View style={style.ModalListContainer}>
                    <PaymentButton
                      onSelectingPaymentMethod={() =>
                        this.onSelectingPaymentMethod("crypto")
                      }
                      title={i18n.t("checkout.cryptocurrency")}
                      icon={"fab fa-bitcoin"}
                      iconSize={10}
                      design={this.props.design}
                    />
                  </View>
                )
              }


            </>
          )}
          {this.props.config.room_charge && (
            <View style={style.ModalListContainer}>
              <PaymentButton
                onSelectingPaymentMethod={() =>
                  this.onSelectingPaymentMethod("room")
                }
                title={i18n.t("order.room_charge")}
                icon={"fas fa-money-bill-wave-alt"}
                iconSize={10}
                design={this.props.design}
              />
            </View>
          )}

          {/* {this.props.config.is_full_service && (
            <View style={style.ModalListContainer}>
              <PaymentButton
                onSelectingPaymentMethod={() =>
                  this.onSelectingPaymentMethod("pay_later")
                }
                title={i18n.t("order.pay_later")}
                icon={"fas fa-money-bill-wave-alt"}
                iconSize={10}
                design={this.props.design}
              />
            </View>
          )} */}

        {this.customPaymentMethods()}
        </>
      );
    }
    if (this.props.menu_type == "delivery") {
      return (
        <>
          {this.props.config.allow_online_payment && (
            <>
            {
              this.showAppleGoogleMethods(this.state.availablePaymentMethod.applePay) && (
                <View style={style.ModalListContainer}>
                  <PaymentButton
                    onSelectingPaymentMethod={() =>
                      this.onSelectingPaymentMethod("apple_pay")
                    }
                    title={i18n.t("checkout.apple_pay")}
                    icon={"fab fa-apple-pay"}
                    iconSize={10}
                    design={this.props.design}
                  />
                </View>
              )
            }
            {
              this.showAppleGoogleMethods(this.state.availablePaymentMethod.googlePay) && (
                <View style={style.ModalListContainer}>
                  <PaymentButton
                    onSelectingPaymentMethod={() =>
                      this.onSelectingPaymentMethod("google_pay")
                    }
                    title={i18n.t("checkout.google_pay")}
                    icon={"fab fa-google"}
                    iconSize={10}
                    design={this.props.design}
                  />
                </View>
              )
            }
            <View style={style.ModalListContainer}>
              <PaymentButton
                onSelectingPaymentMethod={() =>
                  this.onSelectingPaymentMethod("card")
                }
                title={i18n.t("checkout.card_payment")}
                icon={"far fa-credit-card"}
                iconSize={10}
                design={this.props.design}
              />
            </View>

            {
                this.props.config.allow_crypto && (
                  <View style={style.ModalListContainer}>
                    <PaymentButton
                      onSelectingPaymentMethod={() =>
                        this.onSelectingPaymentMethod("crypto")
                      }
                      title={i18n.t("checkout.cryptocurrency")}
                      icon={"fab fa-bitcoin"}
                      iconSize={10}
                      design={this.props.design}
                    />
                  </View>
                )
              }

            </>
          )}
          {this.props.config.allow_cash_on_delivery && (
            <View style={style.ModalListContainer}>
              <PaymentButton
                onSelectingPaymentMethod={() =>
                  this.onSelectingPaymentMethod("cash")
                }
                title={i18n.t("checkout.cash_on_delivery")}
                icon={"square-inc-cash"}
                iconSize={17}
                design={this.props.design}
              />
            </View>
          )}
          {this.props.config.allow_card_on_delivery && (
            <View style={style.ModalListContainer}>
              <PaymentButton
                onSelectingPaymentMethod={() =>
                  this.onSelectingPaymentMethod("cc_delivery")
                }
                title={i18n.t("checkout.card_on_delivery")}
                icon={"credit-card"}
                iconSize={17}
                design={this.props.design}
              />
            </View>
          )}
          {this.customPaymentMethods()}
        </>
      );
    } else {
      return (
        <>
          {this.props.config.allow_online_payment && (
            <>
            {
              this.showAppleGoogleMethods(this.state.availablePaymentMethod.applePay) && (
                <View style={style.ModalListContainer}>
                  <PaymentButton
                    onSelectingPaymentMethod={() =>
                      this.onSelectingPaymentMethod("apple_pay")
                    }
                    title={i18n.t("checkout.apple_pay")}
                    icon={"fab fa-apple-pay"}
                    iconSize={10}
                    design={this.props.design}
                  />
                </View>
              )
            }
            {
              this.showAppleGoogleMethods(this.state.availablePaymentMethod.googlePay) && (
                <View style={style.ModalListContainer}>
                  <PaymentButton
                    onSelectingPaymentMethod={() =>
                      this.onSelectingPaymentMethod("google_pay")
                    }
                    title={i18n.t("checkout.google_pay")}
                    icon={"fab fa-google"}
                    iconSize={10}
                    design={this.props.design}
                  />
                </View>
              )
            }
            {
             !this.state.availablePaymentMethod.googlePay && !this.state.availablePaymentMethod.applePay && this.state.availablePaymentMethod.savedPayment && (
                <View style={style.ModalListContainer}>
                  <PaymentButton
                    onSelectingPaymentMethod={() =>
                      this.onSelectingPaymentMethod("saved_payment")
                    }
                    title={i18n.t("checkout.saved_payment")}
                    icon={"fas fa-credit-card"}
                    iconSize={10}
                    design={this.props.design}
                  />
                </View>
              )
            }

            <View style={style.ModalListContainer}>
              <PaymentButton
                onSelectingPaymentMethod={() =>
                  this.onSelectingPaymentMethod("card")
                }
                title={i18n.t("checkout.card_payment")}
                icon={"far fa-credit-card"}
                iconSize={10}
                design={this.props.design}
              />
            </View>

            {
                this.props.config.allow_crypto && (
                  <View style={style.ModalListContainer}>
                    <PaymentButton
                      onSelectingPaymentMethod={() =>
                        this.onSelectingPaymentMethod("crypto")
                      }
                      title={i18n.t("checkout.cryptocurrency")}
                      icon={"fab fa-bitcoin"}
                      iconSize={10}
                      design={this.props.design}
                    />
                  </View>
                )
              }

            </>
          )}
          {this.props.config.allow_cash_on_pickup && (
            <View style={style.ModalListContainer}>
              <PaymentButton
                onSelectingPaymentMethod={() =>
                  this.onSelectingPaymentMethod("cash")
                }
                title={i18n.t("checkout.cash_on_pickup")}
                icon={"square-inc-cash"}
                iconSize={17}
                design={this.props.design}
              />
            </View>
          )}
          {this.props.config.allow_card_on_pickup && (
            <View style={style.ModalListContainer}>
              <PaymentButton
                onSelectingPaymentMethod={() =>
                  this.onSelectingPaymentMethod("cc_delivery")
                }
                title={i18n.t("checkout.card_on_pickup")}
                icon={"credit-card"}
                iconSize={17}
                design={this.props.design}
              />
            </View>
          )}
          {this.customPaymentMethods()}
        </>
      );
    }

  };

  _paymentMethodList = () => (
    <ModalComponent
    design={this.props.design}
      height={330}
      toggleModal={this.togglePaymentModal}
      title={i18n.t("labels.select_payment_mode")}
      custom_font={this.props.design.custom_font}
    >
      {this.paymentMethods()}
    </ModalComponent>
  );

  _renderAddressList = () => (
    <ModalComponent
    design={this.props.design}
      height={300}
      toggleModal={this.closeAddressModal}
      title={i18n.t("checkout.select_address")}
      custom_font={this.props.design.custom_font}
    >
      {this.state.all_address.map((item) => (
        <TouchableOpacity onPress={() => this.setDefaultAddress(item)}>
          <View style={style.ModalListContainer}>
            <View
              style={{
                flex: 0.7,
                justifyContent: "center",
                alignItems: "flex-start",
                marginLeft: 8,
              }}
            >
              <View style={style.closeButton}>
                <Material
                  size={13}
                  name={"map-marker-multiple"}
                  color={"#5d6670"}
                />
              </View>
            </View>
            <View
              style={{
                flex: 6,
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <StyledText 
              font_color={this.props.design.popup_text_color}
              font_weight={this.props.design.popup_text_font_weight}
              font_style={this.props.design.popup_text_font_style}
              text_transform={this.props.design.popup_text_font_transform}
              font_family={this.props.design.popup_text_font_family}
              font_size={this.props.design.popup_text_font_size??14}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

              style={{}}>
                {item.address_nickname == 1
                  ? "Home"
                  : item.address_nickname == 2
                  ? "Office"
                  : "Other"}
              </StyledText>
              <StyledText 
              font_color={this.props.design.popup_text_color}
              font_weight={this.props.design.popup_text_font_weight}
              font_style={this.props.design.popup_text_font_style}
              text_transform={this.props.design.popup_text_font_transform}
              font_family={this.props.design.popup_text_font_family}
              font_size={this.props.design.popup_text_font_size??14}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

              numberOfLines={1} style={{ }}>
                {item.complete_address.slice(0, 23)}.., {item.location_str}
              </StyledText>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "flex-end",
                marginRight: 8,
              }}
            >
              {item.default_address ? (
                <OctI size={19} name={"check"} color={"green"} />
              ) : (
                <TouchableOpacity
                  onClick={() => this.deleteAddress(item.id)}
                  hitSlop={{ top: 20, bottom: 20, left: 50, right: 50 }}
                >
                  <OctI size={19} name={"trashcan"} color={"red"} />
                </TouchableOpacity>
              )}
            </View>
          </View>
        </TouchableOpacity>
      ))}
      <TouchableOpacity onPress={this.navigateToAddressForm}>
        <View style={[style.ModalListContainer, {backgroundColor:this.props.design.popup_button_color}]}>
          <View
            style={{
              flex: 6,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <StyledText
            font_color={this.props.design.popup_button_text_color}
            font_weight={this.props.design.popup_button_font_weight}
            font_style={this.props.design.popup_button_font_style}
            text_transform={this.props.design.popup_button_font_transform}
            font_family={this.props.design.popup_button_font_family}
            font_size={this.props.design.popup_button_font_size}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
             style={{ fontWeight: "bold", fontSize: this.props.design.custom_font.sub_heading_size ?? 14, marginLeft: 10, fontFamily:'SecondaryFont' }}>
              {i18n.t("labels.add_new_address")}
            </StyledText>
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-end",
              marginRight: 8,
            }}
          ></View>
        </View>
      </TouchableOpacity>
    </ModalComponent>
  );

  onPressOKFromErrorCard = () => {
    this.setState(
      {
        loader: false,
      },
      () =>
        this.setState({
          card_error: false,
          card_error_mzg: "Unauthorized Card",
        })
    );
  };
  throwMessageFn = (message, status) => {
    this.setState({
      message_status: status,
      error_message: message,
      message_display: "inherit",
    });

    setTimeout(
      function () {
        this.setState({
          message_status: "",
          error_message: "",
          message_display: "none",
        });
      }.bind(this),
      5000
    );
  };
  setPaymentMethodOnOrder = (total) => {
    if (total == 0) {
      return 4;
    }
    if (this.state.payment_method == "cash") {
      if(this.props.platform == "qr"){
        return 3
      }else{
        return 1;
      }
    } else if (this.state.payment_method == "card") {
      return 2;
    } else if (this.state.payment_method == "cc_delivery") {
      return 5;
    } else if (this.state.payment_method == "room") {
      return 6;
    }else if(this.state.payment_method == "apple_pay") {
      return 2;
    }else if(this.state.payment_method == "google_pay") {
      return 2;
    }else if(this.state.payment_method == "crypto") {
      return 8;
    }
    else if(this.state.payment_method == ""){
      return 4
    }
    else {
      return 7;
    }
    // total == 0 ? 4 : this.state.payment_method == 'cash' ? 1 : 2,
  };

  getTotalFn = () => {
    let total_amount_by_curr = PriceLoader(this.props.total_price, this.props.config.currency, this.props.config.display_fraction);
    let total = 0;
    if (this.state.default_promo) {
      total = percentageHandler.getTotal(
        this.state.default_promo,
        total_amount_by_curr,
        this.props.config.delivery_charge,
        this.props.menu_type,
        this.props.config.currency
      );
    } else {
      if (
        this.props.config.delivery_charge &&
        this.props.menu_type == "delivery" &&
        this.props.platform != "qr"
      ) {
        total =
          this.props.total_price +
          parseFloat(this.props.config.delivery_charge);
      } else {
        total = total_amount_by_curr;
      }
    }
    if (this.state.tips) {
      total = parseFloat(total) + parseFloat(this.state.tips);
    }
    for (let i = 0; i < this.props.config.taxes_services.taxes.length; i++) {
      if (this.props.config.taxes_services.taxes[i].inclusive == false) {
        total =
          parseFloat(total) +
          this.percentagePriceCalculator(
            this.props.total_price,
            this.props.config.taxes_services.taxes[i].rate
          );
      }
    }
    return this.isZero(total);
    // return total
  };
  getTaxData = () => {
    let total = this.props.total_price;
    if (this.state.default_promo) {
      if (this.state.default_promo.promo_type == 1) {
        total -= PriceLoader(
          (this.state.default_promo.percentage_discount / 100) * total,
          this.props.config.currency, this.props.config.display_fraction
        );
      } else {
        total -= PriceLoader(
          this.state.default_promo.percentage_discount, this.props.config.currency, this.props.config.display_fraction
        );
      }
    }

    let inclusive_total = 0;
    for (let i = 0; i < this.props.config.taxes_services.taxes.length; i++) {
      if (this.props.config.taxes_services.taxes[i].inclusive == true) {
        // inclusive_total += parseFloat(this.props.config.taxes_services.taxes[i].rate)
        let percentage_total = this.getPercentageTotal();
        let inclu_tax =
          (parseFloat(this.inclusiveTaxCalculator(total, percentage_total)) *
            parseFloat(this.props.config.taxes_services.taxes[i].rate)) /
          100;
        inclusive_total += inclu_tax;
      }
    }

    let tax = [];
    let tax_array = [...this.props.config.taxes_services.taxes];

    for (let i = 0; i < tax_array.length; i++) {
      if (tax_array[i].inclusive == false) {
        let total_without_inclusive = total - inclusive_total;
        let exclu_tax = this.percentagePriceCalculator(
          total_without_inclusive,
          tax_array[i].rate
        );
        tax_array[i].amount = PriceLoader(exclu_tax, this.props.config.currency, this.props.config.display_fraction);
      } else {
        let percentage_total = this.getPercentageTotal();
        let inclu_tax =
          (parseFloat(this.inclusiveTaxCalculator(total, percentage_total)) *
            parseFloat(tax_array[i].rate)) /
          100;
        tax_array[i].amount = PriceLoader(inclu_tax, this.props.config.currency, this.props.config.display_fraction);
      }
      tax.push(tax_array[i]);
    }
    return tax;
    // return this.isZero(tax)
  };
  isZero = (value) => {
    if (value > 0) {
      return PriceLoader(value, this.props.config.currency, this.props.config.display_fraction);
    } else {
      return PriceLoader(0, this.props.config.currency, this.props.config.display_fraction);
    }
  };
  getOrderData = async(
    // cart,
    token,
    total,
    amount_discount,
    delivery_details,
    mid,
    taxes,
    payment_status
  ) => {
    const {payment, pos_connected} = this.props.config
    // let uuid = window.location.pathname.substring(1);
    let uuid = await AsyncStorage.getItem("uuid");
    return {
      uuid,
      venue: this.props.config.venue,
      // items: JSON.stringify(cart),
      total: total,
      currency: this.props.config.currency_code,
      token: token,
      with_payment:
        this.state.payment_method == "cash" ||
        this.state.payment_method == "cc_delivery" ||
        !this.state.payment_method ||
        this.state.payment_method == "room" || await this.setPaymentMethodOnOrder(total) == 7
          ? false
          : true,
      time: this.state.default_time,
      // 'payment_mode': total == 0 ? 4 : this.state.payment_method == 'cash' ? 1 : 2,
      payment_mode: await this.setPaymentMethodOnOrder(total),

      customer: this.props.user.id,
      channel: this.props.platform == "qr" ? 2 : this.props.menu_type == "delivery" ? 3 : 4,
      applied_promo: JSON.stringify(this.state.default_promo),
      amount_discount: amount_discount,
      delivery_details: JSON.stringify(delivery_details),
      comment: this.state.instructions,

      payment_service: this.props.config.payment
        ? this.props.config.payment.service
        : "my menu payment",
      payment_service_id: this.props.config.payment
        ? this.props.config.payment.id
        : "",

      delivery_charge: this.props.menu_type == "delivery" || this.props.platform == "qr" && this.props.config.delivery_charge ? this.props.config.delivery_charge : PriceLoader(0, this.props.config.currency, this.props.config.display_fraction),
      delivery_date: this.state.selectedDate,
      card: this.props.card,
      saveCard: this.props.saveCard,
      oldCard: this.props.oldCard,
      mid: mid,
      table: this.props.table_detail.table,
      tips: this.state.tips,
      taxes: JSON.stringify(taxes),
      net_total: await this.calculateNetTotal(parseFloat(this.props.total_price)),
      sub_total: await this.subTotalLoader(this.props.total_price),
      payment_status: payment_status,
      auto_accept_orders: this.props.config.auto_accept_orders,
      custom_payment: this.state.payment_method,
      crypto_payment: {
        invoice_id: this.state.coinbase_invoice,
        payment_amount: "",
        payment_currency: ""
      },
      square_pos_pay: pos_connected && pos_connected.name.toLowerCase() == "square pos" && payment && payment.service == "square pay" && (this.state.payment_method == "card" || this.state.payment_method == "google_pay" || this.state.payment_method == "apple_pay"),
      paytm: this.state.paytmData
    };
  };

  checkout = async (token, payment_status="") => {


    let delivery_details = { ...this.props.user };
    delivery_details["address"] = this.state.default_address;
    
    const {config, default_language} = this.props;
    let custom_label = config.custom_label_multi ? 
      IntlLabelFn(
        JSON.parse(config.custom_label_multi), 
        config.default_language.value, 
        config.default_language.value
        ) : ""
    let custom_label_2 = config.custom_label_2_multi ? 
      IntlLabelFn(
        JSON.parse(config.custom_label_2_multi), 
        config.default_language.value, 
        config.default_language.value
        ) : ""
      
    delivery_details["plate_no"] = {
      [custom_label]: this.state.pickup_plate_no,
    };
    delivery_details["plate_no_2"] = {
      [custom_label_2]: this.state.pickup_plate_no_2,
    };
    delivery_details["tips"] = this.state.tips;

    let all_cart = [...this.props.cart];
    // let cart = all_cart.map((item) => {
    //   let total_price = item.selected_price.price;
    //   // item.temp_price = item.selected_price.price;
    //   if (item.modifier) {
    //     item.modifier.map((mod_item) => {
    //       item.selected_price.price =
    //         item.selected_price.price + mod_item.price;
    //     });
    //   }

    //   return {
    //     name: item.item_names[this.props.default_language],
    //     modifier: item.modifier ? item.modifier : [],
    //     count: item.count,
    //     unit_price: total_price,
    //     selected_price: item.selected_price,
    //     id: item.id,
    //     note: "",
    //     comment: item.comment ? item.comment : "",
    //   };
    // });

    // let total = this.getTotalFn()
    let total = this.state.overview.total;
    let taxes = this.getTaxData();
    let total_amount_by_curr = PriceLoader(this.props.total_price, this.props.config.currency, this.props.config.display_fraction);
    let amount_discount = null;
    if (this.state.default_promo) {
      if (this.state.default_promo.promo_type == 1) {
        amount_discount = percentageHandler.getPercentage(
          this.state.default_promo.percentage_discount,
          total_amount_by_curr,
          this.props.config.currency,
          this.props.config.display_fraction
        );
      } else {
        amount_discount = this.state.default_promo.percentage_discount;
      }
    }


    let data = await this.getOrderData(
      // cart,
      token,
      total,
      amount_discount,
      delivery_details,
      mid,
      taxes,
      payment_status
    );
    console.log(data, "dataaaaaaaaa")

    axios
      .post(API_URL + "api/v2/complete/order/qr/ ", data)
      .then(async (response) => {
 

        if(response.data?.next_action?.status == "requires_action" && this.props.stripeObject){
          let {stripeObject} = this.props
          let result = await stripeObject
          .confirmCardPayment(response.data.next_action.payment_intent_client_secret)
          console.log(result, "rrrrrrr")
          if(result.error){
            this.props.addCard("", "", "", "", "", "", "", "", "");
            let message = result.error?.message || "Something Went Wrong";
            this.setState({ 
              card_error: true, 
              card_error_mzg: message,
              // stripe_client_secret : response.data.next_action.payment_intent_client_secret,
              // pendingOrderResponse: response 
             });
            return;
          }
        }
        else if(response.data?.next_action?.status == "requires_action" && !this.props.stripeObject){
          var stripe = Stripe(this.props.config.payment.key);
          let result = await stripe
            .confirmCardPayment(response.data.next_action.payment_intent_client_secret)
            console.log(result, "rrrrrrr")
            if(result.error){
              this.props.addCard("", "", "", "", "", "", "", "", "");
              let message = result.error?.message || "Something Went Wrong";
              this.setState({ 
                card_error: true, 
                card_error_mzg: message,
                // stripe_client_secret : response.data.next_action.payment_intent_client_secret,
                // pendingOrderResponse: response 
               });
              return;
            }
        }
        this.postOrderHandler(response, all_cart)

      })
      .catch((error) => {
        console.log(error, 'rrrrrrrrrrrr')
        let message = error.response.data
          ? error.response.data.status
            ? error.response.data.status
            : "Something Went Wrong"
          : "Something Went Wrong";
        this.setState({ card_error: true, card_error_mzg: message });
      });
  };

  postOrderHandler = (response, all_cart) => {
    this.tagManager(response);
    this.setState({ 
      order_placed: true,
      pendingOrderResponse: "", 
      stripe_client_secret: ""
    }, async() => {
      let uuid = await AsyncStorage.getItem("uuid");
      if(this.state.overview.cart){
        performRequest('delete', `api/pwa/cart/overview/${this.state.overview.cart}?uuid=${uuid}&mid=${Constants.deviceId}&platform=${this.props.platform!=="qr"? this.props.menu_type : this.props.platform}`)
      }
      setTimeout(
        function () {
          this.setState({
            loader: false,
            orderSuccessModal: true,
            order_response: response.data,
            
            

          });
          all_cart.map((item) => {
            if (item.modifier) {
              item.modifier.map((mod_item) => {
                item.selected_price.price =
                  item.selected_price.price - mod_item.price;
              });
            }
          });
          this.props.resetCart();
          this.props.setOrderData({})
          this.props.resetChildren();
          this.props.addCard("", "", "", "", "", "", "", "", "");
          this.setState({ order_placed: false, tips: "" });
          // this.props.navigation.navigate('HomeScreen')
        }.bind(this),
        3000
      );
    });
  }

  tagManager = (response) => {
    AsyncStorage.getItem("uuid", (err, uuid) => {
      if (err) {
        return;
      } else {
        this.setState({ uuid: uuid });
        PushData(
          this.props.navigation.state.routeName,
          this.props.config.venueName ? this.props.config.venueName : "Ent",
          uuid ? uuid : "Ent",
          {
            order_id: response.data.ticket,
            total: response.data.total,
          }
        );
      }
    });
  };
  makeRazorPayPayment = () => {
    axios
      .post(API_URL + "api/razorpay/order/", {
        // this.qrTotalSummary()
        amount: this.priceHandlerCheckout(this.state.overview.total),
        currency: this.props.config.currency_code,
        receipt: this.props.config.venue + Math.round((new Date()).getTime() / 1000),
        payment_id: this.props.config.payment.id,
        // access_token: this.props.config.payment.access_token,
      })
      .then((response) => {
        var options = {
          key: this.props.config.payment.public_token, // Enter the Key ID generated from the Dashboard
          amount: this.priceHandlerCheckout(this.state.overview.total), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: this.props.config.currency_code,
          name: "MyMenu",
          description: "MyMenu Online Transaction",
          image:
            "https://mymenu-backend-staging.s3.ap-south-1.amazonaws.com/MY+MENU+STRIPE+128x128.jpg",
          order_id: response.data.id,
          handler: (response) => {
            this.setState({ loader: true }, () => {
              this.checkout(response.razorpay_payment_id);
            });
          },
          prefill: {
            name: "",
            email: "",
            contact: "",
          },
          notes: {
            address: "MyMenu, Dubai",
          },
          theme: {
            color: "#F37254",
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      })
      .catch((error) => {
        this.throwMessageFn("Something went wrong", "red");
      });
  };

  onIOLPaymentResponse = (response) => {
    console.log("onIOLPaymentResponse", response);
  }

  onIOLPaySubmitPayment = (response) => {
    console.log("submitPaymentt..", response )
  }

  onIOLPayNowClick = () => {
    window.iolpay.submitPayment(); 
    window.addEventListener("message", this.receiveMessage, false);
  }

  receiveMessage = (response) => {   
    var resp = false;
    if (response.data != null) {
      if (response.data.type === "ON_SUCCESS" && response.data.value.status === "Succeeded") {
        resp = true;
      }

      if (response.data.type === "ON_ERROR" && response.data.value.message != "") {
        resp = true;
      }
    }
    if (!resp) {
      return;
    } 
    if(response.data.type === "ON_SUCCESS" && response.data.value.transactionId)  {
      this.setState({iolpayModal:false});
      window.iolpay.paymentSettings = {};
      this.setState({ loader: true }, () => {
        this.checkout(response.data.value.transactionId, "iolpay_paid");   
      });
    }



  }

  onIOLPayModalClose = () => {
    this.setState({iolpayModal:false}); 
    const IFRAME_ID = "iol-pay-iframe-element";      
    if(document.getElementById(IFRAME_ID)) {
      document.getElementById(IFRAME_ID).remove();
    }
  }

  iolPayModalView = () => {       
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backdropFilter: "blur(5px)"  }}>
        <View
          style={{
            width:"100%", //Dimensions.get('window').width*.8,
            backgroundColor: this.props.design.popup_background_color,
            borderWidth:1,
            // padding:  22,
            justifyContent: 'center',
            // alignItems: 'center',
            borderRadius: 4,
            borderColor: 'rgba(0, 0, 0, 0.1)',
            height:"100%"//Dimensions.get('window').height*.8
          }}
        > 
     
        <ImageBackground imageStyle={{borderRadius:4}} style={{width: '100%', height: '100%'}}>

        <View style={{ flexDirection: 'row', width: '90%' }}>
                <View style={{ alignItems: 'center', borderRadius: 10, flex: 1, marginTop: 20 }}>
                  <View style={{ alignItems: 'center', marginBottom: 22 }}>
                    <StyledText 
                    font_color={this.props.design.popup_text_color}
                    font_weight={this.props.design.popup_text_font_weight}
                    font_style={this.props.design.popup_text_font_style}
                    text_transform={this.props.design.popup_text_font_transform}
                    font_family={this.props.design.popup_text_font_family}
                    font_size={this.props.design.popup_text_font_size??16}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
      
                    style={{ }}>Checkout</StyledText>
                  </View>
                </View>
              </View>
        
          <View style={{ width: '100%' }} nativeID="iolpaymodal"></View>
         
          <View style={{borderRadius:10,position:'absolute',right:5,top:5}}>
            <TouchableOpacity onPress={() => this.onIOLPayModalClose()}>
                <View  style={{borderWidth:1,borderColor:'black',width:40,height:40,borderRadius:7,alignItems:'center',justifyContent:'center',backgroundColor: this.props.design.popup_button_color }}>
                <Icon style={{ color: this.props.design.popup_button_text_color}} size={30} name={'close'}/>
                </View>
            </TouchableOpacity>
          </View>

          <View style={{ borderRadius: 10, backgroundColor: "black", width: '30%', position: 'absolute', bottom: 20, alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }} >

            <TouchableOpacity onPress={() => this.onIOLPayNowClick()}>
              <View
                style={{
                  backgroundColor: this.props.design.popup_button_color,
                  
                  marginHorizontal: 3,
                  alignSelf: "center",
                  justifyContent: "center",
                  height: 35,
                  borderRadius: 7,
                }}
              >
                <StyledText 
                 font_color={this.props.design.popup_button_text_color}
                 font_weight={this.props.design.popup_button_font_weight}
                 font_style={this.props.design.popup_button_font_style}
                 text_transform={this.props.design.popup_button_font_transform}
                 font_family={this.props.design.popup_button_font_family}
                 font_size={this.props.design.popup_button_font_size}
                 letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{}}>
                  Pay Now
                </StyledText>
              </View>
            </TouchableOpacity>
          </View>
         
          </ImageBackground>
        </View>
      </View>
    );
  };


  createIolPayInstance = () => {
    const paymentSettings = {
      transactionId: this.props.config.venue + Math.round((new Date()).getTime() / 1000),
      payerDetails: {
        payerName: "Payer Name",
        payerEmail: this.props.user.email || "payer@email.com",
        payerAddress: this.props.config.address.address,
        payerCity: this.props.config.address.city,
        payerCountry: this.props.config.country.cca2,
        payerPostCode: "12462"
      },
      paymentDetails: {
        referenceNo: this.props.config.venue,
        currency: this.props.config.currency_code,
        country: this.props.config.country.cca2,
        amount: this.state.overview.total, //this.priceHandlerCheckout(this.qrTotalSummary()),
        orderDate: new Date(),
        description: "My Menu",
        mode: "Payment",
        statementSuffix: this.props.config.venueName || "",
        imageLink: null,
        submitLabel: null
      },
      returnUrlSuccess: "",
      returnUrlCancel: "",
      merchantPubKey: this.props.config.payment.public_token,
      language: this.props.default_language.toUpperCase()//"EN"
    }
  

    window.iolpay = {};
    const methods = ['init', 'onResponse', 'submitPayment'];
    window.iolpay._c = [];
    methods.forEach(method => window.iolpay[method] = function () {
        window.iolpay._c.push([method, arguments]);
    });

    window.iolpay.paymentSettings = paymentSettings;
    window.iolpay.onResponse = this.onIOLPaymentResponse;
    window.iolpay.onSubmitPayment = this.onIOLPaySubmitPayment;
    const elt = document.createElement('script');
    elt.type = 'module';
    elt.async = true;
    elt.src = 'shim.js';
    elt.id = "iolpay";
    const before = document.getElementsByTagName('script')[0];
    before.parentNode.insertBefore(elt, before);
  }

  makeIOLPayPayment = () => {     
    this.setState({ iolpayModal: true });
    this.createIolPayInstance();   
    iolpay.init();
  }

  

  makePaytmPayment = () => {
    this.setState({ loader: true });
    const elt = document.createElement('script');   
    elt.src = PAYTM_URL + this.props.config.payment.public_token + ".js";
    elt.id = "paytm";
    elt.onload = this.onPayTMLoad;
    const before = document.getElementsByTagName('script')[0];
    before.parentNode.insertBefore(elt, before);       
  }

  onPayTMLoad = () => { 
    var _this = this;

    axios
    .post(API_URL + "api/v2/paytm/init/", {
      currency: "INR",
      service_id: this.props.config.payment.id,
      amount: this.state.overview.total,
      order_id: "ORD_" + Math.round((new Date()).getTime() / 1000),
      venue: this.props.config.venue,
      user_id: this.props.user.id ?? null
    })
    .then((response) => {     
      var config = {
        "root": "",
        "flow": "DEFAULT",
        "data": {
          "orderId": response.data.data.order_id, /* update order id */
          "token":  response.data.data.txnToken, /* update token value */
          "tokenType": "TXN_TOKEN",
          "amount": this.state.overview.total /* update amount */
        },
        "merchant": {
          "mid": this.props.config.payment.public_token || '',
          "redirect":false
        },
        "handler": {
          "notifyMerchant": function (eventName, data) {
            console.log("notifyMerchant handler function called");
            console.log("eventName => ", eventName);
            console.log("data => ", data);
            if(eventName == "APP_CLOSED") {
              window.Paytm.CheckoutJS.close();         
            }
          },
          "transactionStatus":function(data){     
            console.log(data, "txndata");                     
            if(data.STATUS == "TXN_SUCCESS") {
              _this.setState({ loader: true, paytmData: data }, () => {
                _this.checkout(data.TXNID, "paytm_paid");                  
              });
            }
            
            if(data.STATUS == "TXN_FAILURE") {    
              _this.throwMessageFn(i18n.t("labels.payment_failed"), "red");
              setTimeout( function () {
                _this.throwMessageFn("ERROR_CODE: " + data.RESPMSG, "red");
              }.bind(_this),
              1000);
            }
            window.Paytm.CheckoutJS.close();         
          } 
        }
     
      }    
    
      if (window.Paytm && window.Paytm.CheckoutJS) {
        console.log("hereeeeeeeeeee")
        // window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
          window.Paytm.CheckoutJS.init(config).then(function onSuccess() {  
            window.Paytm.CheckoutJS.invoke();    
            _this.setState({ loader: false });
          }).catch(function onError(error) {
            console.log("error => ", error);
            _this.setState({ loader: false });
          }); 
        // });
      } 
    }).catch(err=> {  
      console.log(err)  

      _this.setState({ loader: false });  
      if(err && err.response?.data?.error) {
        _this.throwMessageFn(err.response?.data?.error, "red");
      } else {
        _this.throwMessageFn(i18n.t("labels.payment_failed"), "red");
      }
    

    })   
  }

   sha256 = async(message) => {
    const msgBuffer = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));          
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  


  makeSpadesPayment = async (payment) => {
    let uuid = await AsyncStorage.getItem("uuid");
    let order_id = this.state.overview.cart
    if(!this.props.config.is_full_service){
      let payload =  {
          uuid,
          venue: this.props.config.venue,
         
          venue: this.props.config.venue,
          currency: this.props.config.currency_code,
                   
          customer: this.props.user.id??null,
          channel: this.props.platform == "qr" ? 2 : this.props.menu_type == "delivery" ? 3 : 4,
          delivery_details: JSON.stringify({}),
          comment: this.state.instructions,
    
    
          delivery_charge:  this.props.config.delivery_charge,
          
          mid,
          table: this.props.table_detail.table,
          auto_accept_orders: this.props.config.auto_accept_orders,
        
        };
        try{
          let response = await performRequest("post", "api/pwa/express-order",payload)
          console.log(response,response.data,"ressssssss")
          order_id = response.data.id
        }catch(err){
          this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
          this.setState({ loader: false });  
          return
        }
    }

      
    var form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('id', 'merchant_form');   
    form.setAttribute('action', '');    
     
    var total_amountInput = document.createElement('input');
    total_amountInput.setAttribute('type', 'text');
    total_amountInput.setAttribute('name', 'total_amount');  
    total_amountInput.setAttribute("hidden", true);
    total_amountInput.setAttribute("value", this.state.overview.total);

    var currencyInput = document.createElement('input');
    currencyInput.setAttribute('type', 'text');
    currencyInput.setAttribute('name', 'currency');
    currencyInput.setAttribute("hidden", true);
    currencyInput.setAttribute("value", this.props.config.currency_code);

    var referenceInput = document.createElement('input');
    referenceInput.setAttribute('type', 'text');
    referenceInput.setAttribute('name', 'reference');
    referenceInput.setAttribute("hidden", true);
    referenceInput.setAttribute("value", order_id);

    var customer_emailInput = document.createElement('input');
    customer_emailInput.setAttribute('type', 'text');
    customer_emailInput.setAttribute('name', 'customer_email');
    customer_emailInput.setAttribute("hidden", true);
    customer_emailInput.setAttribute("value", this.props.user.email || "payer@email.com");

    var customer_mobileInput = document.createElement('input');
    customer_mobileInput.setAttribute('type', 'text');
    customer_mobileInput.setAttribute('name', 'customer_mobile');
    customer_mobileInput.setAttribute("hidden", true);
    customer_mobileInput.setAttribute("value", '');

    var descriptionInput = document.createElement('input');
    descriptionInput.setAttribute('type', 'text');
    descriptionInput.setAttribute('name', 'description');
    descriptionInput.setAttribute("hidden", true);
    descriptionInput.setAttribute("value", 'My Menu');

   
    var commandInput = document.createElement('input');
    commandInput.setAttribute('type', 'text');
    commandInput.setAttribute('name', 'command');
    commandInput.setAttribute("hidden", true);
    commandInput.setAttribute("value", 'PAYMENT_AUTHORIZATION');
    
    var merchant_idInput = document.createElement('input');
    merchant_idInput.setAttribute('type', 'text');
    merchant_idInput.setAttribute('name', 'merchant_id');
    merchant_idInput.setAttribute("hidden", true);
    merchant_idInput.setAttribute("value", payment.merchant_id);

    var success_urlInput = document.createElement('input');
    success_urlInput.setAttribute('type', 'text');
    success_urlInput.setAttribute('name', 'success_url');
    success_urlInput.setAttribute("hidden", true);
    success_urlInput.setAttribute("value", `${window.location.origin}/${uuid}?payment_status=SUCCESS`);


    var cancel_urlInput = document.createElement('input');
    cancel_urlInput.setAttribute('type', 'text');
    cancel_urlInput.setAttribute('name', 'cancel_url');
    cancel_urlInput.setAttribute("hidden", true);
    cancel_urlInput.setAttribute("value", `${window.location.origin}/${uuid}?payment_status=CANCELLED`);

    
    var failure_urlInput = document.createElement('input');
    failure_urlInput.setAttribute('type', 'text');
    failure_urlInput.setAttribute('name', 'failure_url');
    failure_urlInput.setAttribute("hidden", true);
    failure_urlInput.setAttribute("value", `${window.location.origin}/${uuid}?payment_status=FAILED`);

    var notify_urlInput = document.createElement('input');
    notify_urlInput.setAttribute('type', 'text');
    notify_urlInput.setAttribute('name', 'notify_url');
    notify_urlInput.setAttribute("hidden", true);
    notify_urlInput.setAttribute("value", API_URL+'api/v2/spades/webhook');
    

    // var notify_urlInput = document.createElement('input');
    // // notify_urlInput.setAttribute('type', 'text');
    // notify_urlInput.setAttribute('name', 'metadata');
    // notify_urlInput.setAttribute("hidden", true);
    // notify_urlInput.setAttribute("value", JSON.stringify({"order":"order"}));
    


    // Create the submit button
    var submitButton = document.createElement('button');
    submitButton.setAttribute('type', 'submit');
    submitButton.textContent = 'Submit';
    
    // Append the form elements to the form
    form.appendChild(total_amountInput);
    form.appendChild(currencyInput);
    form.appendChild(referenceInput);
    form.appendChild(customer_emailInput);
    form.appendChild(customer_mobileInput);
    form.appendChild(descriptionInput);
    form.appendChild(commandInput);
    form.appendChild(merchant_idInput);
    form.appendChild(success_urlInput);
    form.appendChild(cancel_urlInput);
    form.appendChild(failure_urlInput);    
    form.appendChild(notify_urlInput);
    

    document.body.appendChild(form);

    submitButton.onclick = this.onMerchantFormClick(payment, order_id);  
  }


  onMerchantFormClick = (payment, order_id) => {
    let passPhrase = payment.pass_phrase; //bind from props   
    const elements = document.getElementById('merchant_form').elements
    const keys = Object.keys(elements).filter(key=> (key==0 || key=='signature' || key=='metadata') ? false : !Number(key))
    const shaString = `${passPhrase}${keys.sort().map((key) => `${key}=${elements[key].value}`).join('')}${passPhrase}`;

    this.sha256(shaString).then((hex) => {
      this.setState({ loader: false });  
      var form = document.getElementById('merchant_form');
      var signatureInput = document.createElement('input');
      signatureInput.setAttribute('type', 'text');
      signatureInput.setAttribute('name', 'signature');
      signatureInput.setAttribute("hidden", true);
      signatureInput.setAttribute("value", hex);
      form.appendChild(signatureInput); 
      
      form.setAttribute('action', `https://app.sandbox.spadesapp.io/api/gateway/v1/payment_redirects`)
      localStorage.setItem("menuID", this.props.menuID)
      localStorage.setItem("order_data", JSON.stringify({
        amount_discount: this.state.overview.discount,
        total: this.state.overview.total,
        payment_mode: "Online",
        ticket: order_id,
        order: order_id
      }))
      document.getElementById('merchant_form').submit();
    });
    
   
  }
    


  priceHandlerCheckout = (price) => {
    if (THREE_FRACTION_CURRENCY.includes(this.props.config.currency)) {
      return parseInt(price * 1000);
    } else {
      return parseInt(price * 100);
    }
  };

  makeCheckoutPayment = () => {
    Checkout.configure({
      publicKey: CHECKOUT_PUB_KEY,
      customerEmail: "",
      value: this.priceHandlerCheckout(this.getTotalFn()),
      currency: this.props.config.currency_code,
      paymentMode: "cards",
      cardFormMode: "cardTokenisation",
      // logoUrl: "https://mymenu-backend-staging.s3.ap-south-1.amazonaws.com/MY+MENU+STRIPE+128x128.jpg",
      themeColor: "#A98535",
      formButtonLabel: "Pay Now",
      cardTokenised: (event) => {
        this.setState({ loader: true }, () => {
          this.checkout(event.data.cardToken);
        });
      },
    });
    Checkout.open();
  };
  payNow = () => {
    let payment = this.props.config.payment;
    //TEST for Spades
    // let payment = {
    //   application_fee: 0,
    //   id: 1034,
    //   env:"SB",
    //   pass_phrase: "SPADES_dev_TEST",
    //   service: "spades",
    //   merchant_id: "614186b0847f66001cf59857",
    //   base_url:"https://app.sandbox.spadesapp.io"
    // }
   

    if (payment && (payment.service == "stripe" || payment.service == "my menu pay") ) {
      this.checkout(this.props.token);
    } else if (payment && payment.service == "razor pay") {
      this.makeRazorPayPayment();      
    } else if (payment && payment.service == "my menu payment") {
      this.checkout(this.props.token);

    } 
    // else if (payment && payment.service.toLowerCase() == "square pay") {
    //   this.makeSquarePayPayment()
    // } 
    else if (payment && payment.service.toLowerCase() == "iol pay") {
      this.makeIOLPayPayment();
    } else if(payment && payment.service.toLowerCase() == "paytm") {
      this.makePaytmPayment();
    } 
    else if(payment && payment.service.toLowerCase() == "spades") {
      this.makeSpadesPayment(payment);
    }
     else {
      this.checkout(this.props.token);
    }
  };
  makeSquarePayPayment = () => {
    let sqPaymentScript = document.createElement('script');
    sqPaymentScript.src = SQUARE_URL;
    sqPaymentScript.type = "text/javascript"
    sqPaymentScript.async = false;
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    this.setState({ loader: true })
    sqPaymentScript.onload = async()=>{
      const payments = window.Square.payments(SQUARE_APP_ID, this.props.config.payment.locationId);
      if(this.state.payment_method == "card"){
        this.setState({squarePayModal: true })
        
        this.card = await payments.card();
        await this.card.attach('#card');
      }else if(this.state.payment_method == "google_pay"){
        const request = payments.paymentRequest({
          countryCode: this.props.config.country.cca2,
          currencyCode: this.props.config.currency_code,
          total: {
            amount: this.state.overview.total,
            label: 'Total',
          },
        });
        const googlePay = await payments.googlePay(request);
        await googlePay.attach('#google-pay-button');
        try {
          const result = await googlePay.tokenize();
          if (result.status === 'OK') {
            console.log(`Payment token is ${result.token}`);
            this.setState({squarePayModal: false})
            this.checkout(result.token)
            
          }else{
            this.setState({squarePayModal: false, loader: false})
            this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
          }  
        } catch (e) {
          this.setState({squarePayModal: false, loader: false})
          this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
          console.error(e);
  
        }
  
      }else if(this.state.payment_method == "apple_pay"){
        const request = payments.paymentRequest({
          countryCode: this.props.config.country.cca2,
          currencyCode: this.props.config.currency_code,
          total: {
            amount: this.state.overview.total,
            label: 'Total',
          },
        });
        
        try {
          const applePay = await payments.applePay(request);
          // await applePay.attach('#apple-pay-button');
          const result = await applePay.tokenize();
          
          if (result.status === 'OK') {
            console.log(`Payment token is ${result.token}`);
            this.setState({squarePayModal: false})
            this.checkout(result.token)
          }else{
            this.setState({squarePayModal: false, loader: false})
            this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
            console.error(e);
          }
        } catch (e) {
          this.setState({squarePayModal: false, loader: false})
          this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
          console.error(e);
        }



        // const paymentRequest = window.Square.payments.paymentRequest({
        //   total: {
        //     amount: this.priceHandlerCheckout(this.qrTotalSummary()),  
        //     currencyCode: this.props.config.currency_code
        //   }
        // });
        // const googlePay = await payments.googlePay(paymentRequest);
      }


      
  
    };
  }

  squareTokanizeCard = async() => {
    try {

      const result = await this.card.tokenize();

      if (result.status === 'OK') {
        if(result && result.details && result.details.card){
          
          const {brand, expMonth, expYear, last4} = result.details.card;
          console.log(brand, expMonth, expYear, last4, "kkkkkk")
          this.props.addCard(
            last4,
            expYear,
            expMonth,
            "dummy",
            "square pay",
            result.token,
            true,
            true,
            "",
            brand,
          );
        }
        console.log(`Payment token is ${result.token}`, result);
        this.setState({squarePayModal: false})
        this.checkout(result.token)
        
      }else{
        this.setState({squarePayModal: false, loader: false})
        this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
      }

    } catch (e) {
      this.setState({squarePayModal: false, loader: false})
      this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
      console.error(e);

    }
  }
  placeOrder = () => {
    const { cvv, year, month, card_number } = this.props;
   
    if (
      (!this.state.payment_method && this.props.platform != "qr") ||
      (this.props.platform == "qr" &&
        this.props.config.complete_order_only_on_payment &&
        !this.state.payment_method)
    ) {
      this.throwMessageFn(i18n.t("labels.add_a_payment_method_first"), "red");
      return;
    }
    if(
      this.props.config.label_enabled &&
      // this.props.menu_type !== "delivery" && 
      this.props.config.custom_label_required &&
      !this.state.pickup_plate_no
      ){
        const {config, default_language} = this.props;
        let custom_label = config.custom_label_multi ? 
          IntlLabelFn(
            JSON.parse(config.custom_label_multi), 
            config.default_language.value, 
            default_language
            ) : ""
       
        this.throwMessageFn( custom_label +" "+ i18n.t("labels.required"), "red");
        return
      }
      if(
        this.props.config.label_2_enabled &&
        // this.props.menu_type !== "delivery" && 
        this.props.config.custom_label_2_required &&
        !this.state.pickup_plate_no_2
        ){
          const {config, default_language} = this.props;
          
          let custom_label_2 = config.custom_label_2_multi ? 
            IntlLabelFn(
              JSON.parse(config.custom_label_2_multi), 
              config.default_language.value, 
              default_language
              ) : ""
          this.throwMessageFn( custom_label_2 +" "+ i18n.t("labels.required"), "red");
          return
        }

        if (
          !this.state.default_address &&
          this.props.menu_type == "delivery" &&
          this.props.platform != "qr"
        ) {
          this.setState({ loader: false }, () => {
            this.throwMessageFn(i18n.t("labels.add_address"), "red");
          });
          return;
        }
        


    if (
      this.props.config.minimum_order_amount && parseFloat(this.props.config.minimum_order_amount) > 0 &&
      this.props.menu_type == "delivery" &&
      this.props.platform != "qr"
    ) {
      if (
        this.props.total_price.toFixed(2) <
        parseFloat(this.props.config.minimum_order_amount)
      ) {
        this.setState({ loader: false }, () => {
          this.throwMessageFn(
            `${i18n.t("labels.minimum_order_amount_is")} ${this.props.config.currency} ${this.props.config.minimum_order_amount}`,
            "red"
          );
        });
        return;
      }
    }

    // else
    if (this.state.payment_method == "card") {         
      if (
        this.props.config.payment &&
        (this.props.config.payment.service == "stripe" || this.props.config.payment.service == "my menu pay")
      ) {
        if (!cvv || !year || !month || !card_number) {
          this.throwMessageFn(
            i18n.t("labels.add_a_payment_method_first"),
            "red"
          );
          return;
        }
        this.setState({ loader: true }, () => {
          this.payNow();
          return;
        });

      }
      if (
        this.props.config.payment &&
        this.props.config.payment.service == "razor pay"
      ) {
        this.payNow();
        return;
      }
      if (
        (this.props.config.payment &&
          this.props.config.payment.service == "my menu payment") ||
        !this.props.config.payment
      ) {
        if (!cvv || !year || !month || !card_number) {
          this.throwMessageFn(
            i18n.t("labels.add_a_payment_method_first"),
            "red"
          );
          return;
        }
        this.setState({ loader: true }, () => {
          this.payNow();
          return;
        });
      } 

      if (
        this.props.config.payment &&
        this.props.config.payment.service == "iol pay"
      ) {
        this.payNow();
        return;
      }
      if (
        this.props.config.payment &&
        this.props.config.payment.service == "square pay"

      ) {

        if (!cvv || !year || !month || !card_number) {
          this.throwMessageFn(
            i18n.t("labels.add_a_payment_method_first"),
            "red"
          );
          return;
        }
        this.setState({ loader: true }, () => {
          this.payNow();
          return;
        });

      }
      if (
        this.props.config.payment &&
        this.props.config.payment.service == "paytm"

      ) {
        
        this.setState({ loader: true }, () => {
          this.payNow();
          return;
        });
      }

      if (
        this.props.config.payment &&
        this.props.config.payment.service == "spades"

      ) {
        
        this.setState({ loader: true }, () => {
          this.payNow();
          return;
        });
      }

      // else {
      //   this.setState({ loader: true }, () => {
      //     this.payNow();
      //     return;
      //   });
      // }
    }else if(this.state.payment_method == "crypto"){
      this.cryptoPayment()
    }else if((this.state.payment_method == "apple_pay" || this.state.payment_method == "google_pay" ) && (this.props.config.payment && this.props.config.payment.service=="square pay")){
      // this.payNow();
      this.makeSquarePayPayment()
      return;
    }
     else {
      this.setState({ loader: true }, () => {
        this.checkout("token");
        return;
      });
    }
  };

  cryptoPaymentCoinbase = () => {
    this.setState({ crypto_invoice_loader: true })
    if(this.checkIfWorking()){
      if(
        this.props.platform != "qr" && 
        this.props.config.label_enabled &&
        this.props.menu_type !== "delivery" && 
        this.props.config.custom_label_required &&
        !this.state.pickup_plate_no
        ){
          const {config, default_language} = this.props;
          let custom_label = config.custom_label_multi ? 
            IntlLabelFn(
              JSON.parse(config.custom_label_multi), 
              config.default_language.value, 
              default_language
              ) : ""
       
          this.setState({ loader: false, crypto_invoice_loader: false, payment_method: "", mymenu_charges: "" })
          this.throwMessageFn( custom_label +" "+ i18n.t("labels.required"), "red");
          return
        }
        if(
          this.props.platform != "qr" && 
          this.props.config.label_2_enabled &&
          this.props.menu_type !== "delivery" && 
          this.props.config.custom_label_2_required &&
          !this.state.pickup_plate_no_2
          ){
            const {config, default_language} = this.props;
            let custom_label_2 = config.custom_label_2_multi ? 
            IntlLabelFn(
              JSON.parse(config.custom_label_2_multi), 
              config.default_language.value, 
              default_language
              ) : ""
            this.setState({ loader: false, crypto_invoice_loader: false, payment_method: "", mymenu_charges: "" })
            this.throwMessageFn( custom_label_2 +" "+ i18n.t("labels.required"), "red");
            return
          }

          if (this.props.config.minimum_order_amount && parseFloat(this.props.config.minimum_order_amount) > 0 &&this.props.menu_type == "delivery" && this.props.platform != "qr") {
            if (this.props.total_price.toFixed(2) < parseFloat(this.props.config.minimum_order_amount)) {
              this.setState({ loader: false, crypto_invoice_loader: false, payment_method: "", mymenu_charges: "" }, () => {
                this.throwMessageFn(
                  `${i18n.t("labels.minimum_order_amount_is")} ${this.props.config.currency} ${this.props.config.minimum_order_amount}`,
                  "red"
                );
              });
              return;
            }
          }

// coinbase invoice
        let data = {
          amount: this.state.overview.total,
          currency: this.props.config.currency_code,
          email : this.props.user ? this.props.user.email : "",
          venue: this.props.config.venueName,
          venue_id: this.props.config.venue,
          phone: this.props.number
        }
        performRequest("post", `api/crypto/create-invoice/`, data, false)
        .then(response => {
          this.setState({coinbase_code: response.data.data.code, crypto_invoice_loader: false,coinbase_invoice: response.data.data.id })

        }).catch(err=> {
          this.setState({ loader: false, crypto_invoice_loader: false, payment_method: "", mymenu_charges: "" })
          this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");

        })
// coinbase invoice

        }else{
            this.throwMessageFn(i18n.t("labels.not_working"), "red");
        }

    

  }

  cryptoPayment = () => {
    this.setState({ loader: true })
    let data = {
      amount: this.state.overview.total,
      currency: this.props.config.currency_code,
      "email": this.props.user ? this.props.user.email : ""
  }
    performRequest("post", `api/crypto/create-invoice/`, data, false).then(response => {

      var is_paid = false
      let payment_status
      window.addEventListener("message",  event => {
        payment_status = event.data.status;
        if (payment_status == "paid") {
          is_paid = true
          //take action PAID
          return;
        }
      }, false);
      if(debug){
        window.bitpay.enableTestMode();
      }
      window.bitpay.onModalWillEnter(function() {
        // this.setState({ loader: false })
        // console.log(response, 'modal is opening');
      });
      
      window.bitpay.onModalWillLeave(function() {
        // console.log(is_paid, 'modal is closing');
        // if(is_paid){
        //   this.setState({ loader: true }, () => {
        //     this.checkout("token");
        //   })
        //   return;
        // }else{
        //   this.setState({ loader: false })
        //   this.throwMessageFn(i18n.t("labels.transaction_failed"), "red"); 
        // }
      });
      bitpay.showInvoice(response.data.data.id);

    }).catch(err=> {
      this.setState({ loader: false })
      this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");

    })
  }
  loadAddress = () =>
    performRequest("get", `api/v2/pwa/address/?venue=${this.props.config.venue}`).then(
      (response) => {
        let all_address = response.data.filter(item => item.location_str.toLowerCase() == this.props.location.label.toLowerCase())
        let default_address = all_address.find(
          (item) => item.default_address == true
        );
        if (!default_address) {
          this.setState({ addressModal: true });
        } else {
          this.setState({ default_address: default_address });
        }
        
        this.setState({
          all_address: all_address,
          // default_address: default_address,
        });
      }
    );
  setDefaultSchedule = (schedules) => {
    let default_time = schedules[0]
    schedules.map(item => {
      let start_time  = moment(item.split(" - ")[0], 'hh:mm a');
      let end_time  = moment(item.split(" - ")[1], 'hh:mm a');
      let now = moment()
      if(moment(now,'HH:mm:ss').isSameOrAfter(moment(start_time,'HH:mm:ss')) && 
      moment(now,'HH:mm:ss').isSameOrBefore(moment(end_time,'HH:mm:ss'))){
        default_time = item
      }
    })
  return default_time
  }

  getCartData = async() => {
    // let uuid = window.location.pathname.substring(1);
    let uuid = await AsyncStorage.getItem("uuid");
    console.log(uuid, "pppppppp")
    performRequest('get', `api/pwa/cart/overview?uuid=${uuid}&mid=${Constants.deviceId}&platform=${this.props.platform!=="qr"? this.props.menu_type : this.props.platform}&payment_method=${this.state.payment_method}&delivery_charge=${this.props.config.delivery_charge}`)
    .then(response => {
        axios
          .get(
            API_URL + `api/check-if-default-promo/?venue=${this.props.config.venue}&uuid=${uuid}&mid=${Constants.deviceId}&platform=${this.props.platform!=="qr"? this.props.menu_type : this.props.platform}&cart=${response.data.cart}&payment_method=${this.state.payment_method}`
          )
          .then((discount) => {
            if(discount.data.status !== "error"){
              // this.setState({ default_promo: response.data });
              this.setState({overview: discount.data, dataLoader: false, tips: discount.data.tips || 0 , entered_coupon : discount.data.discount_code})
              return
            }else{
              this.setState({overview: response.data, dataLoader: false, tips: response.data.tips || 0 , entered_coupon : response.data.discount_code})
              return
            }
          }).catch(err=>{
            this.setState({overview: response.data, dataLoader: false, tips: response.data.tips || 0 , entered_coupon : response.data.discount_code})
          })

    })
    .catch(error=> {
      this.setState({dataLoader: false})
      console.log(error.response, "errorrrr")
    })

  }
  updateCart = async(is_promo_update=false) => {
    // let uuid = window.location.pathname.substring(1);
    let uuid = await AsyncStorage.getItem("uuid");
    let coupon = this.state.entered_coupon;

    console.log(uuid,this.state.overview, "pppppppp")
    this.setState({dataLoader: true})
    let payload = {
      payment_method: this.state.payment_method,
      platform: this.props.platform!=="qr"? this.props.menu_type : this.props.platform,
      tips: this.state.tips,
      promo: coupon

    }
    performRequest('patch', `api/pwa/cart/overview/${this.state.overview.cart}?uuid=${uuid}&mid=${Constants.deviceId}&platform=${this.props.platform!=="qr"? this.props.menu_type : this.props.platform}`, payload)
    .then(response => {
      console.log(response.data, "dataaaaaaaaaaa")
      let overview = {
        ...this.state.overview,
        tips: response.data.tips,
        total: response.data.total,
        mymenu_charge: response.data.mymenu_charge,
        discount: response.data.discount,
        discount_code: response.data.discount_code,
        discount_name: response.data.discount_name,
        sub_total: response.data.sub_total

      }
      if(is_promo_update && !response.data.discount_code){
        this.throwMessageFn("Invalid Coupon", "red");
        this.setState({ coupon_status: true });
      }
      this.setState({overview, dataLoader: false})
    })
    .catch(error=> {
      this.setState({dataLoader: false})
     

    })

  }
  componentDidMount() {
    console.log("hereeeeeeeeeeeeeeeeeeee")
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    
    if(params.payment_status){
      this.setState({
        loader: true,
      });
      console.log(params.payment_status, "statussssssssssssss")
      if(params.payment_status == "FAILED" || params.payment_status == "CANCELLED"){
        this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
      }else if(params.payment_status=="SUCCESS"){
        let response = JSON.parse(localStorage.getItem("order_data"))
        console.log(response, "parammmmmmmmmmmmmmmmm")
        localStorage.removeItem("order_data")
        this.setState({go_back_modal: true})
        this.postOrderHandler({data: response}, [])
        var url= document.location.href;
      window.history.pushState({}, "", url.split("?")[0]);
        return
      }
      var url= document.location.href;
      window.history.pushState({}, "", url.split("?")[0]);

    }
    this.setState({dataLoader: true})
    this.setInitialDate()
    this.checkPaymentMethods()
    this.getCartData()
    let uuid = window.location.pathname.substring(1);
    // axios
    //   .get(
    //     API_URL + `api/check-if-default-promo/?venue=${this.props.config.venue}&uuid=${uuid}&mid=${Constants.deviceId}`
    //   )
    //   .then((response) => {
    //     if(response.data.status !== "error"){
    //       this.setState({ default_promo: response.data });
    //     }
    //   });
      var startDate = moment();
      let _date_list = []
      let formatted_today = startDate.format('DD/MM/YYYY')
      if(this.props.config.show_today){
        _date_list.push(formatted_today)
      }
    if (this.props.platform == "qr") {

      // future orderrrrrrrr
      
      if (this.props.config.qr_delivery_date_enabled) {
        if(this.props.config.is_future_date_defined){
          let days = JSON.parse(this.props.config.block_out_days).filter(item=> !item.blocked)

          let final_days = days.map(item => {return item.date})
          let if_today = final_days.find(item => item == formatted_today)
          if(!if_today && this.props.config.show_today){
            final_days.unshift(formatted_today)
          }else if(!this.props.config.show_today && if_today){
            final_days.splice(final_days.indexOf(if_today), 1)
          }
          this.setState({dateList: final_days})
        }else{
          var endDate = moment().add(parseInt(this.props.config.date_duration), 'days');
          if(endDate.format("DD/MM/YYYY") !== formatted_today){
            var dateList = this.getDaysBetweenDates(startDate.add(1, "days"), endDate.add(1, "days"));
            let blocked_days = JSON.parse(this.props.config.block_out_days).filter(item=> item.blocked)
            let new_blocked = blocked_days.map(item => item.date)
   
  
            let final_days = dateList.filter(item=> {
              if(!new_blocked.includes(item)){
                return item
              }
            })
            this.setState({dateList: [..._date_list,...final_days] })
          }

        }
      }else{
        this.setState({dateList: _date_list})
      }
    // future   orderrrrrrrr


      if (this.props.config.schedule_delivery) {
        try {
          let schedules = JSON.parse(this.props.config.schedule_delivery);
          if(this.props.config.enable_asap){
            schedules.unshift(i18n.t("button_text.asap"));
          }else{
            this.setState({default_time: schedules.length ? this.setDefaultSchedule(schedules) : "" })
          }
          this.setState({ schedule_list: schedules });
        } catch (error) {
          // console.log();
        }
      }else{
        this.setState({ schedule_list: [i18n.t("button_text.asap")] });
      }
    } else {
      if (this.props.menu_type == "delivery") {
        performRequest("get", `api/v2/pwa/address/?venue=${this.props.config.venue}`)
          .then((response) => {

            let all_address = response.data.filter(item => item.location_str.toLowerCase() == this.props.location.label.toLowerCase())
            this.setState({ all_address });
            let default_address = all_address.find(
              (item) => item.default_address == true
            );
            if (!default_address) {
              this.setState({ addressModal: true });
            } else {
              this.setState({ default_address: default_address });
            }
          })
          .catch((error) => {
            console.log(error, "errrrrrrrrr");
          });

      // future orderrrrrrrr
      if (this.props.config.online_delivery_date_enabled) {
        if(this.props.config.is_future_date_defined){
          let days = JSON.parse(this.props.config.block_out_days).filter(item=> !item.blocked)

          let final_days = days.map(item => {return item.date})
          let if_today = final_days.find(item => item == formatted_today)
          if(!if_today && this.props.config.show_today){
            final_days.unshift(formatted_today)
          }else if(!this.props.config.show_today && if_today){
            final_days.splice(final_days.indexOf(if_today), 1)
          }
          this.setState({dateList: final_days})
        }else{
          var endDate = moment().add(parseInt(this.props.config.date_duration), 'days');
          if(endDate.format("DD/MM/YYYY") !== formatted_today){
            var dateList = this.getDaysBetweenDates(startDate.add(1, "days"), endDate.add(1, "days"));
            let blocked_days = JSON.parse(this.props.config.block_out_days).filter(item=> item.blocked)
            let new_blocked = blocked_days.map(item => item.date)
   
  
            let final_days = dateList.filter(item=> {
              if(!new_blocked.includes(item)){
                return item
              }
            })
            this.setState({dateList: [..._date_list,...final_days] })
          }

        }
      }else{
        this.setState({dateList: _date_list})
      }
    // future   orderrrrrrrr


      }else{
      // future orderrrrrrrr
      if (this.props.config.pickup_delivery_date_enabled) {
        if(this.props.config.is_future_date_defined){
          let days = JSON.parse(this.props.config.block_out_days).filter(item=> !item.blocked)

          let final_days = days.map(item => {return item.date})
          let if_today = final_days.find(item => item == formatted_today)
          if(!if_today && this.props.config.show_today){
            final_days.unshift(formatted_today)
          }else if(!this.props.config.show_today && if_today){
            final_days.splice(final_days.indexOf(if_today), 1)
          }
          this.setState({dateList: final_days})
        }else{
          var endDate = moment().add(parseInt(this.props.config.date_duration), 'days');
          if(endDate.format("DD/MM/YYYY") !== formatted_today){
            var dateList = this.getDaysBetweenDates(startDate.add(1, "days"), endDate.add(1, "days"));
            let blocked_days = JSON.parse(this.props.config.block_out_days).filter(item=> item.blocked)
            let new_blocked = blocked_days.map(item => item.date)
   
  
            let final_days = dateList.filter(item=> {
              if(!new_blocked.includes(item)){
                return item
              }
            })
            this.setState({dateList: [..._date_list,...final_days] })
          }

        }
      }else{
        this.setState({dateList: _date_list})
      }
    // future   orderrrrrrrr
      }
      
      if (this.props.config.schedule_delivery) {
        try {
          let schedules = JSON.parse(
            this.props.config.schedule_delivery
          );
          if(this.props.config.enable_asap){
            schedules.unshift(i18n.t("button_text.asap"));
          }else{
            this.setState({default_time: schedules.length ? this.setDefaultSchedule(schedules) : "" })
          }
          this.setState({ schedule_list: schedules });
        } catch (error) {
          // console.log();
        }
      }else{
        this.setState({ schedule_list: [i18n.t("button_text.asap")] });
      }
    }




  }

  checkPaymentMethods = () => {
    if (
      this.state.allow_online_payment &&
      this.props.config.payment &&
     ( this.props.config.payment.service == "stripe" || this.props.config.payment.service == "my menu pay")
    ) {
      this.setState({
        stripePromise: loadStripe(this.props.config.payment.key)
      }, () => {
        if(this.state.stripePromise.applePay){
          this.state.stripePromise.applePay.stripeAccount = this.props.config.payment.stripe_user_id;;
        }
      })
      // this.state.stripePromise = loadStripe(this.props.config.payment.key);
      

    }
  }
  validateCoupon = async() => {
    let coupon = this.state.entered_coupon;
    let uuid = await AsyncStorage.getItem("uuid");

    // let uuid = window.location.pathname.substring(1);
    console.log(uuid,this.state.overview, "pppppppp")
    this.setState({dataLoader: true})
    let payload = {
      payment_method: this.state.payment_method,
      platform: this.props.platform!=="qr"? this.props.menu_type : this.props.platform,
      tips: this.state.tips,
      promo: coupon
    }
    performRequest('patch', `api/pwa/cart/overview/${this.state.overview.cart}?uuid=${uuid}&mid=${Constants.deviceId}&platform=${this.props.platform!=="qr"? this.props.menu_type : this.props.platform}`, payload)
    .then(response => {
      console.log(response.data, "dataaaaaaaaaaa")
      let overview = {
        ...this.state.overview,
        tips: response.data.tips,
        total: response.data.total,
        mymenu_charge: response.data.mymenu_charge,
        discount: response.data.discount,
        discount_code: response.data.discount_code,
        discount_name: response.data.discount_name,
      }
      this.setState({overview, dataLoader: false})
    })
    .catch(error=> {
      this.setState({dataLoader: false})
          this.throwMessageFn("Invalid Coupon", "red");
        this.setState({ coupon_status: true });
      console.log(error.response, "errorrrr")
    })

  //   axios
  //     .get(
  //       API_URL +
  //         `api/verify/promo/?venue=${this.props.config.venue}&promo=${coupon}&uuid=${uuid}&mid=${Constants.deviceId}`
  //     )
  //     .then((response) => {
  //       this.setState({ default_promo: response.data }, () => {
  //         // console.log(this.state.promo)
  //       });
  //     })
  //     .catch((error) => {
  //       this.throwMessageFn("Invalid Coupon", "red");
  //       this.setState({ coupon_status: true });
  //     });
  };
  closeSuccessModal = (go_back=false) => {
    this.setState(
      {
        orderSuccessModal: false,
        order_response: "",
      },
      () => {
        if(this.state.go_back_modal){
          this.props.navigation.goBack()
        }else{
        this.props.navigation.navigate("HomeScreen")
        }
      }
    );
  };
  orderScreen = () => {
    this.setState(
      {
        orderSuccessModal: false,
        order_response: "",
      },
      () =>
        this.props.navigation.navigate("RecentOrdersScreen", {
          returnAccount: this.returnAccount,
        })
    );
  };
  returnAccount = () => {
    this.props.navigation.navigate("HomeScreen");
  };
  cardSchemeLoader = () => {
    switch(this.props.card_scheme.toLowerCase()){
      case "visa":
        return "fab fa-cc-visa";
      case "mastercard":
        return "fab fa-cc-mastercard";
      case "american express":
        return "fab fa-cc-amex";
      case "discover":
        return "fab fa-cc-discover";
      case "diners club":
        return "fab fa-cc-diners-club";
      case "jcb":
        return "fab fa-cc-jcb";
      default:
        return "far fa-credit-card"
    }
    
  }
  chooseDefaultPayment = () => {
    console.log(this.state.payment_method,"payyyyyyyyyyyyyyyyyy")
    const {
      list_view_background_color,
      list_view_text_color,
      home_button_background_color,
      home_button_text_color
    } = this.props.design
    if (!this.state.payment_method) {
      return (
        <>
          <View style={style.checkoutIconWrap}>
            <SI size={12} name={"credit-card"} color={list_view_text_color} />
          </View>
          <View style={{ flex: 4, justifyContent: "center" }}>
            <TouchableOpacity
              onPress={() => this.setState({ paymentMethodModal: true })}
            >
              <StyledText 
              font_color={this.props.design.list_view_text_color}
              font_weight={this.props.design.list_view_text_font_weight}
              font_style={this.props.design.list_view_text_font_style}
              text_transform={this.props.design.list_view_text_font_transform}
              font_family={this.props.design.list_view_text_font_family}
              font_size={this.props.design.list_view_text_font_size?? 10}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

              style={{}}>
                {i18n.t("labels.select_payment")}
              </StyledText>
            </TouchableOpacity>
          </View>
        </>
      );
    } else {

      // this.state.payment_method == 'cc_delivery'
      if (this.state.payment_method == "cash") {
        return (
          <>
          
            <SelectedPayment
              list_view_text_color={list_view_text_color}
              icon={"fas fa-money-bill-wave"}
              togglePaymentModal={this.togglePaymentModal}
              label={"checkout.cash"}
              label_suf = {this.props.platform != "qr"
              ? ` on ${this.props.menu_type}`
              : "" +" "}
              custom_font={this.props.design.custom_font}
              design={this.props.design}
            />
            
          </>
        );
      } else if (this.state.payment_method == "room") {
        return (
          <>
          <SelectedPayment
            list_view_text_color={list_view_text_color}

              icon={"fas fa-hotel"}
              togglePaymentModal={this.togglePaymentModal}
              label={"labels.room_charge"}
              custom_font={this.props.design.custom_font}
              design={this.props.design}
            />
          
          </>
        );
      } else if (this.state.payment_method == "cc_delivery") {
        return (
          <>
      
            <SelectedPayment
            list_view_text_color={list_view_text_color}
              icon={"fas fa-credit-card"}
              togglePaymentModal={this.togglePaymentModal}
              label={"labels.card_on"}
              custom_font={this.props.design.custom_font}
              design={this.props.design}
            />
          </>
        );
      } 
      else if(this.state.payment_method == "saved_payment"){
        return (<SelectedPayment
          list_view_text_color={list_view_text_color}
          icon={"fas fa-credit-card"}
          togglePaymentModal={this.togglePaymentModal}
          label={"checkout.saved_payment"}
          custom_font={this.props.design.custom_font}
          design={this.props.design}
        />)
      }
      else if(this.state.payment_method == "apple_pay"){
        return (<SelectedPayment
          list_view_text_color={list_view_text_color}
          icon={"fab fa-apple-pay"}
          togglePaymentModal={this.togglePaymentModal}
          label={"checkout.apple_pay"}
          custom_font={this.props.design.custom_font}
          design={this.props.design}
        />)
      }
      else if(this.state.payment_method == "google_pay"){
        return (<SelectedPayment
          list_view_text_color={list_view_text_color}
          icon={"fab fa-google"}
          togglePaymentModal={this.togglePaymentModal}
          label={"checkout.google_pay"}
          custom_font={this.props.design.custom_font}
          design={this.props.design}
        />)
      }
      else if (this.state.payment_method == "card") {
        if (this.props.card_number) {
          return (
            <>
              <SelectedPayment
              list_view_text_color={list_view_text_color}
                icon={this.cardSchemeLoader()}
                togglePaymentModal={this.togglePaymentModal}
                label={""}
                label_suf={"XXXX-"+this.props.card_number.slice(0, 4)}
                custom_font={this.props.design.custom_font}
                design={this.props.design}
              />
       
            </>
          );
        } else if (
          this.props.config.payment &&
          this.props.config.payment.service == "razor pay"
        ) {
          return (
            <>
              <SelectedPayment
              list_view_text_color={list_view_text_color}
                icon={"fas fa-money-bill-wave-alt"}
                togglePaymentModal={this.togglePaymentModal}
                label={"checkout.online_payment"}
                custom_font={this.props.design.custom_font}
                design={this.props.design}
              />
     
            </>
          );
        } else {
          return (
            <>
              <SelectedPayment
              list_view_text_color={list_view_text_color}
                icon={"fas fa-credit-card"}
                togglePaymentModal={this.togglePaymentModal}
                label={"checkout.online_payment"}
                custom_font={this.props.design.custom_font}
                design={this.props.design}
              />
         
            </>
          );
        }
      }else{
        return (
          <>
            <SelectedPayment
              list_view_text_color={list_view_text_color}
              icon={"fas fa-credit-card"}
              togglePaymentModal={this.togglePaymentModal}
              label_suf={this.state.payment_method}
              custom_font={this.props.design.custom_font}
              design={this.props.design}
            />
     
          </>
        );
      }
    }
  };


  priceLoader = (price) => {
    if (THREE_FRACTION_CURRENCY.includes(this.props.config.currency)) {
      return price ? price.toFixed(3) : "0.000";
    } else {
      if (this.props.config.currency == "¥") {
        return price ? parseInt(price) : "0";
      } else {
        return price ? price.toFixed(2) : "0.00";
      }
    }
  };

  subTotalLoader = (price) => {
    let total = price;

    if (this.state.default_promo) {
      if (this.state.default_promo.promo_type == 1) {
        total -= PriceLoader(
          (this.state.default_promo.percentage_discount / 100) * total, this.props.config.currency, this.props.config.display_fraction
        );
      } else {
        total -= PriceLoader(
          this.state.default_promo.percentage_discount, this.props.config.currency, this.props.config.display_fraction
        );
      }
    }

    let inclusive_total = 0;
    for (let i = 0; i < this.props.config.taxes_services.taxes.length; i++) {
      if (this.props.config.taxes_services.taxes[i].inclusive == true) {
        // inclusive_total += parseFloat(this.props.config.taxes_services.taxes[i].rate)
        let percentage_total = this.getPercentageTotal();
        let inclu_tax =
          (parseFloat(this.inclusiveTaxCalculator(total, percentage_total)) *
            parseFloat(this.props.config.taxes_services.taxes[i].rate)) /
          100;
        inclusive_total += inclu_tax;
      }
    }

    // tax
    let exclusive_tax = 0;
    for (let i = 0; i < this.props.config.taxes_services.taxes.length; i++) {
      if (this.props.config.taxes_services.taxes[i].inclusive == false) {
        // total+= this.percentagePriceCalculator(this.props.total_price, this.props.config.taxes_services.taxes[i].rate)
        let total_after_inclusive = total - inclusive_total;
        exclusive_tax += this.percentagePriceCalculator(
          total_after_inclusive,
          this.props.config.taxes_services.taxes[i].rate
        );
      }
    }
    total += exclusive_tax;
    let final_amount = parseFloat(total);
    return this.isZero(final_amount);
    //    return PriceLoader(parseFloat(total))
  };

  changeDate = (date) => {
    this.setState({ selectedDate: date });
  };
  addressContainer = () => {
    const {
      list_view_background_color,
      list_view_text_color,
      home_button_background_color,
      home_button_text_color
    } = this.props.design
    if (this.props.platform == "qr") {
      return (
        <>
       
          <StyledText
           font_color={this.props.design.list_view_text_color}
           font_weight={this.props.design.list_view_text_font_weight}
           font_style={this.props.design.list_view_text_font_style}
           text_transform={this.props.design.list_view_text_font_transform}
           font_family={this.props.design.list_view_text_font_family}
           font_size={this.props.design.list_view_text_font_size?? 20}
           letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
            style={{
             
              marginTop: 80,
              marginVertical: 20,
            }}
          >
            {i18n.t("labels.serving_to")}
          </StyledText>
          <View style={style.checkoutContainer}>
            <View style={{ width: 40, justifyContent: "center" }}>
              <View style={style.checkoutIconWrap}>
                <Material
                  size={24}
                  name={"map-marker-multiple"}
                  color={list_view_text_color}
                />
              </View>
            </View>
            <View style={{ flex: 4, justifyContent: "center" }}>
              <StyledText 
               font_color={this.props.design.list_view_text_color}
               font_weight={this.props.design.list_view_text_font_weight}
               font_style={this.props.design.list_view_text_font_style}
               text_transform={this.props.design.list_view_text_font_transform}
               font_family={this.props.design.list_view_text_font_family}
               font_size={this.props.design.list_view_text_font_size?? 20}
               letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{ }}>
                {this.props.table_detail.name}
              </StyledText>
              <StyledText
               font_color={this.props.design.list_view_text_color}
               font_weight={this.props.design.list_view_text_font_weight}
               font_style={this.props.design.list_view_text_font_style}
               text_transform={this.props.design.list_view_text_font_transform}
               font_family={this.props.design.list_view_text_font_family}
               font_size={this.props.design.list_view_text_font_size?? 20}
               letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
               style={{ }}>
                {this.props.table_detail.venue}
              </StyledText>
            </View>
            <View
              style={{
                flex: 1,
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            ></View>
          </View>
        </>
      );
    } else {
      if (this.props.menu_type == "delivery") {
        return (
          <>
            <StyledText
            font_color={this.props.design.list_view_text_color}
            font_weight={this.props.design.list_view_text_font_weight}
            font_style={this.props.design.list_view_text_font_style}
            text_transform={this.props.design.list_view_text_font_transform}
            font_family={this.props.design.list_view_text_font_family}
            font_size={this.props.design.list_view_text_font_size?? 20}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{
                fontWeight: "bold",
                marginTop: 80,
                marginVertical: 15,
           
              }}
            >
              {i18n.t("labels.delivering_to")}
            </StyledText>

            <View style={style.checkoutContainer}>
              <View style={{ width: 40, justifyContent: "center" }}>
                <View style={style.checkoutIconWrap}>
                  <Material
                    size={24}
                    name={"map-marker-multiple"}
                    color={list_view_text_color}
                  />
                </View>
              </View>

              <View style={{ flex: 4, justifyContent: "center" }}>
                <StyledText 
                font_color={this.props.design.list_view_text_color}
                font_weight={this.props.design.list_view_text_font_weight}
                font_style={this.props.design.list_view_text_font_style}
                text_transform={this.props.design.list_view_text_font_transform}
                font_family={this.props.design.list_view_text_font_family}
                font_size={this.props.design.list_view_text_font_size?? 20}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                style={{ fontWeight: "bold"}}>
                  {this.state.default_address.address_nickname == 1
                    ? "Home"
                    : this.state.default_address.address_nickname == 2
                    ? "Office"
                    : "Other"}
                </StyledText>
                <StyledText 
                font_color={this.props.design.list_view_text_color}
                font_weight={this.props.design.list_view_text_font_weight}
                font_style={this.props.design.list_view_text_font_style}
                text_transform={this.props.design.list_view_text_font_transform}
                font_family={this.props.design.list_view_text_font_family}
                font_size={this.props.design.list_view_text_font_size?? 20}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{ 
                  
                 }}>
                  {this.state.default_address.complete_address}
                </StyledText>
                <StyledText 
                font_color={this.props.design.list_view_text_color}
               font_weight={this.props.design.list_view_text_font_weight}
               font_style={this.props.design.list_view_text_font_style}
               text_transform={this.props.design.list_view_text_font_transform}
               font_family={this.props.design.list_view_text_font_family}
               font_size={this.props.design.list_view_text_font_size?? 20}
               letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{ 
                  
                 }}>
                  {this.state.default_address.location_str}
                </StyledText>
              </View>
              <View
                style={{
                  flex: 1,
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => this.setState({ addressModal: true })}
                >
                  <View style={{ backgroundColor: home_button_background_color, borderRadius: 6 }}>
                    <StyledText
                               font_color={this.props.design.home_button_text_color}
                               font_weight={this.props.design.home_button_text_font_weight}
                               font_style={this.props.design.home_button_text_font_style}
                               text_transform={this.props.design.home_button_text_font_transform}
                               font_family={this.props.design.home_button_text_font_family}
                               font_size={this.props.design.home_button_text_font_size}
                               letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                      style={{  padding: 8,  }}
                    >
                      {i18n.t("button_text.change")}
                    </StyledText>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </>
        );
      } else {
        return (
          <>
       
            <StyledText
            font_color={this.props.design.list_view_text_color}
            font_weight={this.props.design.list_view_text_font_weight}
            font_style={this.props.design.list_view_text_font_style}
            text_transform={this.props.design.list_view_text_font_transform}
            font_family={this.props.design.list_view_text_font_family}
            font_size={this.props.design.list_view_text_font_size?? 20}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{
              
                marginTop: 80,
                marginVertical: 15,
                
              }}
            >
              {i18n.t("labels.pick_your_order_from")}
            </StyledText>

            <View style={style.checkoutContainer}>
              <View style={{ width: 40, justifyContent: "center" }}>
                <View style={style.checkoutIconWrap}>
                  <Material
                    size={24}
                    name={"map-marker-multiple"}
                    color={list_view_text_color}
                  />
                </View>
              </View>

              <View style={{ flex: 4, justifyContent: "center" }}>
                <StyledText 
                font_color={this.props.design.list_view_text_color}
                font_weight={this.props.design.list_view_text_font_weight}
                font_style={this.props.design.list_view_text_font_style}
                text_transform={this.props.design.list_view_text_font_transform}
                font_family={this.props.design.list_view_text_font_family}
                font_size={this.props.design.list_view_text_font_size?? 20}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{  }}>
                  {this.props.config.venueName}
                </StyledText>
                <StyledText 
                font_color={this.props.design.list_view_text_color}
                font_weight={this.props.design.list_view_text_font_weight}
                font_style={this.props.design.list_view_text_font_style}
                text_transform={this.props.design.list_view_text_font_transform}
                font_family={this.props.design.list_view_text_font_family}
                font_size={this.props.design.list_view_text_font_size?? 20}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{  }}>
                  {this.props.config.address.address}
                </StyledText>
                <StyledText
                font_color={this.props.design.list_view_text_color}
                font_weight={this.props.design.list_view_text_font_weight}
                font_style={this.props.design.list_view_text_font_style}
                text_transform={this.props.design.list_view_text_font_transform}
                font_family={this.props.design.list_view_text_font_family}
                font_size={this.props.design.list_view_text_font_size?? 20}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                 style={{  }}>
                  {this.props.config.address.city}
                </StyledText>
                <StyledText 
                font_color={this.props.design.list_view_text_color}
                font_weight={this.props.design.list_view_text_font_weight}
                font_style={this.props.design.list_view_text_font_style}
                text_transform={this.props.design.list_view_text_font_transform}
                font_family={this.props.design.list_view_text_font_family}
                font_size={this.props.design.list_view_text_font_size?? 20}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{  }}>
                  {this.props.config.address.mobile_number}
                </StyledText>
                <StyledText 
                font_color={this.props.design.list_view_text_color}
                font_weight={this.props.design.list_view_text_font_weight}
                font_style={this.props.design.list_view_text_font_style}
                text_transform={this.props.design.list_view_text_font_transform}
                font_family={this.props.design.list_view_text_font_family}
                font_size={this.props.design.list_view_text_font_size?? 20}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{ }}>
                  {this.props.config.address.email}
                </StyledText>
              </View>
              <View
                style={{
                  flex: 1,
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              ></View>
            </View>
          </>
        );
      }
    }
  };

  discountLoader = () => {
    const {
      list_view_background_color,
      list_view_text_color,
      home_button_background_color,
      home_button_text_color,
      top_buttons_background_color,
      top_buttons_text_color
    } = this.props.design
    // if(
    //     this.props.platform == "qr"
    //     // && this.props.config.complete_order_only_on_payment
    //     ){
    //     return(
    //         <View style={style.checkoutContainer}>
    //         <View style={{flex:3,}}>
    //             <Item error={this.state.coupon_status}>
    //                 <Input placeholder='Apply coupon' onChangeText={text => this.setState({entered_coupon: text, coupon_status: false})}/>
    //                 {
    //                     this.state.coupon_status &&
    //                     <Icon name='close-circle' />
    //                 }
    //             </Item>
    //         </View>

    //             <TouchableOpacity onPress={this.validateCoupon} >
    //                 {/* <View style={{flex:1,margin:10,}} >
    //                     <Icon type='MaterialIcons' name='local-offer' style={{color:'green'}}/>
    //                 </View> */}
    //                 <View style={{borderRadius:5,border:'1px solid grey',alignItems:'center',justifyContent:'center'}}>
    //                             <StyledText style={{color:'blue',padding:5,color:'grey'}}>Apply</StyledText>
    //                         </View>
    //             </TouchableOpacity>

    //         </View>
    //     )
    // }else if(this.props.platform == 'online' || this.props.platform == "pickup"){
    if (this.state.overview.discount) {
      return (
        <View style={style.checkoutContainer}>
          <View style={{ flex: 2, justifyContent: "center" }}>
            <StyledText 
            font_color={this.props.design.list_view_text_color}
            font_weight={this.props.design.list_view_text_font_weight}
            font_style={this.props.design.list_view_text_font_style}
            text_transform={this.props.design.list_view_text_font_transform}
            font_family={this.props.design.list_view_text_font_family}
            font_size={this.props.design.list_view_text_font_size?? 20}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
            style={{  }}>
              {this.state.overview.discount_name} (applied)
            </StyledText>
          </View>

          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => this.setState({ default_promo: "", entered_coupon: "" }, () => {
                this.updateCart()
              })}
            >
              <View
                style={{
                  borderRadius: 5,
                  border: "1px solid grey",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: top_buttons_background_color
                }}
              >
                <StyledText 
                font_color={this.props.design.top_buttons_text_color}
                font_weight={this.props.design.top_button_text_font_weight}
                font_style={this.props.design.top_button_text_font_style}
                text_transform={this.props.design.top_button_text_font_transform}
                font_family={this.props.design.top_button_text_font_family}
                font_size={this.props.design.top_button_text_font_size}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                style={{  padding: 5, }}>
                  {i18n.t("button_text.remove")}
                </StyledText>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return (
        <View style={[style.checkoutContainer, {alignItems:"center"}]}>
          <View style={{ flex: 3 }}>
            <Item error={this.state.coupon_status}>
              <Input
                placeholder={i18n.t("labels.apply_coupon")}
                // style={{fontFamily:'SecondaryFont'}}
                onChangeText={(text) =>
                  this.setState({ entered_coupon: text, coupon_status: false })
                }
              />
              {this.state.coupon_status && <Icon name="close-circle" />}
            </Item>
          </View>

          <TouchableOpacity onPress={()=>this.updateCart(true)}>
            {/* <View style={{flex:1,margin:10,}} >
                                <Icon type='MaterialIcons' name='local-offer' style={{color:'green'}}/>
                            </View> */}
            <View
              style={{
                borderRadius: 5,
                border: "1px solid "+top_buttons_text_color,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:top_buttons_background_color
              }}
            >
              <StyledText 
              font_color={this.props.design.top_buttons_text_color}
              font_weight={this.props.design.top_button_text_font_weight}
              font_style={this.props.design.top_button_text_font_style}
              text_transform={this.props.design.top_button_text_font_transform}
              font_family={this.props.design.top_button_text_font_family}
              font_size={this.props.design.top_button_text_font_size}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{padding: 5,}}>
                {i18n.t("button_text.apply")}
              </StyledText>
            </View>
          </TouchableOpacity>
        </View>
      );
    }
    // }else{
    //     return <View/>
    // }
  };

  paymentLoader = () => {
    const {
      list_view_background_color,
      list_view_text_color,
      home_button_background_color,
      home_button_text_color
    } = this.props.design
    if (
      this.props.platform == "qr" &&
      this.props.config.complete_order_only_on_payment
    ) {
      return (
        <>
          <View style={[style.paymentContainer, {
            color:list_view_text_color,
            border:0,
            }]}>
            {this.chooseDefaultPayment()}

          </View>

          
        </>
      );
    } else if (
      this.props.platform == "online" ||
      this.props.platform == "pickup" ||
      this.props.platform == "order_enterprise"
    ) {
      return (
        <>
          {/* <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              marginTop: 10,
              marginBottom: 15,
              color: this.props.design.home_button_text_color
            }}
          >
            {i18n.t("button_text.payment")}
          </StyledText> */}
          <View style={[style.paymentContainer, {
            color:this.props.design.home_button_text_color, 
            border:0,
            }]}>
            {this.chooseDefaultPayment()}
          </View>
        </>
      );
    } else {
      return <View />;
    }
  };
  qrTotalSummary = () => {

    let total = this.totalWithoutMymenuCharge()

    if(this.state.mymenu_charges){
      total += parseFloat(this.state.mymenu_charges);
    }
    
    return this.isZero(total);
    // return PriceLoader(total)
  };

  totalWithoutMymenuCharge = () => {
    let total = this.props.total_price;
    if (this.state.default_promo) {
      if (this.state.default_promo.promo_type == 1) {
        total -= PriceLoader(
          (this.state.default_promo.percentage_discount / 100) * total, this.props.config.currency, this.props.config.display_fraction
        );
      } else {
        total -= PriceLoader(
          this.state.default_promo.percentage_discount, this.props.config.currency, this.props.config.display_fraction
        );
      }
    }

    let inclusive_total = 0;
    for (let i = 0; i < this.props.config.taxes_services.taxes.length; i++) {
      if (this.props.config.taxes_services.taxes[i].inclusive == true) {
        // inclusive_total += parseFloat(this.props.config.taxes_services.taxes[i].rate)
        let percentage_total = this.getPercentageTotal();
        let inclu_tax =
          (parseFloat(this.inclusiveTaxCalculator(total, percentage_total)) *
            parseFloat(this.props.config.taxes_services.taxes[i].rate)) /
          100;
        inclusive_total += inclu_tax;
      }
    }

    let exclusive_tax = 0;
    for (let i = 0; i < this.props.config.taxes_services.taxes.length; i++) {
      if (this.props.config.taxes_services.taxes[i].inclusive == false) {
        let total_without_inclusive = total - inclusive_total;
        exclusive_tax += this.percentagePriceCalculator(
          total_without_inclusive,
          this.props.config.taxes_services.taxes[i].rate
        );
      }
    }
    total += exclusive_tax;
    if (total < 0) {
      total = 0;
    }
    if (this.state.tips) {
      total += parseFloat(this.state.tips);
    }
    if (
      this.props.platform == "online" || this.props.platform == "qr" || this.props.platform == "order_enterprise"  &&
      this.props.config.delivery_charge 
      // &&
      // this.props.menu_type == "delivery"
    ) {
      total += parseFloat(this.props.config.delivery_charge ? this.props.config.delivery_charge : 0);
      // total= PriceLoader(parseFloat(amount+this.state.tips ? parseFloat(this.state.tips) : 0))
    }

    return total;
  }

  percentagePriceCalculator = (total, percentage) => {
    return (parseFloat(total) / 100) * parseFloat(percentage);
  };
  inclusiveTaxCalculator = (total, percentage) => {
    let new_total = total;
    let value = (new_total / (100 + percentage)) * 100;
    return PriceLoader(value, this.props.config.currency, this.props.config.display_fraction);
    // return (parseFloat(total)/100) * parseFloat(percentage)
  };
  onChangeTip = (value) => {
    let data = value.split(".");
    if (data.length == 2) {
      if (data[1].length <= 3) {
        this.setState({ tips: value },()=>{
          this.updateCart()
        });
        
        return;
      }
    } else {
      this.setState({ tips: value },()=>{
        this.updateCart()
      });
    }
  };
  calculateThirtyPercent = () => {
    if (this.state.selected_tip == "thirty") {
      this.setState({ tips: "", selected_tip: "" },()=>{
        this.updateCart()
      });
      return;
    }
    // let price = PriceLoader(this.props.total_price)
    let price = this.subTotalLoader(this.props.total_price);
    let tip = (price / 100) * 30;
    this.setState({ tips: parseFloat(tip.toFixed(2)), selected_tip: "thirty" },()=>{
      this.updateCart()
    });
  };
  calculateTwentyFivePercent = () => {
    if (this.state.selected_tip == "twenty-five") {
      this.setState({ tips: "", selected_tip: "" },()=>{
        this.updateCart()
      });
      return;
    }
    // let price = PriceLoader(this.props.total_price)
    let price = this.subTotalLoader(this.props.total_price);
    let tip = (price / 100) * 25;
    this.setState({
      tips: parseFloat(tip.toFixed(2)),
      selected_tip: "twenty-five",
    },()=>{
      this.updateCart()
    });
  };
  calculateTwentyPercent = () => {
    if (this.state.selected_tip == "twenty") {
      this.setState({ tips: "", selected_tip: "" },()=>{
        this.updateCart()
      });
      return;
    }
    // let price = PriceLoader(this.props.total_price)
    let price = this.subTotalLoader(this.props.total_price);
    let tip = (price / 100) * 20;
    this.setState({ tips: parseFloat(tip.toFixed(2)), selected_tip: "twenty" },()=>{
      this.updateCart()
    });
  };
  calculateFifteenPercent = () => {
    if (this.state.selected_tip == "fifteen") {
      this.setState({ tips: "", selected_tip: "" },()=>{
        this.updateCart()
      });
      return;
    }
    // let price = PriceLoader(this.props.total_price)
    let price = this.subTotalLoader(this.props.total_price);
    let tip = (price / 100) * 15;
    this.setState({
      tips: parseFloat(tip.toFixed(2)),
      selected_tip: "fifteen",
    },()=>{
      this.updateCart()
    });
  };
  calculateTenPercent = () => {
    if (this.state.selected_tip == "ten") {
      this.setState({ tips: "", selected_tip: "" },()=>{
        this.updateCart()
      });
      return;
    }
    // let price = PriceLoader(this.props.total_price)
    let price = this.subTotalLoader(this.props.total_price);
    let tip = (price / 100) * 10;
    this.setState({ tips: parseFloat(tip.toFixed(2)), selected_tip: "ten" },()=>{
      this.updateCart()
    });
  };
  calculateFivePercent = () => {
    if (this.state.selected_tip == "five") {
      this.setState({ tips: "", selected_tip: "" },()=>{
        this.updateCart()
      });
      return;
    }
    // let price = PriceLoader(this.props.total_price)
    let price = this.subTotalLoader(this.props.total_price);
    let tip = (price / 100) * 5;

    this.setState({ tips: parseFloat(tip.toFixed(2)), selected_tip: "five" },()=>{
      this.updateCart()
    });
  };
  taxCalculator = (total, tax) => {
    let new_total = total;
    if (this.state.default_promo) {
      if (this.state.default_promo.promo_type == 1) {
        new_total -= PriceLoader(
          (this.state.default_promo.percentage_discount / 100) * total, this.props.config.currency, this.props.config.display_fraction
        );
      } else {
        new_total -= PriceLoader(
          this.state.default_promo.percentage_discount, this.props.config.currency, this.props.config.display_fraction
        );
      }
    }

    if (tax.rate) {
      if (tax.inclusive == false) {
        let inclusive_total = 0;
        for (
          let i = 0;
          i < this.props.config.taxes_services.taxes.length;
          i++
        ) {
          if (this.props.config.taxes_services.taxes[i].inclusive == true) {
            // inclusive_total += parseFloat(this.props.config.taxes_services.taxes[i].rate)
            let percentage_total = this.getPercentageTotal();
            let inclu_tax =
              (parseFloat(
                this.inclusiveTaxCalculator(new_total, percentage_total)
              ) *
                parseFloat(this.props.config.taxes_services.taxes[i].rate)) /
              100;
            inclusive_total += inclu_tax;
          }
        }
        new_total -= inclusive_total;
        let tax_cal = (parseFloat(new_total) / 100) * parseFloat(tax.rate);
        return this.isZero(tax_cal);
        // return PriceLoader(tax_cal)
      } else {
        let percentage_total = this.getPercentageTotal();
        let inclu_tax =
          (parseFloat(
            this.inclusiveTaxCalculator(new_total, percentage_total)
          ) *
            parseFloat(tax.rate)) /
          100;
        return this.isZero(inclu_tax);
        // return PriceLoader(inclu_tax)
      }
    }
    return PriceLoader(0, this.props.config.currency, this.props.config.display_fraction);
  };
  getPercentageTotal = () => {
    let percentage_total = 0;
    for (let i = 0; i < this.props.config.taxes_services.taxes.length; i++) {
      if (this.props.config.taxes_services.taxes[i].inclusive == true) {
        percentage_total += parseFloat(
          this.props.config.taxes_services.taxes[i].rate
        );
      }
    }
    return percentage_total;
  };
  calculateNetTotal = (total) => {
    // let percentage_total = this.getPercentageTotal()
    // let result = this.inclusiveTaxCalculator(total, percentage_total)
    // return result
    let new_total = total;
    if (this.state.default_promo) {
      if (this.state.default_promo.promo_type == 1) {
        new_total -= PriceLoader(
          (this.state.default_promo.percentage_discount / 100) * total, this.props.config.currency, this.props.config.display_fraction
        );
      } else {
        new_total -= PriceLoader(
          this.state.default_promo.percentage_discount, this.props.config.currency, this.props.config.display_fraction
        );
      }
    }
    let percentage_total = this.getPercentageTotal();

    let result = parseFloat(
      this.inclusiveTaxCalculator(new_total, percentage_total)
    );
    return this.isZero(result);
    // return result
  };

  handleSubmitMaster = () => {
    window.PaymentSession.configure({
      session: "SESSION0002628915220J3559735N91",
      fields: {
        // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
        card: {
          number: "#card-number",
          securityCode: "#security-code",
          expiryMonth: "#expiry-month",
          expiryYear: "#expiry-year",
          nameOnCard: "#cardholder-name",
        },
      },
      //SPECIFY YOUR MITIGATION OPTION HERE
      frameEmbeddingMitigation: ["javascript"],
      callbacks: {
        initialized: function (response) {
          // HANDLE INITIALIZATION RESPONSE
        },
        formSessionUpdate: function (response) {
          // HANDLE RESPONSE FOR UPDATE SESSION
          if (response.status) {
            if ("ok" == response.status) {
              // console.log("Session updated with data: " + response.session.id);

              //check if the security code was provided by the user
              if (response.sourceOfFunds.provided.card.securityCode) {
                // console.log("Security code was provided.");
              }

              //check if the user entered a Mastercard credit card
              if (response.sourceOfFunds.provided.card.scheme == "MASTERCARD") {
                // console.log("The user entered a Mastercard credit card.");
              }
            } else if ("fields_in_error" == response.status) {
              // console.log("Session update failed with field errors.");
              if (response.errors.cardNumber) {
                // console.log("Card number invalid or missing.");
              }
              if (response.errors.expiryYear) {
                // console.log("Expiry year invalid or missing.");
              }
              if (response.errors.expiryMonth) {
                // console.log("Expiry month invalid or missing.");
              }
              if (response.errors.securityCode) {
                // console.log("Security code invalid.");
              }
            } else if ("request_timeout" == response.status) {
              console.log(
                "Session update failed with request timeout: " +
                  response.errors.message
              );
            } else if ("system_error" == response.status) {
              console.log(
                "Session update failed with system error: " +
                  response.errors.message
              );
            }
          } else {
            console.log("Session update failed: " + response);
          }
        },
      },
      interaction: {
        displayControl: {
          formatCard: "EMBOSSED",
          invalidFieldCharacters: "REJECT",
        },
      },
    });

    window.PaymentSession.updateSessionFromForm("card");
  };
  showOrderButton = () => {
    if( this.props.config.payment && (this.props.config.payment.service=="stripe" || this.props.config.payment.service=="my menu pay") && (this.state.payment_method == "apple_pay" || this.state.payment_method == "google_pay" || this.state.payment_method == "saved_payment")){
      return false
    } 
    return true
  }

   getDaysBetweenDates = (startDate, endDate) => {
    var now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('DD/MM/YYYY'));
        now.add(1, 'days');
    }
    return dates;
};
dateSelectHandler = () => {
  this.setState({dateModal : !this.state.dateModal})
}
showDefaultDate = () => {
  // let uuid = window.location.pathname.substring(1);
  // if(uuid == SPECIAL_VENUE){
  //   return moment("24/11/2021").format("DD/MM/YYYY")
  // }
  return moment().format("DD/MM/YYYY")
}
checkIfWorking = () => {
  let now = moment()
let is_working = true

if(this.props.config.working_hour && this.state.selectedDate == moment().format("DD/MM/YYYY")){

  let time = this.state.default_time.split(" - ")[1]
  let moment_time  = moment(time, 'hh:mm a');

  if(now>moment_time){
    return false
  }else{
    return true
  }

}else if(this.state.selectedDate && this.state.selectedDate != moment().format("DD/MM/YYYY")){
is_working=true
}else if(!this.state.selectedDate){
  is_working = false
}else{
is_working = false
}

return is_working
}

toggleSquarePay = () => this.setState({squarePayModal: !this.state.squarePayModal})

SquareModalComponent = () => {
  const { config, design } = this.props;

  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backdropFilter: "blur(5px)"  }}>
      <View
        style={{
          width:Dimensions.get('window').width*.98,
          backgroundColor: this.props.design.popup_background_color,
          borderWidth:1,
          // padding:  22,
          justifyContent: 'center',
          // alignItems: 'center',
          borderRadius: 4,
          borderColor: 'rgba(0, 0, 0, 0.1)',
          height:Dimensions.get('window').height*.8
        }}
      >
      <View imageStyle={{borderRadius:4}} style={{width: '100%', height: 300,justifyContent:'flex-end'}} source={this.props.config.info.image && {uri: this.props.config.info.image}}>
        <ScrollView>
          <View style={{width:'100%',marginTop:50}}>
            {this.state.squarePayModal && (
              <>
              <View style={{marginTop:30}} nativeID="card" ></View>
              <Button
              style={{
                marginTop: 10,
                backgroundColor: this.props.design.top_buttons_background_color,
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
      
              }}
              onPress={() => this.squareTokanizeCard()}
            >
              <StyledText
              font_color={this.props.design.top_buttons_text_color}
              font_weight={this.props.design.top_button_text_font_weight}
              font_style={this.props.design.top_button_text_font_style}
              text_transform={this.props.design.top_button_text_font_transform}
              font_family={this.props.design.top_button_text_font_family}
              font_size={this.props.design.top_button_text_font_size}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

               style={{ }}>
                {" "}
                {i18n.t("labels.submit")}
              </StyledText>
            </Button>
            </>
 

                // <SquarePayForm
                //   paymentForm={ window.SqPaymentForm }
                //   amount= {this.priceHandlerCheckout(this.qrTotalSummary())}
                //   currency= {this.props.config.currency_code}
                //   country={this.props.config.country.cca2}
                //   location_id = {this.props.config.payment.locationId}
                //   application_id={SQUARE_APP_ID}
                // />
            )}
          </View>
        </ScrollView>
            <View style={{borderRadius:10,position:'absolute',right:5,top:5}}>
              <TouchableOpacity onPress={() => this.toggleSquarePay()}>
                  <View  style={{borderWidth:1,borderColor:'black',width:50,height:50,borderRadius:7,alignItems:'center',justifyContent:'center',backgroundColor: this.props.design.popup_button_color }}>
                  <Icon style={{ color: this.props.design.popup_button_text_color}} size={30} name={'close'}/>
                  </View>
              </TouchableOpacity>
            </View>
        </View>
      </View>
    </View>
  );
};

showAppleGoogleMethods = (method) => {
  if(method || (this.props.config.payment && this.props.config.payment.service=="square pay" )){
    return true
  }
  return false

}

setCustomLabel = (label) => {
  const {config, default_language} = this.props;
  let text = label? 
  IntlLabelFn(
    JSON.parse(label), 
    config.default_language.value, 
    default_language
    ) : ""
    return text
}


selectNewPaymentMethod = () => {
  this.props.addCard("", "", "", "", "", "", "", "", "");
  this.setState({stripeRetryPaymentModal:false, pendingOrderResponse: ""}, () => {
    this.props.navigation.push("PaymentScreen", {
    });
  })
}

stripeRetryPayment = async() => {
  let {stripeObject} = this.props
  let result = await stripeObject
  .confirmCardPayment(this.state.stripe_client_secret)
  if(result.error){
    // this.props.addCard("", "", "", "", "", "", "", "", "");
    let message = result.error?.message || "Something Went Wrong";
    this.setState({ 
      card_error: true, 
      card_error_mzg: message,
      stripeRetryPaymentModal:false 
      });
    return;
  }
  this.postOrderHandler(this.state.pendingOrderResponse)

}

  render() {
    const {
      list_view_background_color,
      list_view_text_color,
      home_button_background_color,
      home_button_text_color,
      top_buttons_background_color,
      top_buttons_text_color
    } = this.props.design
    return (
      <View style={{flex:1, maxHeight:"-webkit-fill-available"}}>
      <ScrollView style={{marginBottom:70, backgroundColor:list_view_background_color, height:"90vh"}} >
      <View style={{display:"none"}} nativeID="google-pay-button"></View>

        <View style={{ alignSelf: "center", width: "96%" }}>
          {this.addressContainer()}
          {
                this.state.selectedDate
                ?
            <>
              <StyledText
               font_color={this.props.design.list_view_header_color}
               font_weight={this.props.design.list_view_header_font_weight}
               font_style={this.props.design.list_view_header_font_style}
               text_transform={this.props.design.list_view_header_font_transform}
               font_family={this.props.design.list_view_header_font_family}
               font_size={this.props.design.list_view_header_font_size?? 20}
               letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{ marginVertical: 15, }}
              >
                {
                  this.props.platform == "qr" ?
                  i18n.t("labels.serving_date") :
                this.props.menu_type == "delivery" ?
                  i18n.t("labels.delivery_date")
                  : i18n.t("labels.pickup_date")
                  }
              </StyledText>


                <TouchableOpacity onPress={this.dateSelectHandler} >
                <View style={style.checkoutContainer} >
                <View style={{ width: 40, justifyContent: "center" }}>
                {/* <SI size={24} name={'clock'} color={'blue'} />   */}
                <View style={style.checkoutIconWrap}>
                  <SI size={22} name={"clock"} color={"#5d6670"} />
                </View>
              </View>
                  <StyledText 
                  font_color={this.props.design.list_view_text_color}
                  font_weight={this.props.design.list_view_text_font_weight}
                  font_style={this.props.design.list_view_text_font_style}
                  text_transform={this.props.design.list_view_text_font_transform}
                  font_family={this.props.design.list_view_text_font_family}
                  font_size={this.props.design.list_view_text_font_size?? 20}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                  style={{ padding:5,}} >{dateLoader(this.state.selectedDate)} | {this.state.default_time ? this.state.default_time : ""}</StyledText>
                  
                </View>
              </TouchableOpacity>
              </>
              :
              <TouchableOpacity disabled onPress={this.dateSelectHandler} >
              <View style={style.checkoutContainer} >
              <View style={{ width: 40, justifyContent: "center" }}>
              {/* <SI size={24} name={'clock'} color={'blue'} />   */}
              <View style={style.checkoutIconWrap}>
                <SI size={22} name={"clock"} color={"#5d6670"} />
              </View>
            </View>
                <StyledText 
                font_color={this.props.design.list_view_text_color}
                font_weight={this.props.design.list_view_text_font_weight}
                font_style={this.props.design.list_view_text_font_style}
                text_transform={this.props.design.list_view_text_font_transform}
                font_family={this.props.design.list_view_text_font_family}
                font_size={this.props.design.list_view_text_font_size}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                style={{padding:5}} >{i18n.t("labels.not_accepting_order")}</StyledText>
                
              </View>
            </TouchableOpacity>
              }





          {this.discountLoader()}

          {
            this.props.config.comment_enabled_checkout &&
            <View style={{ marginVertical: 20, marginHorizontal: 5 }}>
              {/* // fontWeight: "bold", */}
              <StyledText 
               font_color={this.props.design.list_view_header_color}
               font_weight={this.props.design.list_view_header_font_weight}
               font_style={this.props.design.list_view_header_font_style}
               text_transform={this.props.design.list_view_header_font_transform}
               font_family={this.props.design.list_view_header_font_family}
               font_size={this.props.design.list_view_header_font_size?? 20}
               letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{ marginTop: 10,  }}>
                {i18n.t("labels.write_instructions")}
              </StyledText>
              <View style={{ marginTop: 5 }}>
                <Textarea
                  rowSpan={2}
                  bordered
                  maxLength={250}
                  value={this.state.instructions}
                  onChangeText={(text) => this.setState({ instructions: text })}
                  placeholderTextColor={"#8686867a"}
                  // placeholder="E.g. Don't ring bell."
                  style={{
                    color: "grey",
                    border: "1px solid grey",
                    borderRadius: 5,
                    height: 90,
                    fontFamily:'SecondaryFont'
                  }}
                />
              </View>
            </View>
          }



          {
            this.props.config.label_enabled &&
            (
              <View style={{ marginVertical: 20, marginHorizontal: 5 }}>
                <StyledText
                 font_color={this.props.design.list_view_header_color}
                 font_weight={this.props.design.list_view_header_font_weight}
                 font_style={this.props.design.list_view_header_font_style}
                 text_transform={this.props.design.list_view_header_font_transform}
                 font_family={this.props.design.list_view_header_font_family}
                 font_size={this.props.design.list_view_header_font_size?? 20}
                 letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                  numberOfLines={1}
                  style={{   marginTop: 10 }}
                >
                  {this.setCustomLabel(this.props.config.custom_label_multi)}
                </StyledText>
                <View style={{ marginTop: 5 }}>
                  <Input
                    rowSpan={1}
                    bordered
                    maxLength={40}
                    value={this.state.pickup_plate_no}
                    onChangeText={(text) =>
                      this.setState({ pickup_plate_no: text })
                    }
                    placeholderTextColor={"#8686867a"}
                    placeholder={this.setCustomLabel(this.props.config.custom_label_multi)}
                    style={{
                      // fontSize:30,
                      color: "grey",
                      border: "1px solid grey",
                      borderRadius: 5,
                      padding: 8,
                    }}
                  />
                </View>
              </View>
            )}
          {
            this.props.config.label_2_enabled &&
             (
              <View style={{ marginVertical: 20, marginHorizontal: 5 }}>
                <StyledText
                  numberOfLines={1}
                  font_color={this.props.design.list_view_header_color}
                  font_weight={this.props.design.list_view_header_font_weight}
                  font_style={this.props.design.list_view_header_font_style}
                  text_transform={this.props.design.list_view_header_font_transform}
                  font_family={this.props.design.list_view_header_font_family}
                  font_size={this.props.design.list_view_header_font_size?? 20}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                  style={{ marginTop: 10 , }}
                >
                  {this.setCustomLabel(this.props.config.custom_label_2_multi)}
                </StyledText>
                <View style={{ marginTop: 5 }}>
                  <Input
                    rowSpan={1}
                    bordered
                    maxLength={40}
                    value={this.state.pickup_plate_no_2}
                    onChangeText={(text) =>
                      this.setState({ pickup_plate_no_2: text })
                    }
                    placeholderTextColor={"#8686867a"}
                    placeholder={this.setCustomLabel(this.props.config.custom_label_2_multi)}
                    style={{
                      // fontSize:30,
                      color: "grey",
                      border: "1px solid grey",
                      borderRadius: 5,
                      padding: 8,
                    }}
                  />
                </View>
              </View>
            )}
          {this.props.platform == "qr" && this.props.config.enable_tips && (
            <View style={{ marginVertical: 20, marginHorizontal: 5 }}>
              <StyledText
                numberOfLines={1}
                font_color={this.props.design.list_view_header_color}
                font_weight={this.props.design.list_view_header_font_weight}
                font_style={this.props.design.list_view_header_font_style}
                text_transform={this.props.design.list_view_header_font_transform}
                font_family={this.props.design.list_view_header_font_family}
                font_size={this.props.design.list_view_header_font_size?? 20}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{ fontSize: this.props.design.custom_font.heading_size ?? 20, fontWeight: "bold", marginTop: 10, color: list_view_text_color, fontFamily:'SecondaryFont' }}
              >
                {i18n.t("button_text.tips")}
              </StyledText>
              <ScrollView horizontal>
                <TipsButtons
                  design={this.props.design}
                  calculateFivePercent={this.calculateFivePercent}
                  calculateTenPercent={this.calculateTenPercent}
                  calculateTwentyPercent={this.calculateTwentyPercent}
                  calculateFifteenPercent={this.calculateFifteenPercent}
                  calculateTwentyFivePercent={this.calculateTwentyFivePercent}
                  calculateThirtyPercent={this.calculateThirtyPercent}
                  top_buttons_background_color={top_buttons_background_color}
                  top_buttons_text_color={top_buttons_text_color}
                />
              </ScrollView>

              <View style={{ marginTop: 5 }}>
                <Input
                  keyboardType={"phone-pad"}
                  rowSpan={1}
                  bordered
                  maxLength={40}
                  value={this.state.tips}
                  onChangeText={(text) => this.onChangeTip(text)}
                  placeholderTextColor={"#8686867a"}
                  placeholder={i18n.t("button_text.tips")}
                  style={{
                    // fontSize:30,
                    color: "grey",
                    border: "1px solid grey",
                    borderRadius: 5,
                    padding: 8,
                    // fontFamily:'SecondaryFont'
                  }}
                />
              </View>
            </View>
          )}
          {this.props.platform != "qr" && this.props.config.enable_tips_online && (
            <View style={{ marginVertical: 20, marginHorizontal: 5 }}>

              <StyledText
                numberOfLines={1}
                font_color={this.props.design.list_view_header_color}
                font_weight={this.props.design.list_view_header_font_weight}
                font_style={this.props.design.list_view_header_font_style}
                text_transform={this.props.design.list_view_header_font_transform}
                font_family={this.props.design.list_view_header_font_family}
                font_size={this.props.design.list_view_header_font_size?? 20}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{ marginTop: 10, }}
              >
                {i18n.t("button_text.tips")}
              </StyledText>
                <ScrollView horizontal>
                <TipsButtons
                design={this.props.design}
                  calculateFivePercent={this.calculateFivePercent}
                  calculateTenPercent={this.calculateTenPercent}
                  calculateTwentyPercent={this.calculateTwentyPercent}
                  calculateFifteenPercent={this.calculateFifteenPercent}
                  calculateTwentyFivePercent={this.calculateTwentyFivePercent}
                  calculateThirtyPercent={this.calculateThirtyPercent}
                  top_buttons_background_color={top_buttons_background_color}
                  top_buttons_text_color={top_buttons_text_color}
                />
                </ScrollView>
              <View style={{ marginTop: 5 }}>
                <Input
                  keyboardType={"phone-pad"}
                  rowSpan={1}
                  bordered
                  maxLength={40}
                  value={this.state.tips}
                  onChangeText={(text) => this.onChangeTip(text)}
                  placeholderTextColor={"#8686867a"}
                  placeholder={i18n.t("button_text.tips")}
                  style={{
                    // fontSize:30,
                    color: "grey",
                    border: "1px solid grey",
                    borderRadius: 5,
                    padding: 8,
                    // fontFamily:'SecondaryFont'
                  }}
                />
              </View>
              
            </View>
          )}

          <View style={style.summaryPrice}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <StyledText 
               font_color={this.props.design.list_view_text_color}
               font_weight={this.props.design.list_view_text_font_weight}
               font_style={this.props.design.list_view_text_font_style}
               text_transform={this.props.design.list_view_text_font_transform}
               font_family={this.props.design.list_view_text_font_family}
               font_size={this.props.design.list_view_text_font_size?? 12}
               letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{ padding: 5}}
              >{i18n.t("checkout.net_total")}</StyledText>
              <StyledText 
              font_color={this.props.design.list_view_text_color}
              font_weight={this.props.design.list_view_text_font_weight}
              font_style={this.props.design.list_view_text_font_style}
              text_transform={this.props.design.list_view_text_font_transform}
              font_family={this.props.design.list_view_text_font_family}
              font_size={this.props.design.list_view_text_font_size?? 12}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{ padding: 5}}>
                {this.props.config.currency}{" "}
                {this.state.overview.net_total || 0.00}
              </StyledText>
            </View>
            {/*endddddd discountttttttttttttttttt */}
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <StyledText 
             font_color={this.props.design.list_view_text_color}
             font_weight={this.props.design.list_view_text_font_weight}
             font_style={this.props.design.list_view_text_font_style}
             text_transform={this.props.design.list_view_text_font_transform}
             font_family={this.props.design.list_view_text_font_family}
             font_size={this.props.design.list_view_text_font_size?? 12}
             letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{  paddingLeft: 5,  }} numberOfLines={1}>
                {!!this.state.overview?.discount &&
                  `${i18n.t("checkout.discount")}`}
                {/* {this.state.default_promo &&
                  this.state.default_promo.promo_type == 2 &&
                  `${i18n.t("checkout.discount")}`} */}
              </StyledText>
              <StyledText 
              font_color={this.props.design.list_view_text_color}
              font_weight={this.props.design.list_view_text_font_weight}
              font_style={this.props.design.list_view_text_font_style}
              text_transform={this.props.design.list_view_text_font_transform}
              font_family={this.props.design.list_view_text_font_family}
              font_size={this.props.design.list_view_text_font_size?? 12}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{ padding: 5}}>
                {/* {this.state.overview?.discount &&
                  this.state.default_promo.promo_type == 1 &&
                  "-" +
                    this.props.config.currency +
                    " " +
                    percentageHandler.getPercentage(
                      this.state.default_promo.percentage_discount,
                      this.props.total_price.toFixed(2),
                      this.props.config.currency,
                      this.props.config.display_fraction
                    )} */}
                {
                  !!this.state.overview?.discount &&
                    this.props.config.currency +
                    "-" +
                      this.state.overview?.discount
                }
              </StyledText>
            </View>
           
            {/* discountttttttttttttttttt */}

            {this.state.overview?.taxes?.map((item, index) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <StyledText 
                  font_color={this.props.design.list_view_text_color}
                  font_weight={this.props.design.list_view_text_font_weight}
                  font_style={this.props.design.list_view_text_font_style}
                  text_transform={this.props.design.list_view_text_font_transform}
                  font_family={this.props.design.list_view_text_font_family}
                  font_size={this.props.design.list_view_text_font_size?? 12}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                  style={{ padding: 5, }}>{item.name} </StyledText>
                  <StyledText 
                  font_color={this.props.design.list_view_text_color}
                  font_weight={this.props.design.list_view_text_font_weight}
                  font_style={this.props.design.list_view_text_font_style}
                  text_transform={this.props.design.list_view_text_font_transform}
                  font_family={this.props.design.list_view_text_font_family}
                  font_size={this.props.design.list_view_text_font_size?? 12}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                  style={{ padding: 5,  }}>
                    {this.props.config.currency}{" "}
                    {/* {this.taxCalculator(this.props.total_price, item)} */}
                    {item.amount}
                  </StyledText>
                </View>
              );
            })}

            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <StyledText 
              font_color={this.props.design.list_view_text_color}
              font_weight={this.props.design.list_view_text_font_weight}
              font_style={this.props.design.list_view_text_font_style}
              text_transform={this.props.design.list_view_text_font_transform}
              font_family={this.props.design.list_view_text_font_family}
              font_size={this.props.design.list_view_text_font_size?? 12}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{ padding: 5 , }}>{i18n.t("amount.sub_total")}</StyledText>
              <StyledText 
              font_color={this.props.design.list_view_text_color}
              font_weight={this.props.design.list_view_text_font_weight}
              font_style={this.props.design.list_view_text_font_style}
              text_transform={this.props.design.list_view_text_font_transform}
              font_family={this.props.design.list_view_text_font_family}
              font_size={this.props.design.list_view_text_font_size?? 12}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{ padding: 5,  }}>
                {this.props.config.currency}{" "}
                {
                  this.state.overview.sub_total || 0.00
                }
              </StyledText>
            </View>

            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              {this.props.menu_type == "delivery" &&
              this.props.platform != "qr" ? (
                <StyledText 
                font_color={this.props.design.list_view_text_color}
              font_weight={this.props.design.list_view_text_font_weight}
              font_style={this.props.design.list_view_text_font_style}
              text_transform={this.props.design.list_view_text_font_transform}
              font_family={this.props.design.list_view_text_font_family}
              font_size={this.props.design.list_view_text_font_size?? 12}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{ padding: 5,}}>
                  {i18n.t("amount.delivery_fee")}
                </StyledText>
              ) : null}
              {this.props.platform != "qr" &&
              this.props.menu_type == "delivery" ? (
                <StyledText
                font_color={this.props.design.list_view_text_color}
                  font_weight={this.props.design.list_view_text_font_weight}
                  font_style={this.props.design.list_view_text_font_style}
                  text_transform={this.props.design.list_view_text_font_transform}
                  font_family={this.props.design.list_view_text_font_family}
                  font_size={this.props.design.list_view_text_font_size?? 12}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                 style={{ padding: 5, }}>
                  {this.props.config.currency}{" "}
                  {
                  this.state.overview.delivery_charge || 0.00
                }
                </StyledText>
              ) : null}
            </View>
            {/* delivery charge in qr for us client */}
            {
                this.props.platform == "qr" && this.props.config.delivery_charge ?
                <View
                  style={{ flexDirection: "row", justifyContent: "space-between" }}
                >
                  <StyledText
                  font_color={this.props.design.list_view_text_color}
                  font_weight={this.props.design.list_view_text_font_weight}
                  font_style={this.props.design.list_view_text_font_style}
                  text_transform={this.props.design.list_view_text_font_transform}
                  font_family={this.props.design.list_view_text_font_family}
                  font_size={this.props.design.list_view_text_font_size?? 12}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                   style={{ padding: 5,  }}>
                    {i18n.t("amount.delivery_fee")}
                  </StyledText>
                    <StyledText 
                    font_color={this.props.design.list_view_text_color}
                    font_weight={this.props.design.list_view_text_font_weight}
                    font_style={this.props.design.list_view_text_font_style}
                    text_transform={this.props.design.list_view_text_font_transform}
                    font_family={this.props.design.list_view_text_font_family}
                    font_size={this.props.design.list_view_text_font_size?? 12}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                    style={{ padding: 5 }}>
                      {this.props.config.currency}{" "}
                      {
                        this.state.overview.delivery_charge || 0.00
                      }

                    </StyledText>
                </View>
                :
                <View/>
              }
              {/* delivery charge in qr for us client end*/}
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              {((this.props.platform == "qr" &&
                this.props.config.enable_tips) ||
                (this.props.platform != "qr" &&
                  this.props.config.enable_tips_online)) &&
              this.state.tips != "" ? (
                <StyledText 
                font_color={this.props.design.list_view_text_color}
              font_weight={this.props.design.list_view_text_font_weight}
              font_style={this.props.design.list_view_text_font_style}
              text_transform={this.props.design.list_view_text_font_transform}
              font_family={this.props.design.list_view_text_font_family}
              font_size={this.props.design.list_view_text_font_size?? 12}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{ padding: 5,  }}>{i18n.t("button_text.tips")}</StyledText>
              ) : null}
              {((this.props.platform == "qr" &&
                this.props.config.enable_tips) ||
                (this.props.platform != "qr" &&
                  this.props.config.enable_tips_online)) &&
              this.state.tips != "" ? (
                <StyledText 
                  font_color={this.props.design.list_view_text_color}
                  font_weight={this.props.design.list_view_text_font_weight}
                  font_style={this.props.design.list_view_text_font_style}
                  text_transform={this.props.design.list_view_text_font_transform}
                  font_family={this.props.design.list_view_text_font_family}
                  font_size={this.props.design.list_view_text_font_size?? 12}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                  style={{ padding: 5,}}>
                  {this.props.config.currency}{" "}
                  {
                    this.state.overview.tips || 0.00
                  }
                </StyledText>
              ) : null}
            </View>
            {
              !! this.state.overview.mymenu_charge &&
              <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
              >
                <StyledText 
                font_color={this.props.design.list_view_text_color}
                font_weight={this.props.design.list_view_text_font_weight}
                font_style={this.props.design.list_view_text_font_style}
                text_transform={this.props.design.list_view_text_font_transform}
                font_family={this.props.design.list_view_text_font_family}
                font_size={this.props.design.list_view_text_font_size?? 12}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{ padding: 5,  }}>
                  {i18n.t("labels.mymenu_charge")}
                </StyledText>
                <StyledText 
                font_color={this.props.design.list_view_text_color}
                font_weight={this.props.design.list_view_text_font_weight}
                font_style={this.props.design.list_view_text_font_style}
                text_transform={this.props.design.list_view_text_font_transform}
                font_family={this.props.design.list_view_text_font_family}
                font_size={this.props.design.list_view_text_font_size?? 12}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                
                style={{  padding: 5, }}>
                  {this.props.config.currency + " "}
                  {
                    this.state.overview.mymenu_charge || 0.00
                  }
                </StyledText>
              </View>
            }


            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <StyledText 
              font_color={this.props.design.list_view_text_color}
              font_weight={this.props.design.list_view_text_font_weight}
              font_style={this.props.design.list_view_text_font_style}
              text_transform={this.props.design.list_view_text_font_transform}
              font_family={this.props.design.list_view_text_font_family}
              font_size={this.props.design.list_view_text_font_size?? 12}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{  padding: 5 }}>
                {i18n.t("amount.total")}
              </StyledText>
              <StyledText
              font_color={this.props.design.list_view_text_color}
              font_weight={this.props.design.list_view_text_font_weight}
              font_style={this.props.design.list_view_text_font_style}
              text_transform={this.props.design.list_view_text_font_transform}
              font_family={this.props.design.list_view_text_font_family}
              font_size={this.props.design.list_view_text_font_size?? 12}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
               style={{ padding: 5, }}>
                {this.props.config.currency + " "}
                {
                    this.state.overview.total || 0.00
                }
              </StyledText>
            </View>
          </View>

        
        </View>


        <TouchableOpacity
          style={{ position: "absolute", top: 0 }}
          onPress={() => {
            if(this.props.cart.length){
              this.props.navigation.navigate("CartScreen")
            }else{
              this.props.navigation.navigate("HomeScreen")

            }
          }}
        >
          <BackButton
            border_color={`1px solid ${this.props.design.top_buttons_border_color}`}
            // goBack={() => this.props.navigation.goBack()}
            backgroundColor={this.props.design.top_buttons_background_color}
            borderColor={"black"}
            color={this.props.design.top_buttons_text_color}
            default_language={this.props.default_language}
            font_size={this.props.design.custom_font.heading_size}
          />
        </TouchableOpacity>

        <Modal transparent animationType="slide" visible={this.state.loader}>
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <View
              style={{
                width: "90%",
                height: "50%",
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
              }}
            >
              {this.state.order_placed ? (
                <>
                  <Image
                    source={require("./../../assets/placed.png")}
                    style={{ width: 80, height: 80, marginBottom: 20 }}
                  />
                  <StyledText style={{fontFamily:'SecondaryFont'}}>{i18n.t("order.order_placed")}</StyledText>
                </>
              ) : this.state.card_error ? (
                <>
                  <Image
                    source={require("./../../assets/error.png")}
                    style={{ width: 80, height: 80, marginBottom: 20 }}
                  />
                  <StyledText style={{ textAlign: "center", marginHorizontal: 3, fontFamily:'SecondaryFont' }}>
                    {this.state.card_error_mzg}
                  </StyledText>
                  {/* <Text>(Please try again or use another card)</StyledText>    */}
                  <Button
                    onPress={() => this.onPressOKFromErrorCard()}
                    danger
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                      width: "70%",
                      alignSelf:"center"
                    }}
                  >
                    <StyledText style={{fontFamily:'SecondaryFont'}}>{i18n.t("button_text.ok")} </StyledText>
                  </Button>
                </>
              ) : (
                <>
                  <Image
                    source={require("./../../assets/gif.gif")}
                    style={{ width: 80, height: 80, marginBottom: 20 }}
                  />
                  <StyledText style={{fontFamily:'SecondaryFont'}}>
                    {i18n.t(
                      "labels.please_wait_while_we_are_placing_your_order"
                    )}
                  </StyledText>
                  <StyledText style={{fontFamily:'SecondaryFont'}}>{i18n.t("labels.dont_press_back_button")}</StyledText>
                </>
              )}
            </View>
          </View>
        </Modal>


        <Modal transparent animationType="slide" visible={this.state.stripeRetryPaymentModal}>
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <View
              style={{
                width: "90%",
                height: "50%",
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
              }}
            >
                  <Image
                    source={require("./../../assets/error.png")}
                    style={{ width: 80, height: 80, marginBottom: 20 }}
                  />
                  <StyledText style={{ textAlign: "center", marginHorizontal: 3, fontFamily:'SecondaryFont' }}>
                    {i18n.t("labels.stripe_retry")}
                  </StyledText>
                  <View style={{flexDirection:"row", justifyContent:"space-between", alignItems:"center"}} >
                  <Button
                    onPress={() => this.selectNewPaymentMethod()}
                    danger
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                      width: "50%",
                      alignSelf:"center"
                    }}
                  >
                    <StyledText style={{fontFamily:'SecondaryFont'}}>{i18n.t("button_text.no")} </StyledText>
                  </Button>
                  <Button
                    onPress={() => this.stripeRetryPayment()}
                    danger
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                      width: "50%",
                      alignSelf:"center"
                    }}
                  >
                    <StyledText style={{fontFamily:'SecondaryFont'}}>{i18n.t("button_text.yes")} </StyledText>
                  </Button>
                    </View>             
            </View>
          </View>
        </Modal>

        <Modal
          transparent
          style={{ backgroundColor: "transparent" }}
          animationType="slide"
          visible={this.state.addressModal}
        >
          {this._renderAddressList()}
        </Modal>

        <Modal
          transparent
          style={{ backgroundColor: "transparent" }}
          animationType="slide"
          visible={this.state.paymentMethodModal}
        >
          {this._paymentMethodList()}
        </Modal>

        <Modal
          transparent
          style={{ backgroundColor: "transparent" }}
          animationType="slide"
          visible={this.state.timeScheduleModal}
        >
          {this._timeScheduleList()}
        </Modal>

        <Modal
          transparent
          style={{ backgroundColor: "black" }}
          animationType="slide"
          visible={this.state.masterCardView}
        >
          {this.state.masterCardView && (
            <ModalComponent
            design={this.props.design}
              height={500}
              toggleModal={this.togglePaymentModal}
              title={i18n.t("labels.select_payment_mode")}
              custom_font={this.props.design.custom_font}
            >
              <iframe src={`${API_URL}make-payment`}></iframe>
            </ModalComponent>
          )}
        </Modal>

        <Modal
          transparent
          style={{ backgroundColor: "transparent" }}
          animationType="slide"
          // visible={true}
          visible={this.state.orderSuccessModal}
        >
          <OrderSuccessModal
            order_response={this.state.order_response}
            platform={this.props.platform}
            menu_type={this.props.menu_type}
            config={this.props.config}
            design={this.props.design}
            orderScreen={() => this.orderScreen()}
            closeSuccessModal={() => this.closeSuccessModal()}
          />
        </Modal>


        <Modal
          transparent
          style={{ backgroundColor: "transparent" }}
          animationType="slide"
          // visible={true}
          visible={this.state.dateModal}
        >
          <DateModalComponent
            showWarning={() => this.throwMessageFn("Select a Date & Time", "red")}
            onSelectDate={(date)=>this.changeDate(date)}
            selectedDate={this.state.selectedDate}
            default_time={this.state.default_time}
            onSelectTime={(default_time) => this.setState({default_time})}
            orderScreen={() => this.dateSelectHandler()}
            closeDateModal={() => this.dateSelectHandler()}
            button_background_color={this.props.design.top_buttons_background_color}
            button_text_color={this.props.design.top_buttons_text_color}
            dateList={this.state.dateList}
            timeList={this.state.schedule_list}
            block_out_days={this.props.config.block_out_days}
            working_hours={this.props.config.working_hours}
            working_hour={this.props.config.working_hour}
            heading_font_size={this.props.design.custom_font.heading_size}
            future_date_start_from={this.props.config.future_date_start_from}
          />
        </Modal>

          {/*iolpay modal*/}
          <Modal
            transparent
            animationType="slide"
            visible={this.state.iolpayModal}
            ariaHideApp="false"
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            {this.iolPayModalView()}
          </Modal>
          {/*end iolpay modal*/}


        <Modal
          transparent
          style={{ backgroundColor: "black" }}
          animationType="slide"
          visible={this.state.squarePayModal}
        >
          {this.SquareModalComponent()}
        </Modal>


      </ScrollView>
      <Toast
          error_message={this.state.error_message}
          message_status={this.state.message_status}
          message_display={this.state.message_display}
          custom_font={this.props.design.custom_font}
        />
      <View style={[{backgroundColor: list_view_background_color,},style.checkoutButtonContainer]} >
        <View style={{flex:.7, alignItems:'center'
}}>
        {this.paymentLoader()}

        </View> 

     
        <View style={{flex:1, alignItems:'center'}} >
          
            <View style={{width:'100%', height:'100%', display: this.showOrderButton() ? "block": "none" }} >
              {
                this.state.payment_method == "crypto" ? 
                <CoinbaseCommerceButton 
                onLoad = {() => {
                  console.log("loaddddddddd")
                }}
                onChargeSuccess={MessageData => {
                  console.log(MessageData, "lll")
                  // if(MessageData.event==="charge_confirmed" || "payment_detected" == MessageData.event){
                  //   this.setState({ loader: true }, () => {
                  //     this.checkout("token")
                  //   })
                  // }
                }}
                onPaymentDetected = {MessageData => {
                  console.log(MessageData, "ppppppp")
                    this.setState({ loader: true }, () => {
                      this.checkout("token")
                    })
                }}
                onChargeFailure={(MessageData) => {
                  console.log(MessageData, "sss")

                  this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
                }}
                disabled={this.state.crypto_invoice_loader} 
                style={{
                  margin:0,
                  padding:0,
                  border:0,backgroundColor:"transparent",
                  width:"100%"
                }} 
                name="" 
                chargeId={this.state.coinbase_code}
                // chargeId={'4FXLDDXG'}
                >
                  {
                    this.state.crypto_invoice_loader ? 
                    <i style={{color: this.props.color, fontSize: 25}} class="fas fa-spinner fa-spin"></i>
                    :
                    <PlaceOrderButton
                      design={this.props.design}
                      currency={this.props.config.currency}
                      placeOrder={() => {
                        
                      }}
                      total={this.state.overview.total || 0.00}
                      custom_font={this.props.design.custom_font}
                    />
                  }

                  </CoinbaseCommerceButton>
                  :
                  <PlaceOrderButton
                  dataLoader={this.state.dataLoader}
                  design={this.props.design}
                  currency={this.props.config.currency}
                  placeOrder={() => {
                    if(this.checkIfWorking()){                     
                      if(this.props.allow_ordering){
                        if(this.state.stripe_client_secret && this.state.pendingOrderResponse){
                          this.setState({stripeRetryPaymentModal: true})
                        }else{
                          this.placeOrder()
                        }
                        
                      }else{
                        this.throwMessageFn(i18n.t("labels.menu_change_detected"), "red");                       
                      }
                      
                    }else{
                      this.throwMessageFn(i18n.t("labels.not_working"), "red");
                    }
                    // if(this.props.config.working_hour || this.state.selectedDate != moment().format("DD/MM/YYYY")){
                    //   this.placeOrder()
                    // }else{
                    //   this.throwMessageFn(i18n.t("labels.not_working"), "red");
                    // }
                  }}
                  total={this.state.overview.total || 0.00}
                  custom_font={this.props.design.custom_font}
                />
              }

            
            </View>
            <View style={{width:'100%', height:'100%', display:  this.showOrderButton() ? "none": "block" }} >
            {
              this.state.stripePromise && this.state.overview.total || 0.00  > 0 ?
              
              <Elements stripe={this.state.stripePromise}>
                  <DigiWallet
                      
                      paymentConfigured = {this.props.config.payment}
                      completeOnlinePayment={this.completeOnlinePayment}
                      throwMessageFn={this.throwMessageFn}
                        service_id={this.props.config.payment
                          ? this.props.config.payment.id
                          : ""}
                        venue={this.props.config.venueName}
                        platform={this.props.config.platform}
                        currency={this.props.config.currency_code.toLowerCase()}
                        country={this.props.config.country.cca2}
                        total={this.priceHandlerCheckout(this.state.overview.total || 0.00)}
                        setPaymentRequest={(e)=> this.setState({paymentRequest:e})}
                        paymentRequest={this.state.paymentRequest}
                        setAvailablePaymentMethod={e=> {
                          this.setState({availablePaymentMethod: {...e, savedPayment: true}}, ()=> {
                            if(!this.state.payment_method){
                              if(this.state.availablePaymentMethod.applePay){
                                this.setState({payment_method: "apple_pay"})
                              }else if(this.state.availablePaymentMethod.googlePay){
                                this.setState({payment_method: "google_pay"})
                              }
                              // else if(!this.state.availablePaymentMethod.applePay && !this.state.availablePaymentMethod.googlePay && this.state.availablePaymentMethod.savedPayment){
                              //   this.setState({payment_method: "saved_payment"})
                              // }
                            }
                          })

                        }}
                      >
                        <PlaceOrderButton
                        dataLoader={this.state.dataLoader}
                          design={this.props.design}
                          currency={this.props.config.currency}
                          placeOrder={() => {
                            if(this.checkIfWorking()){
                              if(
                                this.props.platform != "qr" && 
                                this.props.config.label_enabled &&
                                this.props.menu_type !== "delivery" && 
                                this.props.config.custom_label_required &&
                                !this.state.pickup_plate_no
                                ){
                                  const {config, default_language} = this.props;
                                  let custom_label = config.custom_label_multi ? 
                                    IntlLabelFn(
                                      JSON.parse(config.custom_label_multi), 
                                      config.default_language.value, 
                                      default_language
                                      ) : ""
                                  
                                  this.throwMessageFn( custom_label +" "+ i18n.t("labels.required"), "red");
                                  return
                                }
                                if(
                                  this.props.platform != "qr" && 
                                  this.props.config.label_2_enabled &&
                                  this.props.menu_type !== "delivery" && 
                                  this.props.config.custom_label_2_required &&
                                  !this.state.pickup_plate_no_2
                                  ){
                                const {config, default_language} = this.props;
                                let custom_label_2 = config.custom_label_2_multi ? 
                                  IntlLabelFn(
                                    JSON.parse(config.custom_label_2_multi), 
                                    config.default_language.value, 
                                    config.default_language.value
                                    ) : ""
                                    this.throwMessageFn( custom_label_2 +" "+ i18n.t("labels.required"), "red");
                                    return
                                  }
                                  console.log('hereeeeeeeeeee',this.props.total_price.toFixed(2) , parseFloat(this.props.config.minimum_order_amount) )

                                  if (this.props.config.minimum_order_amount && parseFloat(this.props.config.minimum_order_amount) > 0 &&this.props.menu_type == "delivery" && this.props.platform != "qr") {
                                    if (this.props.total_price.toFixed(2) < parseFloat(this.props.config.minimum_order_amount)) {
                                      this.setState({ loader: false }, () => {
                                        this.throwMessageFn(
                                          `${i18n.t("labels.minimum_order_amount_is")} ${this.props.config.currency} ${this.props.config.minimum_order_amount}`,
                                          "red"
                                        );
                                      });
                                      return;
                                    }
                                  }
                                  if(this.props.allow_ordering){
                                    this.state.paymentRequest.show()
                                  }else{
                                    this.throwMessageFn(i18n.t("labels.menu_change_detected"), "red");
                                  }

                            }else{
                              this.throwMessageFn(i18n.t("labels.not_working"), "red");
                            }
                        
                            
                          }}
                          total={this.state.overview.total || 0.00}
                          custom_font={this.props.design.custom_font}
                        />
                      </DigiWallet>
                </Elements>
              
              :
              <View/>
            }
          </View>
        </View>

        </View>
        
      </View>

   

     

    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    cart_count: state.cart.cart_count,
    total_price: state.cart.total_price,
    allChildren: state.menus.allChildren,
    config: state.config.config,
    default_language: state.languages.default_language,
    isRTL: state.languages.isRTL,

    table_detail: state.config.table,
    card_number: state.payment.cardNumber,
    year: state.payment.expiryYear,
    month: state.payment.expiryMonth,
    cvv: state.payment.cvv,
    number: state.number.number,
    user: state.number.user,
    menu_type: state.config.menu_type,

    token: state.payment.token,
    card: state.payment.card,
    saveCard: state.payment.saveCard,
    oldCard: state.payment.oldCard,
    platform: state.config.platform,
    design: state.config.design,
    card_scheme: state.payment.scheme,
    number: state.number.number,
    allow_ordering: state.messages.allow_ordering,
    stripeObject: state.payment.stripeObject,
    CardElement: state.payment.CardElement,
    menuID: state.menus.menuID,
    location: state.location.location,

  };
};
export default connect(mapStateToProps, { resetCart, resetChildren, addCard, setOrderData })(
  CheckoutScreen
);
