import React,{useState} from "react";
import { View, Text, TouchableOpacity, AsyncStorage } from "react-native";
// import Ant from '@expo/vector-icons/AntDesign';
import Icon from "./Warning";
import { THREE_FRACTION_CURRENCY } from "./../constants";
import i18n from "../i18n";
import { FontLetterSpacing, PriceLoader } from "./../handlers/utils"
import StyledText from "../components/styledText"
import { performRequest } from "../services/apiHandler";
import { connect } from "react-redux";
import Constants from "expo-constants";
import {
    setOrderData,
    resetCart,
    resetChildren
  } from "./../store/actions/index";

  
let mid = Constants.installationId;



const CartTotal = (props) => {
    const [is_loading, setIsLoading]=useState(false)

    const onClickRightButton = async() => {
        setIsLoading(true)
        if(props.cartScreen){
            await PlaceExpressOrder(true)
            
        }else{
            props.navigateToCart()
            setIsLoading(false)
        }
    }
    const onPressLeftButton = async() => {
        setIsLoading(true)
        if(props.cartScreen){
            PlaceExpressOrder()
        }else{
            props.navigateToCheckout()
            setIsLoading(false)
        }
    }

    const PlaceExpressOrder = async(is_right_button=false) => {
        let uuid = await AsyncStorage.getItem("uuid");
        let payload =  {
            uuid,
            venue: props.config.venue,
           
            currency: props.config.currency_code,
                     
            customer: props.user.id??null,
            channel: "qr",
            delivery_details: JSON.stringify({}),
            comment: "",
      
      
            delivery_charge:  props.config.delivery_charge,
            
            mid,
            table: props.table_detail.table,
            auto_accept_orders: props.config.auto_accept_orders,
          
          };
          performRequest("post", "api/pwa/express-order",payload)
          .then(response => {
            props.ShowToast()
            console.log(response.data)
            props.setOrderData(response.data)
            postOrderHandler()
            setIsLoading(false)
            setTimeout(() => {
                if(is_right_button){
                    props.navigateToCheckout();
                }else{
                    props.navigateToHome()
                }
            }, 2000);
          })
          .catch(err=>{
            console.log(err.response)
            setIsLoading(false)
          })
    }

    const postOrderHandler = () => {
        let all_cart = [...props.cart];
        all_cart.map((item) => {
        if (item.modifier) {
            item.modifier.map((mod_item) => {
            item.selected_price.price =
                item.selected_price.price - mod_item.price;
            });
        }
        });
        props.resetCart();
        props.resetChildren();
        }

      
    return(
    <View
        style={{
            width: "100%" /*height:75*/,
            position: "absolute",
            bottom: 0,
            backgroundColor: "transparent",
            //   justifyContent: "flex-end",
            flexDirection: "row",
        }}
    >
        {
            props.order.id && !props.cartScreen &&
            <TouchableOpacity disabled={is_loading} style={{ flex: 1, }} onPress={onPressLeftButton}>
            <View
                style={{
                    minHeight: 50,
                    backgroundColor: props.design.top_buttons_background_color,
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 10,
                    borderRadius: 8
                    // flex:1,
                    // width:"50%"
                }}
            >
                {
                    is_loading?
                    <i style={{color: props.design.top_buttons_text_color, fontSize: 20}} class="fas fa-spinner fa-spin"></i>
                    :
                        <>
                            <StyledText
                                font_color={props.design.top_buttons_text_color}
                                font_weight={props.design.top_button_text_font_weight}
                                font_style={props.design.top_button_text_font_style}
                                text_transform={props.design.top_button_text_font_transform}
                                font_family={props.design.top_button_text_font_family}
                                font_size={10}
                                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}


                                style={{
                                    marginBottom: 2,
                                }}
                            >

                                {props.order.quantity} Items |{" "}{props.display_currency && props.currency} {props.order.total}
                            </StyledText>
                            <StyledText
                                font_color={props.design.top_buttons_text_color}
                                font_weight={400}
                                font_style={props.design.top_button_text_font_style}
                                text_transform={props.design.top_button_text_font_transform}
                                font_family={props.design.top_button_text_font_family}
                                font_size={props.design.top_button_text_font_size}
                                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}


                                style={{
                                    marginBottom: 2,
                                }}
                            >
                                Checkout
                            </StyledText>
                        </>
                }


            </View>
        </TouchableOpacity>
        }
        {
            props.cartScreen && !!props.cart.length &&
            <TouchableOpacity disabled={is_loading} style={{ flex: 1, }} onPress={onPressLeftButton}>
            <View
                style={{
                    minHeight: 50,
                    backgroundColor: props.design.top_buttons_background_color,
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 10,
                    borderRadius: 8
                    // flex:1,
                    // width:"50%"
                }}
            >
                {
                    is_loading?
                    <i style={{color: props.design.top_buttons_text_color, fontSize: 20}} class="fas fa-spinner fa-spin"></i>
                    :
                        <>
                        
                            <StyledText
                                font_color={props.design.top_buttons_text_color}
                                font_weight={props.design.top_button_text_font_weight}
                                font_style={props.design.top_button_text_font_style}
                                text_transform={props.design.top_button_text_font_transform}
                                font_family={props.design.top_button_text_font_family}
                                font_size={10}
                                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}


                                style={{
                                    marginBottom: 2,
                                }}
                            >
                                {props.total} Item(s) |{" "}{props.display_currency && props.currency}
                                {props.display_price &&
                                PriceLoader(props.total_price, props.currency, props.display_fraction)}

                            </StyledText>
                            <StyledText
                                font_color={props.design.top_buttons_text_color}
                                font_weight={400}
                                font_style={props.design.top_button_text_font_style}
                                text_transform={props.design.top_button_text_font_transform}
                                font_family={props.design.top_button_text_font_family}
                                font_size={props.design.top_button_text_font_size}
                                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}


                                style={{
                                    marginBottom: 2,
                                }}
                            >
                                Order Now
                            </StyledText>
                            <StyledText
                                font_color={props.design.top_buttons_text_color}
                                font_weight={400}
                                font_style={props.design.top_button_text_font_style}
                                text_transform={props.design.top_button_text_font_transform}
                                font_family={props.design.top_button_text_font_family}
                                font_size={8}
                                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}


                                style={{
                                    marginBottom: 2,
                                }}
                            >
                                (Pay Later)
                            </StyledText>
                        </>
                        
                       
                }


            </View>
        </TouchableOpacity>
        }

        {
            !!props.cart.length &&
            <TouchableOpacity disabled={is_loading}  style={{ flex: 2, }} onPress={onClickRightButton}>
                <View
                    style={{
                        minHeight: 50,
                        flex: 1,
                        backgroundColor: props.design.top_buttons_background_color,
                        flexDirection: "row",
                        justifyContent:is_loading?"center": "space-between",
                        alignItems: "center",
                        margin: 10,
                        borderRadius: 8
                    }}
                >
                    {
                    is_loading?
                    <i style={{color: props.design.top_buttons_text_color, fontSize: 20}} class="fas fa-spinner fa-spin"></i>
                    :
                    <>
                    <View style={{ marginLeft: 17, flexDirection: "row" }}>
                        <StyledText
                            font_color={props.design.top_buttons_text_color}
                            font_weight={props.design.top_button_text_font_weight}
                            font_style={props.design.top_button_text_font_style}
                            text_transform={props.design.top_button_text_font_transform}
                            font_family={props.design.top_button_text_font_family}
                            font_size={props.design.top_button_text_font_size}
                            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}


                            style={{
                                marginBottom: 2,
                            }}
                        >
                            {
                            props.cartScreen ?
                            (props.total || 0 )+ (props.order.quantity || 0)
                            :
                            props.total
                        }
                           {" "}Item(s) | {" "}

                        </StyledText>
                        <StyledText
                            font_color={props.design.top_buttons_text_color}
                            font_weight={props.design.top_button_text_font_weight}
                            font_style={props.design.top_button_text_font_style}
                            text_transform={props.design.top_button_text_font_transform}
                            font_family={props.design.top_button_text_font_family}
                            font_size={props.design.top_button_text_font_size}
                            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                            style={{ fontWeight: "500", }}
                        >
                            {props.display_currency && props.currency}{" "}
                            {props.display_price &&
                            props.cartScreen ?
                            PriceLoader(((props.order.total||0) + (props.total_price||0)), props.currency, props.display_fraction)
                            :
                            PriceLoader(props.total_price, props.currency, props.display_fraction)
                            }
                        </StyledText>
                    </View>
                    <View style={{ flexDirection: "row", marginRight: 10 }}>
                        <StyledText
                            font_color={props.design.top_buttons_text_color}
                            font_weight={props.design.top_button_text_font_weight}
                            font_style={props.design.top_button_text_font_style}
                            text_transform={props.design.top_button_text_font_transform}
                            font_family={props.design.top_button_text_font_family}
                            font_size={props.design.top_button_text_font_size}
                            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                            style={{}}
                        >
                            {
                                props.cartScreen ?
                                "Checkout"
                                :
                                i18n.t("labels.view_cart")
                            }
                        </StyledText>
                        <Icon
                            color={props.design.top_buttons_text_color}
                            size={15}
                            name={"play"}
                            style={{ marginLeft: 5, marginTop: 1.5 }}
                        />
                    </View>
                    </>
                }
                </View>
                
            </TouchableOpacity>
        }


    </View>
)};
const mapStateToProps = state => {
    return {
        table_detail: state.config.table,
        config: state.config.config,
        user: state.number.user,
        cart: state.cart.cart,
        order: state.cart.order


    }
};

export default connect(mapStateToProps,{setOrderData, resetCart, resetChildren})(CartTotal) ;
