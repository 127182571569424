'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var DeviceCodeList = require('./v1/deviceCode').DeviceCodeList;
var OauthList = require('./v1/oauth').OauthList;
var OpenidDiscoveryList = require('./v1/openidDiscovery').OpenidDiscoveryList;
var TokenList = require('./v1/token').TokenList;
var UserInfoList = require('./v1/userInfo').UserInfoList;
var Version = require('../../base/Version');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize the V1 version of Oauth
 *
 * @constructor Twilio.Oauth.V1
 *
 * @property {Twilio.Oauth.V1.OauthList} oauth - oauth resource
 * @property {Twilio.Oauth.V1.DeviceCodeList} deviceCode - deviceCode resource
 * @property {Twilio.Oauth.V1.OpenidDiscoveryList} openidDiscovery -
 *          openidDiscovery resource
 * @property {Twilio.Oauth.V1.TokenList} token - token resource
 * @property {Twilio.Oauth.V1.UserInfoList} userInfo - userInfo resource
 *
 * @param {Twilio.Oauth} domain - The twilio domain
 */
/* jshint ignore:end */
function V1(domain) {
  Version.prototype.constructor.call(this, domain, 'v1');

  // Resources
  this._oauth = undefined;
  this._deviceCode = undefined;
  this._openidDiscovery = undefined;
  this._token = undefined;
  this._userInfo = undefined;
}

_.extend(V1.prototype, Version.prototype);
V1.prototype.constructor = V1;

Object.defineProperty(V1.prototype,
  'oauth', {
    get: function() {
      this._oauth = this._oauth || new OauthList(this);
      return this._oauth;
    }
});

Object.defineProperty(V1.prototype,
  'deviceCode', {
    get: function() {
      this._deviceCode = this._deviceCode || new DeviceCodeList(this);
      return this._deviceCode;
    }
});

Object.defineProperty(V1.prototype,
  'openidDiscovery', {
    get: function() {
      this._openidDiscovery = this._openidDiscovery || new OpenidDiscoveryList(this);
      return this._openidDiscovery;
    }
});

Object.defineProperty(V1.prototype,
  'token', {
    get: function() {
      this._token = this._token || new TokenList(this);
      return this._token;
    }
});

Object.defineProperty(V1.prototype,
  'userInfo', {
    get: function() {
      this._userInfo = this._userInfo || new UserInfoList(this);
      return this._userInfo;
    }
});

module.exports = V1;
