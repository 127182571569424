'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var WebChannelsList;
var WebChannelsPage;
var WebChannelsInstance;

/* jshint ignore:start */
/**
 * Initialize the WebChannelsList
 *
 * @constructor Twilio.FlexApi.V2.WebChannelsList
 *
 * @param {Twilio.FlexApi.V2} version - Version of the resource
 */
/* jshint ignore:end */
WebChannelsList = function WebChannelsList(version) {
  /* jshint ignore:start */
  /**
   * @function webChannels
   * @memberof Twilio.FlexApi.V2#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.FlexApi.V2.WebChannelsContext}
   */
  /* jshint ignore:end */
  function WebChannelsListInstance(sid) {
    return WebChannelsListInstance.get(sid);
  }

  WebChannelsListInstance._version = version;
  // Path Solution
  WebChannelsListInstance._solution = {};
  WebChannelsListInstance._uri = `/WebChats`;
  /* jshint ignore:start */
  /**
   * create a WebChannelsInstance
   *
   * @function create
   * @memberof Twilio.FlexApi.V2.WebChannelsList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.addressSid - The SID of the Conversations Address
   * @param {string} [opts.chatFriendlyName] - The Conversation's friendly name
   * @param {string} [opts.customerFriendlyName] -
   *          The Conversation participant's friendly name
   * @param {string} [opts.preEngagementData] - The pre-engagement data
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed WebChannelsInstance
   */
  /* jshint ignore:end */
  WebChannelsListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['addressSid'])) {
      throw new Error('Required parameter "opts[\'addressSid\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'AddressSid': _.get(opts, 'addressSid'),
      'ChatFriendlyName': _.get(opts, 'chatFriendlyName'),
      'CustomerFriendlyName': _.get(opts, 'customerFriendlyName'),
      'PreEngagementData': _.get(opts, 'preEngagementData')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new WebChannelsInstance(this._version, payload));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.FlexApi.V2.WebChannelsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  WebChannelsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  WebChannelsListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return WebChannelsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the WebChannelsPage
 *
 * @constructor Twilio.FlexApi.V2.WebChannelsPage
 *
 * @param {V2} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {WebChannelsSolution} solution - Path solution
 *
 * @returns WebChannelsPage
 */
/* jshint ignore:end */
WebChannelsPage = function WebChannelsPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(WebChannelsPage.prototype, Page.prototype);
WebChannelsPage.prototype.constructor = WebChannelsPage;

/* jshint ignore:start */
/**
 * Build an instance of WebChannelsInstance
 *
 * @function getInstance
 * @memberof Twilio.FlexApi.V2.WebChannelsPage#
 *
 * @param {WebChannelsPayload} payload - Payload response from the API
 *
 * @returns WebChannelsInstance
 */
/* jshint ignore:end */
WebChannelsPage.prototype.getInstance = function getInstance(payload) {
  return new WebChannelsInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V2.WebChannelsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
WebChannelsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WebChannelsPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the WebChannelsContext
 *
 * @constructor Twilio.FlexApi.V2.WebChannelsInstance
 *
 * @property {string} conversationSid -
 *          The unique string representing the Conversation resource created
 * @property {string} identity - The unique string representing the User created
 *
 * @param {V2} version - Version of the resource
 * @param {WebChannelsPayload} payload - The instance payload
 */
/* jshint ignore:end */
WebChannelsInstance = function WebChannelsInstance(version, payload) {
  this._version = version;

  // Marshaled Properties
  this.conversationSid = payload.conversation_sid; // jshint ignore:line
  this.identity = payload.identity; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V2.WebChannelsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
WebChannelsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WebChannelsInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  WebChannelsList: WebChannelsList,
  WebChannelsPage: WebChannelsPage,
  WebChannelsInstance: WebChannelsInstance
};
