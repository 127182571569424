'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var DomainConfigList;
var DomainConfigPage;
var DomainConfigInstance;
var DomainConfigContext;

/* jshint ignore:start */
/**
 * Initialize the DomainConfigList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.DomainConfigList
 *
 * @param {Twilio.Messaging.V1} version - Version of the resource
 */
/* jshint ignore:end */
DomainConfigList = function DomainConfigList(version) {
  /* jshint ignore:start */
  /**
   * @function domainConfig
   * @memberof Twilio.Messaging.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Messaging.V1.DomainConfigContext}
   */
  /* jshint ignore:end */
  function DomainConfigListInstance(sid) {
    return DomainConfigListInstance.get(sid);
  }

  DomainConfigListInstance._version = version;
  // Path Solution
  DomainConfigListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a domain_config
   *
   * @function get
   * @memberof Twilio.Messaging.V1.DomainConfigList#
   *
   * @param {string} domainSid -
   *          Unique string used to identify the domain that this config should be associated with.
   *
   * @returns {Twilio.Messaging.V1.DomainConfigContext}
   */
  /* jshint ignore:end */
  DomainConfigListInstance.get = function get(domainSid) {
    return new DomainConfigContext(this._version, domainSid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Messaging.V1.DomainConfigList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  DomainConfigListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  DomainConfigListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return DomainConfigListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the DomainConfigPage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.DomainConfigPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {DomainConfigSolution} solution - Path solution
 *
 * @returns DomainConfigPage
 */
/* jshint ignore:end */
DomainConfigPage = function DomainConfigPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(DomainConfigPage.prototype, Page.prototype);
DomainConfigPage.prototype.constructor = DomainConfigPage;

/* jshint ignore:start */
/**
 * Build an instance of DomainConfigInstance
 *
 * @function getInstance
 * @memberof Twilio.Messaging.V1.DomainConfigPage#
 *
 * @param {DomainConfigPayload} payload - Payload response from the API
 *
 * @returns DomainConfigInstance
 */
/* jshint ignore:end */
DomainConfigPage.prototype.getInstance = function getInstance(payload) {
  return new DomainConfigInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.DomainConfigPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
DomainConfigPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

DomainConfigPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the DomainConfigContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.DomainConfigInstance
 *
 * @property {string} domainSid -
 *          The unique string that we created to identify the Domain resource.
 * @property {string} configSid -
 *          The unique string that we created to identify the Domain config (prefix ZK).
 * @property {string} messagingServiceSids -
 *          A list of messagingServiceSids (with prefix MG).
 * @property {string} fallbackUrl -
 *          We will redirect requests to urls we are unable to identify to this url.
 * @property {string} callbackUrl -
 *          URL to receive click events to your webhook whenever the recipients click on the shortened links.
 * @property {Date} dateCreated - Date this Domain Config was created.
 * @property {Date} dateUpdated - Date that this Domain Config was last updated.
 * @property {string} url - The url
 *
 * @param {V1} version - Version of the resource
 * @param {DomainConfigPayload} payload - The instance payload
 * @param {sid} domainSid -
 *          Unique string used to identify the domain that this config should be associated with.
 */
/* jshint ignore:end */
DomainConfigInstance = function DomainConfigInstance(version, payload,
                                                      domainSid) {
  this._version = version;

  // Marshaled Properties
  this.domainSid = payload.domain_sid; // jshint ignore:line
  this.configSid = payload.config_sid; // jshint ignore:line
  this.messagingServiceSids = payload.messaging_service_sids; // jshint ignore:line
  this.fallbackUrl = payload.fallback_url; // jshint ignore:line
  this.callbackUrl = payload.callback_url; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {domainSid: domainSid || this.domainSid, };
};

Object.defineProperty(DomainConfigInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new DomainConfigContext(this._version, this._solution.domainSid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * update a DomainConfigInstance
 *
 * @function update
 * @memberof Twilio.Messaging.V1.DomainConfigInstance#
 *
 * @param {object} opts - Options for request
 * @param {string|list} opts.messagingServiceSids -
 *          A list of messagingServiceSids (with prefix MG)
 * @param {string} [opts.fallbackUrl] -
 *          We will redirect requests to urls we are unable to identify to this url.
 * @param {string} [opts.callbackUrl] -
 *          URL to receive click events to your webhook whenever the recipients click on the shortened links
 * @param {string} [opts.messagingServiceSidsAction] -
 *          An action type for messaging_service_sids operation (ADD, DELETE, REPLACE)
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed DomainConfigInstance
 */
/* jshint ignore:end */
DomainConfigInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * fetch a DomainConfigInstance
 *
 * @function fetch
 * @memberof Twilio.Messaging.V1.DomainConfigInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed DomainConfigInstance
 */
/* jshint ignore:end */
DomainConfigInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.DomainConfigInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
DomainConfigInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

DomainConfigInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the DomainConfigContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.DomainConfigContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} domainSid -
 *          Unique string used to identify the domain that this config should be associated with.
 */
/* jshint ignore:end */
DomainConfigContext = function DomainConfigContext(version, domainSid) {
  this._version = version;

  // Path Solution
  this._solution = {domainSid: domainSid, };
  this._uri = `/LinkShortening/Domains/${domainSid}/Config`;
};

/* jshint ignore:start */
/**
 * update a DomainConfigInstance
 *
 * @function update
 * @memberof Twilio.Messaging.V1.DomainConfigContext#
 *
 * @param {object} opts - Options for request
 * @param {string|list} opts.messagingServiceSids -
 *          A list of messagingServiceSids (with prefix MG)
 * @param {string} [opts.fallbackUrl] -
 *          We will redirect requests to urls we are unable to identify to this url.
 * @param {string} [opts.callbackUrl] -
 *          URL to receive click events to your webhook whenever the recipients click on the shortened links
 * @param {string} [opts.messagingServiceSidsAction] -
 *          An action type for messaging_service_sids operation (ADD, DELETE, REPLACE)
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed DomainConfigInstance
 */
/* jshint ignore:end */
DomainConfigContext.prototype.update = function update(opts, callback) {
  if (_.isUndefined(opts)) {
    throw new Error('Required parameter "opts" missing.');
  }
  if (_.isUndefined(opts['messagingServiceSids'])) {
    throw new Error('Required parameter "opts[\'messagingServiceSids\']" missing.');
  }

  var deferred = Q.defer();
  var data = values.of({
    'MessagingServiceSids': serialize.map(_.get(opts, 'messagingServiceSids'), function(e) { return e; }),
    'FallbackUrl': _.get(opts, 'fallbackUrl'),
    'CallbackUrl': _.get(opts, 'callbackUrl'),
    'MessagingServiceSidsAction': _.get(opts, 'messagingServiceSidsAction')
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new DomainConfigInstance(this._version, payload, this._solution.domainSid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * fetch a DomainConfigInstance
 *
 * @function fetch
 * @memberof Twilio.Messaging.V1.DomainConfigContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed DomainConfigInstance
 */
/* jshint ignore:end */
DomainConfigContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new DomainConfigInstance(this._version, payload, this._solution.domainSid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.DomainConfigContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
DomainConfigContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

DomainConfigContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  DomainConfigList: DomainConfigList,
  DomainConfigPage: DomainConfigPage,
  DomainConfigInstance: DomainConfigInstance,
  DomainConfigContext: DomainConfigContext
};
